/* eslint-disable camelcase */
import React, { useEffect } from 'react';

import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';

import { Button, Form } from 'antd';

import { CustomTextarea } from '../../../components/CustomTextfield/CustomTextfield';
import CustomInputNumber from '../../../components/CustomInputNumber/CustomInputNumber';

import { setOnboardingVerifed } from '../../../data/store/account/accountActions';
import { decimalSeparator } from '../../../helpers/decimalSeparator';

const CategoryFinancialSituation = ({ userId, t, onboardingData }) => {
  const [form] = Form.useForm();
  const dispatch = useDispatch();

  useEffect(() => {
    if (onboardingData) {
      form.setFieldsValue(onboardingData?.result);
    }
  }, [onboardingData]);

  const employmentIncome = Form.useWatch(['fin_state', 'revenue_and_expenditure', 'employment_income'], form);
  const expenditure = Form.useWatch(['fin_state', 'revenue_and_expenditure', 'expenditure'], form);
  const otherIncome = Form.useWatch(['fin_state', 'revenue_and_expenditure', 'other_income'], form);

  useEffect(() => {
    if ((employmentIncome || employmentIncome === 0)) {
      const surplus = employmentIncome + (otherIncome || 0) - (expenditure || 0);
      surplus >= 0 && form.setFieldValue(['fin_state', 'revenue_and_expenditure', 'surplus'], surplus);
    }
  }, [employmentIncome, expenditure, otherIncome]);

  const handleSaveOnbording = () => {
    form.submit();
  };

  const onSubmit = (data) => {
    const result = { ...onboardingData.result, ...data };
    dispatch(setOnboardingVerifed({ userId, id: onboardingData.id, data: { result } }));
  };

  return (
    <div className="card-right-wrap">
      <div style={{ width: '327px', margin: '0 auto' }}>
        <h2 className="onbording-card-header complet-step">
          {t('REVENUE_AND_EXPENDITURE')}
        </h2>
      </div>
      <Form
        form={form}
        name="onboarding-form"
        layout="vertical"
        onFinish={onSubmit}
        className="onboarding-form-body" >
        <Form.Item
          className='custom-required'
          name={['fin_state', 'revenue_and_expenditure', 'employment_income']}
          label={t('INCOME_EMPLOYMENT')}>
          <CustomInputNumber
            placeholder='0,00'
            min={0}
            formatter={(value) => value.toString()?.replace('.', decimalSeparator()).replace(/\B(?=(\d{3})+(?!\d))/g, ' ') }
            parser={(value) => Number(value.replace(/ /g, '').replace(',', '.'))}
          />
        </Form.Item>
        <Form.Item
          className='custom-required'
          name={['fin_state', 'revenue_and_expenditure', 'other_income']}
          label={t('DO_YOU_HAVE_OTHER_INCOME')}>
          <CustomInputNumber
            placeholder='0,00'
            min={0}
            formatter={(value) => value.toString()?.replace('.', decimalSeparator()).replace(/\B(?=(\d{3})+(?!\d))/g, ' ') }
            parser={(value) => Number(value.replace(/ /g, '').replace(',', '.'))}
          />
        </Form.Item>
        <Form.Item
          className='custom-required'
          name={['fin_state', 'revenue_and_expenditure', 'expenditure']}
          label={t('EXPENDITURE_QUESTION')}>
          <CustomInputNumber
            placeholder='0,00'
            min={0}
            formatter={(value) => value.toString()?.replace('.', decimalSeparator()).replace(/\B(?=(\d{3})+(?!\d))/g, ' ') }
            parser={(value) => Number(value.replace(/ /g, '').replace(',', '.'))}
          />
        </Form.Item>
        <Form.Item
          className='custom-required'
          name={['fin_state', 'revenue_and_expenditure', 'surplus']}
          label={t('WHAT_YOUR_SURPLUS')}>
          <CustomInputNumber
            placeholder='0,00'
            min={0}
            formatter={(value) => value.toString()?.replace('.', decimalSeparator()).replace(/\B(?=(\d{3})+(?!\d))/g, ' ') }
            parser={(value) => Number(value.replace(/ /g, '').replace(',', '.'))}
            disabled/>
        </Form.Item>
        <Form.Item
          name={['fin_state', 'revenue_and_expenditure', 'commentary']}
          className="onboarding-textarea-purpose"
          label={t('COMMENT')}>
          <CustomTextarea rows={4} placeholder={t('YOUR_TEXT')} />
        </Form.Item>
        <div style={{ width: '327px', margin: '0 auto' }}>
          <h2 className="onbording-card-header complet-step">
            {t('FINANCIAL_ASSETS')}
          </h2>
        </div>
        <Form.Item
          className='custom-required'
          name={['fin_state', 'financial_assets', 'financial_investments']}
          label={t('YOUR_FINANCIAL_INVESTMENTS')}>
          <CustomInputNumber
            placeholder='0,00'
            formatter={(value) => value.toString()?.replace('.', decimalSeparator()).replace(/\B(?=(\d{3})+(?!\d))/g, ' ') }
            parser={(value) => Number(value.replace(/ /g, '').replace(',', '.'))}
            min={0}/>
        </Form.Item>
        <Form.Item
          className='custom-required'
          name={['fin_state', 'financial_assets', 'cash_equivalents']}
          label={t('CASH_EQUIVALENTS')}>
          <CustomInputNumber
            placeholder='0,00'
            formatter={(value) => value.toString()?.replace('.', decimalSeparator()).replace(/\B(?=(\d{3})+(?!\d))/g, ' ') }
            parser={(value) => Number(value.replace(/ /g, '').replace(',', '.'))}
            min={0}/>
        </Form.Item>
        <Form.Item
          className='custom-required'
          name={['fin_state', 'financial_assets', 'pension_savings']}
          label={t('PENSION_SAVINGS')}>
          <CustomInputNumber
            placeholder='0,00'
            formatter={(value) => value.toString()?.replace('.', decimalSeparator()).replace(/\B(?=(\d{3})+(?!\d))/g, ' ') }
            parser={(value) => Number(value.replace(/ /g, '').replace(',', '.'))}
            min={0}/>
        </Form.Item>
        <Form.Item
          className='custom-required'
          name={['fin_state', 'financial_assets', 'other_assets']}
          label={t('OTHER_ASSETS')}>
          <CustomInputNumber
            placeholder='0,00'
            formatter={(value) => value.toString()?.replace('.', decimalSeparator()).replace(/\B(?=(\d{3})+(?!\d))/g, ' ') }
            parser={(value) => Number(value.replace(/ /g, '').replace(',', '.'))}
            min={0}/>
        </Form.Item>
        <Form.Item
          name={['fin_state', 'financial_assets', 'commentary']}
          className="onboarding-textarea-purpose"
          label={t('COMMENT')}>
          <CustomTextarea rows={4} placeholder={t('YOUR_TEXT')} />
        </Form.Item>
        <div style={{ width: '327px', margin: '0 auto' }}>
          <h2 className="onbording-card-header complet-step">
            {t('FIXED_ASSETS')}
          </h2>
        </div>
        <Form.Item
          className='custom-required'
          name={['fin_state', 'fixed_assets', 'housing']}
          label={t('ONB_HOUSING')}>
          <CustomInputNumber
            placeholder='0,00'
            formatter={(value) => value.toString()?.replace('.', decimalSeparator()).replace(/\B(?=(\d{3})+(?!\d))/g, ' ') }
            parser={(value) => Number(value.replace(/ /g, '').replace(',', '.'))}
            min={0}/>
        </Form.Item>
        <Form.Item
          className='custom-required'
          name={['fin_state', 'fixed_assets', 'other_fixed_assets']}
          label={t('OTHER_FIXED_ASSETS')}>
          <CustomInputNumber
            placeholder='0,00'
            formatter={(value) => value.toString()?.replace('.', decimalSeparator()).replace(/\B(?=(\d{3})+(?!\d))/g, ' ') }
            parser={(value) => Number(value.replace(/ /g, '').replace(',', '.'))}
            min={0}/>
        </Form.Item>
        <Form.Item
          name={['fin_state', 'fixed_assets', 'commentary']}
          className="onboarding-textarea-purpose"
          label={t('COMMENT')}>
          <CustomTextarea rows={4} placeholder={t('YOUR_TEXT')} />
        </Form.Item>
        <div style={{ width: '327px', margin: '0 auto' }}>
          <h2 className="onbording-card-header complet-step">
            {t('SHOULDER')}
          </h2>
        </div>
        <Form.Item
          className='custom-required'
          name={['fin_state', 'shoulder', 'housing']}
          label={t('ONB_HOUSING_2')}>
          <CustomInputNumber
            placeholder='0,00'
            formatter={(value) => value.toString()?.replace('.', decimalSeparator()).replace(/\B(?=(\d{3})+(?!\d))/g, ' ') }
            parser={(value) => Number(value.replace(/ /g, '').replace(',', '.'))}
            min={0}/>
        </Form.Item>
        <Form.Item
          className='custom-required'
          name={['fin_state', 'shoulder', 'other_liabilities']}
          label={t('OTHER_LIABILITIES')}>
          <CustomInputNumber
            placeholder='0,00'
            formatter={(value) => value.toString()?.replace('.', decimalSeparator()).replace(/\B(?=(\d{3})+(?!\d))/g, ' ') }
            parser={(value) => Number(value.replace(/ /g, '').replace(',', '.'))}
            min={0} />
        </Form.Item>
        <Form.Item
          name={['fin_state', 'shoulder', 'commentary']}
          className="onboarding-textarea-purpose"
          label={t('COMMENT')}>
          <CustomTextarea rows={4} placeholder={t('YOUR_TEXT')} />
        </Form.Item>
        <div style={{ width: '327px', margin: '0 auto' }}>
          <h2 className="onbording-card-header complet-step">
            {t('SCOPE_INVESTABLE_CAPITAL')}
          </h2>
        </div>
        <Form.Item
          name={['fin_state', 'investable_capital', 'non_covered_elements']}
          className="onboarding-textarea-purpose"
          label={t('NON_COVERED_ELEMENTS')}>
          <CustomTextarea rows={4} placeholder={t('YOUR_ANSWER')} />
        </Form.Item>
        <Form.Item
          className='custom-required'
          name={['fin_state', 'investable_capital', 'unadvised_capital']}
          label={t('CAPITAL_NOT_COVERED_ADVICE')}>
          <CustomInputNumber
            placeholder='0,00'
            formatter={(value) => value.toString()?.replace('.', decimalSeparator()).replace(/\B(?=(\d{3})+(?!\d))/g, ' ') }
            parser={(value) => Number(value.replace(/ /g, '').replace(',', '.'))}
            min={0}/>
        </Form.Item>
        <Form.Item
          className='custom-required'
          name={['fin_state', 'investable_capital', 'investment_capital']}
          label={t('WHAT_YOUR_INVESTABLE_CAPITAL')}>
          <CustomInputNumber
            placeholder='0,00'
            formatter={(value) => value.toString()?.replace('.', decimalSeparator()).replace(/\B(?=(\d{3})+(?!\d))/g, ' ') }
            parser={(value) => Number(value.replace(/ /g, '').replace(',', '.'))}
            min={0}/>
        </Form.Item>

        <Button
          disabled={false}
          className="optimise-btn start-onbording-btn"
          onClick={handleSaveOnbording} >
          {t('SAVE')}
        </Button>
      </Form>
    </div>
  );
};

export default CategoryFinancialSituation;

CategoryFinancialSituation.propTypes = {
  userId: PropTypes.string,
  t: PropTypes.func,
  onboardingData: PropTypes.object,
  setOnboardingData: PropTypes.func,
};
