/* eslint-disable no-undef */
import { colorsCategoryOpt } from '../helpers/colors';

export const getPieDataOptCurrent = (data) => {
  if (!data) return {};
  let assetCategory = [];

  data?.forEach(el => {
    const name = el.financial_asset_category?.name;
    if (assetCategory.some(i => i?.name === name)) {
      assetCategory = assetCategory.map(i => i.name === name ? { ...i, y: i?.y + el.weight_fin } : i);
    } else if (name) {
      assetCategory.push(
        {
          name,
          y: el.weight_fin,
          color: colorsCategoryOpt[name] ? colorsCategoryOpt[name] : colorsCategoryOpt.other,
        }
      );
    }
  });
  const sortRule = {
    Avkastningstillgångar: 1,
    Riskspridare: 2,
    'Defensiva Tillgångar': 3
  };
  const categoryRound = assetCategory.map(i => ({ ...i, y: i.y, sort: sortRule[i.name] })).sort((a, b) => a.sort - b.sort);
  const pieData = { category: categoryRound };
  return pieData;
};
