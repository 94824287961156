/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from 'react';

import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { useLocation, useHistory } from 'react-router-dom';
import { v4 as uuidv4 } from 'uuid';

import { Button, Col, Divider, Form, Input, Layout, Row, Select, Table } from 'antd';
import Container from '../../components/Container';
import ModelEditableCellConstraints from './ModelEditableCellConstraints';

import { ReactComponent as Save } from '../../assets/save-check-icon.svg';
import { ReactComponent as Pluse } from '../../assets/add-icon.svg';
import { ReactComponent as Delete } from '../../assets/delete-black-icon.svg';
import { ReactComponent as Edit } from '../../assets/edit-icon.svg';
import { ReactComponent as Decrease } from '../../assets/decrease-icon.svg';
import { ReactComponent as Increase } from '../../assets/increase-icon.svg';

import { textSort } from '../../helpers/textSort';
import { useDispatch, useSelector } from 'react-redux';
import { createModelLabel, getModelConstraints, getModelLabel, setModelConstraints, updateModelLabel } from '../../data/store/instruments/instrumentsActions';
import ModelDeleteModalLabel from './ModelDeleteModalLabel';
import CustomSelect from '../../components/CustomSelect/CustomSelect';


export const EditableContext = React.createContext(null);

const EditableRow = ({ ...props }) => {
  const [form] = Form.useForm();
  return (
    <Form form={form} component={false}>
      <EditableContext.Provider value={form}>
        <tr {...props} />
      </EditableContext.Provider>
    </Form>
  );
};

const { Header } = Layout;
const ModelPortfolioConstraints = ({model}) => {
  const { t } = useTranslation('');
  const { pathname } = useLocation();
  const history = useHistory();
  const [form] = Form.useForm();
  const dispatch = useDispatch();

  const initValuesRatio = {
    asset_id: null,
    labels_id: null,
    weight: {
      denominator: 1,
      numerator: 1
    }
  };

  const initValuesSimple = {
    asset_id: null,
    weight: {
      denominator: 1,
      numerator: 1
    }
  };

  const assetClasses = useSelector((state) => state.instrumentsReducer.assets);
  const {labels, isLoading, constraints} = useSelector((state) => state.instrumentsReducer);

  const getAssetName = (id) => assetClasses?.find(i => i.ID === id)?.name ?? '';
  const getLabelAsset = (id) => labels?.find(i => i.id === +id)?.asset_classes;

  const [data, setData] = useState(null);
  const [tableColumns, setTableColumns] = useState([]);
  const [editingKey, setEditingKey] = useState('');
  const [deleteId, setDeleteId] = useState(null);

  const [bothOptions, setBothOptions] = useState(null);

  useEffect(() => {
    console.log('🚀 ~ useEffect ~ bothOptions:', bothOptions);
  }, [bothOptions]);
  

  useEffect(() => {
    const newArray = [];
    if(assetClasses) {
      labels?.sort((a,b) => textSort(a.name, b.name))?.map( i => {
        const data = {
          id: i.id + '-labels',
          name: i.name,
        };
      newArray.push(data);
      });
      assetClasses.sort((a,b) => textSort(a.name, b.name)).map( i => {
        const data = {
          id: i.ID + '-assetClasses',
          name: i.name,
        };
      newArray.push(data);
      });

    }
    setBothOptions(newArray);
  }, [assetClasses, labels]);
  

  useEffect(() => {
    model?.id && dispatch(getModelLabel(model.id));
    model?.id && dispatch(getModelConstraints(model.id));
  }, [model]);

  useEffect(() => {
    constraints && form.setFieldsValue(constraints);
  }, [constraints]);

  useEffect(() => {
    if(labels) {
      const newData = labels.map(i => ({key: uuidv4(), ...i}));
      setData(newData);
    }
  }, [labels]);
  

  useEffect(() => {
    const columns = [
      {
        title: t('LABEL_NAME'),
        dataIndex: 'name',
        width: '33%',
        editable: true,
        sorter: (a, b) => textSort(a.name, b.name),
      },
      {
        title: t('SUM'),
        dataIndex: 'asset_classes',
        width: '33%',
        editable: true,
        render: (value) => 
        <>
        {value?.map( (item, index) => {
          return (
          <span key={item} className=''> {getAssetName(item)}{index === value?.length - 1 ? '' : ', '}</span>
        );
        })
        }
        </>
      },
      {
        title: t('ACTIONS'),
        dataIndex: 'operation',
        width: '33%',
        render: (_, record) =>
          <>
            {editingKey === record.key
              ? (<Button
                icon={<Save/>}
                type="text"
                onClick={() => handleSubmitData(record.key)}
              />
                )
              : (
                <Button
                disabled={isLoading}
                icon={<Edit />}
                type="text"
                onClick={() => handleEdit(record.key)}
              />
                )
}
              <Button
                disabled={isLoading}
                icon={<Delete />}
                type="text"
                onClick={() => handleDeleteModal(record.key)}
              />
          </>
      },
    ];
    setTableColumns(columns);
  }, [editingKey, data, assetClasses, isLoading]);

  const isEditing = (record) => record.key === editingKey;

  const handleSubmitData = (key) => {
    const newData = data?.find(i => i.key === key);
    delete newData.key;
    if (newData?.name && newData?.asset_classes?.length) {
      if (!newData?.id) {
        dispatch(createModelLabel({...newData, asset_class_model_id: model.id }));
      } else {
        dispatch(updateModelLabel({...newData, asset_class_model_id: model.id }));
      }
      setEditingKey('');
    }
  };

  const handleSave = (row) => {
    const newData = data.map((item) => (row.key === item.key ? row : item));
    setData(newData);
  };

  const handleEdit = (key) => {
    setEditingKey(key);
  };

  const closeOpenModalDelete = () => {
    setDeleteId(null);
  };

  const handleDeleteModal = (id) => {
    const delData = data.find(item => id === item.key);
    if (delData?.id) {
      setDeleteId(delData.id);
    } else {
      const newData = data.filter(item => item.key !== id);
      setData(newData);
      setEditingKey('');
    }
  };

  const columns = tableColumns?.map((col) => {
    if (!col.editable) {
      return col;
    }
    return {
      ...col,
      onCell: (record) => ({
        record,
        dataIndex: col.dataIndex,
        title: col.title,
        handleSave,
        editing: isEditing(record),
        assetClasses
      }),
    };
  });

  const components = {
    body: {
      row: EditableRow,
      cell: ModelEditableCellConstraints,
    },
  };
  
  const handleAdd = () => {
    const newData = {
      key: uuidv4(),
      name: '',
      asset_classes: [],
    };

    data?.length ? setData([...data, newData]) : setData([newData]);
    setEditingKey(newData.key);
  };

  const handleDecrease = (field) => {
    const currentValue = form.getFieldValue(field);
    const newValue = Math.max(currentValue - 1, 1);
    form.setFieldValue(field, newValue);
  };
  
  const handleIncrease = (field) => {
    const currentValue = form.getFieldValue(field);
    const newValue = Math.min(currentValue + 1, 100);
    form.setFieldValue(field, newValue);
  };

  const handleCancel = () => {
    history.replace(`${pathname}?step=index-series`);
  };

  const createQuantConstraints = () => {
    const values = form.getFieldsValue();
    console.log('🚀 ~ createQuantConstraints ~ values:', values);

    const quant_constraints = [];
    const creareArray = (array) => {
      const assetTopIds = array.map(i => {
        const item = i.split('-');
        if(item[1] === 'assetClasses') {
          return +item[0];
        } else if(item[1] === 'labels') {
          const data = getLabelAsset(item[0]);
          return data;
        }
      });
      return assetTopIds.flat();
    };
    if(values?.ratio_constraints || values?.simple_constraints) {
      const {ratio_constraints, simple_constraints} = values;
      ratio_constraints?.map(el => {
        const assetTopIds = creareArray(el.asset_id);
        const assetBottomIds = creareArray(el.labels_id);
        
        const quantItem = {
          constraint_type: el.comparison === 2 ? 8 : 7,
          value: 0,
          poly_terms: []
        };
        assetTopIds.forEach(element => {
          const isPresent = quantItem.poly_terms.find(i => i?.asset_class_id === element);
          if(!isPresent) {
            quantItem.poly_terms.push({asset_class_id: element, coef: el.weight.denominator});
          } else {
            const newPolyTerms = quantItem.poly_terms.map(i => i.asset_class_id === element ? {...i, coef: i.coef + el.weight.denominator} : i);
            quantItem.poly_terms = newPolyTerms;
          }
        });
        assetBottomIds.forEach(element => {
          const isPresent = quantItem.poly_terms.find(i => i?.asset_class_id === element);
          if(!isPresent) {
            quantItem.poly_terms.push({asset_class_id: element, coef: -el.weight.numerator});
          } else {
            const newPolyTerms = quantItem.poly_terms.map(i => i.asset_class_id === element ? {...i, coef: i.coef - el.weight.numerator} : i);
            quantItem.poly_terms = newPolyTerms;
          }
        });
        quant_constraints.push(quantItem);
      });
      simple_constraints?.map(el => {
        const assetTopIds = creareArray(el.asset_id);
        
        const quantItem = {
          constraint_type: el.comparison === 2 ? 8 : 7,
          value: el.weight.numerator,
          poly_terms: []
        };
        assetTopIds.forEach(element => {
          const isPresent = quantItem.poly_terms.find(i => i?.asset_class_id === element);
          if(!isPresent) {
            quantItem.poly_terms.push({asset_class_id: element, coef: el.weight.denominator});
          } else {
            const newPolyTerms = quantItem.poly_terms.map(i => i.asset_class_id === element ? {...i, coef: i.coef + el.weight.denominator} : i);
            quantItem.poly_terms = newPolyTerms;
          }
        });
        quant_constraints.push(quantItem);
      });
        return quant_constraints;
    } else {
      return null;
    }
  };

  const callback = () => {
    history.replace('/quant/model/list');
  };
  
  const onSubmit = (data) => {
    const quant_constraints = createQuantConstraints();
    const newData = {...data, quant_constraints};
    dispatch(setModelConstraints({id: model.id, callback, data: newData}));
  };

  return (
    <>
      <Header className="headerStyle">
        <Row className="overview-tabs-wrapper">
          <Col className="tabs-tab-wrapper" span={18}>
            <Button type="link" className="overview-btn ant-btn-active">
              {t('NEW_MODEL')}
            </Button>
          </Col>
        </Row>
        <div className="top-btn-wrapper">
          <Button
            className="portfolio-details-btn portfolio-export-btn"
            onClick={handleCancel}
          >
            {t('BACK_TO_INDEX_SERIES')}
          </Button>
          <Button
            onClick={() => form.submit()}
            className="portfolio-details-btn btn-edit-save"
          >
            {t('CONTINUE')}
          </Button>
        </div>
      </Header>
      <Row justify={'space-between'}>
        <Col>
          <h2 style={{margin: '24px 0'}} className="out-container-title">{t('MODEL_PRIMITIVES')}</h2>
        </Col>
      </Row>
      <div style={{ padding: '0 24px' }} className="table-inner-wrapper">
        <Container widthP={100} mb="24px" padding="0">
          <Row
            justify={'space-between'}
            align={'middle'}
            style={{ marginBottom: '18px', padding: '16px 40px' }}
          >
            <Col>
              <h2 className="portfolio-header-title">
                {t('LABELS')}
              </h2>
            </Col>
          </Row>
          <Table
            components={components}
            rowClassName={(_, index) =>
              index % 2 === 0 ? 'editable-row' : 'editable-row is-odd'
            }
            bordered
            dataSource={data}
            columns={columns}
            pagination={false}
          />
          <Row style={{ padding: '0 24px 36px' }} justify={'end'}>
            <Button
              type="text"
              className="holdings-add-btn"
              onClick={handleAdd}
              icon={<Pluse />}
              iconPosition={'end'}
            >
              {t('ADD')}
            </Button>
          </Row>
        </Container>
        <ModelDeleteModalLabel
          openModalDelete={deleteId}
          closeOpenModalDelete={closeOpenModalDelete}
          labels={labels}
        />
      </div>
      <div style={{ padding: '0 24px' }} className="table-inner-wrapper">
        <Form
          layout="vertical"
          name='model-constraints-form'
          form={form}
          className="portfolio-constraints"
          onFinish={onSubmit}
        >
          <Container widthP={100} mb="24px" padding="0">
            <Row
              justify={'space-between'}
              align={'middle'}
              style={{ marginBottom: '18px', padding: '16px 40px' }}
            >
              <Col>
                <h2 className="portfolio-header-title">
                  {t('RATIO_CONSTRAINTS')}
                </h2>
              </Col>
            </Row>
            <Form.List name="ratio_constraints">
            {(fields, { add, remove }) => (
              <div className='ratio-constraints-wrapper'>
                {fields.map(({ key, name, ...restField }) => (
                  <div className='bias-row-wrapper' key={key}>
                  <Row align={'middle'} className="ratio-constraints-row" >
                    <Col>
                    <Form.Item
                      label={t('ASSET_CLASS_SETS')}
                      name={[name, 'asset_id']}
                      required={false}
                      className="custom-required ratio-constraints-asset"
                      rules={[
                        {
                          required: true,
                          message: t('SELECT_YOUR_OPTION'),
                        },
                      ]}
                    >
                      <CustomSelect
                        width='360px'
                        className="custom-select"
                        mode={'multiple'}
                        maxTagCount='responsive'
                        maxTagTextLength={12}
                      >
                        {bothOptions
                          ?.map(({ id, name }) => (
                          <Select.Option key={id} value={id}>
                            {id.includes('-labels') ? `${name} -- ${t('LABEL')} --` : name}
                          </Select.Option>
                          ))
                        }
                      </CustomSelect>
                    </Form.Item>
                    <Divider className='ratio-constraints-divider'/>
                    <Form.Item
                      label={t('ASSET_CLASS_SETS')}
                      name={[name, 'labels_id']}
                      required={false}
                      className="custom-required"
                      rules={[
                        {
                          required: true,
                          message: t('SELECT_YOUR_OPTION'),
                        },
                      ]}
                    >
                      <CustomSelect
                        width='360px'
                        className="custom-select"
                        mode={'multiple'}
                        maxTagCount='responsive'
                        maxTagTextLength={12}
                      >
                        {bothOptions
                          ?.map(({ id, name }) => (
                          <Select.Option key={id} value={id}>
                            {id.includes('-labels') ? `${name} -- ${t('LABEL')} --` : name}
                          </Select.Option>
                          ))
                        }
                      </CustomSelect>
                    </Form.Item>
                    </Col>
                    <Col>
                      <Form.Item
                        label={t('COMPARISON')}
                        name={[name, 'comparison']}
                        required={false}
                        className="custom-required"
                        rules={[
                          {
                            required: true,
                            message: t('SELECT_YOUR_OPTION'),
                          },
                        ]}
                      >
                        <CustomSelect
                          width='168px'
                          className="custom-select"
                        >
                          <Select.Option value={1}>
                            {'<'}
                          </Select.Option>
                          <Select.Option value={2}>
                            {'='}
                          </Select.Option>
                          <Select.Option value={3}>
                            {'>'}
                          </Select.Option>
                        </CustomSelect>
                      </Form.Item>
                    </Col>
                    <Col>
                      <div className='controll-constraints-item'>
                        <div className="controll-wrapper controll-constraints">
                          <div
                            className="controll-decrease"
                            onClick={() => handleDecrease(['ratio_constraints', name, 'weight', 'numerator'])}
                          >
                            <Decrease />
                          </div>
                        <Form.Item
                          label={t('WEIGHT')}
                          name={[name, 'weight', 'numerator']}
                          normalize={(value) => Number(value)}
                          className='weight-data custom-required'
                        >
                          <Input
                            min={1}
                            max={100}
                            className="control-min-value"
                          />
                        </Form.Item>
                          <div
                            className="controll-increase"
                            onClick={() => handleIncrease(['ratio_constraints', name, 'weight', 'numerator'])}
                          >
                            <Increase />
                          </div>
                        </div>
                      </div>
                      <Divider className='ratio-constraints-divider weight'/>
                      <div className='controll-constraints-item'>
                        <div className="controll-wrapper controll-constraints without-label">
                          <div
                            className="controll-decrease"
                            onClick={() => handleDecrease(['ratio_constraints', name, 'weight', 'denominator'])}
                          >
                            <Decrease />
                          </div>
                        <Form.Item
                          name={[name, 'weight', 'denominator']}
                          normalize={(value) => Number(value)}
                          className='weight-data'
                        >
                          <Input
                            min={1}
                            max={100}
                            className="control-min-value"
                          />
                        </Form.Item>
                          <div
                            className="controll-increase"
                            onClick={() => handleIncrease(['ratio_constraints', name, 'weight', 'denominator'])}
                          >
                            <Increase />
                          </div>
                        </div>
                      </div>
                    </Col>
                    <Col>
                      <div className='del-btn-wrapper'>
                        <Button
                          type='text'
                          style={{ marginTop: '10px', cursor: 'pointer' }}
                          icon={<Delete />}
                          onClick={() => {
                            remove(name);
                          }}
                        />
                      </div>
                    </Col>
                  </Row>
                  </div>
                ))}
                <Row className='add-btn-wrapper' justify={'start'}>
                  <Button
                    type='text'
                    className="holdings-add-btn"
                    onClick={() => add(initValuesRatio)}
                    icon={<Pluse />}
                    iconPosition={'end'}
                  >{t('ADD')}</Button>
                </Row>
              </div>
            )}
          </Form.List>

          </Container>
          <Container widthP={100} mb="24px" padding="0">
            <Row
              justify={'space-between'}
              align={'middle'}
              style={{ marginBottom: '18px', padding: '16px 40px' }}
            >
              <Col>
                <h2 className="portfolio-header-title">
                  {t('SIMPLE_CONSTRAINTS')}
                </h2>
              </Col>
            </Row>
            <Form.List name="simple_constraints">
            {(fields, { add, remove }) => (
              <div className='ratio-constraints-wrapper'>
                {fields.map(({ key, name, ...restField }) => (
                  <div className='bias-row-wrapper' key={key}>
                  <Row align={'bottom'} className="ratio-constraints-row" >
                    <Col>
                    <Form.Item
                      label={t('ASSET_CLASS_SETS')}
                      name={[name, 'asset_id']}
                      required={false}
                      className="custom-required ratio-constraints-asset"
                      rules={[
                        {
                          required: true,
                          message: t('SELECT_YOUR_OPTION'),
                        },
                      ]}
                    >
                      <CustomSelect
                        width='360px'
                        className="custom-select"
                        mode={'multiple'}
                        maxTagCount='responsive'
                        maxTagTextLength={12}
                      >
                        {bothOptions
                          ?.map(({ id, name }) => (
                          <Select.Option key={id} value={id}>
                            {id.includes('-labels') ? `${name} -- ${t('LABEL')} --` : name}
                          </Select.Option>
                          ))
                        }
                      </CustomSelect>
                    </Form.Item>
                    </Col>
                    <Col className='comparison-column'>
                      <Form.Item
                        label={t('COMPARISON')}
                        name={[name, 'comparison']}
                        required={false}
                        className="custom-required"
                        rules={[
                          {
                            required: true,
                            message: t('SELECT_YOUR_OPTION'),
                          },
                        ]}
                      >
                        <CustomSelect
                          width='168px'
                          className="custom-select"
                        >
                          <Select.Option value={1}>
                            {'<'}
                          </Select.Option>
                          <Select.Option value={2}>
                            {'='}
                          </Select.Option>
                          <Select.Option value={3}>
                            {'>'}
                          </Select.Option>
                        </CustomSelect>
                      </Form.Item>
                    </Col>
                    <Col>
                      <div className='controll-constraints-item'>
                        <div className="controll-wrapper controll-constraints">
                          <div
                            className="controll-decrease"
                            onClick={() => handleDecrease(['simple_constraints', name, 'weight', 'numerator'])}
                          >
                            <Decrease />
                          </div>
                        <Form.Item
                          label={t('WEIGHT')}
                          className="weight-data custom-required"
                          name={[name, 'weight', 'numerator']}
                          normalize={(value) => Number(value)}
                        >
                          <Input
                            min={1}
                            max={100}
                            className="control-min-value"
                          />
                        </Form.Item>
                        <div
                          className="controll-increase"
                          onClick={() => handleIncrease(['simple_constraints', name, 'weight', 'numerator'])}
                        >
                          <Increase />
                        </div>
                        </div>
                      </div>
                      <Divider className='ratio-constraints-divider weight'/>
                      <div className='controll-constraints-item'>
                        <div className="controll-wrapper controll-constraints without-label">
                          <div
                            className="controll-decrease"
                            onClick={() => handleDecrease(['simple_constraints', name, 'weight', 'denominator'])}
                          >
                            <Decrease />
                          </div>
                        <Form.Item
                          name={[name, 'weight', 'denominator']}
                          className="weight-data custom-required"
                          normalize={(value) => Number(value)}
                        >
                          <Input
                            min={1}
                            max={100}
                            className="control-min-value"
                          />
                        </Form.Item>
                          <div
                            className="controll-increase"
                            onClick={() => handleIncrease(['simple_constraints', name, 'weight', 'denominator'])}
                          >
                            <Increase />
                          </div>
                        </div>
                      </div>
                    </Col>
                    <Col>
                      <div className='del-btn-wrapper'>
                        <Button
                          type='text'
                          style={{ marginTop: '10px', cursor: 'pointer' }}
                          icon={<Delete />}
                          onClick={() => {
                            remove(name);
                          }}
                        />
                      </div>
                    </Col>
                  </Row>
                  </div>
                ))}
                <Row className='add-btn-wrapper' justify={'start'}>
                  <Button
                    type='text'
                    className="holdings-add-btn"
                    onClick={() => add(initValuesSimple)}
                    icon={<Pluse />}
                    iconPosition={'end'}
                  >{t('ADD')}</Button>
                </Row>
              </div>
            )}
          </Form.List>

          </Container>
        </Form>
      </div>
    </>
  );
};

export default ModelPortfolioConstraints;

ModelPortfolioConstraints.propTypes = {
  model: PropTypes.object,
};