import React, { useEffect } from 'react';
import { useLocation } from 'react-router';

import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { confirmAuth } from '../../../data/store/user/userActions';

import pic from '../../../assets/login-pic.png';
import titleLogo from '../../../assets/lt_logo.png';
import titleLogo_dark from '../../../assets/lt_logo_dark.png';
import { ReactComponent as BlackDots } from '../../../assets/black-dots.svg';
import { ReactComponent as Success } from '../../../assets/done-icon.svg';
import { ReactComponent as Arrow } from '../../../assets/arrow-up-right.svg';

import './style.scss';
import { LOGIN } from '../../../constants/routes';
import { Divider } from 'antd';
import PropTypes from 'prop-types';

const SignUpConfirmSuccess = ({theme}) => {
  const history = useHistory();
  const dispatch = useDispatch();
  const { t } = useTranslation('');

  const { search } = useLocation();
  const params = new URLSearchParams(search);

  useEffect(() => {
    if (params.get('code')) {
      dispatch(confirmAuth(params.get('code')));
    }
  }, []);

  // const goClientPage = useCallback(() => {
  //   history.push(LOGIN);
  // }, [history, dispatch]);

  return (
    <div className="login-wrapper">
      <div className="login-picture">
        <img src={pic} alt="login-picture" />
        <BlackDots className='login-black-dots' />
      </div>
      <div className="login-form">
        <div className="login-form-title">
        <img src={theme !== 'dark' ? titleLogo : titleLogo_dark} alt="title logo" />
        </div>
        <div className='confirm-success-wrapper'>
          <h2 className='confirm-success-title'>{t('YOU_ARE_DONE')}</h2>
          <div>
            <Success style={{ display: 'block', margin: '0 auto' }}/>
          </div>
          <p className='confirm-success-text'>{t('THANKS_FOR_APPLYING')}</p>
          <Divider className='login-divider'/>
          <a onClick={() => history.push(LOGIN)}>
          <p className="confirm-success-link">
            {t('GO_TO_HOME_PAGE')}
            <Arrow />
          </p>
          </a>
        </div>
        </div>
        </div>
  );
};

export default SignUpConfirmSuccess;

SignUpConfirmSuccess.propTypes = {
  theme: PropTypes.string,
};
