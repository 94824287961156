/* eslint-disable no-unused-vars */
import React, { useEffect, useMemo, useState } from 'react';

import PropTypes from 'prop-types';
import { v4 as uuidv4 } from 'uuid';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useHistory } from 'react-router-dom';

import Container from '../../components/Container';
import CustomSelect from '../../components/CustomSelect/CustomSelect';
import { Button, Form, Row, Select, Table } from 'antd';

import { ReactComponent as Pluse } from '../../assets/add-icon.svg';
import { ReactComponent as Delete } from '../../assets/delete-black-icon.svg';
import { ReactComponent as Save } from '../../assets/save-check-icon.svg';
import { textSort } from '../../helpers/textSort';
import { useQuery } from '../../hooks/useQuery';
import { getCurrency, getDiversification, getInstrumentsAll } from '../../data/store/instruments/instrumentsActions';
import CustomCheckbox from '../../components/CustomCheckbox/CustomCheckbox';
import EditableCell from './EditableCell';

export const EditableContext = React.createContext(null);

const EditableRow = ({ ...props }) => {
  const [form] = Form.useForm();
  return (
    <Form form={form} component={false}>
      <EditableContext.Provider value={form}>
        <tr {...props} />
      </EditableContext.Provider>
    </Form>
  );
};

const ApproveInstruments = ({ selectedAssetClass, setSelectedAssetClass, assetList, editingKey, setEditingKey }) => {
  const { t } = useTranslation('');
  const history = useHistory();
  const dispatch = useDispatch();
  const { search, pathname } = useLocation();
  const query = useQuery(search);
    
  const assetId = query.get('assetId');
  
  const { assets, currencyList, diversificationList } = useSelector((state) => state.instrumentsReducer);
  const instruments = useSelector((state) => state.instrumentsReducer.withoutCashes.list);
  
  const [options, setOptions] = useState([]);
  const [selectedOptions, setSelectedOptions] = useState(null);
  const [tableColumns, setTableColumns] = useState([]);
  const [data, setData] = useState(null);
  const [optionForAdd, setOptionForAdd] = useState([]);
  
  
  const getAssetClass = (id) => assets?.find((i) => i.ID === id);
  const getAsset = (id) => instruments?.find((i) => i.ID === id);
  const getCurrencyName = (id) => {
    const asset = instruments?.find((i) => i.ID === id);
    return currencyList?.find((i) => i.id === asset?.currency_id)?.name;
  };
  const getDiversificationName = (id) => {
    const asset = instruments?.find((i) => i.ID === id);
    return diversificationList?.find((i) => i.id === asset?.diversification_id)?.short_name;
  };
  const presrntAssets = useMemo(() => {
    return selectedAssetClass?.map(i => i.asset_id) ?? [];
  }, [selectedAssetClass]);
  
  const createOption = () => {
    const newOpt = [];
    assetList?.filter(i => i.selected)?.forEach((i) => {
      newOpt.indexOf(i.asset_class_id) === -1 && newOpt.push(i.asset_class_id);
    });
    console.log('🚀 ~ createOption ~ newOpt:', newOpt?.length);
    setOptions(newOpt);
  };
  
  useEffect(() => {
    console.log('selectedOptions', selectedOptions);
    
  }, [selectedOptions]);

  useEffect(() => {
    if (assetId) {
      setSelectedOptions(+assetId);
    } else if(options?.length){
      const firstOption = options.slice().sort( (a,b) => a-b)?.at(0);
      history.push(`${pathname}?tab=approve-instruments&assetId=${firstOption}`);
    }
  }, [assetId, options]);

  useEffect(() => {
    if (assetList) {
      createOption();
    }
  }, [assetList]);

  useEffect(() => {
    if (selectedOptions) {
      const data = selectedAssetClass.filter(i => i.asset_class_id === +assetId);
      setData(data);
      const option = instruments.filter(i => i.financialAssetID === +assetId);
      setOptionForAdd(option);
    }
  }, [selectedOptions, selectedAssetClass]);

  const handleCheck = (id) => {
    const isOne = selectedAssetClass.filter(i => i.asset_class_id === +assetId && i.is_recomended);
    const target = selectedAssetClass.find(i => i.asset_id === id);
    if(target.is_recomended && (!isOne?.length || isOne?.length < 2)) return;
    const data = selectedAssetClass.map(i => {
      if(i.asset_id === id) {
        return {...i, is_recomended: !i.is_recomended};
      } else {
        return i;
      }
    });
    setSelectedAssetClass(data);
  };

  const handleSubmitData = () => {
    setEditingKey(null);
  };

  useEffect(() => {
    const columns = [
      {
        title: t('NAME'),
        dataIndex: 'asset_id',
        className: 'name',
        width: '20%',
        editable: true,
        render: (value, record) => (getAsset(record.asset_id)?.name),
        sorter: (a, b) => textSort(getAsset(a.asset_id)?.name, getAsset(b.asset_id)?.name),
      },
      {
        title: t('ISIN'),
        dataIndex: 'isin',
        width: '10%',
        render: (value, record) => (getAsset(record.asset_id)?.isin ?? 'N/A'),
        sorter: (a, b) => textSort(getAsset(a.asset_id)?.isin, getAsset(b.asset_id)?.isin),
      },
      {
        title: t('TICKER'),
        dataIndex: 'ticker',
        width: '10%',
        className: 'table-header-position-center',
        align: 'center',
        render: (value, record) => (getAsset(record.asset_id)?.ticker ?? '-'),
        sorter: (a, b) => textSort(getAsset(a.asset_id)?.ticker, getAsset(b.asset_id)?.ticker),
      },
      {
        title: t('FEE'),
        dataIndex: 'fee',
        width: '10%',
        className: 'fee table-header-position-center',
        align: 'center',
        render: (value, record) => <span>{(getAsset(record.asset_id)?.fee)?.toFixed(2) ?? '-'} %</span>,
        sorter: (a, b) => getAsset(a.asset_id)?.fee - getAsset(b.asset_id)?.fee,
      },
      {
        title: t('CURRENCY'),
        dataIndex: 'currency',
        width: '10%',
        render: (value, record) => (getCurrencyName(record.asset_id) ?? '-'),
        sorter: (a, b) => textSort(getCurrencyName(a.asset_id), getCurrencyName(b.asset_id)),
      },
      {
        title: t('DIVERSIFICATION'),
        dataIndex: 'diversification',
        className: 'diversification',
        align: 'center',
        width: '10%',
        render: (_, record) => {
          return <span className={`diver-block 
                ${getDiversificationName(record.asset_id) === 'None'
                  ? 'none'
                  : getDiversificationName(record.asset_id) === 'Mid'
                    ? 'medi'
                    : getDiversificationName(record.asset_id) === 'High'
                      ? 'hight'
                      : ''}`
                }>
                  {getDiversificationName(record.asset_id) ?? 'N/A'}
                </span>;
        },
        sorter: (a, b) => textSort(getDiversificationName(a.asset_id), getDiversificationName(b.asset_id)),
      },
      {
        title: t('RECOMMENDED'),
        dataIndex: 'is_recomended',
        width: '10%',
        align: 'center',
        render: (value, record) => {
          return <CustomCheckbox checked={value} onClick={() => handleCheck(record.asset_id)}/>;
        },
        sorter: (a, b) => textSort(a.is_recomended, b.is_recomended),
      },
      {
        title: t('ACTIONS'),
        dataIndex: 'operation',
        width: '33%',
        render: (_, record) => (
          <>
          {(editingKey === record.key || editingKey === record.id) &&
            (<Button
              icon={<Save/>}
              type="text"
              onClick={() => handleSubmitData(record.key)}/>
            )
          }
            <Button
              icon={<Delete />}
              type="text"
              onClick={() => handleDeleteModal(record.asset_id)}
            />
          </>
        ),
      },
    ];
    setTableColumns(columns);
  }, [selectedAssetClass, currencyList, diversificationList, instruments, editingKey]);

  const isEditing = (record) => record?.key === editingKey || record?.id === editingKey;

  const handleSaveAsset = (data) => {
    console.log('🚀 ~ handleSaveAsset ~ data:', data);
    const key = data?.id || data?.key;
    console.log('🚀 ~ handleSaveAsset ~ key:', key);
    console.log('🚀 ~ selectedAssetClass:', selectedAssetClass);
    const newData = selectedAssetClass.map(i => (i?.id === key || i?.key === key) ? data : i);
      console.log('🚀 ~ handleSaveAsset ~ newData:', newData);
      setSelectedAssetClass(newData);
  };

  const columns = tableColumns?.map((col) => {
    if (!col.editable) {
      return col;
    }
    return {
      ...col,
      onCell: (record) => ({
        record,
        dataIndex: col.dataIndex,
        title: col.title,
        handleSaveAsset,
        editing: isEditing(record),
        optionForAdd,
        presrntAssets
      }),
    };
  });

  const components = {
    body: {
      row: EditableRow,
      cell: EditableCell,
    },
  };

  const handleChangeSelect = (id) => {
    history.push(`${pathname}?tab=approve-instruments&assetId=${id}`);
  };

  const handleAdd = () => {
    console.log('🚀 ~ handleAdd ~ selectedAssetClass:', selectedAssetClass);
    if(data.length < 2) {
      const newData = selectedAssetClass.map(i => i.asset_class_id === +assetId ? {...i, key: uuidv4(),asset_id: '', is_recomended: true} : i);
      setSelectedAssetClass(newData);
      setEditingKey(data[0]?.id || data[0].key);
    } else {
      const newData = [...selectedAssetClass];
      const blank = {key: uuidv4(), asset_id: '', asset_class_id: +assetId, is_recomended: false};
      newData.push(blank);
      setSelectedAssetClass(newData);
      setEditingKey(blank?.key);
    }
  };

  const handleDeleteModal = (id) => {
    const isOne = selectedAssetClass.filter(i => i.asset_class_id === +assetId && i.is_recomended);
    const target = selectedAssetClass.find(i => i.asset_id === id);
    if(isOne && target.is_recomended) {
      console.log('Cann`t Delete');
    } else {
      console.log('Delete');
      const data = selectedAssetClass.filter(i => i.asset_id !== id );
      setSelectedAssetClass(data);
    }
  };

  return (
    <Container widthP={100} mb="28px" padding="24px 0 24px 0">
      <div className='custom-select-wrapper'>
        <CustomSelect
          disabled={editingKey}
          onChange={handleChangeSelect}
          className="custom-select"
          placeholder={t('SELECT_OPTION')}
          value={selectedOptions}
        >
          {options?.map((i) => (
            <Select.Option key={i} value={i}>
              {getAssetClass(i)?.name}
            </Select.Option>
          ))}
        </CustomSelect>
      </div>
      <Table
        rowKey={(record) => record.asset_id}
        rowClassName={(_, index) =>
          index % 2 === 0 ? 'editable-row' : 'editable-row is-odd'
        }
        bordered
        components={components}
        dataSource={data?.filter(i=> i.asset_id !== null)}
        columns={columns}
        pagination={false}
      />
      <Row style={{ padding: '0 24px 36px' }} justify={'end'}>
        <Button
          disabled={editingKey}
          type="text"
          className="holdings-add-btn"
          onClick={handleAdd}
          icon={<Pluse />}
          iconPosition={'end'}
        >
          {t('ADD')}
        </Button>
      </Row>
    </Container>
  );
};

export default ApproveInstruments;

ApproveInstruments.propTypes = {
  onSubmit: PropTypes.func,
  selectedAssetClass: PropTypes.array,
  setSelectedAssetClass: PropTypes.func,
  assetList: PropTypes.array,
  setAssetList: PropTypes.func,
  editingKey: PropTypes.number,
  setEditingKey: PropTypes.func,
};
