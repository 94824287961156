import { put, select } from '@redux-saga/core/effects';
import {
  ASSET_REQUEST,
  CREATE_ASSET_CLASS,
  CREATE_INSTRUMENT,
  CREATE_MODEL,
  CREATE_MODEL_LABEL,
  CREATE_RECOMMENDATION_MAPS,
  DELETE_ASSET_CLASS,
  DELETE_INSTRUMENT,
  DELETE_MODEL_LABEL,
  DELETE_NOTACTIVE_MODEL,
  DELETE_RECOMMENDATION_MAPS,
  DELETE_REQUEST,
  GET_ASSET_CATEGORIES,
  GET_ASSETS,
  GET_CURRENCY,
  GET_DIVERSIFICATION,
  GET_INSTRUMENTS_ALL,
  GET_INSTRUMENTS_TBD,
  GET_MODEL_BY_ID,
  GET_MODEL_CONSTRAINTS,
  GET_MODEL_LABEL,
  GET_MODEL_LIST,
  GET_MODEL_PRIMITIVES,
  GET_MODEL_SERIES,
  GET_RECOMMENDATION_MAP_BY_ID,
  GET_RECOMMENDATION_MAPS,
  MAPPING_REQUEST,
  SET_ACTIVE_MODEL,
  SET_MODEL_CONSTRAINTS,
  SET_MODEL_DATE,
  SET_MODEL_PRIMITIVES,
  SET_MODEL_SERIES,
  TEMP_DOWNLOAD_CONSTRAINTS,
  TEMP_DOWNLOAD_PRIMITIVES,
  UPDATE_ASSET_CLASS,
  UPDATE_FINANCIAL_ASSET,
  UPDATE_INSTRUMENT,
  UPDATE_MODEL_LABEL,
  UPDATE_RECOMMENDATION_MAPS,
  UPLOAD_MODEL_PRIMITIVES,
  UPLOAD_MODEL_SERIES,
} from './instrumentsActionTypes';
import { InstrumentsService } from '../../../services/InstrumentsService';

export function * getInstrumentsAll (action) {
  const { type = '' } = action.payload;
  try {
    yield put({ type: GET_INSTRUMENTS_ALL.REQUEST });
    const response = yield InstrumentsService.getAllInstruments(action.payload);
    if (response.status === 200) {
      if (type) {
        response.data.type = type;
      }
      yield put({ type: GET_INSTRUMENTS_ALL.SUCCESS, payload: response.data });
    } else {
      yield put({
        type: GET_INSTRUMENTS_ALL.ERROR,
        payload: response.response.data.message,
      });
    }
  } catch (e) {
    yield put({
      type: GET_INSTRUMENTS_ALL.ERROR,
      payload: e.response.data.message,
    });
  }
}

export function * getInstrumentsTBDAll () {
  try {
    yield put({ type: GET_INSTRUMENTS_TBD.REQUEST });

    const {
      instrumentsReducer: { instrumentsTBDPagination },
    } = yield select();

    const response = yield InstrumentsService.getInstrumentsTBD(
      instrumentsTBDPagination,
    );
    if (response.status === 200) {
      yield put({ type: GET_INSTRUMENTS_TBD.SUCCESS, payload: response.data });
    } else {
      yield put({
        type: GET_INSTRUMENTS_TBD.ERROR,
        payload: response.response.data.message,
      });
    }
  } catch (e) {
    yield put({
      type: GET_INSTRUMENTS_TBD.ERROR,
      payload: e.response.data.message,
    });
  }
}

export function * getAssets () {
  try {
    yield put({ type: GET_ASSETS.REQUEST });
    const response = yield InstrumentsService.getAssetsAll();
    if (response.status === 200) {
      yield put({ type: GET_ASSETS.SUCCESS, payload: response.data });
    } else {
      yield put({
        type: GET_ASSETS.ERROR,
        payload: response.response.data.message,
      });
    }
  } catch (e) {
    yield put({ type: GET_ASSETS.ERROR, payload: e.response.data.message });
  }
}

export function * createAssetClass (action) {
  try {
    yield put({ type: CREATE_ASSET_CLASS.REQUEST });
    const response = yield InstrumentsService.createAssetClass(action.payload);
    if (response.status === 200) {
      yield put({ type: CREATE_ASSET_CLASS.SUCCESS, payload: response.data.asset });
    } else {
      yield put({
        type: CREATE_ASSET_CLASS.ERROR,
        payload: response?.response?.data.message,
      });
    }
  } catch (e) {
    yield put({ type: CREATE_ASSET_CLASS.ERROR, payload: e?.response?.data?.message });
  }
}

export function * deleteAssetClass (action) {
  try {
    yield put({ type: DELETE_ASSET_CLASS.REQUEST });
    const response = yield InstrumentsService.deleteAssetClass(action.payload);
    if (response.status === 200) {
      yield put({ type: DELETE_ASSET_CLASS.SUCCESS, payload: action.payload });
    } else {
      yield put({
        type: DELETE_ASSET_CLASS.ERROR,
        payload: response?.response?.data.message,
      });
    }
  } catch (e) {
    yield put({ type: CREATE_ASSET_CLASS.ERROR, payload: e?.response?.data?.message });
  }
}

export function * updateAssetClass (action) {
  try {
    yield put({ type: UPDATE_ASSET_CLASS.REQUEST });
    const response = yield InstrumentsService.updateAssetClass(action.payload);
    if (response.status === 200) {
      yield put({ type: UPDATE_ASSET_CLASS.SUCCESS, payload: action.payload });
    } else {
      yield put({
        type: UPDATE_ASSET_CLASS.ERROR,
        payload: response?.response?.data.message,
      });
    }
  } catch (e) {
    yield put({ type: CREATE_ASSET_CLASS.ERROR, payload: e?.response?.data?.message });
  }
}

export function * updateFinancialAssets (action) {
  try {
    yield put({ type: UPDATE_FINANCIAL_ASSET.REQUEST });
    const response = yield InstrumentsService.updateAsset(action.payload);
    if (response.status === 200) {
      yield put({ type: UPDATE_FINANCIAL_ASSET.SUCCESS });
    } else {
      yield put({
        type: UPDATE_FINANCIAL_ASSET.ERROR,
        payload: response.response.data.message,
      });
    }
  } catch (e) {
    yield put({
      type: UPDATE_FINANCIAL_ASSET.ERROR,
      payload: e.response.data.message,
    });
  }
}

export function * updateInstrument (action) {
  const { id, data, history, path } = action.payload;
  try {
    yield put({ type: UPDATE_INSTRUMENT.REQUEST });
    const response = yield InstrumentsService.instrumentUpdate({ id, data });
    if (response.status === 200) {
      yield put({
        type: UPDATE_INSTRUMENT.SUCCESS,
        payload: response.data.instrument,
      });
      yield put({ type: GET_INSTRUMENTS_ALL.ACTION, payload: { limit: -1, offset: 0 } });
      if (path) {
        history.push(path);
      } else {
        history.push('/quant/asset/list');
      }
    } else {
      yield put({
        type: UPDATE_INSTRUMENT.ERROR,
        payload: response.response.data.message,
      });
    }
  } catch (e) {
    yield put({
      type: UPDATE_INSTRUMENT.ERROR,
      payload: e.response.data.message,
    });
  }
}

export function * deleteInstrument (action) {
  try {
    yield put({ type: DELETE_INSTRUMENT.REQUEST });
    const response = yield InstrumentsService.delete(action.payload);
    if (response.status === 200) {
      yield put({ type: DELETE_INSTRUMENT.SUCCESS });
      yield put({
        type: GET_INSTRUMENTS_ALL.ACTION,
        payload: { limit: -1, offset: 0 },
      });
    } else {
      yield put({
        type: DELETE_INSTRUMENT.ERROR,
        payload: response.response?.data?.message,
      });
    }
  } catch (e) {
    yield put({
      type: DELETE_INSTRUMENT.ERROR,
      payload: e.response?.data?.message,
    });
  }
}

export function * getAssetCategories () {
  try {
    yield put({ type: GET_ASSET_CATEGORIES.REQUEST });
    const response = yield InstrumentsService.getCategoriesAll();
    if (response.status === 200) {
      yield put({ type: GET_ASSET_CATEGORIES.SUCCESS, payload: response.data });
    } else {
      yield put({
        type: GET_ASSET_CATEGORIES.ERROR,
        payload: response.response.data.message,
      });
    }
  } catch (e) {
    yield put({
      type: GET_ASSET_CATEGORIES.ERROR,
      payload: e.response.data.message,
    });
  }
}

export function * createInstrument (action) {
  const { data, history, path } = action.payload;
  try {
    yield put({ type: CREATE_INSTRUMENT.REQUEST });
    const response = yield InstrumentsService.create(data);
    if (response.status === 200) {
      yield put({ type: CREATE_INSTRUMENT.SUCCESS });
      yield put({ type: GET_INSTRUMENTS_ALL.ACTION, payload: { limit: -1, offset: 0 } });
      if (path) {
        history.push(path);
      } else {
        history.push('/quant/asset/list');
      }
    } else {
      yield put({
        type: CREATE_INSTRUMENT.ERROR,
        payload: response?.response?.data.message,
      });
    }
  } catch (e) {
    yield put({
      type: CREATE_INSTRUMENT.ERROR,
      payload: e.response?.data?.message,
    });
  }
}

export function * assetRequest (action) {
  try {
    yield put({ type: ASSET_REQUEST.REQUEST });
    const { newData, portfolioContentID, id, history, path } = action.payload;
    const newInstrument = yield InstrumentsService.create({
      instrument: { ...newData },
    });
    if (newInstrument.status === 200) {
      const response = yield InstrumentsService.requestAsset({
        id,
        portfolioContentID,
        instrumentID: newInstrument.data.instrument.ID,
      });
      if (response.status === 200) {
        yield put({ type: ASSET_REQUEST.SUCCESS });
        yield put({ type: GET_INSTRUMENTS_ALL.ACTION, payload: { limit: -1, offset: 0 } });
        const {
          instrumentsReducer: { instrumentsTBDPagination },
        } = yield select();
        yield put({
          type: GET_INSTRUMENTS_TBD.ACTION,
          payload: instrumentsTBDPagination,
        });
        if (path) {
          history.push(path);
        } else {
          history.push('/quant/asset/tbd');
        }
      } else {
        yield put({
          type: ASSET_REQUEST.ERROR,
          payload: response.response.data.message,
        });
      }
    } else {
      yield put({
        type: ASSET_REQUEST.ERROR,
        payload: newInstrument.response.data.message,
      });
    }
  } catch (e) {
    yield put({ type: ASSET_REQUEST.ERROR, payload: e.response.data.message });
  }
}

export function * mappingRequest (action) {
  try {
    yield put({ type: MAPPING_REQUEST.REQUEST });
    const { instrumentID, portfolioContentID, id, callback } = action.payload;
    const response = yield InstrumentsService.requestAsset({
      id,
      portfolioContentID,
      instrumentID,
    });
    if (response.status === 200) {
      yield put({ type: MAPPING_REQUEST.SUCCESS });
      callback();
      const {
        instrumentsReducer: { instrumentsTBDPagination },
      } = yield select();
      yield put({
        type: GET_INSTRUMENTS_TBD.ACTION,
        payload: instrumentsTBDPagination,
      });
    } else {
      yield put({
        type: ASSET_REQUEST.ERROR,
        payload: response.response.data.message,
      });
      yield put({
        type: MAPPING_REQUEST.ERROR,
        payload: response.response.data,
      });
    }
  } catch (e) {
    yield put({ type: ASSET_REQUEST.ERROR, payload: e.response.data.message });
  }
}

export function * deleteRequest (action) {
  try {
    const response = yield InstrumentsService.deleteRequest(action.payload);
    const {
      instrumentsReducer: { instrumentsTBDPagination },
    } = yield select();
    if (response.status === 200) {
      yield put({ type: DELETE_REQUEST.SUCCESS });
      yield put({
        type: GET_INSTRUMENTS_TBD.ACTION,
        payload: instrumentsTBDPagination,
      });
    } else {
      yield put({
        type: DELETE_REQUEST.ERROR,
        payload: response.response?.data?.message,
      });
      yield put({
        type: GET_INSTRUMENTS_TBD.ACTION,
        payload: instrumentsTBDPagination,
      });
    }
  } catch (e) {
    yield put({
      type: DELETE_REQUEST.ERROR,
      payload: e.response?.data?.message,
    });
  }
}

export function * getCurrency () {
  try {
    yield put({ type: GET_CURRENCY.REQUEST });
    const response = yield InstrumentsService.getCurrency();
    if (response.status === 200) {
      yield put({ type: GET_CURRENCY.SUCCESS, payload: response.data });
    } else {
      yield put({
        type: GET_CURRENCY.ERROR,
        payload: response.response?.data?.message,
      });
    }
  } catch (e) {
    yield put({ type: GET_CURRENCY.ERROR, payload: e.response?.data?.message });
  }
}

export function * getDiversification () {
  try {
    yield put({ type: GET_DIVERSIFICATION.REQUEST });
    const response = yield InstrumentsService.getDiversification();
    if (response.status === 200) {
      yield put({ type: GET_DIVERSIFICATION.SUCCESS, payload: response.data });
    } else {
      yield put({
        type: GET_DIVERSIFICATION.ERROR,
        payload: response.response?.data?.message,
      });
    }
  } catch (e) {
    yield put({
      type: GET_DIVERSIFICATION.ERROR,
      payload: e.response?.data?.message,
    });
  }
}

export function * createModel (action) {
  const {history, callback, data} = action.payload;
  try {
    yield put({ type: CREATE_MODEL.REQUEST });
    const response = yield InstrumentsService.createModel(data);
    if (response.status === 200) {
      yield put({ type: CREATE_MODEL.SUCCESS, payload: response.data });
      callback();
      history.replace(`/quant/model/item/${response?.data?.id}?step=settings`);
    } else {
      yield put({
        type: CREATE_MODEL.ERROR,
        payload: response.response?.data?.message,
      });
    }
  } catch (e) {
    yield put({
      type: CREATE_MODEL.ERROR,
      payload: e.response?.data?.message,
    });
  }
}

export function * getModelById (action) {
  try {
    yield put({ type: GET_MODEL_BY_ID.REQUEST });
    const response = yield InstrumentsService.getModelById(action.payload);
    if (response.status === 200) {
      yield put({ type: GET_MODEL_BY_ID.SUCCESS, payload: response.data });
    } else {
      yield put({
        type: GET_MODEL_BY_ID.ERROR,
        payload: response.response?.data?.message,
      });
    }
  } catch (e) {
    yield put({
      type: GET_MODEL_BY_ID.ERROR,
      payload: e.response?.data?.message,
    });
  }
}

export function * getModelList () {
  try {
    yield put({ type: GET_MODEL_LIST.REQUEST });
    const response = yield InstrumentsService.getModelList();
    if (response.status === 200) {
      yield put({ type: GET_MODEL_LIST.SUCCESS, payload: response.data });
    } else {
      yield put({
        type: GET_MODEL_LIST.ERROR,
        payload: response.response?.data?.message,
      });
    }
  } catch (e) {
    yield put({
      type: GET_MODEL_LIST.ERROR,
      payload: e.response?.data?.message,
    });
  }
}
//TODO delete this
export function * setModelDate (action) {
  try {
    yield put({ type: SET_MODEL_DATE.REQUEST });
    const response = yield InstrumentsService.setModelDate(action.payload);
    if (response.status === 200) {
      yield put({ type: SET_MODEL_DATE.SUCCESS, payload: response.data });
    } else {
      yield put({
        type: SET_MODEL_DATE.ERROR,
        payload: response.response?.data?.message,
      });
    }
  } catch (e) {
    yield put({
      type: SET_MODEL_DATE.ERROR,
      payload: e.response?.data?.message,
    });
  }
}

export function * setActiveModel (action) {
  const {id, callback} = action.payload;
  try {
    yield put({ type: SET_ACTIVE_MODEL.REQUEST });
    const response = yield InstrumentsService.setActiveModel(id);
    if (response.status === 200) {
      yield put({ type: SET_ACTIVE_MODEL.SUCCESS, payload: response.data });
      yield put({ type: GET_MODEL_LIST.ACTION });
      callback();
    } else {
      yield put({
        type: SET_ACTIVE_MODEL.ERROR,
        payload: response.response?.data?.message,
      });
    }
  } catch (e) {
    yield put({
      type: SET_ACTIVE_MODEL.ERROR,
      payload: e.response?.data?.message,
    });
  }
}

export function * deleteNotActiveModel (action) {
  const {id, callback, errMessage} = action.payload;
  try {
    yield put({ type: DELETE_NOTACTIVE_MODEL.REQUEST });
    const response = yield InstrumentsService.deleteNotActiveModel(id);
    if (response.status === 200) {
      yield put({ type: DELETE_NOTACTIVE_MODEL.SUCCESS, payload: response.data });
      yield put({ type: GET_MODEL_LIST.ACTION });
      callback();
    } else {
      errMessage();
      yield put({
        type: DELETE_NOTACTIVE_MODEL.ERROR,
        payload: response.response?.data?.message,
      });
    }
  } catch (e) {
    yield put({
      type: DELETE_NOTACTIVE_MODEL.ERROR,
      payload: e.response?.data?.message,
    });
  }
}

export function * setModelSeries (action) {
  const {callback, ...rest} = action.payload;
  try {
    yield put({ type: SET_MODEL_SERIES.REQUEST });
    const response = yield InstrumentsService.setModelSeries(rest);
    if (response.status === 200) {
      yield put({ type: SET_MODEL_SERIES.SUCCESS, payload: response.data });
      yield put({ type: GET_MODEL_PRIMITIVES.ACTION, payload: action.payload.id });
      callback();
    } else {
      callback();
      yield put({
        type: SET_MODEL_SERIES.ERROR,
        payload: response.response?.data?.message,
      });
    }
  } catch (e) {
    yield put({
      type: SET_MODEL_SERIES.ERROR,
      payload: e.response?.data?.message,
    });
  }
}

export function * getModelSeries (action) {
  try {
    yield put({ type: GET_MODEL_SERIES.REQUEST });
    const response = yield InstrumentsService.getModelSeries(action.payload);
    if (response.status === 200) {
      yield put({ type: GET_MODEL_SERIES.SUCCESS, payload: response.data });
    } else {
      yield put({
        type: GET_MODEL_SERIES.ERROR,
        payload: response.response?.data?.message,
      });
    }
  } catch (e) {
    yield put({
      type: GET_MODEL_SERIES.ERROR,
      payload: e.response?.data?.message,
    });
  }
}

export function * getModelPrimitives (action) {
  try {
    yield put({ type: GET_MODEL_PRIMITIVES.REQUEST });
    const response = yield InstrumentsService.getModelPrimitives(action.payload);
    if (response.status === 200) {
      yield put({ type: GET_MODEL_PRIMITIVES.SUCCESS, payload: response.data });
    } else {
      yield put({
        type: GET_MODEL_PRIMITIVES.ERROR,
        payload: response.response?.data?.message,
      });
    }
  } catch (e) {
    yield put({
      type: GET_MODEL_PRIMITIVES.ERROR,
      payload: e.response?.data?.message,
    });
  }
}

export function * setModelPrimitives (action) {
  const {callback, ...rest} = action.payload;
  try {
    yield put({ type: SET_MODEL_PRIMITIVES.REQUEST });
    const response = yield InstrumentsService.setModelPrimitives(rest);
    if (response.status === 200) {
      yield put({ type: SET_MODEL_PRIMITIVES.SUCCESS, payload: response.data });
      callback();
    } else {
      callback();
      yield put({
        type: SET_MODEL_PRIMITIVES.ERROR,
        payload: response.response?.data?.message,
      });
    }
  } catch (e) {
    yield put({
      type: SET_MODEL_PRIMITIVES.ERROR,
      payload: e.response?.data?.message,
    });
  }
}

export function * getModelLabel (action) {
  try {
    yield put({ type: GET_MODEL_LABEL.REQUEST });
    const response = yield InstrumentsService.getModelLabel(action.payload);
    if (response.status === 200) {
      yield put({ type: GET_MODEL_LABEL.SUCCESS, payload: response.data });
    } else {
      yield put({
        type: GET_MODEL_LABEL.ERROR,
        payload: response.response?.data?.message,
      });
    }
  } catch (e) {
    yield put({
      type: GET_MODEL_LABEL.ERROR,
      payload: e.response?.data?.message,
    });
  }
}

export function * createModelLabel (action) {
  try {
    yield put({ type: CREATE_MODEL_LABEL.REQUEST });
    const response = yield InstrumentsService.createModelLabel(action.payload);
    if (response.status === 200) {
      yield put({ type: CREATE_MODEL_LABEL.SUCCESS, payload: response.data });
    } else {
      yield put({
        type: CREATE_MODEL_LABEL.ERROR,
        payload: response.response?.data?.message,
      });
    }
  } catch (e) {
    yield put({
      type: CREATE_MODEL_LABEL.ERROR,
      payload: e.response?.data?.message,
    });
  }
}

export function * updateModelLabel (action) {
  try {
    yield put({ type: UPDATE_MODEL_LABEL.REQUEST });
    const response = yield InstrumentsService.updateModelLabel(action.payload);
    if (response.status === 200) {
      yield put({ type: UPDATE_MODEL_LABEL.SUCCESS, payload: response.data });
    } else {
      yield put({
        type: UPDATE_MODEL_LABEL.ERROR,
        payload: response.response?.data?.message,
      });
    }
  } catch (e) {
    yield put({
      type: UPDATE_MODEL_LABEL.ERROR,
      payload: e.response?.data?.message,
    });
  }
}

export function * deleteModelLabel (action) {
  const {id, labelId} = action.payload;
  console.log('🚀 ~ *deleteModelLabel ~ id:', id);
  try {
    yield put({ type: DELETE_MODEL_LABEL.REQUEST });
    const response = yield InstrumentsService.deleteModelLabel(labelId);
    if (response.status === 200) {
      yield put({ type: DELETE_MODEL_LABEL.SUCCESS, payload: response.data });
      yield put({ type: GET_MODEL_LABEL.ACTION, payload: id });
    } else {
      yield put({
        type: DELETE_MODEL_LABEL.ERROR,
        payload: response.response?.data?.message,
      });
    }
  } catch (e) {
    yield put({
      type: DELETE_MODEL_LABEL.ERROR,
      payload: e.response?.data?.message,
    });
  }
}

export function * uploadModelSeries (action) {
  try {
    const { file } = action.payload;
    let responseImport = null;
    if (file) {
      yield put({ type: UPLOAD_MODEL_SERIES.REQUEST });
      responseImport = yield InstrumentsService.uploadModelSeries(action.payload);
      if (responseImport.status === 200) {
        yield put({
          type: UPLOAD_MODEL_SERIES.SUCCESS,
          payload: responseImport.data,
        });
      } else {
        yield put({
          type: UPLOAD_MODEL_SERIES.ERROR,
          payload: responseImport.response.data.message,
        });
      }
    } else {
      yield put({type: UPLOAD_MODEL_SERIES.ERROR});
    }
  } catch (e) {
    yield put({
      type: UPLOAD_MODEL_SERIES.ERROR,
      payload: e.response?.data?.message,
    });
  }
}

export function * uploadModelPrimitives (action) {
  try {
    const { file } = action.payload;
    let responseImport = null;
    if (file) {
      yield put({ type: UPLOAD_MODEL_PRIMITIVES.REQUEST });
      responseImport = yield InstrumentsService.uploadModelPrimitives(action.payload);
      if (responseImport.status === 200) {
        yield put({
          type: UPLOAD_MODEL_PRIMITIVES.SUCCESS,
          payload: responseImport.data,
        });
      } else {
        yield put({
          type: UPLOAD_MODEL_PRIMITIVES.ERROR,
          payload: responseImport.response.data.message,
        });
      }
    } else {
      yield put({type: UPLOAD_MODEL_PRIMITIVES.ERROR});
    }
  } catch (e) {
    yield put({
      type: UPLOAD_MODEL_SERIES.ERROR,
      payload: e.response?.data?.message,
    });
  }
}

export function * getModelConstraints (action) {
  try {
    yield put({ type: GET_MODEL_CONSTRAINTS.REQUEST });
    const response = yield InstrumentsService.getModelConstraints(action.payload);
    if (response.status === 200) {
      yield put({ type: GET_MODEL_CONSTRAINTS.SUCCESS, payload: response.data });
    } else {
      yield put({
        type: GET_MODEL_CONSTRAINTS.ERROR,
        payload: response.response?.data?.message,
      });
    }
  } catch (e) {
    yield put({
      type: GET_MODEL_CONSTRAINTS.ERROR,
      payload: e.response?.data?.message,
    });
  }
}

export function * setModelConstraints (action) {
  const {callback} = action.payload;
  try {
    yield put({ type: SET_MODEL_CONSTRAINTS.REQUEST });
    const response = yield InstrumentsService.setModelConstraints(action.payload);
    if (response.status === 200) {
      yield put({ type: SET_MODEL_CONSTRAINTS.SUCCESS, payload: response.data });
      // yield put({ type: GET_MODEL_CONSTRAINTS.ACTION, payload: id });
      callback();
    } else {
      yield put({
        type: SET_MODEL_CONSTRAINTS.ERROR,
        payload: response.response?.data?.message,
      });
    }
  } catch (e) {
    yield put({
      type: SET_MODEL_CONSTRAINTS.ERROR,
      payload: e.response?.data?.message,
    });
  }
}

export function * tempDownloadConstraints (action) {
  try {
    yield put({ type: TEMP_DOWNLOAD_CONSTRAINTS.REQUEST });
    const response = yield InstrumentsService.tempDownloadConstraints(action.payload);
    const { data } = response;
    const blob = new Blob([data],
      {
      type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
    });
    const link = document.createElement('a');
    link.href = URL.createObjectURL(blob);
    link.click();
    if (response.status === 200) {
      yield put({ type: TEMP_DOWNLOAD_CONSTRAINTS.SUCCESS });
    } else {
      yield put({
        type: TEMP_DOWNLOAD_CONSTRAINTS.ERROR,
        payload: response?.response?.data?.message,
      });
    }
  } catch (e) {
    yield put({
      type: TEMP_DOWNLOAD_CONSTRAINTS.ERROR,
      payload: e.response?.data?.message,
    });
  }
}

export function * tempDownloadPrimitives (action) {
  try {
    yield put({ type: TEMP_DOWNLOAD_PRIMITIVES.REQUEST });
    const response = yield InstrumentsService.tempDownloadPrimitives(action.payload);
    const { data } = response;
    const blob = new Blob([data],
      {
      type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
    });
    if (response.status === 200) {
      const link = document.createElement('a');
      link.href = URL.createObjectURL(blob);
      link.click();
      yield put({ type: TEMP_DOWNLOAD_PRIMITIVES.SUCCESS });
    } else {
      yield put({
        type: TEMP_DOWNLOAD_PRIMITIVES.ERROR,
        payload: response?.response?.data?.message,
      });
    }
  } catch (e) {
    yield put({
      type: TEMP_DOWNLOAD_PRIMITIVES.ERROR,
      payload: e.response?.data?.message,
    });
  }
}

export function * getRecommendationMaps () {
  try {
    yield put({ type: GET_RECOMMENDATION_MAPS.REQUEST });
    const response = yield InstrumentsService.getRecommendationMaps();
    if (response.status === 200) {
      yield put({ type: GET_RECOMMENDATION_MAPS.SUCCESS, payload: response.data });
    } else {
      yield put({
        type: GET_RECOMMENDATION_MAPS.ERROR,
        payload: response.response?.data?.message,
      });
    }
  } catch (e) {
    yield put({
      type: GET_RECOMMENDATION_MAPS.ERROR,
      payload: e.response?.data?.message,
    });
  }
}

export function * getRecommendationMapById (action) {
  try {
    yield put({ type: GET_RECOMMENDATION_MAP_BY_ID.REQUEST });
    const response = yield InstrumentsService.getRecommendationMapById(action.payload);
    if (response.status === 200) {
      yield put({ type: GET_RECOMMENDATION_MAP_BY_ID.SUCCESS, payload: response.data });
    } else {
      yield put({
        type: GET_RECOMMENDATION_MAP_BY_ID.ERROR,
        payload: response.response?.data?.message,
      });
    }
  } catch (e) {
    yield put({
      type: GET_RECOMMENDATION_MAP_BY_ID.ERROR,
      payload: e.response?.data?.message,
    });
  }
}

export function * createRecommendationMaps (action) {
  const {history, data} = action.payload;
  try {
    yield put({ type: CREATE_RECOMMENDATION_MAPS.REQUEST });
    const response = yield InstrumentsService.createRecommendationMaps(data);
    if (response.status === 200) {
      yield put({ type: CREATE_RECOMMENDATION_MAPS.SUCCESS, payload: response.data });
      history.replace(`/quant/approved-instruments/${response.data?.id}/new?tab=approve-instruments`);
    } else {
      yield put({
        type: CREATE_RECOMMENDATION_MAPS.ERROR,
        payload: response.response?.data?.message,
      });
    }
  } catch (e) {
    yield put({
      type: CREATE_RECOMMENDATION_MAPS.ERROR,
      payload: e.response?.data?.message,
    });
  }
}

export function * updateRecommendationMaps (action) {
  try {
    yield put({ type: UPDATE_RECOMMENDATION_MAPS.REQUEST });
    const response = yield InstrumentsService.updateRecommendationMaps(action.payload);
    if (response.status === 200) {
      yield put({ type: UPDATE_RECOMMENDATION_MAPS.SUCCESS, payload: response.data });
    } else {
      yield put({
        type: UPDATE_RECOMMENDATION_MAPS.ERROR,
        payload: response.response?.data?.message,
      });
    }
  } catch (e) {
    yield put({
      type: UPDATE_RECOMMENDATION_MAPS.ERROR,
      payload: e.response?.data?.message,
    });
  }
}

export function * deleteRecommendationMaps (action) {
  try {
    yield put({ type: DELETE_RECOMMENDATION_MAPS.REQUEST });
    const response = yield InstrumentsService.deleteRecommendationMaps(action.payload);
    if (response.status === 200) {
      yield put({ type: DELETE_RECOMMENDATION_MAPS.SUCCESS, payload: response.data });
      yield put({ type: GET_RECOMMENDATION_MAPS.ACTION });
    } else {
      yield put({
        type: DELETE_RECOMMENDATION_MAPS.ERROR,
        payload: response.response?.data?.message,
      });
    }
  } catch (e) {
    yield put({
      type: DELETE_RECOMMENDATION_MAPS.ERROR,
      payload: e.response?.data?.message,
    });
  }
}