import React, { useEffect, useMemo, useState } from 'react';

import PropTypes from 'prop-types';

import { useTranslation } from 'react-i18next';
import { textSort } from '../../helpers/textSort';
import { useSelector } from 'react-redux';

import { Col, Form, Input, Row, Table } from 'antd';
import Container from '../../components/Container';
import { CustomTextarea } from '../../components/CustomTextfield/CustomTextfield';
import CustomCheckbox from '../../components/CustomCheckbox/CustomCheckbox';

const MapCreation = ({
  // eslint-disable-next-line no-unused-vars
  mapsId,
  onSubmit,
  form,
  selectedAssetClass,
  setSelectedAssetClass,
  assetList,
}) => {
  const { t } = useTranslation('');

  const { assets, recommendationById } = useSelector((state) => state.instrumentsReducer);

  useEffect(() => {
    if (recommendationById) {
      form.setFieldsValue(recommendationById);
    }
  }, [recommendationById]);

  const [columns, setColumns] = useState(null);

  const getAsset = (id) => assets?.find((i) => i.ID === id);

  useEffect(() => {
    const columns = [
      {
        title: t('MAP'),
        dataIndex: 'asset_class_id',
        className: 'name',
        render: (value) => {
          return getAsset(value)?.name;
        },
        sorter: (a, b) =>
          textSort(
            getAsset(a.asset_class_id).name,
            getAsset(b.asset_class_id).name,
          ),
        width: '40%',
      },
      {
        title: t('ASSET_CATEGORY'),
        className: 'asset-category',
        width: '40%',
        render: (_, record) => {
          return getAsset(record.asset_class_id)?.financialAssetCategory?.name;
        },
        sorter: (a, b) =>
          textSort(
            getAsset(a.asset_class_id)?.financialAssetCategory?.name,
            getAsset(b.asset_class_id)?.financialAssetCategory?.name,
          ),
      },
      {
        title: (
          <div className="select-all-wrap">
            <span>{t('SELECT_ALL')}</span>
            <CustomCheckbox
              checked={selectedAll}
              onChange={() => handleToggleAll()}
            />
          </div>
        ),
        align: 'center',
        dataIndex: 'selected',
        width: '20%',
        render: (value, record) => {
          return (
            <CustomCheckbox
              checked={value}
              onChange={() => handleChangeCheck(record.asset_class_id)}
            />
          );
        },
      },
    ];
    setColumns(columns);
  }, [assetList]);

  const handleChangeCheck = (id) => {
    const isSelected = assetList?.find(
      (i) => i.asset_class_id === id,
    )?.selected;
    if (isSelected) {
      const newData = selectedAssetClass.filter((i) => i.asset_class_id !== id);
      setSelectedAssetClass(newData);
    } else {
      const newData = [
        ...selectedAssetClass,
        {
          asset_id: null,
          asset_class_id: id,
          is_recomended: false,
        },
      ];
      setSelectedAssetClass(newData);
    }
  };

  const selectedAll = useMemo(() => {
    if (assetList?.length > 0) {
      return assetList?.every((i) => i.selected);
    } else {
      return false;
    }
  }, [assetList]);

  const handleToggleAll = () => {
    if(selectedAssetClass?.length === assetList?.length) {
      setSelectedAssetClass([]);
    } else {
      const newData = assetList.map((i) => {
        if(i.selected) {
          return selectedAssetClass.find( el => el.asset_class_id === i.asset_class_id);
        } else {
          return ({
            asset_id: null,
            asset_class_id: i.asset_class_id,
            is_recomended: false,
          });
        }
      });
      setSelectedAssetClass(newData);
    }
  };

  return (
    <>
      <Container widthP={100} mb="28px" padding="0 0 24px 0">
        <h2 className="container-title">{t('MAP')}</h2>
        <Form
          form={form}
          name="onboarding-form"
          layout="vertical"
          onFinish={onSubmit}
          className="instrument-form"
          autoComplete="off"
        >
          <Row className="map-name-wrapper">
            <Col className="map-name-column">
              <Form.Item
                className="custom-required"
                label={t('NAME')}
                name="name"
                rules={[
                  {
                    required: true,
                    message: t('PLEASE_ENTER_A_NAME'),
                  },
                ]}
                required={false}
              >
                <Input placeholder={t('NAME')} className="custom-input" />
              </Form.Item>
            </Col>
            <Col className="map-description-column">
              <Form.Item
                name="description"
                className="onboarding-textarea-purpose custom-required"
                label={t('DESCRIPTION')}
              >
                <CustomTextarea
                  width={'100%'}
                  rows={4}
                  placeholder={t('ENTER_DESCRIPTION')}
                  maxLength={200}
                />
              </Form.Item>
            </Col>
          </Row>
        </Form>
      </Container>
      <Container widthP={100} mb="28px" padding="0 0 24px 0">
        <h2 className="container-title">{t('ASSET_CLASSES')}</h2>
        <Table
          rowKey={(record) => record.asset_class_id}
          rowClassName={(_, index) =>
            index % 2 === 0 ? 'editable-row' : 'editable-row is-odd'
          }
          style={{ width: '100%' }}
          bordered
          dataSource={assetList}
          columns={columns}
          pagination={false}
        />
      </Container>
    </>
  );
};

export default MapCreation;

MapCreation.propTypes = {
  mapsId: PropTypes.string,
  onSubmit: PropTypes.func,
  form: PropTypes.object,
  recommendationById: PropTypes.object,
  selectedAssetClass: PropTypes.array,
  setSelectedAssetClass: PropTypes.func,
  assetList: PropTypes.array,
  setAssetList: PropTypes.func,
};
