/* eslint-disable no-unused-vars */
import React from 'react';
import { Button, Col, Modal, Row } from 'antd';

import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { rescheduleOnboarding } from '../../../data/store/account/accountActions';
import dayjs from 'dayjs';

const ModalReschedule = ({modalRescheduleVisible, handleModalRescheduleClose, oldTime, clientId, id }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const formattedDate = (date) => {
    const textDate = date
      ? (date.format('dddd MMM D, YYYY [⋅] h:mm A'))
      : '';
    return textDate;
  };

  const submitReschedule = () => {
    console.log('Re');
    const data = { clientId, id, start_date: dayjs(modalRescheduleVisible).format() };
    dispatch(rescheduleOnboarding(data));
    handleModalRescheduleClose();
  };

  return (
    <Modal
        destroyOnClose
        width={650}
        className="modal-content-paper"
        open={modalRescheduleVisible}
        onCancel={handleModalRescheduleClose}
        footer={null}
        zIndex={1600}>
        <Row justify="center">
          <Col className="modal-title">{t('CONFIRM_RESCHEDULE')}</Col>
        </Row>
        <Row justify="center" className="modal-text-wrapper">
          <Col>
            <p className="modal-advisor-text">{t('MODAL_DELETE_TEXT_CLIENT')}</p>
            <p className="modal-advisor-text modal-reschedule-text">{t('FROM')}</p>
            <p className="modal-advisor-name modal-reschedule">{` ${formattedDate(dayjs(oldTime))}`}</p>
            <p className="modal-advisor-text modal-reschedule-text">{t('TO')}</p>
            <p className="modal-advisor-name modal-reschedule">{` ${formattedDate(modalRescheduleVisible)}`}</p>
          </Col>
        </Row>
        <Row className="modal-advisor-btn-wrapper">
          <Button
            className="modal-action-btn cancel-btn"
            onClick={handleModalRescheduleClose}
          >
            {t('CANCEL')}
          </Button>
          <Button className="modal-action-btn delete-btn" onClick={submitReschedule}>
            {t('RESCHEDULE')}
          </Button>
        </Row>
      </Modal>
  );
};

export default ModalReschedule;

ModalReschedule.propTypes = {
  id: PropTypes.number,
  clientId: PropTypes.string,
  oldTime: PropTypes.string,
  modalRescheduleVisible: PropTypes.object,
  handleModalRescheduleClose: PropTypes.func,
};