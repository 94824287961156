/* eslint-disable no-unused-vars */
import React, { useEffect, useMemo, useState } from 'react';

import PropTypes from 'prop-types';
import { v4 as uuidv4 } from 'uuid';
import { useHistory } from 'react-router-dom';

import { Button, Form, Row, Table } from 'antd';
import { useTranslation } from 'react-i18next';
import { textSort } from '../../../helpers/textSort';

import { ReactComponent as Save } from '../../../assets/save-check-icon.svg';
import { ReactComponent as Cancel } from '../../../assets/cancel-icon-min.svg';
import { ReactComponent as Delete } from '../../../assets/delete-black-icon.svg';
import { ReactComponent as Edit } from '../../../assets/edit-icon.svg';
import { ReactComponent as Pluse } from '../../../assets/add-icon.svg';
import { ReactComponent as Eye } from '../../../assets/eye-icon.svg';

import EditableCell from './EditableCell';
import { useDispatch } from 'react-redux';
import { addRelatedPerson, updateRelatedPerson } from '../../../data/store/account/accountActions';


export const EditableContext = React.createContext(null);
const EditableRow = ({ ...props }) => {
  const [form] = Form.useForm();
  return (
    <Form form={form} component={false}>
      <EditableContext.Provider value={form}>
        <tr {...props} />
      </EditableContext.Provider>
    </Form>
  );
};

const RelatedPersons = ( {accountId, users, relatedPersons, setRelatedPersons, setDeleteModal } ) => {
  const { t } = useTranslation('');
  const history = useHistory();
  const dispatch = useDispatch();

  const [editingKey, setEditingKey] = useState(null);
  const [tableColumns, setTableColumns] = useState([]);
  const [reset, setReset] = useState(null);

  useEffect(() => {
  
    return () => {
      setEditingKey(null);
    };
  }, []);
  


  const getAccount = (id) => {
    const user = users?.find(i => i.id === id );
    return user;
  };

  const handleAdd = () => {
      const newData = [...relatedPersons];
      const blank = {key: uuidv4(), id: null, isOwner: false, access_type: 'VIEW'};
      newData.push(blank);
      setRelatedPersons(newData);
      setEditingKey(blank?.key);
    };

  const handleSaveList = (data) => {
    const newData = relatedPersons
      .map(i => i.id === data.id || (i?.key && i?.key === data.key)
        ? data 
        : i);
    setRelatedPersons(newData);
  };
  
  const handleSubmitData = (key) => {
    const newPersons = relatedPersons.find(i => i?.key === key || i?.id === key);
    if(newPersons?.key) {
      delete newPersons.key;
      newPersons.role = newPersons.isOwner ? 'OWNER' : 'CLIENT';
      delete newPersons.isOwner;
      dispatch(addRelatedPerson({accountId, data: newPersons }));
    } else {
      const newData = {role:newPersons.isOwner ? 'OWNER' : 'CLIENT', access_type: newPersons.access_type };
      dispatch(updateRelatedPerson({accountId, userId: newPersons?.id, data: newData }));
    }
    setEditingKey(null);
  };

  const handleEdit = (key) => {
    setEditingKey(key);
  };

  
    const hendleCancel = () => {
      setEditingKey(null);
      setReset(uuidv4());
    };

  const handleDeleteModal = ({key, type}) => {
    if(type === 'local') {
      const newData = relatedPersons.filter(i => i?.key !== key && i.id !== key);
      setRelatedPersons(newData);
      setEditingKey(null);
    } else if(type === 'remove') {
      setDeleteModal(key);
    }
  };

  useEffect(() => {
    const columns = [
      {
        title: t('NAME'),
        dataIndex: 'id',
        className: 'name',
        width: '20%',
        editable: true,
        render: (value) => (`${getAccount(value)?.first_name} ${getAccount(value)?.last_name}` ),
        sorter: (a, b) => textSort(getAccount(a.id)?.first_name, getAccount(b.id)?.first_name),
      },
      {
        title: t('ROLE'),
        dataIndex: 'isOwner',
        width: '10%',
        editable: true,
        render: (value, record) => {
          if(record?.role) {
            return record?.role === 'OWNER' ? 'Owner' : 'Teammate';
          } else {
            return value ? 'Owner': 'Teammate';
          }},
        sorter: (a, b) => textSort(a.isOwner, b.isOwner),
      },
      {
        title: t('PERMISSION'),
        dataIndex: 'access_type',
        width: '10%',
        align: 'center',
        editable: true,
      },
      {
        title: t('ACTIONS'),
        dataIndex: 'operation',
        width: '33%',
        render: (_, record) => (
          <>
          {editingKey === record.key || editingKey === record.id
              ? (
                <>
                <Button
                icon={<Save/>}
                disabled={!record?.id}
                type="text"
                onClick={() => handleSubmitData(record.key)}
                />
                <Button
                icon={<Cancel/>}
                type="text"
                onClick={hendleCancel}
                />

                </>
                )
              : (
                <Button
                // disabled={isLoading}
                icon={<Edit />}
                type="text"
                onClick={() => handleEdit(record?.key || record.id)}
              />
                )
}
              <Button
                // disabled={isLoading}
                icon={<Delete />}
                type="text"
                onClick={() => handleDeleteModal(record?.key 
                  ?  {key: record?.key, type: 'local'} 
                  : {key: record.id, type: 'remove'})}
              />
              <Button
                // disabled={isLoading}
                icon={<Eye />}
                type="text"
                onClick={() => !record?.key && record?.id && history.push(`/accounts/${record.id}/inform/profile`)}
              />
          </>
        ),
      },
    ];
    setTableColumns(columns);
  }, [relatedPersons, editingKey, reset]);
  
  const isEditing = (record) => record?.key === editingKey || record?.id === editingKey;

  const columns = useMemo(() => tableColumns?.map((col) => {
    if (!col.editable) {
      return col;
    }
    return {
      ...col,
      onCell: (record) => ({
        record,
        dataIndex: col.dataIndex,
        title: col.title,
        handleSaveList,
        editing: isEditing(record),
        users,
      }),
    };
  }), [relatedPersons, tableColumns]);

  const components = {
    body: {
      row: EditableRow,
      cell: EditableCell,
    },
  };

  return (
    <div style={{padding: '24px 0'}}>
      <Table
        rowKey={(record) => record.id || record.key}
        rowClassName={(_, index) =>
          index % 2 === 0 ? 'editable-row' : 'editable-row is-odd'
        }
        bordered
        components={components}
        dataSource={relatedPersons}
        columns={columns}
        pagination={false}
      />
      <Row style={{ padding: '0 24px 36px' }} justify={'end'}>
        <Button
          disabled={editingKey}
          type="text"
          className="holdings-add-btn"
          onClick={handleAdd}
          icon={<Pluse />}
          iconPosition={'end'}
        >
          {t('ADD')}
        </Button>
      </Row>
    </div>
  );
};

export default RelatedPersons;

RelatedPersons.propTypes = {
  accountId: PropTypes.string,
  users: PropTypes.array,
  relatedPersons: PropTypes.array,
  setRelatedPersons: PropTypes.func,
  setDeleteModal: PropTypes.func,
};