import React, { useEffect, useMemo, useState } from 'react';

import PropTypes from 'prop-types';
import { v4 as uuidv4 } from 'uuid';

import { Button, Form, Row, Table } from 'antd';
import { useTranslation } from 'react-i18next';
import { textSort } from '../../../helpers/textSort';

import { ReactComponent as Save } from '../../../assets/save-check-icon.svg';
import { ReactComponent as Cancel } from '../../../assets/cancel-icon-min.svg';
import { ReactComponent as Delete } from '../../../assets/delete-black-icon.svg';
import { ReactComponent as Edit } from '../../../assets/edit-icon.svg';
import { ReactComponent as Pluse } from '../../../assets/add-icon.svg';

import EditableCell from './EditableCell';
import { useDispatch } from 'react-redux';
import { addRelatedPerson, updateRelatedPerson } from '../../../data/store/account/accountActions';


export const EditableContext = React.createContext(null);
const EditableRow = ({ ...props }) => {
  const [form] = Form.useForm();
  return (
    <Form form={form} component={false}>
      <EditableContext.Provider value={form}>
        <tr {...props} />
      </EditableContext.Provider>
    </Form>
  );
};

const RelatedAvisors = ( {accountId, advisors, relatedAdvisors, setRelatedAdvisors, setDeleteModal} ) => {
  const { t } = useTranslation('');
  const dispatch = useDispatch();

  const [editingKey, setEditingKey] = useState(null);
  const [tableColumns, setTableColumns] = useState([]);
  const [reset, setReset] = useState(null);

  const getAccount = (id) => {
    const user = advisors?.find(i => i.id === id );
    return user;
  };

  const handleAdd = () => {
      const newData = [...relatedAdvisors];
      const blank = {key: uuidv4(), id: null, access_type: 'VIEW', role: 'ADVISER'};
      newData.push(blank);
      setRelatedAdvisors(newData);
      setEditingKey(blank?.key);
    };

  const handleSaveList = (data) => {
    const newData = relatedAdvisors
      .map(i => i.id === data.id || (i?.key && i?.key === data.key)
        ? data 
        : i);
        setRelatedAdvisors(newData);
  };
  
  const handleSubmitData = (key) => {
      const newAdvisor = relatedAdvisors.find(i => i?.key === key || i?.id === key);
      if(newAdvisor?.key) {
        delete newAdvisor.key;
        dispatch(addRelatedPerson({accountId, data: newAdvisor }));
      } else {
        const newData = {role: 'ADVISER', access_type: newAdvisor.access_type };
        dispatch(updateRelatedPerson({accountId, userId: newAdvisor?.id, data: newData }));
      }
      setEditingKey(null);
    };

  const handleEdit = (key) => {
    setEditingKey(key);
  };
  
  const handleDeleteModal = ({key, type}) => {
    if(type === 'local') {
      const newData = relatedAdvisors.filter(i => i?.key !== key && i.id !== key);
      setRelatedAdvisors(newData);
      setEditingKey(null);
    } else if(type === 'remove') {
      console.log('🚀 ~ handleDeleteModal ~ key:', key);
      setDeleteModal(key);
    }
  };

  const hendleCancel = () => {
    setEditingKey(null);
    setReset(uuidv4());
  };

  useEffect(() => {
    const columns = [
      {
        title: t('NAME'),
        dataIndex: 'id',
        className: 'name',
        width: '20%',
        editable: true,
        render: (value, record) => (
          `${record?.first_name ?? getAccount(value)?.first_name} ${record?.last_name ?? getAccount(value)?.last_name}` ),
        sorter: (a, b) => textSort(getAccount(a.id)?.first_name, getAccount(b.id)?.first_name),
      },
      {
        title: t('PERMISSION'),
        dataIndex: 'access_type',
        width: '10%',
        align: 'center',
        editable: true,
      },
      {
        title: t('ACTIONS'),
        dataIndex: 'operation',
        width: '33%',
        render: (_, record) => (
          <>
          {editingKey === record.key || editingKey === record.id
            ? (
              <>
                <Button
                  icon={<Save/>}
                  disabled={!record?.id}
                  type="text"
                  onClick={() => handleSubmitData(record?.key ?? record.id)}
                />
                <Button
                  icon={<Cancel/>}
                  type="text"
                  onClick={hendleCancel}
                />
              </>
              )
            : (
              <Button
                icon={<Edit />}
                type="text"
                onClick={() => handleEdit(record?.key || record.id)}
              />
              )
          }
            <Button
              icon={<Delete />}
              type="text"
              onClick={() => handleDeleteModal(record?.key 
                  ?  {key: record?.key, type: 'local'} 
                  : {key: record.id, type: 'remove'})}
          />
          </>
        ),
      },
    ];
    setTableColumns(columns);
  }, [relatedAdvisors, editingKey, reset]);
  
  const isEditing = (record) => record?.key === editingKey || record?.id === editingKey;

  const columns = useMemo(() => tableColumns?.map((col) => {
    if (!col.editable) {
      return col;
    }
    return {
      ...col,
      onCell: (record) => ({
        record,
        dataIndex: col.dataIndex,
        title: col.title,
        handleSaveList,
        editing: isEditing(record),
        advisors,
      }),
    };
  }), [relatedAdvisors, tableColumns, advisors]);

  const components = {
    body: {
      row: EditableRow,
      cell: EditableCell,
    },
  };

  return (
    <div style={{padding: '24px 0'}}>
      <Table
        rowKey={(record) => record.id || record.key}
        rowClassName={(_, index) =>
          index % 2 === 0 ? 'editable-row' : 'editable-row is-odd'
        }
        bordered
        components={components}
        dataSource={relatedAdvisors}
        columns={columns}
        pagination={false}
      />
      <Row style={{ padding: '0 24px 36px' }} justify={'end'}>
        <Button
          disabled={editingKey}
          type="text"
          className="holdings-add-btn"
          onClick={handleAdd}
          icon={<Pluse />}
          iconPosition={'end'}
        >
          {t('ADD')}
        </Button>
      </Row>
    </div>
  );
};

export default RelatedAvisors;

RelatedAvisors.propTypes = {
  accountId: PropTypes.string,
  advisors: PropTypes.array,
  relatedAdvisors: PropTypes.array,
  setRelatedAdvisors: PropTypes.func,
  setDeleteModal: PropTypes.func,
};