import React, { useEffect, useState } from 'react';

import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';

import { Button, Col, Row } from 'antd';
import { twoDecimalWithSpaces } from '../../helpers/formatFractional';
import { useHistory } from 'react-router-dom';
import dayjs from 'dayjs';

import { ReactComponent as Arrow } from '../../assets/arrow-up-right.svg';
import { ReactComponent as Edit } from '../../assets/edit-icon.svg';
import { ReactComponent as Delete } from '../../assets/delete-black-icon.svg';
import { patchFinPlan } from '../../data/store/account/accountActions';

import SessionService from '../../services/SessionService';
import StorageService from '../../services/StorageService';

const ItemsListItem = ({ item, userId, finplanId, selected, setSelectedPlanItems, type, getFinPlanData }) => {
  const { t } = useTranslation('');
  const dispatch = useDispatch();
  const history = useHistory();

  const baseURL = process.env.REACT_APP_BASE_URL;
  const token = StorageService.getJWTToken() || SessionService.getJWTToken();
  const imagePath = `${baseURL}/v1/financialplan/image/${item.image}`;

  const [imgSrc, setImgSrc] = useState('');

  const handleSelectItem = (event) => {
    event.preventDefault();
    setSelectedPlanItems(item.id);
  };

  const handleClose = () => {
    console.log('Close');
    setSelectedPlanItems('sds');
  };

  const hendleEdit = () => {
    history.push(`/accounts/${userId}/lifetiming/${finplanId}/items/edit?category=${type}&key=${item.id}`);
  };

  const handleDelete = () => {
    const data = getFinPlanData[type]?.filter(i => i.id !== item.id);
    dispatch(patchFinPlan({ history, clientId: userId, finplanId, body: { [type]: [...data] } }));
  };

  const getType = (id) => {
    return ['ONE_TIME', 'MONTHLY', 'ANNUAL'][id];
  };

  useEffect(() => {
    if (item.image) {
      fetch(imagePath, {
        method: 'GET',
        headers: {
          Authorization: `Bearer ${token}`
        }
      })
        .then(response => {
          if (!response.ok) {
            throw new Error('Network response was not ok');
          }
          return response.blob();
        })
        .then(blob => {
          const objectURL = URL.createObjectURL(blob);
          setImgSrc(objectURL);
        })
        .catch(error => {
          console.error('There was a problem with your fetch operation:', error);
        });
    }
  }, [item]);

  return (
    <Row
      onClick={!selected ? handleSelectItem : null}
      className={selected ? 'finplan-items-row selected' : 'finplan-items-row'}>
      {!selected
        ? (<React.Fragment >
            <Col className="finplan-items-name"><div className='hover-dot'/>{item.name}</Col>
            <Col className="finplan-items-amount">{twoDecimalWithSpaces(item.amount)}kr</Col>
            <Col className="finplan-items-start">
              {dayjs(item.start_date).format('YYYY')}
            </Col>
            <Col className="finplan-items-end">
              {dayjs(item.end_date).format('YYYY')}
            </Col>
          </React.Fragment>
          )
        : (
        <div className='finplan-items-card-wrap'>
          <div className='finplan-items-card-info'>
            <h3 className='card-info-title'>{item.name}</h3>
            <p className='card-info-endtime'>
            {item.frequency !== 0
              ? `${dayjs(item.start_date).format('MMM YYYY')} - `
              : ''}
              {`${dayjs(item.end_date).format('MMM YYYY')}`}
            <span>{t(getType(item.frequency))}</span></p>
            <p className='card-info-amount'>{twoDecimalWithSpaces(item.amount)}kr</p>
            <div className='info-count-wrapper'>
              {type === 'expenses'
                ? <>
                  <div className='info-count-cell'>
                    <p className='info-count-title'>{t('NECESSITY')}</p>
                    <div className='info-count-dig'>{item.necessity}</div>
                  </div>
                  <div className='info-count-cell'>
                    <p className='info-count-title'>{t('PURPOSE')}</p>
                    <div className='info-count-dig'>{item.purpose}</div>
                  </div>
                  <div className='info-count-cell'>
                    <p className='info-count-title'>{t('HAPPINESS')}</p>
                    <div className='info-count-dig'>{item.happiness}</div>
                  </div>
                </>
                : <>
                  <div className='info-count-cell'>
                    <p className='info-count-title'>{t('PROBABILITY')}</p>
                    <div className='info-count-dig'>{item.probability}</div>
                  </div>
                </>

              }
            </div>
          </div>
          <div className='finplan-items-card-img'>
            {item.image &&
              <img src={imgSrc} alt='expenses img' className='' />
            }
            </div>
          <div className='finplan-items-card-action'>
            <Button
              type='text'
              icon={<Arrow />}
              iconPosition='end'
              onClick={handleClose}
              className='finplan-items-close-btn'>
              {t('CLOSE')}
            </Button>
            <div className="">
              <Button
                type='text'
                onClick={hendleEdit} >
                <Edit />
              </Button>
              <Button
                type='text'
                onClick={handleDelete}>
                <Delete />
              </Button>
            </div>
          </div>
        </div>
          )
      }
    </Row>
  );
};

export default ItemsListItem;

ItemsListItem.propTypes = {
  item: PropTypes.object,
  userId: PropTypes.string,
  finplanId: PropTypes.string,
  selected: PropTypes.bool,
  setSelectedPlanItems: PropTypes.func,
  type: PropTypes.string,
  getFinPlanData: PropTypes.object,
};
