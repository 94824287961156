/* eslint-disable camelcase */
import React, { useCallback, useEffect, useState } from 'react';

import { useHistory, useParams } from 'react-router';
import { useSelector, useDispatch } from 'react-redux';

import { Form, Input, Select, Button, Checkbox } from 'antd';
import { useTranslation } from 'react-i18next';

import TextArea from 'antd/lib/input/TextArea';
import MainButtonArray from '../../components/MainButtonArray';
import { Menu } from '../../assets';
import Container from '../../components/Container';
import countryEN from '../../constants/countryEN.json';
import { ReactComponent as AFIcon } from './img/icon-af.svg';
import { ReactComponent as ISKIcon } from './img/icon-isk.svg';
import { ReactComponent as KFIcon } from './img/icon-kf.svg';
import { ReactComponent as QuestionIcon } from './img/icon-question.svg';
import { ReactComponent as IconPlus } from './img/icon-plus.svg';
import { ReactComponent as IconCross } from './img/icon-cross.svg';
import { phoneRules, zipRules } from '../../helpers/validation';
import './style.scss';
import {
  addClientPhone,
  deleteClientPhone,
  getAccount,
  updateClientPhone,
  setClientInformation,
  addRepresentative,
  updateRepresentative,
  updateClientInfo,
  setClientScore,
  setClientAddress,
} from '../../data/store/account/accountActions';
import Representative from './Representative';

const MyDetails = () => {
  const [form] = Form.useForm();
  const history = useHistory();
  const { id } = useParams();
  const { t } = useTranslation('');
  const dispatch = useDispatch();
  const { user } = useSelector((state) => state.accountReducer);
  const [secondPhone, setSecondPhone] = useState(false);
  const [thirdPhone, setThirdPhone] = useState(false);

  useEffect(() => {
    if (id) dispatch(getAccount({ id }));
  }, []);

  const handleAddMobilePhone = () => {
    if (user.phones.length < 3) setSecondPhone(true);
    if (user.phones.length < 3 && (secondPhone || user?.phones[1])) {
      setThirdPhone(true);
    }
  };

  const handleDeletePhone = (phoneID, fieldName) => {
    if (phoneID) {
      if (user.phones.length > 1) {
        dispatch(deleteClientPhone({ id, phoneID }));
        form.setFieldsValue({ [fieldName]: '' });
      }
    } else {
      form.setFieldsValue({ [fieldName]: '' });
      fieldName === 'phone2' ? setSecondPhone(false) : setThirdPhone(false);
    }
  };

  const initialValues = {
    firstName: user?.firstName || '',
    lastName: user?.lastName || '',
    companyName: user?.companyName || '',
    email: user?.email || '',
    leiCode: user?.leiCode || '',
    phone: user?.phones?.length > 0 ? user?.phones[0]?.phone || '' : '',
    phone2: user?.phones2?.length > 0 ? user?.phones[1]?.phone || '' : '',
    phone3: user?.phones3?.length > 0 ? user?.phones[2]?.phone || '' : '',
    postal: user?.address?.length > 0 ? user?.address[0]?.postal || '' : '',
    zip_code: user?.address?.length > 0 ? user?.address[0]?.zip_code || '' : '',
    city: user?.address?.length > 0 ? user?.address[0]?.city || '' : '',
    country: user?.address?.length > 0 ? user?.address[0]?.country || '' : '',
    care_of: user?.address?.length > 0 ? user?.address[0]?.care_of || '' : '',
    marital_status:
      user?.marital?.length > 0 ? user?.marital[0]?.marital_status || '' : '',
    main_occupation:
      user?.marital?.length > 0 ? user?.marital[0]?.main_occupation || '' : '',
    more_info:
      user?.marital?.length > 0
        ? user?.marital[0]?.children_on_support || ''
        : '',
    stock_and_fund: true,
    investment_savings:
      user?.account_setting?.investment_savings?.must_have || false,
    endowment_insurance:
      user?.account_setting?.endowment_insurance?.must_have || false,
    representative: user?.company_representive,
  };

  const onSubmit = useCallback((fields) => {
    if (user.type.type === 'business') {
      const clientInfo = {
        email: user.email,
        firstName: user.firstName,
        lastName: user.lastName,
        companyName: fields.companyName,
        leiCode: fields.leiCode,
      };
      dispatch(updateClientInfo({ id, client: clientInfo }));
      const { stock_and_fund, investment_savings, endowment_insurance } =
        fields;
      const score = {
        stock_and_fund: {
          must_have: stock_and_fund,
          service_type: '',
        },
        investment_savings: {
          must_have: investment_savings,
          service_type: '',
        },
        endowment_insurance: {
          must_have: !!endowment_insurance,
          service_type: '',
        },
      };
      dispatch(setClientScore({ id, score }));
      const { postal, zip_code, city, country, care_of } = fields;
      const clientAddress = {
        postal,
        zip_code,
        city,
        country,
        care_of,
      };
      dispatch(setClientAddress({ id, address: clientAddress }));

      const { representative } = fields;
      const createDocArray = (item) => {
        if (item?.length > 0) {
          return item.map((doc) => ({
            ID: doc?.ID,
            name: doc?.name,
            size: doc?.size || '',
            type: doc?.type || '',
          }));
        } else return [];
      };
      representative.forEach((item) => {
        const data = {
          authority: JSON.stringify(item.authority),
          personal_number: item.personal_number,
          first_name: item.first_name,
          last_name: item.last_name,
          email: item.email,
          mobile_number: JSON.stringify(item.mobile_number),
          county_of_citizenship: item.county_of_citizenship,
          county_of_tax_residence: item.county_of_tax_residence,
          documentProves: createDocArray(item.documentProves),
        };
        item?.ID
          ? dispatch(
            updateRepresentative({
              clientID: id,
              id: item.ID,
              representative: data,
            }),
          )
          : dispatch(
            addRepresentative({
              id,
              representative: data,
              docArray: item.documentProves,
            }),
          );
      });
      history.push(`/dashboard/${id}`);
    } else {
      const client = { ...fields };
      delete client.phones;
      delete client.stock_and_fund;
      delete client.investment_savings;
      delete client.endowment_insurance;
      delete client.representative;

      const { stock_and_fund, investment_savings, endowment_insurance } =
        fields;
      client.score = {
        stock_and_fund: {
          must_have: stock_and_fund,
          service_type: '',
        },
        investment_savings: {
          must_have: investment_savings,
          service_type: '',
        },
        endowment_insurance: {
          must_have: !!endowment_insurance,
          service_type: '',
        },
      };

      const { phone, phone2, phone3 } = fields;
      if (phone && phone !== user?.phones[0]?.phone) {
        user?.phones[0]?.phone
          ? dispatch(updateClientPhone({ id, ID: user?.phones[0]?.ID, phone }))
          : dispatch(addClientPhone({ id, phone }));
      } else if (!phone && user?.phones[0]?.phone) {
        dispatch(deleteClientPhone({ id, phoneID: user?.phones[0]?.ID }));
      }
      if (phone2 && phone2 !== user?.phones[1]?.phone) {
        user?.phones[1]?.phone
          ? dispatch(
            updateClientPhone({ id, ID: user?.phones[1]?.ID, phone: phone2 }),
          )
          : dispatch(addClientPhone({ id, phone: phone2 }));
      } else if (!phone2 && user?.phones[1]?.phone) {
        dispatch(deleteClientPhone({ id, phoneID: user?.phones[1]?.ID }));
      }
      if (phone3 && phone3 !== user?.phones[2]?.phone) {
        user?.phones[2]?.phone
          ? dispatch(
            updateClientPhone({ id, ID: user?.phones[2]?.ID, phone: phone3 }),
          )
          : dispatch(addClientPhone({ id, phone: phone3 }));
      } else if (!phone3 && user?.phones[2]?.phone) {
        dispatch(deleteClientPhone({ id, phoneID: user?.phones[2]?.ID }));
      }
      dispatch(setClientInformation({ id, client, history }));
    }
  }, []);

  const countrysOption = (data) => {
    return (
      <Select.Option key={data} value={data}>
        {countryEN[data]}
      </Select.Option>
    );
  };

  if (!user?.ID) {
    return <></>;
  }
  return (
    <>
      <h1 className="details-header">{t('CUSTOMER_INFORMATION')}</h1>
      <Form
        form={form}
        name="horizontal_login"
        layout="vertical"
        initialValues={initialValues}
        onFinish={onSubmit}
      >
        {user?.type?.type === 'private' && (
          <Container>
            <div className="detail-header">{t('NAME')}</div>
            <Form.Item label={t('FIRST_NAME')} name="firstName">
              <Input placeholder={t('ENTER_FIRST_NAME')} />
            </Form.Item>
            <Form.Item label={t('LAST_NAME')} name="lastName">
              <Input placeholder={t('ANGE_EFTERNAMN')} />
            </Form.Item>
          </Container>
        )}
        {user?.type?.type === 'business' && (
          <Container>
            <div className="detail-header">{t('COMPANY_INFORMATION')}</div>
            <Form.Item
              label={t('COMPANY_NAME')}
              name="companyName"
              rules={[
                {
                  required: true,
                  message: t('ENTER_COMPANY_NAME'),
                },
              ]}
            >
              <Input placeholder={t('ENTER_COMPANY_NAME')} />
            </Form.Item>
            <Form.Item
              label={t('POSTAL_ADDRESS')}
              name="postal"
              rules={[
                {
                  required: true,
                  message: t('ENTER_POSTAL_ADDRESS'),
                },
              ]}
            >
              <Input placeholder={t('ENTER_POSTAL_ADDRESS')} />
            </Form.Item>
            <Form.Item
              label={t('ZIP_CODE')}
              name="zip_code"
              rules={[
                {
                  required: true,
                  message: `${t('ENTER')} ${t('ZIP_CODE')}`,
                },
                () => ({
                  validator (rule, value) {
                    if (
                      !value ||
                      value.match(/^(\d{5}$)/) ||
                      value.match(/^(\d{5}-\d{4}$)/)
                    ) {
                      return Promise.resolve();
                    }
                    // eslint-disable-next-line prefer-promise-reject-errors
                    return Promise.reject('Enter valid zip code');
                  },
                }),
              ]}
            >
              <Input placeholder={t('ENTER_ZIP_CODE')} />
            </Form.Item>
            <Form.Item
              label={'C/O'}
              name="care_of"
              rules={[
                {
                  required: false,
                  message: t('ENTER_CO_ADDRESS'),
                },
              ]}
            >
              <Input placeholder={t('ENTER_CO_ADDRESS')} />
            </Form.Item>
            <Form.Item
              label={t('CITY')}
              name="city"
              rules={[
                {
                  required: false,
                  message: t('ENTER_CITY'),
                },
              ]}
            >
              <Input placeholder={t('ENTER_CITY')} />
            </Form.Item>
            <Form.Item
              label={t('COUNTRY_COMPANY')}
              name="country"
              className="risk-profile-lable"
              rules={[
                {
                  required: true,
                  message: t('ENTER_COUNTRY'),
                },
              ]}
            >
              <Select
                placeholder={t('SELECT_OPTION')}
                className="risk-profile-select"
                type="select"
              >
                <Select.Option value="Sweden">{t('Sweden')}</Select.Option>
                {Object.keys(countryEN).map((item) => {
                  return countrysOption(item);
                })}
              </Select>
            </Form.Item>
            <Form.Item
              label={t('LEI_CODE')}
              name="leiCode"
              rules={[
                {
                  required: true,
                  message: t('ENTER') + ' ' + t('LEI_CODE'),
                },
                {
                  // message: t('ENTER') + ' ' + t('CORRECT') + ' ' + t('LEI_CODE'),
                  validator: (_, value) => {
                    if (/^\d{4}[0]{2}[A-Z0-9]{12}\d{2}$/.test(value)) {
                      return Promise.resolve();
                    } else {
                      // eslint-disable-next-line prefer-promise-reject-errors
                      return Promise.reject(
                        `${t('ENTER')} ${t('CORRECT')} ${t('LEI_CODE')}`,
                      );
                    }
                  },
                },
              ]}
            >
              <Input placeholder={t('ENTER') + ' ' + t('LEI_CODE')} />
            </Form.Item>
          </Container>
        )}
        {user?.type?.type === 'private' && (
          <Container>
            <div className="detail-header">{t('CONTACT_DETAILS')}</div>
            <Form.Item label={t('MAILADRESS')} name="email">
              <Input placeholder={t('ENTER_EMAIL_ADDRESS')} disabled />
            </Form.Item>

            <div className="phone-input-wrapper">
              <Form.Item
                label={t('MOBILE_NUMBER')}
                name="phone"
                className="myDetails-phone"
                rules={phoneRules('phone')}
              >
                <Input placeholder={t('ENTER_PHONE_NUMBER')} />
              </Form.Item>
              <Button
                className="phone-del-button"
                onClick={() => handleDeletePhone(user?.phones[0]?.ID, 'phone')}
                icon={<IconCross />}
              />
            </div>
            {(secondPhone || user?.phones[1]?.phone) && (
              <div className="phone-input-wrapper">
                <Form.Item
                  label={t('MOBILE_NUMBER')}
                  name="phone2"
                  className="myDetails-phone"
                  rules={phoneRules('phone2')}
                >
                  <Input placeholder={t('ENTER_PHONE_NUMBER')} />
                </Form.Item>
                <Button
                  className="phone-del-button"
                  onClick={() =>
                    handleDeletePhone(user?.phones[1]?.ID, 'phone2')
                  }
                  icon={<IconCross />}
                />
              </div>
            )}
            {(thirdPhone || user?.phones[2]?.phone) && (
              <div className="phone-input-wrapper">
                <Form.Item
                  label={t('MOBILE_NUMBER')}
                  name="phone3"
                  className="myDetails-phone"
                  rules={phoneRules('phone3')}
                >
                  <Input placeholder={t('ENTER_PHONE_NUMBER')} />
                </Form.Item>
                <Button
                  className="phone-del-button"
                  onClick={() =>
                    handleDeletePhone(user?.phones[2]?.ID, 'phone3')
                  }
                  icon={<IconCross />}
                />
              </div>
            )}
            {user.phones.length < 3 && !thirdPhone && (
              <div className="goal-addbutton-wrapper">
                <Button
                  className="goal-addbutton myDetails-addbutton"
                  onClick={handleAddMobilePhone}
                >
                  <IconPlus />
                  {t('ADD_MORE_PHONE_NUMBERS')}
                </Button>
              </div>
            )}
          </Container>
        )}

        {user?.type?.type === 'business' && (
          <Container>
            <div className="detail-header">{t('COMPANY_REPRESENTATIVE')}</div>
            <Representative form={form} clientId={id} />
          </Container>
        )}

        {user?.type?.type === 'private' && (
          <Container>
            <div className="detail-header">{t('ADDRESS')}</div>
            <Form.Item label={t('POSTAL_ADDRESS')} name="postal">
              <Input placeholder={t('ENTER_POSTAL_ADDRESS')} />
            </Form.Item>
            <Form.Item label={t('ZIP_CODE')} name="zip_code" rules={zipRules}>
              <Input placeholder={t('ENTER_ZIP_CODE')} />
            </Form.Item>
            <Form.Item label={t('CITY')} name="city">
              <Input placeholder={t('ENTER_CITY')} />
            </Form.Item>
          </Container>
        )}
        {user?.type?.type === 'private' && (
          <Container>
            <div className="detail-header">
              {t('MARITAL_STATUS_AND_EMPLOYMENT')}
            </div>
            <Form.Item label={t('MARITAL_STATUS')} name="marital_status">
              <Select placeholder={t('SELECT_OPTION')} type="select">
                <Select.Option value="" disabled></Select.Option>
                <Select.Option value="unmarried">
                  {t('NO_MARRIED')}
                </Select.Option>
                <Select.Option value="married">{t('MARRIED')}</Select.Option>
                <Select.Option value="widow">
                  {t('WIDOW_OR_WIDOWER')}
                </Select.Option>
              </Select>
            </Form.Item>
            <Form.Item label={t('MAIN_OCCUPATION')} name="main_occupation">
              <Select placeholder={t('SELECT_OPTION')}>
                <Select.Option value="" disabled></Select.Option>
                <Select.Option value="employee">{t('EMPLOYEE')}</Select.Option>
                <Select.Option value="selfEmployed">
                  {t('SELF_EMPLOYED')}
                </Select.Option>
                <Select.Option value="pensioner">
                  {t('PENSIONER')}
                </Select.Option>
                <Select.Option value="student">{t('STUDENT')}</Select.Option>
                <Select.Option value="other">{t('OTHER')}</Select.Option>
              </Select>
            </Form.Item>
            <Form.Item
              label={t('CHILDREN_OR_OTHERS_DEPENDENT')}
              name="more_info"
            >
              <TextArea />
            </Form.Item>
          </Container>
        )}
        <Container>
          <div className="detail-header">{t('ACCOUNT_SELECTION')}</div>
          <p className="account-text">
            {t('ACCOUNT_TEXT1')} <br />
            {t('ACCOUNT_TEXT2')}
            <br />
            {t('ACCOUNT_TEXT3')}
            <br />
            {t('ACCOUNT_TEXT4')}
          </p>
          <div className="checkbox-block">
            <Form.Item
              className="my-detail-checkbox-wrapper"
              name="stock_and_fund"
              valuePropName="checked"
            >
              <div className="radio-container">
                <Checkbox
                  checked
                  className="experience-background-cell center experience-background-checkbox my-details-checkbox"
                />
                <AFIcon />
                <span className="radio-text">{t('STOCK_FUND_ACCOUNT')}</span>
                <QuestionIcon />
              </div>
            </Form.Item>
            <div className="radio-container">
              <Form.Item
                className="my-detail-checkbox-wrapper"
                name="investment_savings"
                valuePropName="checked"
              >
                <Checkbox className="experience-background-cell center experience-background-checkbox my-details-checkbox" />
              </Form.Item>
              <ISKIcon />
              <span className="radio-text">
                {t('INVESTMENT_SAVINGS_ACCOUNT')}
              </span>
              <QuestionIcon />
            </div>

            {user?.type?.type === 'business' && (
              <div className="radio-container">
                <Form.Item
                  className="my-detail-checkbox-wrapper"
                  name="endowment_insurance"
                  valuePropName="checked"
                >
                  <Checkbox className="experience-background-cell center experience-background-checkbox my-details-checkbox" />
                </Form.Item>
                <KFIcon />
                <span className="radio-text">{t('ENDOWMENT_INSURANCE')}</span>
                <QuestionIcon />
              </div>
            )}
          </div>
        </Container>
        <div className="btn-container">
          <MainButtonArray
            style={{ width: 150, height: 40 }}
            type="link"
            arrayIconStart
            onClick={() => history.goBack()}
          >
            {t('GO_BACK')}
          </MainButtonArray>
          <div className="menu-wrapper">
            <Menu
              onClick={() => history.replace(`/dashboard/${id}`)}
              style={{ width: 85 }}
            />
            <p className="menu-title">{t('MY_DETAILS')}</p>
          </div>
          <MainButtonArray
            style={{ width: 150, height: 40 }}
            type="submit"
            arrayIconEnd
          >
            {t('MOVE_ON')}
          </MainButtonArray>
        </div>
      </Form>
    </>
  );
};

export default MyDetails;
