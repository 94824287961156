import React from 'react';

import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import {ReactComponent as FirstIcon} from './img/first-icon.svg';
import {ReactComponent as SecondIcon} from './img/second-icon.svg';

import './style.scss';

const SwitcherButton = ({position, t, text}) => {
  return (
    <div 
      className={`switcher-button-wrap ${position ? 'second' : 'first'}`}
    >
        {position ? <SecondIcon /> : <FirstIcon />}
      <span className='switcher-button-text'>{t(text[position])}</span>
    </div>
  );
};

const ClientTopSwitcher = ({text, switcher, handleClick}) => {
  const { t } = useTranslation('');

  return (
    <div 
      className='top-switcher-wrapper'
      onClick={handleClick}
    >
      <div className='switcher-block'><FirstIcon /><span>{t(text[0])}</span></div>
      <div className='switcher-block'><SecondIcon /><span>{t(text[1])}</span></div>
      <SwitcherButton 
        position={switcher}
        t={t}
        text={text}
        
      />
    </div>
  );
};

export default ClientTopSwitcher;

ClientTopSwitcher.propTypes = {
    text: PropTypes.array,
    switcher: PropTypes.number,
    handleClick: PropTypes.func
  };
  
  SwitcherButton.propTypes = {
    position: PropTypes.number,
    text: PropTypes.array,
    t: PropTypes.func,
    handleClick: PropTypes.func,
};