export const portfolioOptimizationSW = {
  MIN_MAX: 'Min/Max',
  PORTFOLIO_TYPE: 'Portföljtyp',
  PRODUCT_CHOICE_TEXT: 'Välj om kunden ska ha en Quant-portfölj eller en 4-fondsportfölj. Citroneer rekommenderar 4-fondsportföljen till kunder som har mindre än 3 000 000 kr i sin totala portfölj. Detta för att undvika onödigt höga transaktionskostnader för små positioner i specifika instrument.',
  SELECTION_PORTFOLIO_TYPE: 'Val av portföljtyp*',
  QUANT: 'Quant',
  FOUR_FUND: '4-Fond',
  ETHICAL_CONSIDERATIONS: 'Etiska överväganden vid produktval*',
  EXCLUDE_HOLDINGS: 'Exkludera innehav',
  EXCLUDE_HOLDINGS_CONTENT: 'Om det finns några innehav i de valda portföljerna som kunden inte vill ha med i optimeringen kan du exkludera specifika innehav här. Genom att markera rutan "Exkludera innehav" får du möjlighet att kontrollera vilka innehav kunden inte vill ska ingå i optimeringen.',
  PORTFOLIO_CONSTRAINTS: 'Portföljbegränsning',
  STRATEGY_CONSTRAINTS_CONTENT: 'Ange om det finns någon investor bias som bör gälla för optimeringen. Investor bias avser om kunden har någon investerarpartiskhet avseende aktietillgångsklasser som inte ingår i Citroneer\'s Equity Strategy Basket, till exempel Svenska aktier. Om kunden har några investor biases, ange procentandelen av deras portfölj som påverkas av varje bias. Den totala summan av kundens investor bias får inte överstiga 25 % av deras portfölj.',
  INVESTOR_BIAS: 'Investor bias',
  ADD_INVESTOR_BIAS: 'Lägg till investor bias',
  ADD_CONSTRAINT: 'Lägg till begränsning',
  ENETR_BIAS_NAME: 'Ange namn',
  PERCENTAGE: 'Procent',
  ENTER_PERCENTAGE: 'Ange procent',
  PRODUCT_NAME_ISIN: 'Produktnamn & ISIN',
  SETTINGS: 'Inställningar',
  BACK_TO_SETTINGS: 'Tillbaka till Inställningar',
  CAPITAL_PLANNING_REAL: 'Real',
  CAPITAL_PLANNING_NOMINAL: 'Nominell',
  CAPITAL_PLANNING: 'Capital planning',
  CHOOSE_ALTERNATIVE: 'Välj alternativ',
  AGE: 'Ålder',
  ENTER_AGE: 'Ange ålder',
  TIME_HORIZON: 'Tidshorisont',
  FEES: 'Avgifter',
  PRODUCT_FEES: 'Produktavgifter',
  DEPOSIT_ADVISORY_FEES: 'Depositions- och rådgivningsavgifter',
  DEPOSIT_FEES: 'Depositionsavgifter',
  DEPOSIT_OPT_FEE: 'Depositionsavgift hos Citroneer',
  ENTER_FEE: 'Ange avgift',
  ENTER_THE_DEPOSIT_FEE: 'Ange depositionsavgiften',
  ENTER_THE_ADVISORY_FEE: 'Ange rådgivaravgiften',
  ADVISORY_FEES: 'Rådgivaravgifter',
  ADVISORY_OPT_FEE: 'Rådgivaravgift hos Citroneer',
  INCLUDE: 'Inkludera',
  TYPE_OF_CLIENT: 'Typ av kund',
  EXISTING: 'Existerande',
  POTENTIAL: 'Potentiell',
  DEPOSIT_FEE: 'Depositionsavgift',
  ADVISORY_FEE: 'Rådgivaravgift',
  INCLUDE_DEPOSIT_FEES: 'Inkludera depositionsavgifter',
  INCLUDE_ADVISORY_FEES: 'Inkludera rådgivaravgifter',
  DEPOSITS: 'Depositioner',
  ADD_DEPOSIT: 'Lägg till deposition',
  TYPE: 'Typ',
  CHOOSE_TYPE: 'Välj typ',
  ANNUAL: 'Årlig',
  MONTHLY: 'Månads',
  ONE_TIME: 'Engångs',
  DESCRIPTION: 'Beskrivning',
  ENTER_DESCRIPTION: 'Ange beskrivning',
  AMOUNT: 'Antal',
  ENTER_AMOUNT: 'Ange antal',
  START: 'Start',
  ENTER_START: 'Ange start',
  END: 'Slut',
  ENTER_END: 'Ange slut',
  WITHDRAWALS: 'Uttag',
  ADD_WITHDRAWALS: 'Ange uttag',
  PORTFOLIO_DEVELOPMENT: 'Portföljutveckling',
  WEALTH_APPRECIATION: 'Förmögenhetsutveckling',
  PORTFOLIO_SIGNATURE: 'Portföljsignatur',
  SUGGESTED_PORTFOLIO: 'Föreslagen portfölj',
  PORTFOLIO_OPTIMIZATION: 'Portföljoptimering',
  FINANCIAL_PLANNING: 'Finansiell planering',
  ASSET_ALLOCATION_CURRENT_PORTFOLIO: 'Tillgångsallokering nuvarande portfölj',
  ASSET_ALLOCATION_OPTIMAL_PORTFOLIO: 'Tillgångsallokering optimal portfölj',
  ASSET_ALLOCATION: 'Tillgångsklassfördelning',
  ASSET_CLASS: 'Tillgångsklass',
  GENERAL: 'Generell',
  DIVERSIFICATION: 'Diversifiering',
  DEFENSIVE_BASKET: 'Defensiv korg',
  SWEDISH_GOVERNMENT_BONDS: 'Svenska statsobligationer',
  AMERICAN_GOVERNMENT_BONDS: 'Amerikanska statsobligationer',
  JAPANESE_GOVERNMENT_BONDS: 'Japanska statsobligationer',
  GOLD: 'Guld',
  EQUITY_BASKET: 'Aktiekorg',
  DEFENSIVE_STOCKS_MIN: 'Defensiva aktier min',
  DEFENSIVE_STOCKS_MAX: 'Defensiva aktier max',
  SMALL_CAP_STOCKS: 'Småbolagsaktier',
  MOMENTUM_STOCKS: 'Momentumaktier',
  VALUE_STOCKS: 'Värdeaktier',
  BOND_BASKET: 'Obligationskorg',
  EMERGING_MARKET_STOCKS: 'Tillväxtmarknadsaktier',
  EMERGING_MARKET_BONDS: 'Tillväxtmarknadsobligationer',
  HIGH_YIELD: 'Högavkastande obligationer',
  INVESTMENT_GRADE: 'Obligationer av investeringsklass',
  EFFICIENT_FRONTIER: 'Effektiva fronten',
  NUMBER_PORTFOLIOS_GRAPH: 'Antal portföljer i graf',
  CURRENT_PORTFOLIO: 'Nuvarande portfölj',
  SAVE_OR_DISCARD: 'Vill du spara eller radera ändringarna?',
  SAVE_CHANGES: 'Spara ändringarna',
  DO_NOT_SAVE: 'Spara inte',
  DO_YOU_WANT_SAVE: 'Vill du spara dina ändringar i portföljen?',
  OPTIMIZED_PORTFOLIO: 'Optimerad portfölj',
  PLEASE_ENTER_A_NAME: 'Vänligen ange ett namn',
  PLEASE_ENTER_PORTFOLIO_NUMBER: 'Vänligen ange ett portföljnummer',
  PLEASE_SELECT_METHOD_TAXATION: 'Vänligen ange en beskattningsmetod',
  PLEASE_ENTER_TAX_RATE: 'Vänligen ange skattenivå',
  PLEASE_ENTER_DEPOSIT_FEE: 'Vänligen ange en depositionsavgift',
  PLEASE_ENTER_ADVISORY_FEE: 'Vänligen ange en rådgivaravgift',
  CHOOSE_CAPITAL_PLANNING: 'Välj kapitalplanering',
  AGE_IS_REQUIRED: 'Ålder krävs',
  GO_BACKWARD: 'Gå tillbaka',
  GO_FORWARD: 'Gå framåt',
  START_OPTIMIZE: 'Börja optimera',
  RECOMMENDED_PORTFOLIO: 'Rekommenderad portfölj',
  SHOULD_ANY_AMOUNT: 'Bör något belopp av portföljen tas ut?',
  SHOULD_ANY_AMOUNT_DEPOSITED: 'Bör något belopp sättas in på portföljen?',
  INCLUDE_THE_FINANCIAL_PLAN: 'Vill du ha med den ekonomiska planen i beräkningarna? *',
  MONETARY_BASIS: 'Vilken monetär grund skulle du vilja använda för beräkningarna? *',
  MONET_BASIS: 'Monetär grund*',
  NOMINAL: 'Nominell',
  REAL: 'Verklig',
  TRANSACTIONS: 'Transaktioner',
  SUGGEST_TRANSACTIONS: 'Föreslå transaktioner',
  INFO_TEXT: 'Välj punkten på grafen för att fortsätta till optimering',
  RETURN: 'Avkastning',
  VOLATILITY: 'Volatilitet',
  DISTRIBUTION: 'Tillgångskategorifördelning',
  APPROVE_TRANSACTIONS: 'Godkänn transaktioner',
  WARNING_OPT: 'Varning',
  WARNING_OPT_TEXT: 'Du är på väg att lämna optimeringsflödet. All inmatad information kommer att gå förlorad. Är du säker på det här?',
  CONTINUE_OPTIMIZATION: 'Fortsätt optimeringen',
  LEAVE_OPTIMIZATION_FLOW: 'Lämna optimeringsflödet',
  TIME_HORIZON_YEARS: 'Tidshorisont (år)',
  WEALTH_MKR: 'Förmögenhet (mkr)',
  CREATING_REPORT: 'Skapa rapport',
  DONE: 'Gjort',
  DOWNLOAD: 'Ladda ner',
  REPORT_NOT_READY: 'Rapporten är inte klar',
  TRY_LATER: 'Försök senare',
  CASH_LEVEL: 'Likvida Medel',
  CASH_REMAINING: 'Kontanter kvar',
  CASH_WITHDRAWAL: 'Kontantuttag',
  VOLATILITY_TOOLTIP: ' av den totala portföljvolatiliteten utgör idiosynkratisk risk.',
};
