import React, { useEffect, useMemo, useState } from 'react';

import PropTypes from 'prop-types';

import { Button, Col, Form, Layout, Row } from 'antd';

import { useTranslation } from 'react-i18next';
import { useLocation, useParams, useHistory } from 'react-router-dom';
import { useQuery } from '../../hooks/useQuery';

import MainFooter from '../../components/MainFooter';

import MapCreation from './MapCreation';
import { useDispatch, useSelector } from 'react-redux';
import ApproveInstruments from './ApproveInstruments';
import { clearRecommendationMapById, createRecommendationMaps, getCurrency, getDiversification, getInstrumentsAll, getRecommendationMapById } from '../../data/store/instruments/instrumentsActions';

const { Header } = Layout;

export const steps = [
  {
    key: 1,
    name: 'MAP_CREATION',
    value: 'map-creation',
  },
  {
    key: 2,
    name: 'APPROVE_INSTRUMENTS',
    value: 'approve-instruments',
  },
];

const RecommendationMapsItem = () => {
  const { t } = useTranslation('');
  const dispatch = useDispatch('');
  const [form] = Form.useForm();
  const { search, pathname } = useLocation();
  const { mapsId } = useParams();
  const history = useHistory();
  const query = useQuery(search);

  const tab = query.get('tab');

  const [selectedAssetClass, setSelectedAssetClass] = useState([]);
  const [editingKey, setEditingKey] = useState(null);

  const [assetList, setAssetList] = useState([]);

  const { assets, recommendationById } = useSelector(
    (state) => state.instrumentsReducer,
  );

  useEffect(() => {
    dispatch(getDiversification());
    dispatch(getCurrency());
    dispatch(getInstrumentsAll({ limit: -1, offset: 0, type: 'without-cashes' }));
    
  }, [dispatch]);

  useEffect(() => {
    if (mapsId !== 'new') {
      dispatch(getRecommendationMapById(mapsId));
    } else {
      dispatch(clearRecommendationMapById());
    }
  }, [mapsId]);

  const initialAssetList = useMemo(() => {
    let data = [];
    if (assets) {
      data = assets
        ?.filter((el) => !el.is_virtual)
        .map((i) => ({
          asset_class_id: i.ID,
          selected: false,
        }));
    }
    return data;
  }, [assets]);

  useEffect(() => {
    setAssetList(initialAssetList);
  }, [assets]);

  useEffect(() => {
    if (selectedAssetClass && assetList.length) {
      const selectedArray = selectedAssetClass?.map((i) => i.asset_class_id);
      const newAsetList = assetList.map((el) =>
        selectedArray.includes(el.asset_class_id)
          ? { ...el, selected: true }
          : { ...el, selected: false },
      );
      setAssetList(newAsetList);
    } else {
      setAssetList(initialAssetList);
    }
  }, [selectedAssetClass]);

  useEffect(() => {
    if (recommendationById) {
      setSelectedAssetClass(recommendationById?.asset_recommendation_mappings);
    } else {
      setSelectedAssetClass([]);
    }
  }, [recommendationById]);

  useEffect(() => {
    if (!tab) {
      history.push(`${pathname}?tab=map-creation`);
    }
  }, [tab]);

  const allUnselected = useMemo(() => {
    return assetList.every(i=> !i.selected);
  }, [assetList]);

  const handleChangeTab = (path) => {
    mapsId !== 'new' && history.push(`${pathname}?tab=${path}`);
  };

  const handleCancel = () => {
    history.replace('/quant/approved-instruments/list');
  };

  const onSubmitMapCreation = (data) => {
    const newData = {
      ...data,
      // is_active: !!recommendationById?.is_active,
      asset_recommendation_mappings: selectedAssetClass,
    };
    console.log('🚀 ~ onSubmit ~ newData:', newData);
    //TODO: del after dispatching
    // history.push(`${pathname}?tab=approve-instruments`);
    dispatch(createRecommendationMaps({history, data: newData}));
  };

  return (
    <>
      <div className="instrument-wrapper instrument-block-wrap">
        <div className="instrument-inner-wrapper">
          <Header className="headerStyle">
            <Row className="overview-tabs-wrapper">
              <Col className="tabs-tab-wrapper" span={18}>
                <Button type="text" className="overview-btn ant-btn-active">
                  {t(mapsId === 'new' ? 'CREATE_MAP' : 'EDIT_MAP')}
                </Button>
              </Col>
            </Row>
            <div className="top-btn-wrapper">
              <Button
                className="portfolio-details-btn portfolio-export-btn"
                onClick={handleCancel}
              >
                {t('CANCEL')}
              </Button>
              <Button
                disabled={(editingKey && tab === 'approve-instruments') || allUnselected}
                onClick={() => form.submit()}
                className="portfolio-details-btn btn-edit-save"
              >
                {t('CONTINUE')}
              </Button>
            </div>
          </Header>
          <>
            <Row
              justify={'center'}
              align={'center'}
              className="maps-tabs-wrapper"
            >
              <Col className="model-tabs-column">
                <div
                  onClick={() => handleChangeTab('map-creation')}
                  className={`model-tab-btn-wrapper ${tab === 'map-creation' ? 'selected' : ''}`}
                >
                  <p className="model-tab-btn-title">{t('MAP_CREATION')}</p>
                  <div className="model-tab-btn-round" />
                </div>
              </Col>
              <Col className="model-tabs-column">
                <div
                  onClick={() => handleChangeTab('approve-instruments')}
                  className={`model-tab-btn-wrapper ${tab === 'approve-instruments' ? 'selected' : ''}`}
                >
                  <p className="model-tab-btn-title">
                    {t('APPROVE_INSTRUMENTS')}
                  </p>
                  <div className="model-tab-btn-round" />
                </div>
              </Col>
            </Row>
            {tab === 'map-creation' ? (
              <MapCreation
                mapsId={mapsId}
                form={form}
                onSubmit={onSubmitMapCreation}
                selectedAssetClass={selectedAssetClass}
                setSelectedAssetClass={setSelectedAssetClass}
                recommendationById={recommendationById}
                assetList={assetList}
                setAssetList={setAssetList}
              />
            ) : tab === 'approve-instruments' ? (
              <ApproveInstruments
                selectedAssetClass={selectedAssetClass}
                setSelectedAssetClass={setSelectedAssetClass}
                assetList={assetList}
                setAssetList={setAssetList}
                editingKey={editingKey}
                setEditingKey={setEditingKey}
              />
            ) : null}
          </>
        </div>
        <MainFooter />
      </div>
    </>
  );
};

export default RecommendationMapsItem;

RecommendationMapsItem.propTypes = {
  recommendation: PropTypes.array,
};
