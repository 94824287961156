/* eslint-disable no-unused-vars */
import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';

import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { useLocation, useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import cloneDeep from 'lodash/cloneDeep';

import { Button, Col, Form, Layout, Row, Select } from 'antd';
import Container from '../../components/Container';
import { ReactComponent as Upload } from '../../assets/upoad-icon.svg';
import { ReactComponent as File } from '../../assets/file-icon.svg';
import { useQuery } from '../../hooks/useQuery';
import CustomSelect from '../../components/CustomSelect/CustomSelect';
import { textSort } from '../../helpers/textSort';
import { generateDataObject } from '../../helpers/generateDateObject';
import { monthList } from '../../constants/monthList';
import CustomInputNumber from '../../components/CustomInputNumber/CustomInputNumber';
import { steps } from './ModelItems';
import { setModelSeries, tempDownloadConstraints, uploadModelSeries } from '../../data/store/instruments/instrumentsActions';

const { Header } = Layout;

const ModelStepIndexSeries = ({
  modelId,
  model,
  modelSeries,
  current,
  newDateRange,
}) => {
  const { t } = useTranslation('');
  const history = useHistory();
  const [form] = Form.useForm();
  const { i18n } = useTranslation('');
  const location = useLocation();
  const { pathname, search } = useLocation();
  const dispatch = useDispatch();

  const language = i18n.languages?.length ? i18n.languages[0] : 'en';
  const currentLang = ['en', 'sw'].includes(language) ? language : 'en';

  const query = useQuery(search);
  const asset = query.get('asset');

  const assets = useSelector((state) => state.instrumentsReducer.assets);
  const [selectedOptions, setSelectedOptions] = useState(null);
  const [selectedAsset, setSelectedAsset] = useState(null);
  const [series, setSeries] = useState(null);

  const inputXlsx = useRef(null);

  useEffect(() => {
    
    return () => {
      console.log('unmount');
      form.resetFields();
    };
  }, []);
  

  useEffect(() => {
    if (!newDateRange || !Object.values(newDateRange).every(i => !!i)) {
      history.replace(`${pathname}?step=settings`);
      return;
    }
    const {model_start_date, model_end_date} = newDateRange;
    if ( assets && model_start_date && model_end_date ) {
      const table = [...generateDataObject(model_start_date, model_end_date)];
      let series = [];
      const array = assets.slice()?.filter(i=> !i?.is_virtual)?.sort((a, b) => a.ID - b.ID);
      if (!modelSeries?.series.length) {
        array.forEach((i) => {
          const data = { asset_class_id: i.ID, values: table };
          series.push(data);
        });
      } else {
        array.forEach((asset) => {
          const newTable = [];
          table.forEach((el) => {
            const oldAssetSeries = modelSeries.series.find(
              (i) => i.asset_class_id === asset.ID,
            );
            const oldData = oldAssetSeries?.values?.find(
              (i) => i.year === el.year,
            )?.data;
            let newData;
            newData = cloneDeep(el);
            if (oldData) {
              for (const [month, value] of Object.entries(el.data)) {
                const fillBlanck = (data => {
                return data;
                }); 
                newData.data[month] =
                value === null
                ? null
                    : oldData[month] !== null
                      ? fillBlanck(oldData[month])
                      : value;
                    }
              }
              newTable.push( {year: el.year, ...newData});
          });
          const data = { asset_class_id: asset.ID, values: [...newTable] };
          series.push(data);
        });
      }
      setSeries(series);
    } 
    // else if (modelSeries?.series?.length) {
    //   if (!isChanged()) {
    //     setSeries(modelSeries.series);
    //   }
    // }
    setSelectedOptions(null);
    history.replace('?step=index-series');

  }, [newDateRange, assets, modelSeries]);

  const getStatusAsset = useCallback(
    (id) => {
      if (series?.length) {
        const assetDataForValidate = series.find(
          (i) => i.asset_class_id === id,
        );
        const result = [];
        assetDataForValidate?.values?.forEach((i) => {
          const array = Object.values(i.data);
          result.push(...array.filter((i) => i !== null));
        });
        return result.every((i) => !!i || i === 0)
          ? 'full'
          : result.every((i) => i === '')
            ? 'blank'
            : 'partially';
      }
      return 'blank';
    },
    [series],
  );

  const selectOptions = useMemo(() => {
    if (assets) {
      const options = assets?.filter(i=> !i?.is_virtual).map((i) => {
        const data = {
          id: i.ID,
          name: i.name,
          status: getStatusAsset(i.ID),
        };
        return data;
      });
      return options;
    }
    return null;
  }, [assets, series, modelSeries]);

  useEffect(() => {
    if (asset && selectOptions) {
      setSelectedOptions(+asset);
      form.resetFields();
      const data = series?.find((i) => i.asset_class_id === +asset);
      setSelectedAsset(data);
      form.setFieldsValue(data);
    } else if (!asset && selectOptions && selectedOptions === null) {
      const assetSort = selectOptions.sort((a, b) =>
        textSort(a.name, b.name, 1),
      );
      const firstAsset = assetSort ? assetSort[0]?.id || 0 : 0;
      history.push(`?step=index-series&asset=${firstAsset}`);
    }
  }, [asset, location]);

  const handleUpload = () => {
    if (inputXlsx.current) {
      inputXlsx.current.click();
    }
  };

  const uploadPortfolio = (event) => {
    const file = event.target.files[0];
    dispatch(uploadModelSeries({id: modelId, file}));
    if (inputXlsx.current) {
      console.log('Clear input');
      inputXlsx.current.value = null;
    }
  };

  const handleChangeAssets = (value) => {
    history.push(`?step=index-series&asset=${value}`);
  };

  const hendleChangeValue = (name, month, value) => {
    let newData;
    const values = form.getFieldsValue();
    if (value !== null) {
      newData = { asset_class_id: +asset, ...values };
    } else {
      values.values[name].data[month] = '';
      newData = { asset_class_id: +asset, ...values };
    }
    const newSeries = series.map((i) =>
      i.asset_class_id === newData.asset_class_id ? newData : i,
    );
    setSeries(newSeries);
  };

  const getPoint = (key) => {
    return steps.find((i) => i.key === key)?.value;
  };

  const handleCancel = () => {
    history.replace(`${pathname}?step=settings`);
  };

  const handleContinue = () => {
    history.push(`${pathname}?step=${getPoint(current + 1)}`);
  };

  const handleNextStep = useMemo(() => {
    const isValid = selectOptions
      ?.map((i) => i.status)
      .every((i) => i === 'full');
    return !isValid || !model;
  }, [selectOptions]);

  const download = () => {
    dispatch(tempDownloadConstraints({id: modelId, data:newDateRange}));
  };

  const onSubmit = () => {
    dispatch(
      setModelSeries({
        callback: handleContinue,
        id: model.id,
        seriesSetId: model.series_set_id,
        data: { ...newDateRange, series },
      }),
    );
  };

  return (
    <>
      <Header className="headerStyle">
        <Row className="overview-tabs-wrapper">
          <Col className="tabs-tab-wrapper" span={18}>
            <Button type="link" className="overview-btn ant-btn-active">
              {t('NEW_MODEL')}
            </Button>
          </Col>
        </Row>
        <div className="top-btn-wrapper">
          <Button
            className="portfolio-details-btn portfolio-export-btn"
            onClick={handleCancel}
          >
            {t('BACK_TO_SETTINGS')}
          </Button>
          <Button
            disabled={handleNextStep}
            onClick={() => form.submit()}
            className="portfolio-details-btn btn-edit-save"
          >
            {t('CONTINUE')}
          </Button>
        </div>
      </Header>
      <Row justify={'space-between'}>
        <Col>
          <h2 style={{margin: '24px 0'}} className="out-container-title">{t('INDEX_SERIES')}</h2>
        </Col>
        <Col className="titlerow-btn-wrapper download-series">
          <a onClick={download} className="download-import-temp">
            {t('DOWNLOAD_THE_TEMPLATE_EXCEL')}
            <File />
          </a>
          <Button
            type="text"
            className="portfolio-creat-download upload-model-btn"
            icon={<Upload />}
            iconPosition={'end'}
            onClick={handleUpload}
          >
            {t('UPLOAD_FROM_EXEL')}
          </Button>
          <input
            ref={inputXlsx}
            type="file"
            accept={'.xlsx'}
            className="portfolio-creat-download-input"
            onChange={uploadPortfolio}
          />
        </Col>
      </Row>
      <div style={{ padding: '0 24px' }}>
        <Container widthP={100} mb="24px" padding="48px 0">
          <Form
            form={form}
            name="onboarding-form"
            layout="vertical"
            onFinish={onSubmit}
            className="instrument-form"
            autoComplete="off"
          >
            <Row justify={'start'} style={{ padding: '24px' }}>
              <CustomSelect
                placeholder={t('SELECT_ASSET_CLASS')}
                className="custom-select asset-select-models"
                onChange={(value) => handleChangeAssets(value)}
                value={selectedOptions}
              >
                {selectOptions
                  ?.slice()
                  ?.sort((a, b) => textSort(a.name, b.name, 1))
                  .map((item) => {
                    return (
                      <Select.Option key={item.id} value={item.id}>
                        <div className={`asset-option-status ${item.status}`} />
                        <div className="">{item.name}</div>
                      </Select.Option>
                    );
                  })}
              </CustomSelect>
            </Row>
            <Row justify={'space-between'} className="model-series-headers">
              <Col className="model-series-headers-cell"> </Col>
              {monthList[currentLang].map((i) => (
                <Col key={i} className="model-series-headers-cell">
                  {i}
                </Col>
              ))}
            </Row>
            <Form.List name="values">
              {(fields) => (
                <>
                  {fields.map(({ key, name }) => {
                    return (
                      <Row
                        key={key}
                        className={`model-series-row ${key % 2 !== 0 && 'is-odd'}`}
                      >
                        <Col className="model-series-row-cell">
                          {selectedAsset?.values[name].year}
                        </Col>
                        {Object.keys(selectedAsset.values[name].data).map(
                          (month) => (
                            <Col
                              key={month}
                              className={'model-series-row-cell'}
                            >
                              {selectedAsset.values[name].data[month] !==
                              null ? (
                                <Form.Item name={[name, 'data', month]}>
                                  <CustomInputNumber
                                    precision={2}
                                    min={0}
                                    onChange={(value) =>
                                      hendleChangeValue(name, month, value)
                                    }
                                    className="custom-inputnumber model-series"
                                    controls={false}
                                  />
                                </Form.Item>
                              ) : null}
                            </Col>
                          ),
                        )}
                      </Row>
                    );
                  })}
                </>
              )}
            </Form.List>
          </Form>
        </Container>
      </div>
    </>
  );
};

export default ModelStepIndexSeries;

ModelStepIndexSeries.propTypes = {
  modelId: PropTypes.string,
  model: PropTypes.object,
  modelSeries: PropTypes.object,
  current: PropTypes.number,
  newDateRange: PropTypes.object,
};
