import React from 'react';

import PropTypes from 'prop-types';

import { Input } from 'antd';
import {ReactComponent as Eye} from '../../assets/eye-dark-open-icon.svg';
import {ReactComponent as EyeClose} from '../../assets/eye-dark-close-icon.svg';
import './style.scss';

const CustomPasswordInput = ({onChange, className, ...rest}) => {
  const classN = `custom-password ${className}`;
  return (
    <Input.Password
      {...rest}
      className={classN}
      style={{width: '360px'}}
      styles={{input: {padding: '0 14px'}}}
      onChange={onChange}
      iconRender={(visible) => (visible ? <EyeClose /> : <Eye />)}
    />
  );
};

export default CustomPasswordInput;

CustomPasswordInput.propTypes = {
  onChange: PropTypes.func,
  className: PropTypes.string,
};