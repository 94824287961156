/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from 'react';

import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import { 
  useDispatch, 
  } from 'react-redux';
import { useHistory } from 'react-router-dom';

import { Button, Col, Layout, Modal, Row, Table, } from 'antd';

import Container from '../../components/Container';
import MainFooter from '../../components/MainFooter';
import { ReactComponent as Edit } from '../../assets/edit-icon.svg';
import { ReactComponent as Delete } from '../../assets/delete-black-icon.svg';

import { textSort } from '../../helpers/textSort';
import dayjs from 'dayjs';

import './style.scss';

const { Header } = Layout;

const RecommendationMapsList = ({recommendation}) => {
  const { t } = useTranslation('');
  const dispatch = useDispatch();
  const history = useHistory();

  

  const [deleteKey, setDeleteKey] = useState(null);

  useEffect(() => {
  }, []);

  const handleCreate = () => {
    history.push('/quant/approved-instruments/edit/new', { key: 'edit' });
  };

  const hendleEdit = (id) => {
    history.push(`/quant/approved-instruments/edit/${id}`, { key: 'edit' });
  };

  const handleDelete = (key) => {
    setDeleteKey(key);
  };
  const onDeleteInstrument = () => {
    // dispatch(deleteInstrument(+daleteKey));
    handleCloseDelModal();
  };
  const handleCloseDelModal = () => {
    setDeleteKey(null);
  };

  // const getCurrencyById = (id) => {
  //   return currencyList?.find(el => el.id === id)?.name;
  // };

  const columns = [
    {
      title: t('MAP'),
      dataIndex: 'name',
      className: 'name',
      render: (value, record) => {
        return (
          <>
            <p className='model-table-name'>{value}</p>
            <p className='model-table-subname'>{dayjs(record?.create_time).format('DD/MM/YYYY')} at {dayjs(record?.create_time).format('HH:mm')}</p>
          </>
        );
      },
      sorter: (a, b) => textSort(a.name, b.name),
      width: '17%',
    },
    {
      title: t('DESCRIPTION'),
      dataIndex: 'description',
      className: 'asset-class',
      width: '17%',
    },
    {
      title: t('ACTIONS'),
      align: 'center',
      dataIndex: 'operation',
      width: '8%',
      render: (_, record) => {
        return (
        <div className="cell-button-wrapper">
          <Button
            type='text'
            onClick={() => hendleEdit(record.id)} >
            <Edit />
          </Button>
          <Button
            type='text'
            onClick={() => handleDelete(record.id)}>
            <Delete />
          </Button>
          </div>
        );
      },
    },
  ];
  return (
    <>
      <div className="instrument-wrapper instrument-block-wrap">
        <div className="instrument-inner-wrapper">
          <Header className="headerStyle">
            <Row className="overview-tabs-wrapper">
              <Col className="tabs-tab-wrapper" span={18}>
                <Button
                  type="text"
                  className='overview-btn ant-btn-active'>
                  {t('MAPS')}
                </Button>
              </Col>
            </Row>
          </Header>
          <div className="top-btn-wrapper">
            <Button
              onClick={handleCreate}
              className="portfolio-details-btn optimise-btn"
            >
              {t('CREATE_MAP')}
            </Button>
          </div>
          <h2 className="out-container-title list-title">{t('ACTIVE_MAPS')}</h2>
          <Container widthP={100} mb="28px" padding="24px 0 0">
          <Table
            rowKey={(record) => record.id}
            rowClassName={(_, index) =>
              index % 2 === 0 ? 'editable-row' : 'editable-row is-odd'
            }
            style={{ width: '100%' }}
            bordered
            dataSource={recommendation?.filter(i=> i.is_active)}
            columns={columns}
            pagination={false}
          />
          </Container>
          <h2 className="out-container-title list-title">{t('DRAFT_MAPS')}</h2>
          <Container widthP={100} mb="28px" padding="24px 0 0">
          <Table
            rowKey={(record) => record.id}
            rowClassName={(_, index) =>
              index % 2 === 0 ? 'editable-row' : 'editable-row is-odd'
            }
            style={{ width: '100%' }}
            bordered
            dataSource={recommendation?.filter(i=> !i.is_active)}
            columns={columns}
            pagination={false}
          />
          </Container>
        </div>
        <MainFooter />
      </div>
      <Modal
        width={650}
        className="instrument-del-modal"
        centered
        open={deleteKey}
        onCancel={handleCloseDelModal}
        footer={false}
        zIndex={1700}
      >
        <h2 className="del-modal-header">{t('CONFIRM_DELETION')}</h2>
        <p className="del-modal-text">{t('TEXT_DELETION_INSTRUMENT')}</p>
        <p className="del-modal-name">
          {'----'}
        </p>
        <Row
          gutter={16}
          justify={'center'}
          align={'middle'}
          style={{ marginTop: '50px' }}
        >
          <Col>
            <Button className="del-cancel-button" onClick={handleCloseDelModal}>
              {t('CANCEL')}
            </Button>
          </Col>
          <Col>
            <Button className="del-ok-button" onClick={onDeleteInstrument}>
              {t('DELETE')}
            </Button>
          </Col>
        </Row>
      </Modal>
    </>
  );
};

export default RecommendationMapsList;

RecommendationMapsList.propTypes = {
  recommendation: PropTypes.array,
};