import React, { useContext, useEffect } from 'react';
import PropTypes from 'prop-types';

import { Form, Select } from 'antd';
import { EditableContext } from './ExcludeHoldings';
import CustomSelect from '../../components/CustomSelect/CustomSelect';
import { textSort } from '../../helpers/textSort';

const EditableCell = ({
  title,
  editing,
  children,
  dataIndex,
  record,
  handleSave,
  availableInstrument,
  instrumentsForExclude,
  ...restProps
}) => {
  const form = useContext(EditableContext);

  useEffect(() => {
    if (dataIndex) {
      form.setFieldsValue({
        [dataIndex]: record[dataIndex],
      });
    }
  }, [dataIndex]);

  const getInstrumentName = (id) => {
    const instrument = instrumentsForExclude.find((i) => i.id === id);
    return instrument?.instrument_name || '----';
  };

  const save = async () => {
    try {
      const values = await form.getFieldsValue();
      handleSave({
        ...record,
        ...values,
      });
    } catch (errInfo) {
      console.log('Save failed:', errInfo);
    }
  };

  if (editing) {
    return (
      <>
        {dataIndex === 'id'
          ? (
          <td {...restProps}>
            <Form.Item
              style={{
                margin: 0,
              }}
              name={dataIndex}
            >
              <CustomSelect
                width="100%"
                className="custom-select"
                onSelect={save}
                placeholder={record[title]}
              >
                {availableInstrument
                  ?.slice()
                  ?.sort((a, b) => textSort(a.instrument_name, b.instrument_name))
                  ?.map(({ id }) => (
                    <Select.Option key={id} value={id}>
                      {getInstrumentName(id)}
                    </Select.Option>
                  ))}
              </CustomSelect>
            </Form.Item>
          </td>
            )
          : (
          <td {...restProps}>{children}</td>
            )}
      </>
    );
  } else {
    return <td {...restProps}>{children}</td>;
  }
};

export default EditableCell;

EditableCell.propTypes = {
  title: PropTypes.node,
  editing: PropTypes.bool,
  children: PropTypes.node,
  dataIndex: PropTypes.string,
  record: PropTypes.object,
  handleSave: PropTypes.func,
  availableInstrument: PropTypes.array,
  instrumentsForExclude: PropTypes.array,
};
