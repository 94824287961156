/* eslint-disable no-unused-vars */
import React, { useMemo } from 'react';

import PropTypes from 'prop-types';

import { Switch, Route, useRouteMatch, Redirect, useLocation, useHistory } from 'react-router-dom';

import { Button, Col, Layout, Row } from 'antd';
import ClientProfile from '../../components/ClientProfile/ClientProfile';
import { useTranslation } from 'react-i18next';
import OnboardingRouting from './OnboardingRouting';
import { useDispatch } from 'react-redux';
import { delOnboarding } from '../../data/store/account/accountActions';

const { Header } = Layout;

const ProfileRouting = ({ userId, setUserId, needSave, setNeedSave, saveAlert, setSaveAlert }) => {
  const { url } = useRouteMatch();
  const dispatch = useDispatch();
  const { t } = useTranslation('');
  const { pathname } = useLocation();
  const history = useHistory();

  const suffix = useMemo(() => {
    return pathname.split('/').at(4);
  }, [pathname]);

  const handleTab = (path) => {
    if (needSave) {
      setSaveAlert({ flag: true, path });
    } else {
      history.push(`${url}/${path}`);
    }
  };

  const handleDelete = () => {
    console.log('Del onboarding');

    dispatch(delOnboarding(userId));
  };

  return (
    <>
      <Header className='headerStyle'>
          <Row justify={'space-between'} className="overview-tabs-wrapper">
              <Col className='tabs-tab-wrapper'>
                <Button
                  type="text"
                  className={`overview-btn ${suffix === 'profile' ? 'ant-btn-active' : ''}`}
                  onClick={() => handleTab('profile')}>
                  {t('PROFILE')}
                </Button>
                <Button
                  type="text"
                  className={`overview-btn ${suffix === 'onboarding' ? 'ant-btn-active' : ''}`}
                  onClick={() => handleTab('onboarding')}>
                  {t('ONBOARDING')}
                </Button>
              </Col>
              {pathname?.split('/')[4] === 'onboarding' && <Col>
              <Button
                  type="text"
                  className=''
                  onClick={handleDelete}>
                  {t('DELETE')} {t('ONBOARDING').toLowerCase()}
                </Button>
              </Col>}
          </Row>
      </Header>

      <Switch>
        <Route path={`${url}/profile`}>
          <ClientProfile
            userId={userId}
            setUserId={setUserId}
            needSave={needSave}
            setNeedSave={setNeedSave}
            saveAlert={saveAlert}
            setSaveAlert={setSaveAlert}
            />
        </Route>
        <Route path={`${url}/onboarding`}>
          {/* <Redirect to={`${url}/profile`} /> */}
          <OnboardingRouting
            userId={userId}
          />
        </Route>
        <Route exact path={`${url}`}>
          <Redirect to={`${url}/profile`} />
        </Route>
        <Route exact path="*">
          <Redirect to={`${url}/profile`} />
        </Route>
      </Switch>
    </>
  );
};

export default ProfileRouting;

ProfileRouting.propTypes = {
  userId: PropTypes.string,
  setUserId: PropTypes.func,
  needSave: PropTypes.bool,
  setNeedSave: PropTypes.func,
  saveAlert: PropTypes.object,
  setSaveAlert: PropTypes.func,
};
