import React from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';

import {
  FORGOT_PASSWORD,
  LOGIN,
  SIGN_UP,
  SIGN_UP_CONFIRM,
  SET_USER_PASSWORD,
  CONFIRM_EMAIL,
  TWO_FACTOR,
} from '../../constants/routes';
import ForgotPassword from '../../components/ForgotPassword';
import SignUp from '../../components/SignUp';
import { Layout } from 'antd';
// import { useTranslation } from 'react-i18next';
import SignUpConfirmSuccess from '../../components/SignUp/SignUpConfirmSuccess';
import ResetPassword from '../../components/ResetPassword';
import ErrorNotification from '../../components/ErrorNotification';
import SetUserPassword from '../../components/SetUserPassword';
import ConfirmEmail from '../../components/ConfirmEmail/ConfirmEmail';
import TwoFactorPage from '../TwoFactorPage/TwoFactorPage';

import LoginPage from '../LoginPage/LoginPage';
import PropTypes from 'prop-types';

import './style.scss';

const { Content } = Layout;

const AuthRouting = ({theme}) => {
  // const { t } = useTranslation('');

  return (
    <Layout className="layout-auth">
      <Content>
        <Switch>
          <Route path={LOGIN} >
            <LoginPage theme={theme}/>
          </Route>
          <Route exact path={SIGN_UP} >
            <SignUp theme={theme}/>

          </Route>
          {/* Reset Password for Advisor */}
          <Route path={SET_USER_PASSWORD} >
            <SetUserPassword theme={theme}/>
          </Route>
          <Route path="/auth/reset-password-verify-code">
            <ResetPassword theme={theme}/>
          </Route>
          <Route exact path={CONFIRM_EMAIL} component={ConfirmEmail} />
          <Route
            exact
            path={SIGN_UP_CONFIRM} >
            <SignUpConfirmSuccess theme={theme} />
          </Route>
          <Route exact path={FORGOT_PASSWORD} >
            <ForgotPassword theme={theme} />
          </Route>
          <Route exact path={TWO_FACTOR} component={TwoFactorPage} />
          <Route path="*">
            <Redirect to={LOGIN} />
          </Route>
        </Switch>
      </Content>
      <ErrorNotification />
    </Layout>
  );
};

export default AuthRouting;

AuthRouting.propTypes = {
  theme: PropTypes.string,
};