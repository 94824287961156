import React from 'react';

import { useParams } from 'react-router-dom';
// import PropTypes from 'prop-types';

import { Switch, Route, useRouteMatch } from 'react-router-dom';

import ClientOverview from '../ClientOverview/ClientOverview';


const AccountRouting = () => {
  const { url } = useRouteMatch();
  const { accountId } = useParams();
  console.log('🚀 ~ AccountRouting ~ accountId:', accountId);
  

  return (
    <Switch>
      <Route path={`${url}/overview`}>
        <ClientOverview accountId={accountId}/>
      </Route>
      <Route path={`${url}/account-details`}>
        <div style={{color: '#ffffff', textAlign: 'center', marginTop: '50px'}}>Account details</div>
      </Route>
    </Switch>
  );
};

export default AccountRouting;

AccountRouting.propTypes = {
};
