import React, { useEffect } from 'react';

import PropTypes from 'prop-types';

import { Button, Collapse, Form, Radio, Select, Space } from 'antd';
import CustomSelect from '../../../components/CustomSelect/CustomSelect';
import { CustomTextarea } from '../../../components/CustomTextfield/CustomTextfield';
import CustomInputNumber from '../../../components/CustomInputNumber/CustomInputNumber';
import CustomCheckbox from '../../../components/CustomCheckbox/CustomCheckbox';
import { assetExperienceOptions, managementExperienceOptions } from '../data/SelectOptions';

import { ReactComponent as Icon } from '../../../assets/collaps-icon.svg';
import CustomRadioButtom from '../../../components/CustomRadioButtom/CustomRadioButtom';
import { useDispatch } from 'react-redux';
import { setOnboardingVerifed } from '../../../data/store/account/accountActions';

const KnowledgeDiffTypesItem = ({ t, name }) => {
  return (
    <>
      <Form.Item
        className="custom-required"
        name={[
          'experience',
          'assets_type_knowledge',
          'asset_type',
          name,
          'deals_number',
        ]}
        label={t('DEALS_NUMBER')}
      >
        <CustomInputNumber
          placeholder={t('AMOUNT')}
          width={'360px'}
          min={0}
        />
      </Form.Item>
      <Form.Item
        label={t('ASSET_EXPERIENCE_LABEL')}
        name={[
          'experience',
          'assets_type_knowledge',
          'asset_type',
          name,
          'asset_experience',
        ]}
        className="custom-required"
        required={false}
      >
        <CustomSelect
          width={'360px'}
          className="custom-select"
          placeholder={t('SELECT_YOUR_OPTION')}
        >
          {assetExperienceOptions?.map((i) => (
            <Select.Option key={i.value} value={i.value}>
              {t(i.label)}
            </Select.Option>
          ))}
        </CustomSelect>
      </Form.Item>
      <Form.Item
        name={[
          'experience',
          'assets_type_knowledge',
          'asset_type',
          name,
          'asset_trained',
        ]}
        valuePropName="checked"
        className="filter-check-item"
      >
        <CustomCheckbox >{t('PORTFOLIO_TRAINED_LABEL')}</CustomCheckbox>
      </Form.Item>
      <Form.Item
        name={[
          'experience',
          'assets_type_knowledge',
          'asset_type',
          name,
          'risk_understanding',
        ]}
        valuePropName="checked"
        normalize={(value) => value ?? false}
        className="filter-check-item"
      >
        <CustomCheckbox>{t('RISK_UNDERSTANDING_LABEL')}</CustomCheckbox>
      </Form.Item>
    </>
  );
};

const KnowledgeDiffTypesLabel = ({ index, label }) => {
  return (
    <div className="types-label-wrapper">
      <div className="types-label-number">{index}</div>
      <p className="types-label-title">{label}</p>
    </div>
  );
};

const CategoryExperienceBackground = ({ userId, t, onboardingData }) => {
  const [form] = Form.useForm();
  const dispatch = useDispatch();

  useEffect(() => {
    if (onboardingData) {
      form.setFieldsValue(onboardingData?.result);
    }
  }, [onboardingData]);

  const handleSaveOnbording = () => {
    form.submit();
  };

  const onSubmit = (data) => {
    const result = { ...onboardingData.result, ...data };
    dispatch(setOnboardingVerifed({ userId, id: onboardingData.id, data: { result } }));
  };

  const knowledgeDiffTypes = [
    {
      key: '1',
      label: <KnowledgeDiffTypesLabel index={1} label={t('LISTED_SHARES')} />,
      children: <KnowledgeDiffTypesItem t={t} name="listed_shares" />,
      forceRender: true,
    },
    {
      key: '2',
      label: <KnowledgeDiffTypesLabel index={2} label={t('UNLISTED_SHARES')} />,
      children: <KnowledgeDiffTypesItem t={t} name="unlisted_shares" />,
      forceRender: true,
    },
    {
      key: '3',
      label: <KnowledgeDiffTypesLabel index={3} label={t('UCITS_FUNDS')} />,
      children: <KnowledgeDiffTypesItem t={t} name="ucits_funds" />,
      forceRender: true,
    },
    {
      key: '4',
      label: <KnowledgeDiffTypesLabel index={4} label={t('SPECIAL_FUNDS')} />,
      children: <KnowledgeDiffTypesItem t={t} name="aif_funds" />,
      forceRender: true,
    },
    {
      key: '5',
      label: <KnowledgeDiffTypesLabel index={5} label={t('LISTED_BONDS')} />,
      children: <KnowledgeDiffTypesItem t={t} name="listed_bonds" />,
      forceRender: true,
    },
    {
      key: '6',
      label: <KnowledgeDiffTypesLabel index={6} label={t('UNLISTED_BONDS')} />,
      children: <KnowledgeDiffTypesItem t={t} name="unlisted_bonds" />,
      forceRender: true,
    },
    {
      key: '7',
      label: (
        <KnowledgeDiffTypesLabel index={7} label={t('EQUITY_INDEX_BONDS')} />
      ),
      children: <KnowledgeDiffTypesItem t={t} name="equity_index_bonds" />,
      forceRender: true,
    },
    {
      key: '8',
      label: <KnowledgeDiffTypesLabel index={8} label={t('HEDGE_FUNDS')} />,
      children: <KnowledgeDiffTypesItem t={t} name="hedge_funds" />,
      forceRender: true,
    },
    {
      key: '9',
      label: (
        <KnowledgeDiffTypesLabel index={9} label={t('CERTIFICATE_OF_CREDIT')} />
      ),
      children: <KnowledgeDiffTypesItem t={t} name="certificate_of_credit" />,
      forceRender: true,
    },
    {
      key: '10',
      label: <KnowledgeDiffTypesLabel index={10} label={t('AUTOCALLS')} />,
      children: <KnowledgeDiffTypesItem t={t} name="autocalls" />,
      forceRender: true,
    },
    {
      key: '11',
      label: (
        <KnowledgeDiffTypesLabel
          index={11}
          label={t('EXCHANGE_TRADED_FUNDS')}
        />
      ),
      children: <KnowledgeDiffTypesItem t={t} name="etf" />,
      forceRender: true,
    },
    {
      key: '12',
      label: (
        <KnowledgeDiffTypesLabel
          index={12}
          label={t('DERIVATIVES_AND_COMMODITY')}
        />
      ),
      children: (
        <KnowledgeDiffTypesItem t={t} name="derivatives" />
      ),
      forceRender: true,
    },
  ];

  return (
    <div className="card-right-wrap">
      <div style={{ width: '327px', margin: '0 auto' }}>
        <h2 className="onbording-card-header complet-step">
          {t('GENERAL_INFORMATION')}
        </h2>
      </div>
      <Form
        form={form}
        name="onboarding-form"
        layout="vertical"
        onFinish={onSubmit}
        className="sign-up-form"
        initialValues={{
          experience: {
            assets_type_knowledge: {
              asset_type: {
                listed_shares: { asset_trained: false, risk_understanding: false },
                unlisted_shares: { asset_trained: false, risk_understanding: false },
                ucits_funds: { asset_trained: false, risk_understanding: false },
                special_funds: { asset_trained: false, risk_understanding: false },
                listed_bonds: { asset_trained: false, risk_understanding: false },
                unlisted_bonds: { asset_trained: false, risk_understanding: false },
                equity_index_bonds: { asset_trained: false, risk_understanding: false },
                hedge_funds: { asset_trained: false, risk_understanding: false },
                certificate_of_credit: { asset_trained: false, risk_understanding: false },
                autocalls: { asset_trained: false, risk_understanding: false },
                exchange_traded_funds: { asset_trained: false, risk_understanding: false },
                derivatives_and_commodity: { asset_trained: false, risk_understanding: false },
              },
            },
          }
        }}
      >
        <Form.Item
          name={['experience', 'general_info', 'fin_education']}
          className="onboarding-textarea-purpose custom-required"
          label={t('FIN_EDUCATION')}
        >
          <CustomTextarea rows={4} placeholder={t('YOUR_ANSWER')} minLength={200}/>
        </Form.Item>
        <Form.Item
          className="custom-required"
          name={['experience', 'general_info', 'trading_period']}
          label={t('TRADING_PERIOD')}
        >
          <CustomInputNumber placeholder={t('0,00')} min={0}/>
        </Form.Item>

        <div style={{ width: '327px', margin: '0 auto' }}>
          <h2 className="onbording-card-header complet-step">
            {t('KNOWLEDGE_TYPES_ASSETS')}
          </h2>
        </div>
        <Collapse
          accordion
          bordered={false}
          defaultActiveKey={1}
          expandIconPosition={'end'}
          ghost
          expandIcon={<Icon />}
          items={knowledgeDiffTypes}
        />
        <Form.Item
          name={['experience', 'assets_type_knowledge', 'additional_comment']}
          className="onboarding-textarea-purpose"
          label={t('ASSETS_TYPE_KNOWLEDGE_COMMENT')}>
          <CustomTextarea rows={4} placeholder={t('YOUR_TEXT')} maxLength={400}/>
        </Form.Item>
        <div style={{ width: '327px', margin: '0 auto' }}>
          <h2 className="onbording-card-header complet-step">
            {t('KNOWLEDGE_PORTFOLIO_MANAGEMENT')}
          </h2>
        </div>
        <Form.Item
          label={t('MANAGEMENT_EXPERIENCE_LABEL')}
          name={['experience', 'portfolio_management', 'management_experience']}
          className="custom-required"
          required={false}>
          <CustomSelect
            width={'360px'}
            className="custom-select"
            placeholder={t('SELECT_YOUR_OPTION')}>
            {managementExperienceOptions?.map((i) => (
              <Select.Option key={i.value} value={i.value}>
                {t(i.label)}
              </Select.Option>
            ))}
          </CustomSelect>
        </Form.Item>
        <div style={{ margin: '24px 0' }}>
          <Form.Item
            name={['experience', 'portfolio_management', 'portfolio_trained']}
            valuePropName="checked"
            normalize={(value) => value ?? false}
            className="filter-check-item">
            <CustomCheckbox>{t('PORTFOLIO_TRAINED_LABEL')}</CustomCheckbox>
          </Form.Item>
          <Form.Item
            name={['experience', 'portfolio_management', 'portfolio_understanding']}
            valuePropName="checked"
            normalize={(value) => value ?? false}
            className="filter-check-item">
            <CustomCheckbox>{t('PORTFOLIO_UNDERSTANDING_LABEL')}</CustomCheckbox>
          </Form.Item>
        </div>
        <Form.Item
          name={['experience', 'portfolio_management', 'additional_comment']}
          className="onboarding-textarea-purpose"
          label={t('PORTFOLIO_MANAGEMENT_COMMENT')}>
          <CustomTextarea rows={4} placeholder={t('YOUR_TEXT')} maxLength={400} />
        </Form.Item>
        <div style={{ width: '327px', margin: '0 auto' }}>
          <h2 className="onbording-card-header complet-step">
            {t('RISK_OF_LOSS')}
          </h2>
        </div>
        <Form.Item
          style={{ marginBottom: '40px' }}
          label={t('LOSS_RISK')}
          name={['experience', 'risk_of_loss', 'loss_risk']}
          className="m-top-12 m-bottom-0 custom-required" >
          <Radio.Group className="checkbox-block">
            <Space direction="horizontal" align="start" className='ondoarding-space-wrap'>
              <CustomRadioButtom value={false}>
                {t('NO')}
              </CustomRadioButtom>
              <CustomRadioButtom value={true}>
                {t('YES')}
              </CustomRadioButtom>
            </Space>
          </Radio.Group>
        </Form.Item>
        <Button
          disabled={false}
          className="optimise-btn start-onbording-btn"
          onClick={handleSaveOnbording}>
          {t('SAVE')}
        </Button>
      </Form>
    </div>
  );
};

export default CategoryExperienceBackground;

CategoryExperienceBackground.propTypes = {
  userId: PropTypes.string,
  t: PropTypes.func,
  onboardingData: PropTypes.object,
  setOnboardingData: PropTypes.func,
};

KnowledgeDiffTypesItem.propTypes = {
  t: PropTypes.func,
  name: PropTypes.string,
};

KnowledgeDiffTypesLabel.propTypes = {
  index: PropTypes.number,
  label: PropTypes.string,
};
