/* eslint-disable camelcase */
import React, { useState } from 'react';

import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';

import { useTranslation } from 'react-i18next';

import { Button, Col, Modal, Row } from 'antd';
import { executeApprovedPortfolio, getCandidatTransactions } from '../../data/store/account/accountActions';
import TableBuySell from './TableBuySell';

const ModalTransaction = ({
  openModalTransaction,
  closeModalTransaction,
  approved,
}) => {
  const { t } = useTranslation('');
  const dispatch = useDispatch();

  const [tab, setTab] = useState('buy');
  const [setstepModal, setSetstepModal] = useState(0);

  const buy = useSelector(
    (state) => state.accountReducer.transactionButSell?.buy
  );
  const sell = useSelector(
    (state) => state.accountReducer.transactionButSell?.sell
  );
  const { isLoading } = useSelector((state) => state.accountReducer);

  const getCandidate = () => {
    const candidat = approved?.find((i) => i.id === openModalTransaction);
    return candidat;
  };

  const handleTabsSelect = (tag) => {
    setTab(tag);
  };

  const handleUpdate = () => {
    dispatch(
      getCandidatTransactions({
        portfolio_id: getCandidate()?.portfolio_id,
        candidatId: openModalTransaction,
      })
    );
  };

  const handleExecute = () => {
    setSetstepModal(1);
  };

  const handleExecuteAction = () => {
    const data = {
      callBack: closeModalTransaction,
      candidatId: openModalTransaction,
      portfolioId: getCandidate()?.portfolio_id
    };
    dispatch(executeApprovedPortfolio(data));
  };

  return (
    <Modal
      width={setstepModal ? 650 : 1100}
      className="modal-translation"
      open={openModalTransaction}
      onCancel={closeModalTransaction}
      destroyOnClose={true}
      zIndex={1600}
      footer={null}
    >
      {setstepModal === 0
        ? (
        <>
          <Row className="modal-transaction-header">
            <Col className="tabs-tab-wrapper">
              <Button
                type="text"
                className={`overview-btn ${
                  tab === 'buy' ? 'ant-btn-active' : ''
                }`}
                onClick={() => handleTabsSelect('buy')}
              >
                {t('BUY')}
              </Button>
              <Button
                type="text"
                className={`overview-btn ${
                  tab === 'sell' ? 'ant-btn-active' : ''
                }`}
                onClick={() => handleTabsSelect('sell')}
              >
                {t('SELL')}
              </Button>
            </Col>
            <Col className="modal-action-btn-wrap">
              <Button
                className="modal-action-btn cancel-btn"
                onClick={handleUpdate}
              >
                {t('UPDATE')}
              </Button>
              <Button
                className="modal-action-btn optimise-btn"
                onClick={handleExecute}
              >
                {t('QUANTIFY')}
              </Button>
            </Col>
          </Row>
          <div className="transaction-table-wrap">
            {(buy || sell) && (
              <TableBuySell
                tab={tab}
                dataBuySell={tab === 'buy' ? buy : sell}
                isLoading={isLoading}
              />
            )}
          </div>
        </>
          )
        : (
        <>
          <Row justify="center">
            <Col className="modal-title">{t('CONFIRM_QUANTIFICATION')}</Col>
          </Row>
          <Row justify="center" className="modal-text-wrapper">
            <Col>
              <p className="modal-advisor-text">{t('WANT_ORDER_EXECUTED')}</p>
              <p className="modal-advisor-name">{getCandidate()?.name}</p>
            </Col>
          </Row>
          <Row className="modal-advisor-btn-wrapper">
            <Button
              className="modal-action-btn cancel-btn"
              onClick={closeModalTransaction}
            >
              {t('CANCEL')}
            </Button>
            <Button
              className="modal-action-btn save-btn"
              onClick={handleExecuteAction}
            >
              {t('QUANTIFY')}
            </Button>
          </Row>
        </>
          )}
    </Modal>
  );
};

export default ModalTransaction;

ModalTransaction.propTypes = {
  approved: PropTypes.array,
  openModalTransaction: PropTypes.number,
  closeModalTransaction: PropTypes.func,
};
