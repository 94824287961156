import React, { useCallback, useState, useEffect } from 'react';

import { useSelector, useDispatch } from 'react-redux';

import {
  useHistory,
} from 'react-router-dom';

import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';

import { Typography, Switch, Slider, InputNumber } from 'antd';
import TextArea from 'antd/lib/input/TextArea';

import Container from '../../../components/Container';
import MainButtonArray from '../../../components/MainButtonArray';
import { Menu } from '../../../assets';
import './style.scss';
import { getAccount, getClientData, setClientData } from '../../../data/store/account/accountActions';

const { Title } = Typography;

const FinancialSituationPartOne = (props) => {
  const { id } = props;
  const history = useHistory();
  const { t } = useTranslation('');
  const dispatch = useDispatch();
  const { user } = useSelector((state) => state.accountReducer);

  useEffect(() => {
    if (!user?.ID) {
      dispatch(getAccount({ id }));
    }
  }, []);

  useEffect(() => {
    if (user) {
      if (!user?.client_data || !Object.keys(user.client_data).includes('economicSituation')) {
        dispatch(getClientData({ id, name: 'economicSituation' }));
      }
    }
  }, [user?.client_data]);

  const initFields = {
    incomeAndExpensesSwitcher: user?.client_data?.economicSituation?.data?.incomeAndExpensesSwitcher ?? true,
    incomeFromEmployment: user?.client_data?.economicSituation?.data?.incomeFromEmployment ?? 0,
    otherIncome: user?.client_data?.economicSituation?.data?.otherIncome ?? 0,
    expenditure: user?.client_data?.economicSituation?.data?.expenditure ?? 0,
    comment: user?.client_data?.economicSituation?.data?.comment ?? '',
  };
  const [fields, setFields] = useState(initFields);

  useEffect(() => {
    setFields(initFields);
  }, [user?.client_data]);

  const resetFields = {
    incomeFromEmployment: 0,
    otherIncome: 0,
    expenditure: 0,
  };

  const toggleSwitch = () => {
    setFields({ ...fields, ...resetFields, incomeAndExpensesSwitcher: !fields.incomeAndExpensesSwitcher });
  };

  const fieldChange = (value, name) => {
    setFields({ ...fields, [name]: value });
  };
  const commentChange = (event) => {
    const { name, value } = event.target;
    setFields({ ...fields, [name]: value });
  };

  const getSurplus = () => {
    const sum =
      fields.incomeFromEmployment + fields.otherIncome - fields.expenditure;
    return sum.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ' ');
  };

  const validateFinSitOne = () => {
    const mandatory = fields.incomeAndExpensesSwitcher ? (fields.incomeFromEmployment + fields.otherIncome > 0) : true;
    const fullFill = mandatory && !!fields.comment;
    return fullFill ? 'full-fill' : mandatory ? 'mandatory-only' : '';
  };

  const hendleSubmit = useCallback(() => {
    const data = {
      type: 'economicSituation',
      data: fields,
      full_status: validateFinSitOne()
    };
    dispatch(setClientData({ id, fields: data }));
    history.push('./page-two');
  }, [history, fields]);
  return (
    <>
      <Title className='onboard-component-title'>
        {t('ECONOMIC_SITUATION')}
      </Title>
      <Container>
        <header className='header'>
          <h4 className='ekonomik-header'>{t('INCOME_AND_EXPENSES')}</h4>
          <Switch onChange={toggleSwitch} defaultChecked={fields.incomeAndExpensesSwitcher} />
        </header>
        <div className='ekonomik-subtitle'>
          <span>{t('INCOME_FROM_EMPLOYMENT')}</span>
          <InputNumber
            name='incomeFromEmployment'
            min={0}
            max={100000}
            step={1000}
            disabled={!fields.incomeAndExpensesSwitcher}
            bordered={false}
            size='small'
            formatter={(value) =>
              `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ' ')
            }
            parser={(value) => value.replace(/\$\s?|( *)/g, '')}
            value={fields.incomeFromEmployment}
            onChange={(value) => fieldChange(value, 'incomeFromEmployment')}
          />
        </div>
        <p className='ekonomik-lable'>{t('AFTER_TAX')}</p>
        <Slider
          min={0}
          max={100000}
          step={1000}
          value={fields.incomeFromEmployment}
          onChange={(value) => fieldChange(value, 'incomeFromEmployment')}
          disabled={!fields.incomeAndExpensesSwitcher}
        />
        <div className='ekonomik-subtitle'>
          <span>{t('OTHER_INCOME')}</span>
          <InputNumber
            name='otherIncome'
            min={0}
            max={100000}
            step={1000}
            disabled={!fields.incomeAndExpensesSwitcher}
            bordered={false}
            size='small'
            formatter={(value) =>
              `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ' ')
            }
            parser={(value) => value.replace(/\$\s?|( *)/g, '')}
            value={fields.otherIncome}
            onChange={(value) => fieldChange(value, 'otherIncome')}
          />
        </div>
        <p className='ekonomik-lable'>{t('AFTER_TAX')}</p>
        <Slider
          min={0}
          max={100000}
          step={1000}
          value={fields.otherIncome}
          onChange={(value) => fieldChange(value, 'otherIncome')}
          disabled={!fields.incomeAndExpensesSwitcher}
        />
        <div className='ekonomik-subtitle'>
          <span>{t('EXPENDITURE')}</span>
          <InputNumber
            name='expenditure'
            min={0}
            max={100000}
            step={1000}
            disabled={!fields.incomeAndExpensesSwitcher}
            bordered={false}
            size='small'
            formatter={(value) =>
              `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ' ')
            }
            parser={(value) => value.replace(/\$\s?|( *)/g, '')}
            value={fields.expenditure}
            onChange={(value) => fieldChange(value, 'expenditure')}
          />
        </div>
        <p className='ekonomik-lable'></p>
        <Slider
          min={0}
          max={100000}
          step={1000}
          value={fields.expenditure}
          onChange={(value) => fieldChange(value, 'expenditure')}
          disabled={!fields.incomeAndExpensesSwitcher}
        />
        <div className='ekonomik-subtitle'>
          <span>{t('EXCESS')}</span>
          <span>{`${getSurplus()} kr`}</span>
        </div>
        <p className='ekonomik-lable ekonomik-comment'>{t('COMMENT')}</p>
        <TextArea
          type='text'
          name='comment'
          value={fields.comment}
          onChange={commentChange}
          maxLength={200}
        />
      </Container>
      <div className='btn-container'>
        <MainButtonArray
          style={{ width: 150, height: 40 }}
          type='link'
          arrayIconStart
          onClick={() => history.goBack()}
        >
          {t('GO_BACK')}
        </MainButtonArray>
        <div className='menu-wrapper'>
          <Menu
            onClick={() => history.replace(`/dashboard/${id}`)}
            style={{ width: 85 }}
          />
          <p className='menu-title'>{t('MY_DETAILS')}</p>
        </div>
        <MainButtonArray
          style={{ width: 150, height: 40 }}
          type='link'
          onClick={hendleSubmit}
          arrayIconEnd
        >
          {t('MOVE_ON')}
        </MainButtonArray>
      </div>
    </>
  );
};

FinancialSituationPartOne.propTypes = {
  id: PropTypes.string,
};

export default FinancialSituationPartOne;
