import { combineReducers } from 'redux';
import { userReducer } from './user/userReducer';
import { accountReducer } from './account/accountReducer';
import { auxiliaryReducer } from './helpers/auxiliaryReducer';
import { portfolioReducer } from './portfolio/portfolioReducer';
import { instrumentsReducer } from './instruments/instrumentsReducer';
import { advisorReducer } from './advisors/advisorReducer';

export const reducers = combineReducers({
  userReducer,
  accountReducer,
  auxiliaryReducer,
  portfolioReducer,
  instrumentsReducer,
  advisorReducer
});
