import { actionCreator } from '../../../helpers/actionCreator';

export const GET_INSTRUMENTS_ALL = actionCreator('GET_INSTRUMENTS_ALL');

export const GET_INSTRUMENTS_TBD = actionCreator('GET_INSTRUMENTS_TBD');

export const GET_ASSETS = actionCreator('GET_ASSETS');

export const GET_ASSET_CATEGORIES = actionCreator('GET_ASSET_CATEGORIES');

export const UPDATE_FINANCIAL_ASSET = actionCreator('UPDATE_FINANCIAL_ASSET');

export const UPDATE_INSTRUMENT = actionCreator('UPDATE_INSTRUMENT');

export const CREATE_INSTRUMENT = actionCreator('CREATE_INSTRUMENT');

export const DELETE_INSTRUMENT = actionCreator('DELETE_INSTRUMENT');

export const ASSET_REQUEST = actionCreator('ASSET_REQUEST');

export const CREATE_ASSET_CLASS = actionCreator('CREATE_ASSET_CLASS');

export const DELETE_ASSET_CLASS = actionCreator('DELETE_ASSET_CLASS');

export const UPDATE_ASSET_CLASS = actionCreator('UPDATE_ASSET_CLASS');

export const MAPPING_REQUEST = actionCreator('MAPPING_REQUEST');

export const DELETE_REQUEST = actionCreator('DELETE_REQUEST');

export const GET_CURRENCY = actionCreator('GET_CURRENCY');

export const GET_DIVERSIFICATION = actionCreator('GET_DIVERSIFICATION');

export const CREATE_MODEL = actionCreator('CREATE_MODEL');

export const GET_MODEL_BY_ID = actionCreator('GET_MODEL_BY_ID');

export const CLEAR_MODEL_BY_ID = actionCreator('CLEAR_MODEL_BY_ID');

export const GET_MODEL_LIST = actionCreator('GET_MODEL_LIST');

export const SET_MODEL_DATE = actionCreator('SET_MODEL_DATE');

export const SET_ACTIVE_MODEL = actionCreator('SET_ACTIVE_MODEL');

export const DELETE_NOTACTIVE_MODEL = actionCreator('DELETE_NOTACTIVE_MODEL');

export const SET_MODEL_SERIES = actionCreator('SET_MODEL_SERIES');

export const GET_MODEL_SERIES = actionCreator('GET_MODEL_SERIES');

export const GET_MODEL_PRIMITIVES = actionCreator('GET_MODEL_PRIMITIVES');

export const UPLOAD_MODEL_SERIES = actionCreator('UPLOAD_MODEL_SERIES');

export const UPLOAD_MODEL_PRIMITIVES = actionCreator('UPLOAD_MODEL_PRIMITIVES');

export const CLEAR_PRIMITIVES = actionCreator('CLEAR_PRIMITIVES');

export const SET_MODEL_PRIMITIVES = actionCreator('SET_MODEL_PRIMITIVES');

export const GET_MODEL_LABEL = actionCreator('GET_MODEL_LABEL');

export const CREATE_MODEL_LABEL = actionCreator('CREATE_MODEL_LABEL');

export const DELETE_MODEL_LABEL = actionCreator('DELETE_MODEL_LABEL');

export const UPDATE_MODEL_LABEL = actionCreator('UPDATE_MODEL_LABEL');

export const GET_MODEL_CONSTRAINTS = actionCreator('GET_MODEL_CONSTRAINTS');

export const SET_MODEL_CONSTRAINTS = actionCreator('SET_MODEL_CONSTRAINTS');

export const TEMP_DOWNLOAD_CONSTRAINTS = actionCreator('TEMP_DOWNLOAD_CONSTRAINTS');

export const TEMP_DOWNLOAD_PRIMITIVES = actionCreator('TEMP_DOWNLOAD_PRIMITIVES');
//Recommendation maps
export const GET_RECOMMENDATION_MAPS = actionCreator('GET_RECOMMENDATION_MAPS');

export const GET_RECOMMENDATION_MAP_BY_ID = actionCreator('GET_RECOMMENDATION_MAP_BY_ID');

export const CLEAR_RECOMMENDATION_MAP_BY_ID = actionCreator('CLEAR_RECOMMENDATION_MAP_BY_ID');

export const CREATE_RECOMMENDATION_MAPS = actionCreator('CREATE_RECOMMENDATION_MAPS');

export const UPDATE_RECOMMENDATION_MAPS = actionCreator('UPDATE_RECOMMENDATION_MAPS');

export const DELETE_RECOMMENDATION_MAPS = actionCreator('DELETE_RECOMMENDATION_MAPS');
