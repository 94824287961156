import React, { useEffect, useRef, useState } from 'react';

import PropTypes from 'prop-types';
import { useHistory } from 'react-router-dom';

import { useTranslation } from 'react-i18next';

import { Progress } from 'antd';
import { useDispatch } from 'react-redux';
import { getOnboardingStatus } from '../../data/store/account/accountActions';

const ProcessingPending = (props) => {
  const { userId, url, interval, meeningId, processing } = props;
  const { t } = useTranslation('');
  const dispatch = useDispatch();
  const history = useHistory();

  const [point, setPoint] = useState(0);
  const [statusProgres, setStatusProgres] = useState(null);

  const intervalRef = useRef(null);

  useEffect(() => {
    if (processing === 'done') {
      clearInterval(intervalRef.current);
      setPoint(100);
      setStatusProgres('success');
      setTimeout(() => {
        history.push(`${url}/processing-complete`);
      }, 2000);
    } else if (processing === 'processing') {
      clearInterval(intervalRef.current);
      setStatusProgres('processing');
    }

    return () => {
      console.log('unmount');
      clearInterval(intervalRef.current);
    };
  }, [processing]);

  useEffect(() => {
    if (point > interval - 3 && processing === 'processing') {
      // clearInterval(intervalRef.current);
      dispatch(getOnboardingStatus({ clientId: userId }));
      setPoint(0);
      setStatusProgres('processing');
    }
  }, [point]);

  const startTimer = () => {
    clearInterval(intervalRef.current);
    intervalRef.current = setInterval(() => {
      setPoint((prevPoint) => prevPoint + 1);
    }, 1000);
  };

  useEffect(() => {
    startTimer();
    return () => {
      clearInterval(intervalRef.current);
    };
  }, []);

  return (
    <div className="onboarding-processing-wrapper">
      <div className="report-done-loader">
        <Progress
          type="circle"
          showInfo={statusProgres}
          status={statusProgres}
          steps={50}
          percent={Math.ceil((point * 100) / interval)}
          trailColor="#FAE5D8"
          strokeWidth={10}
          size={[72, 72]}
          gapDegree={0}
        />
      </div>
      <div className="report-done-data">
        {statusProgres === 'processing'
          ? (
          <>
            <p className="report-done-data-header">
              {t('MEETING_TRANSCRIBING')}
            </p>
            <p className="report-done-data-name">
              {t('MEETING_ID')}: {meeningId ?? '---'}
            </p>
          </>
            )
          : (
          <>
            <p className="report-done-data-header">{t('DONE')}...</p>
            <p className="report-done-data-name">
              {t('MEETING_ID')}: {meeningId ?? '---'}
            </p>
          </>
            )}
      </div>
    </div>
  );
};

export default ProcessingPending;

ProcessingPending.propTypes = {
  userId: PropTypes.string,
  interval: PropTypes.number,
  meeningId: PropTypes.number,
  processing: PropTypes.string,
  url: PropTypes.string,
};
