import React, { useState } from 'react';

import ForgotPasswordForm from './ForgotPasswordForm';
import ForgotPasswordConfirmation from './ForgotPasswordConfirmation';

import pic from '../../assets/login-pic.png';
import titleLogo from '../../assets/lt_logo.png';
import titleLogo_dark from '../../assets/lt_logo_dark.png';
import { ReactComponent as BlackDots } from '../../assets/black-dots.svg';
import PropTypes from 'prop-types';
// import { useSelector } from 'react-redux';
// import Loading from '../Loading';

const ForgotPassword = ({theme}) => {
  // const isLoading = useSelector(state => state.userReducer.isLoading);
  const [nextStep, setNextStep] = useState(false);
  const [mail, setMail] = useState('');

  const renderComponent = () =>
    !nextStep
      ? (
      <ForgotPasswordForm setStep={setNextStep} setMail={setMail} theme={theme}/>
        )
      : (
      <ForgotPasswordConfirmation mail={mail}/>
        );

  return (
    <div className="login-wrapper">
      <div className="login-picture">
        <img src={pic} alt="login-picture" />
        <BlackDots className="login-black-dots" />
      </div>
      <div className="login-form">
        <div className="login-form-title">
        <img src={theme !== 'dark' ? titleLogo : titleLogo_dark} alt="title logo" />
        </div>
        {renderComponent()}
      </div>
    </div>
  );
};

export default ForgotPassword;

ForgotPassword.propTypes = {
  theme: PropTypes.string,
};
