import React from 'react';

import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';

import { Button, Col, Form, Input, Modal, Row, Slider } from 'antd';
import { createFinPlan } from '../../data/store/account/accountActions';

const ModalCreate = ({ clientId, t, openCreateModal, closeCreateModal }) => {
  const dispatch = useDispatch();
  const [form] = Form.useForm();

  const handleCreatePlan = () => {
    form.submit();
  };
  const onSubmit = (data) => {
    dispatch(createFinPlan({ clientId, data, history }));
    closeCreateModal();
  };

  return (
    <Modal
        width={650}
        className="modal-content-paper"
        open={openCreateModal}
        onCancel={closeCreateModal}
        footer={null}
        zIndex={1620}
      >
        <div className="finplan-modal-wrapper">
          <Row justify="center">
            <Col>
              <h1 className="plan-create-modal-title">
                {t('FINANCIAL_PLAN_CREATION')}
              </h1>
            </Col>
          </Row>
          <Row justify="center">
            <Col>
              <p className="plan-create-modal-text">{t('PLEASE_PROVIDE')}</p>
            </Col>
          </Row>
          <Form
            form={form}
            name="client-profile"
            layout="vertical"
            onFinish={onSubmit}
          >
            <Form.Item
              label={t('FINANCIAL_PLAN_NAME')}
              className="custom-required"
              placeholder={t('FINANCIAL_PLAN_NAME')}
              name="name"
              required={false}>
              <Input autoComplete="off" className="custom-input" />
            </Form.Item>
            <div className='plan-renge-wrapper'>
              <p className='plan-renge-title'>{t('TIME_HORIZON')}</p>
              <div className='range-wrap'>
                <p className='range-min-max'>20</p>
                <Form.Item
                  className="custom-required plan-slider-item"
                  name="time_horizon"
                  required={false}>
                    <Slider
                      classNames={{
                        rail: 'plan-slider-rail',
                        handle: 'plan-slider-handle',
                        track: 'plan-slider-track'
                      }}
                      min={20}
                      max={100}
                      tooltip={{
                        formatter: value => `${value} ${t('YEARS')}`
                      }}
                    />
                </Form.Item>
                <p className='range-min-max'>100</p>
              </div>
            </div>
          </Form>

          <Row>
            <Button
              className="modal-action-btn save-btn finplan-submit-btn"
              onClick={handleCreatePlan}
            >
              {t('SUBMIT_AND_PROCEED')}
            </Button>
          </Row>
        </div>
      </Modal>
  );
};

export default ModalCreate;

ModalCreate.propTypes = {
  clientId: PropTypes.string,
  openCreateModal: PropTypes.bool,
  closeCreateModal: PropTypes.func,
  t: PropTypes.func,
};
