import React, { useCallback, useState, useEffect } from 'react';

import { useSelector, useDispatch } from 'react-redux';

import { useHistory } from 'react-router';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';

import {
  getAccount,
  getClientData,
  setClientData
} from '../../../data/store/account/accountActions';

import {
  Typography,
  Input,
} from 'antd';
// import TextArea from 'antd/lib/input/TextArea';

import Container from '../../../components/Container';
import MainButtonArray from '../../../components/MainButtonArray';
import { Menu } from '../../../assets';
import './style.scss';
const { Title } = Typography;

const FinancePlanneringPartThree = (props) => {
  const { id } = props;
  const history = useHistory();
  const { t } = useTranslation('');
  const dispatch = useDispatch();
  const { user } = useSelector((state) => state.accountReducer);

  useEffect(() => {
    if (!user?.ID) {
      dispatch(getAccount({ id }));
    }
  }, []);
  useEffect(() => {
    if (user) {
      if (!user?.client_data || !Object.keys(user.client_data).includes('financePlannering3')) {
        dispatch(getClientData({ id, name: 'financePlannering3' }));
      }
    }
  }, [user?.client_data]);

  const initPlanneringThree = {
    yearsLeftUntilRetirement: user?.client_data?.financePlannering3?.data?.yearsLeftUntilRetirement ?? null,
    regularWithdrawals: user?.client_data?.financePlannering3?.data?.regularWithdrawals ?? null,
    requirementForResidualCapital: user?.client_data?.financePlannering3?.data?.requirementForResidualCapital ?? null,
  };
  const [savingsPartThree, setSavingsPartThree] = useState(initPlanneringThree);

  useEffect(() => {
    setSavingsPartThree(initPlanneringThree);
  }, [user?.client_data]);

  const handleChangeNumber = (event) => {
    const { name, value } = event.target;
    const validatedValue = () => {
      if (isNaN(+value)) return false;
      if (name === 'yearsLeftUntilRetirement' && value.length > 3) return false;
      if (name === 'regularWithdrawals' && value.length > 8) return false;
      if (name === 'requirementForResidualCapital' && value.length > 12) return false;
      if (value <= 0) return false;
      return true;
    };
    if (validatedValue()) setSavingsPartThree({ ...savingsPartThree, [name]: +value });
    if (!value) setSavingsPartThree({ ...savingsPartThree, [name]: null });
  };

  const validateFinPlanThree = () => {
    const mandatory = true;
    const fullFill = mandatory && !!savingsPartThree.yearsLeftUntilRetirement && !!savingsPartThree.regularWithdrawals && !!savingsPartThree.requirementForResidualCapital;
    return fullFill ? 'full-fill' : mandatory ? 'mandatory-only' : '';
  };

  const hendleSubmit = useCallback(() => {
    const data = {
      type: 'financePlannering3',
      data: savingsPartThree,
      full_status: validateFinPlanThree()
    };
    dispatch(setClientData({ id, fields: data }));
    history.push(`./dashboard/${id}`);
  }, [history, savingsPartThree]);

  return (
    <>
      <Title className='onboard-component-title'>
      {t('SAVING')}
      </Title>
      <Container>
        <header className='saving-header'>
          <h4 className='ekonomik-header'>{t('SAVING')}</h4>
        </header>
        <p className='saving-lable mt-12'>{t('YEARS_LEFT_UNTIL_RETIREMENT')}</p>
          <Input
          className='saving-input'
          name='yearsLeftUntilRetirement'
          value={savingsPartThree.yearsLeftUntilRetirement}
          type='text'
          placeholder={t('ENTER_YEARS_LEFT_UNTIL_RETIREMENT')}
          onChange={handleChangeNumber}
          />
          <p className='saving-lable mt-12'>{t('REGULAR_WITHDRAWALS')}</p>
          <Input
          className='saving-input'
          name='regularWithdrawals'
          value={savingsPartThree.regularWithdrawals}
          type='text'
          placeholder={t('ENTER_REGULAR_WITHDRAWALS')}
          onChange={handleChangeNumber}
          />
          <p className='saving-lable mt-12'>{t('REQUIREMENT_FOR_RESIDUAL_CAPITAL')}</p>
          <Input
          className='saving-input'
          name='requirementForResidualCapital'
          value={savingsPartThree.requirementForResidualCapital}
          type='text'
          placeholder={t('ENTER_REQUIREMENTS_FOR_RESIDUAL')}
          onChange={handleChangeNumber}
          />
      </Container>
      <div className='btn-container'>
        <MainButtonArray
          style={{ width: 150, height: 40 }}
          type='link'
          arrayIconStart
          onClick={() => history.goBack()}
        >
          {t('GO_BACK')}
        </MainButtonArray>
        <div className='menu-wrapper'>
          <Menu
            onClick={() => history.replace(`/dashboard/${id}`)}
            style={{ width: 85 }}
          />
          <p className='menu-title'>{t('MY_DETAILS')}</p>
        </div>
        <MainButtonArray
          style={{ width: 150, height: 40 }}
          type='link'
          onClick={hendleSubmit}
          arrayIconEnd
        >
          {t('MOVE_ON')}
        </MainButtonArray>
      </div>
    </>
  );
};

FinancePlanneringPartThree.propTypes = {
  id: PropTypes.string,
};

export default FinancePlanneringPartThree;
