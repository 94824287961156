import { ACTIVATE_ADVISOR, CREATE_ADVISOR, DEACTIVATE_ADVISOR, DELETE_ADVISOR, GET_ADVISORS, GET_ADVISORS_AWAIT_APPROVE, GET_USERS_FOR_ADVISOR, REINVITE_ADVISOR, UPDATE_ADVISOR } from './advisorActionTypes';

export const getAdvisors = (data) => ({ type: GET_ADVISORS.ACTION, payload: data });

export const getAdvisorsAwaitApprove = (data) => ({ type: GET_ADVISORS_AWAIT_APPROVE.ACTION, payload: data });

export const activateAdvisor = (data) => ({ type: ACTIVATE_ADVISOR.ACTION, payload: data });

export const deactivateAdvisor = (data) => ({ type: DEACTIVATE_ADVISOR.ACTION, payload: data });

export const createAdvisor = (data) => ({ type: CREATE_ADVISOR.ACTION, payload: data });

export const updateAdvisor = (data) => ({ type: UPDATE_ADVISOR.ACTION, payload: data });

export const reinviteAdvisor = (id) => ({ type: REINVITE_ADVISOR.ACTION, payload: id });

export const deleteAdvisor = (id) => ({ type: DELETE_ADVISOR.ACTION, payload: id });

export const getUsersForAdvisor = () => ({ type: GET_USERS_FOR_ADVISOR.ACTION });
