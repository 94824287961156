/* eslint-disable no-unused-vars */
/* eslint-disable camelcase */
import React, { useMemo } from 'react';

import PropTypes from 'prop-types';

import { ReactComponent as PortfolioSelected } from './img/selected-icon.svg';
import { ReactComponent as PortfolioHovered } from './img/hovered-icon.svg';
import { useTranslation } from 'react-i18next';
import { Col, Row } from 'antd';

import { numberWithSpaces } from '../../../helpers/formatInteger';
import './style.scss';

const ColorDataItem = ({text, value, isSelected}) => (
  <div className="portfolio-card-item ">
    <span className={`portfolio-card-item-title ${isSelected ? 'selected' : ''}`}>{text}</span>
    <span
      className={`portfolio-card-item-value ${value >= 0 ? 'green' : 'red'}`}
    >
      {value > 0 ? '+' : ''}
      {value}%
    </span>
  </div>
);

const ClientPortfolioListItem = ({ item, selectedRowKeys, setSelectedRowKeys, deprecated, is_locked }) => {
  const { t } = useTranslation('');
  const { name, total_value, portfolio_code } = item;
  const return_main = item.return;
  const volatility = item.vol;
  const cvar = item.cvar;

  const isSelecte = useMemo(() => {
    return selectedRowKeys?.includes(item?.id);
  }, [selectedRowKeys, item]);

  const setItemSelected = () => {
    if (selectedRowKeys?.includes(item?.id)) {
      setSelectedRowKeys(selectedRowKeys.filter(i => i !== item?.id));
    } else {
      setSelectedRowKeys([...selectedRowKeys, item?.id]);
    }
  };

  return (
      <div
        onClick={setItemSelected}
        className={`client portfolio-card ${!isSelecte ? 'unselected' : ''}`}>
        <div>
          <p className='portfolio-card-icons'>
            {isSelecte 
              ? <PortfolioSelected /> 
              : <PortfolioHovered/>}
          </p>
          <p className='portfolio-card-name'>
            {name}
            <span className='portfolio-card-code'>
              {portfolio_code}
            </span>
          </p>
            {/* {deprecated
              ? <span className='portfolio-card-deprecated'>{t('IN_EXECUTION')}</span>
              : is_locked
                ? <span className='portfolio-card-deprecated'>{t('LOCKED')}</span>
                : null} */}
        </div>
        <div>
          <p className='portfolio-card-value'>{`${numberWithSpaces(Math.round(total_value))} kr`}</p>
          <Row justify={'center'} className='portfolio-card-text'>
            <Col className=''>
              <ColorDataItem
                isSelected={isSelecte} 
                text={t('RETURN')} 
                value={return_main}/>
            </Col>
            <Col className=''>
              <ColorDataItem
                isSelected={isSelecte} 
                text={t('VOLATILITY')} 
                value={volatility}/>
            </Col>
            <Col className=''>
              <ColorDataItem
                isSelected={isSelecte} 
                text={t('CVaR')} 
                value={cvar}/>

            </Col>
          </Row>
        </div>
      </div>
  );
};

export default ClientPortfolioListItem;

ClientPortfolioListItem.propTypes = {
  item: PropTypes.object,
  selectedRowKeys: PropTypes.array,
  setSelectedRowKeys: PropTypes.func,
  deprecated: PropTypes.bool,
  is_locked: PropTypes.bool,
};

ColorDataItem.propTypes = {
  text: PropTypes.string,
  value: PropTypes.number,
  isSelected: PropTypes.bool,
};
