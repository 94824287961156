import React, { useCallback, useEffect, useState } from 'react';

import PropTypes from 'prop-types';

import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { v4 as uuidv4 } from 'uuid';

import { Button, Col, Form, Layout, Modal, Row, Table } from 'antd';
import { createAssetClass, deleteAssetClass, getAssetCategories, getAssets, updateAssetClass } from '../../data/store/instruments/instrumentsActions';
import Container from '../../components/Container';
// import CustomSelect from '../../components/CustomSelect/CustomSelect';
import ModalUnsaveData from '../../components/ModalUnsaveData/ModalUnsaveData';
import MainFooter from '../../components/MainFooter';
import EditableCell from './EditableCell';
import { ReactComponent as Save } from '../../assets/save-check-icon.svg';
import { ReactComponent as Pluse } from '../../assets/add-icon.svg';
import { ReactComponent as Delete } from '../../assets/delete-black-icon.svg';
import { ReactComponent as Edit } from '../../assets/edit-icon.svg';

const { Header } = Layout;

export const EditableContext = React.createContext(null);

const EditableRow = ({ ...props }) => {
  const [form] = Form.useForm();
  return (
    <Form form={form} component={false}>
      <EditableContext.Provider value={form}>
        <tr {...props} />
      </EditableContext.Provider>
    </Form>
  );
};

const AssetClassManagement = ({
  setNeedSave,
  saveAlert,
  setSaveAlert,
}) => {
  const { t } = useTranslation('');
  const dispatch = useDispatch();
  const history = useHistory();

  const { assets } = useSelector((state) => state.instrumentsReducer);
  const { assetCategories } = useSelector((state) => state.instrumentsReducer);

  const [data, setData] = useState(null);
  const [tableColumns, setTableColumns] = useState([]);
  const [editingKey, setEditingKey] = useState('');
  const [deleteId, setDeleteId] = useState(null);

  const [openModalDelete, setOpenModalDelete] = useState(false);
  const [modalCancelVisible, setModalCancelVisible] = useState(false);

  let instrTryFetch = 0;

  useEffect(() => {
    if (!assets.length && !instrTryFetch) {
      ++instrTryFetch;
      dispatch(getAssets());
    }
    dispatch(getAssetCategories());
  }, []);

  const isEditing = (record) => record.key === editingKey;

  const getCategoryName = (id) => assetCategories.find(i => i.ID === id)?.name;
  const getAssetName = (id) => assets.find(i => i.ID === id)?.name;

  const initialValues = useCallback(() => {
    const initialValues = assets.map(i => ({
      key: i.ID,
      id: i.ID,
      name: i.name,
      financialAssetCategoryID: i?.financialAssetCategoryID
    }));
    setData(initialValues);
  }, [assets]);

  useEffect(() => {
    initialValues();
  }, [assets, assetCategories]);

  useEffect(() => {
    const columns = [
      {
        title: t('NAME'),
        dataIndex: 'name',
        width: '33%',
        editable: true,
        sorter: (a, b) => textSort(a.name, b.name),
      },
      {
        title: t('ASSET_CATEGORY'),
        dataIndex: 'financialAssetCategoryID',
        width: '33%',
        editable: true,
        render: (value) => getCategoryName(value),
        sorter: (a, b) => textSort(getCategoryName(a.financialAssetCategoryID), getCategoryName(b.financialAssetCategoryID)),
      },
      {
        title: t('ACTIONS'),
        dataIndex: 'operation',
        width: '33%',
        render: (_, record) =>
          <>
            {editingKey === record.key
              ? (<Button
                icon={<Save/>}
                type="text"
                onClick={() => handleSubmitData(record.key)}
              />
                )
              : (
                <Button
                icon={<Edit />}
                type="text"
                onClick={() => handleEdit(record.key)}
              />
              )
            }
              <Button
                icon={<Delete />}
                type="text"
                onClick={() => handleDeleteModal(record.key)}
              />
          </>
      },
    ];
    setTableColumns(columns);
  }, [assets, assetCategories, editingKey, data]);

  const components = {
    body: {
      row: EditableRow,
      cell: EditableCell,
    },
  };

  const textSort = (a, b, direction = 1) => {
    if (a?.toLowerCase() < b?.toLowerCase()) {
      return -1 * direction;
    }
    if (a?.toLowerCase() > b?.toLowerCase()) {
      return 1 * direction;
    }
    return 0;
  };

  useEffect(() => {
    saveAlert && saveAlert.flag && setModalCancelVisible(true);
  }, [saveAlert]);

  const columns = tableColumns?.map((col) => {
    if (!col.editable) {
      return col;
    }
    return {
      ...col,
      onCell: (record) => ({
        record,
        dataIndex: col.dataIndex,
        title: col.title,
        handleSave,
        editing: isEditing(record),
        assetCategories
      }),
    };
  });

  const closeModal = () => {
    setModalCancelVisible(false);
  };

  const cancelWithoutChange = () => {
    const path = saveAlert.path;
    setNeedSave(false);
    setSaveAlert({ flag: false, path: '' });
    closeModal();
    history.push(path);
  };

  const handleSubmit = () => {
    closeModal();
  };

  const handleReset = () => {
    setNeedSave(false);
    setSaveAlert({ flag: false, path: '' });
    setEditingKey('');
    initialValues();
  };

  const handleAdd = () => {
    const newData = {
      key: uuidv4(),
      name: '',
      financialAssetCategoryID: '',
    };

    data.length ? setData([...data, newData]) : setData([newData]);
    setEditingKey(newData.key);
  };

  const handleSave = (row) => {
    const newData = data.map((item) => (row.key === item.key ? row : item));
    setData(newData);
  };

  const handleEdit = (key) => {
    setEditingKey(key);
  };
  const handleSubmitData = (key) => {
    const newData = data?.find(i => i.key === key);
    delete newData.key;
    if (newData?.name && newData?.financialAssetCategoryID) {
      if (!newData?.id) {
        dispatch(createAssetClass(newData));
      } else {
        dispatch(updateAssetClass(newData));
      }
      setEditingKey('');
    }
  };

  const handleDeleteModal = (id) => {
    const candidat = data?.find(i => i.key === id);
    if (candidat?.id) {
      setDeleteId(id);
      setOpenModalDelete(true);
    } else {
      const newData = data?.filter(i => i.key !== id);
      setEditingKey('');
      setData(newData);
    }
  };

  const cancelWithoutDelete = () => {
    setDeleteId(null);
    setOpenModalDelete(false);
  };

  const onSubmitDelete = () => {
    dispatch(deleteAssetClass(deleteId));
    cancelWithoutDelete();
    setDeleteId(null);
  };

  return (
    <div className="assetclass-wrapper" >
      <Header className="headerStyle">
        <Row className="overview-tabs-wrapper">
          <Col className="tabs-tab-wrapper" span={18}>
            <Button type="text" className={'overview-btn ant-btn-active'}>
              {t('ASSET_CLASSES')}
            </Button>
          </Col>
        </Row>
      </Header>
      <div className="top-btn-wrapper">
        <Button
          className="portfolio-details-btn portfolio-export-btn"
          onClick={handleReset}
        >
          {t('CANCEL')}
        </Button>
        {/* <Button
          disabled={!needSave}
          onClick={handleSubmit}
          className="portfolio-details-btn btn-create"
        >
          {t('SAVE')}
        </Button> */}
      </div>

      <div>
        <Row
          justify={'space-between'}
          align={'middle'}
          style={{ marginBottom: '4px', padding: '0px 40px' }}
        >
          <Col>
            <h2 className="portfolio-header-title">{t('ASSET_CLASSES')}</h2>
          </Col>
        </Row>
        <div style={{ padding: '0 24px' }} className="table-inner-wrapper">
          <Container widthP={100} mb="24px" padding="0">
            <Row
              justify={'space-between'}
              align={'middle'}
              style={{ marginBottom: '18px', padding: '16px 40px' }}
            >
              <Col>
                <h2 className="portfolio-header-title">
                  {t('DEFINED_ASSET_CLASSES')}
                </h2>
              </Col>
            </Row>
            <Table
              components={components}
              rowClassName={(_, index) =>
                index % 2 === 0 ? 'editable-row' : 'editable-row is-odd'
              }
              bordered
              dataSource={data}
              columns={columns}
              pagination={false}
            />
            <Row style={{ padding: '0 24px 36px' }} justify={'end'}>
              <Button
                type="text"
                className="holdings-add-btn"
                onClick={handleAdd}
                icon={<Pluse />}
                iconPosition={'end'}
              >
                {t('ADD')}
              </Button>
            </Row>
          </Container>
        </div>
      </div>
      <ModalUnsaveData
        open={modalCancelVisible}
        closeModal={closeModal}
        cancelWithoutChange={cancelWithoutChange}
        onSubmit={handleSubmit}
      />
      <Modal
        width={650}
        className="modal-content-paper"
        open={openModalDelete}
        onCancel={() => setOpenModalDelete(false)}
        footer={null}
        zIndex={1600}>
        <Row justify="center">
          <Col className="modal-title">{t('MODAL_DELETE_TITLE')}</Col>
        </Row>
        <Row justify="center" className="modal-text-wrapper">
          <Col>
            <p className="modal-advisor-text">{t('MODAL_DELETE_TEXT_ASSET')}</p>
            <p className="modal-advisor-name">{getAssetName(deleteId)}</p>
          </Col>
        </Row>
        <Row className="modal-advisor-btn-wrapper">
          <Button
            className="modal-action-btn cancel-btn"
            onClick={cancelWithoutDelete}
          >
            {t('CANCEL')}
          </Button>
          <Button className="modal-action-btn delete-btn" onClick={onSubmitDelete}>
            {t('DELETE')}
          </Button>
        </Row>
      </Modal>
      <MainFooter />
    </div>
  );
};

export default AssetClassManagement;

AssetClassManagement.propTypes = {
  needSave: PropTypes.bool,
  setNeedSave: PropTypes.func,
  saveAlert: PropTypes.object,
  setSaveAlert: PropTypes.func,
};

EditableRow.propTypes = {
  index: PropTypes.number,
};
