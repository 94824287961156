import React from 'react';

import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import {ReactComponent as GreenArrow} from '../img/arrow-green-icon.svg';
import {ReactComponent as RedArrow} from '../img/arrow-red-icon.svg';

import './style.scss';
import { twoDecimalWithSpaces } from '../../../helpers/formatFractional';

const FooterHoldings = ({width, data = {}, totalOnly}) => {
  const { t } = useTranslation('');

  return (
    <div style={{width}} className='client footer-holdings-wrapper'>
        <div className='footer-holdings-data'>
          <span className='footer-holdings-title'>{t('RETURN')}</span>
          {!totalOnly
            ? <div>
                <span><GreenArrow /></span>
                <span className='holdings-data-text'>{data?.return?.toFixed(1) ?? '-'}%</span>
              </div>
            : <div></div>
          }
        </div>
        <div className='footer-holdings-data'>
          <span className='footer-holdings-title'>{t('VOLATILITY')}</span>
          {!totalOnly
            ? <div>
                <span><GreenArrow /></span>
                <span className='holdings-data-text'>{data?.vol?.toFixed(1) ?? '-'}%</span>
              </div>
            : <div></div>
          }
        </div>
        <div className='footer-holdings-data'>
          <span className='footer-holdings-title'>{t('CVAR')}</span>
          {!totalOnly
            ? <div>
                <span><RedArrow /></span>
                <span className='holdings-data-text'>{data?.cvar?.toFixed(1) ?? '-'}%</span>
              </div>
            : <div></div>
          }
        </div>
        <div className='footer-holdings-data holdings-total'>
          <span className='footer-holdings-title'>{t('TOTAL')}</span>
          <div>
            <span className='holdings-data-text'>{twoDecimalWithSpaces(data?.total)} kr</span>
          </div>
        </div>
    </div>
  );
};

export default FooterHoldings;

FooterHoldings.propTypes = {
  width: PropTypes.string,
  data: PropTypes.object,
  totalOnly: PropTypes.bool,
};