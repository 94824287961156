import React, { useEffect, useState } from 'react';

import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { Redirect, Switch, useLocation, Route } from 'react-router-dom';

import { getAdvisorById } from '../../data/store/user/userActions';
import { getAssetCategories, getAssets } from '../../data/store/instruments/instrumentsActions';

import { PrivateRoute } from '../../PrivateRouter';
import {
  INFORMATION,
  // REPORT,
  SUPPORT,
  DASHBOARD,
  MY_DETAILS,
  TEST,
  INCOME_AND_EXPENSES,
  FIXED_ASSETS,
  SCOPE_AND_INVESTABLE_CAPITAL,
  FINANCE_PLANERING,
  FINANCIAL_SITUATION,
  RISK_PROFILE,
  LEGAL_PLANNING,
  CUSTOMER_KNOWLEDGE,
  EXPERIENCE_BACKGROUND,
  TERMS,
} from '../../constants/routes';
import Support from '../Support';
import Information from '../Information';
import Profile from '../Profile';
// import AdminInstruments from '../AdminInstruments/AdminInstruments';
import Advisor from '../Advisors';
import { permissions } from '../../config/permissions';
// import TBDInstruments from '../../components/InstrumentsTBD';
import Dashboard from '../Dashboard/Dashboard';
import MyDetails from '../MyDetails';
import Test from '../Test/Test';
import IncomeAndExpenses from '../EkonomikRisk/IncomeAndExpenses';
import FixedAssets from '../EkonomikRisk/FixedAssets';
import ScopeAndInvestable from '../EkonomikRisk/ScopeAndInvestable';
import FinancePlannering from '../FinancePlannering';
import FinancialSituation from '../FinancialSituation';
import RiskProfile from '../RiskProfile';
import LegalNeedsPlanning from '../LegalNeedsPlanning/LegalNeedsPlanning';
import CustomerKnowledge from '../CustomerKnowledge/CustomerKnowledge';
import ExperienceBackground from '../ExperienceBackground/ExperienceBackground';
import TermsConditionsAgreement from '../TermsConditionsAgreement/TermsConditionsAgreement';
import { Layout } from 'antd';

import ErrorNotification from '../../components/ErrorNotification';
import SuccessNotification from '../../components/SuccessNotification';
import SideBar from '../../components/SideBar/SideBar';

import Routing from './Routing';
import { PrivateRouterWithProps } from '../../PrivateRouterWithProps';
import { getAccountsList } from '../../data/store/account/accountActions';
// import NewInstrument from '../AdminInstruments/NewInstrument';
import AdvisorForm from '../../components/AdvisorForm/AdvisorForm';
import QuantRouting from './QuantRouting';
import OrderInstructionsRout from '../SuggestedRoot/OrderInstructionsRout';
import SideBarClient from '../../components/SideBarClient/SideBarClient';
import ProfileClient from '../ProfileClient/ProfileClient';
import SupportClient from '../SupportClient/SupportClient';
import AccountRouting from './AccountRouting';
import { useHistory } from 'react-router-dom';

const { Content } = Layout;

const MainRouting = ({theme}) => {
  const dispatch = useDispatch();
  const {pathname} = useLocation();
  const history = useHistory();
  // eslint-disable-next-line camelcase
  const { role } = useSelector((state) => state.userReducer.advisor);
  const accounts = useSelector((state) => state.accountReducer?.accounts?.list);

  const [userId, setUserId] = useState(null);
  const [needSave, setNeedSave] = useState(false);
  const [saveAlert, setSaveAlert] = useState({ flag: false, path: '' });
  const [optStart, setOptStart] = useState(false);
  const [optAlert, setOptAlert] = useState({ flag: false, path: '' });
  const [selectedAccount, setSelectedAccount] = useState(null);

  useEffect(() => {
    console.log(pathname);
  }, [pathname]);

  useEffect(() => {
    setSaveAlert({ flag: false, path: '' });
  }, [pathname]);

  useEffect(() => {
    if(role === 'CLIENT' && accounts?.length) {
      setSelectedAccount(accounts[0]?.id);
    }
  }, [role, pathname, accounts]);

  useEffect(() => {
    if(selectedAccount) {
      history.replace(`/account/${selectedAccount}/overview`);
    }
  }, [selectedAccount]);

  useEffect(() => {
    dispatch(getAssets());
    dispatch(getAssetCategories());
    dispatch(getAdvisorById());
    dispatch(getAccountsList());
  }, [dispatch]);

  const { SUPER_ADMIN, ADVISER, CLIENT, ADVISER_NOT_SECURE, CLIENT_NOT_SECURE } =
    permissions.roles;

  return (
    <>
      {role
        ? (
        <Layout className="layout-main">
        {role === 'CLIENT_NOT_SECURE' || role === 'CLIENT'
          ? (
            <SideBarClient theme={theme} selectedAccount={selectedAccount}/>
          )
          : <SideBar
              userId={userId}
              setUserId={setUserId}
              needSave={needSave}
              saveAlert={saveAlert}
              optAlert={optAlert}
              setSaveAlert={setSaveAlert}
              setOptAlert={setOptAlert}
              optStart={optStart}
            />
        }
        <Layout>
        {
          role === 'CLIENT_NOT_SECURE' || role === 'CLIENT'
            ? (
              <Content className="client content-row">
                <Switch>
                  <PrivateRoute
                    exact
                    path={SUPPORT}
                    roles={[CLIENT_NOT_SECURE, CLIENT]}
                  >
                    <SupportClient />
                  </PrivateRoute>
                  <PrivateRoute
                    exact
                    path={'/settings'}
                    roles={[CLIENT, CLIENT_NOT_SECURE]}
                  >
                    <ProfileClient/>
                  </PrivateRoute>
                  <PrivateRoute
                    path={'/account/:accountId'}
                    roles={[CLIENT, CLIENT_NOT_SECURE]}
                  >
                    <AccountRouting/>
                  </PrivateRoute>
                  <Route exact path='/'>
                    <Redirect to='/account'/>
                  </Route>
                </Switch>
              </Content>
            )
            : (
              <Content className="content-row">
                <Switch>
                  <PrivateRouterWithProps
                    path="/accounts"
                    roles={[ADVISER, SUPER_ADMIN, CLIENT]}
                    customProps={{
                      setUserId,
                      needSave,
                      setNeedSave,
                      saveAlert,
                      setSaveAlert,
                      optAlert,
                      setOptAlert,
                      setOptStart
                    }}
                    component={Routing}
                  />
                  <PrivateRouterWithProps
                    path='/quant'
                    roles={[SUPER_ADMIN]}
                    customProps={{
                      needSave,
                      setNeedSave,
                      saveAlert,
                      setSaveAlert,
                    }}
                    component={QuantRouting}
                  />
                  <PrivateRouterWithProps
                    path={'/advisors/edit-advisor/:userId'}
                    roles={[SUPER_ADMIN]}
                    component={AdvisorForm}
                    customProps={{
                      needSave,
                      setNeedSave,
                      saveAlert,
                      setSaveAlert,
                    }}
                  />
                  <PrivateRouterWithProps
                    path={'/advisors/invite-advisor'}
                    roles={[SUPER_ADMIN]}
                    component={AdvisorForm}
                    customProps={{
                      needSave,
                      setNeedSave,
                      saveAlert,
                      setSaveAlert,
                    }}
                  />
                  <PrivateRoute
                    path={'/advisors'}
                    roles={[SUPER_ADMIN]}
                    component={Advisor}
                  />
                  <PrivateRoute
                    exact
                    path={SUPPORT}
                    roles={[SUPER_ADMIN, ADVISER, CLIENT]}
                    component={Support}
                  />
                  <Route exact path={'/admin-order-instructions'}>
                    <Redirect to={'/admin-order-instructions/approved'}/>
                  </Route>
                  <PrivateRouterWithProps
                    path={'/admin-order-instructions/:typeTab'}
                    roles={[SUPER_ADMIN]}
                    component={OrderInstructionsRout}
                  />
                  <PrivateRoute
                    exact
                    path={'/settings'}
                    roles={[ADVISER, SUPER_ADMIN, ADVISER_NOT_SECURE]}
                  >
                    <Profile/>
                  </PrivateRoute>
                  <PrivateRoute
                    exact
                    path={INFORMATION}
                    roles={[SUPER_ADMIN, ADVISER]}
                    component={Information}
                  />
                  <PrivateRoute
                    exact
                    path={SUPPORT}
                    roles={[SUPER_ADMIN, ADVISER, CLIENT]}
                    component={Support}
                  />
                  <PrivateRoute
                    exact
                    path={DASHBOARD}
                    roles={[SUPER_ADMIN, ADVISER, CLIENT]}
                    component={Dashboard}
                  />
                  <PrivateRoute
                    exact
                    path={MY_DETAILS}
                    roles={[SUPER_ADMIN, ADVISER, CLIENT]}
                    component={MyDetails}
                  />
                  <PrivateRoute
                    exact
                    path={TEST}
                    roles={[SUPER_ADMIN, ADVISER, CLIENT]}
                    component={Test}
                  />
                  <PrivateRoute
                    path={FINANCIAL_SITUATION}
                    roles={[SUPER_ADMIN, ADVISER, CLIENT]}
                    component={FinancialSituation}
                  />
                  <PrivateRoute
                    path={FINANCE_PLANERING}
                    roles={[SUPER_ADMIN, ADVISER, CLIENT]}
                    component={FinancePlannering}
                  />
                  <PrivateRoute
                    path={RISK_PROFILE}
                    roles={[SUPER_ADMIN, ADVISER, CLIENT]}
                    component={RiskProfile}
                  />
                  <PrivateRoute
                    path={LEGAL_PLANNING}
                    roles={[SUPER_ADMIN, ADVISER, CLIENT]}
                    component={LegalNeedsPlanning}
                  />
                  <PrivateRoute
                    path={CUSTOMER_KNOWLEDGE}
                    roles={[SUPER_ADMIN, ADVISER, CLIENT]}
                    component={CustomerKnowledge}
                  />
                  <PrivateRoute
                    path={EXPERIENCE_BACKGROUND}
                    roles={[SUPER_ADMIN, ADVISER, CLIENT]}
                    component={ExperienceBackground}
                  />
                  <PrivateRoute
                    exact
                    path={TERMS}
                    roles={[SUPER_ADMIN, ADVISER, CLIENT]}
                    component={TermsConditionsAgreement}
                  />
                  <PrivateRoute
                    exact
                    path={INCOME_AND_EXPENSES}
                    roles={[SUPER_ADMIN, ADVISER, CLIENT]}
                    component={IncomeAndExpenses}
                  />
                  <PrivateRoute
                    exact
                    path={FIXED_ASSETS}
                    roles={[SUPER_ADMIN, ADVISER, CLIENT]}
                    component={FixedAssets}
                  />
                  <PrivateRoute
                    exact
                    path={SCOPE_AND_INVESTABLE_CAPITAL}
                    roles={[SUPER_ADMIN, ADVISER, CLIENT]}
                    component={ScopeAndInvestable}
                  />

                  {role && role === 'CLIENTT'
                    ? (
                    // eslint-disable-next-line camelcase
                    <Redirect to={'/acount'} />
                      )
                    : (
                    <PrivateRoute
                      exact
                      path="*"
                      roles={[ADVISER, SUPER_ADMIN, CLIENT]}
                      component={Routing}
                    >
                      <Redirect to={'/accounts'} />
                    </PrivateRoute>
                      )}
                </Switch>
              </Content>
            )
          
        }
          <ErrorNotification />
          <SuccessNotification />
        </Layout>
      </Layout>
          )
        : (
        <div />
          )}
    </>
  );
};

export default MainRouting;

MainRouting.propTypes = {
  theme: PropTypes.string,
};