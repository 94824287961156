import { axios } from '../utils/API';
import { API_URLS } from '../constants/api_urls';

// 1 - cache
// 2 - unlisted
// 3  - listed
export class InstrumentsService {
  static getAllInstruments = async (data) => {
    const { limit, offset = 0, type } = data;
    return type === 'cashes-only'
      ? await axios.get(API_URLS.ALL_INSTRUMENTS, {
        params: { limit, offset, order: 'DESC', display_type: 1 },
      })
      : type === 'without-cashes'
        ? await axios.get(API_URLS.ALL_INSTRUMENTS, {
          params: { limit, offset, order: 'DESC', display_type: 3 },
        })
        : await axios.get(API_URLS.ALL_INSTRUMENTS, {
          params: { limit, offset, order: 'DESC' },
        });
  };

  static getInstrumentsTBD = async (data) => {
    return await axios.get(API_URLS.INSTRUMENTS_TBD, {
      params: {
        limit: data.limit,
        offset: data.offset,
        status: 'ASSET_REQUEST_STATUS_NEW',
        order: 'DESC',
      },
    });
  };

  static getAssetsAll = async () => {
    return await axios.get(API_URLS.ASSETS);
  };

  static getCategoriesAll = async () => {
    return await axios.get(API_URLS.ASSETS_CATEGORIES);
  };

  static updateAsset = async (data) => {
    return await axios.put(`${API_URLS.UPDATE_ASSETS}/${data.ID}`, data.asset);
  };

  static instrumentUpdate = async (values) => {
    const { id, data } = values;
    return await axios.put(`${API_URLS.UPDATE_INSTRUMENT}/${id}`, data);
  };

  static create = async (data) => {
    return await axios.post(API_URLS.CREATE_INSTRUMENT, data);
  };

  static delete = async (id) => {
    return await axios.delete(`${API_URLS.UPDATE_INSTRUMENT}/${id}`);
  };

  static requestAsset = async (data) => {
    const { id, ...rest } = data;
    return await axios.put(`${API_URLS.INSTRUMENTS_TBD}/${id}/resolve`, {
      ...rest,
    });
  };

  static createAssetClass = async (data) => {
    return await axios.post(`${API_URLS.CREATE_ASSET_CLASS}`, data);
  };

  static deleteAssetClass = async (id) => {
    return await axios.delete(`${API_URLS.CREATE_ASSET_CLASS}/${id}`);
  };

  static updateAssetClass = async (data) => {
    const { id, ...rest } = data;
    return await axios.put(`${API_URLS.CREATE_ASSET_CLASS}/${id}`, {
      ...rest,
    });
  };

  static deleteRequest = async (id) => {
    return await axios.delete(`${API_URLS.INSTRUMENTS_TBD}/${id}`);
  };

  static getCurrency = async () => {
    return await axios.get(API_URLS.GET_CURRENCY);
  };

  static getDiversification = async () => {
    return await axios.get(API_URLS.GET_DIVERSIFICATION);
  };

  static createModel = async (data) => {
    return await axios.post(API_URLS.MODEL_V3, data);
  };

  static getModelById = async (id) => {
    return await axios.get(`${API_URLS.MODEL_V3}/${id}`);
  };

  static getModelList = async () => {
    return await axios.get(API_URLS.MODEL_V3);
  };

  static setModelDate = async ({id, date}) => {
    return await axios.patch(`${API_URLS.MODEL_V3}/${id}/series`, date);
  };

  static setActiveModel = async (id) => {
    return await axios.post(`${API_URLS.MODEL_V3}/${id}/activate`);
  };

  static deleteNotActiveModel = async (id) => {
    return await axios.delete(`${API_URLS.MODEL_V3}/${id}`);
  };

  static setModelSeries = async ({id, seriesSetId, data}) => {
    return await axios.put(`${API_URLS.MODEL_V3}/${id}/seriesset/${seriesSetId}`, data);
  };

  static getModelSeries = async ({id, seriesSetId}) => {
    return await axios.get(`${API_URLS.MODEL_V3}/${id}/seriesset/${seriesSetId}`);
  };

  static getModelPrimitives = async (id) => {
    return await axios.get(`${API_URLS.MODEL_V3}/${id}/primitives`);
  };

  static setModelPrimitives = async (data) => {
    const { id, ...rest } = data;
    return await axios.patch(`${API_URLS.MODEL_V3}/${id}/primitives`, rest.data);
  };

  static getModelLabel = async (id) => {
    return await axios.get(`${API_URLS.MODEL_V3}/${id}/labels`);
  };

  static createModelLabel = async (data) => {
    return await axios.post('/v3/labels', {...data});
  };

  static deleteModelLabel = async (id) => {
    return await axios.delete(`/v3/labels/${id}`);
  };

  static updateModelLabel = async (data) => {
    const { id } = data;
    return await axios.put(`/v3/labels/${id}`, {...data});
  };

  static uploadModelSeries = async (payload) => {
    const {id, file} = payload;
    const bodyFormData = new FormData();
    bodyFormData.append('file', file);
    return await axios.post(`${API_URLS.MODEL_V3}/${id}/seriesset-import`, bodyFormData, {
      headers: { 'Content-Type': 'multipart/form-data' },
    });
  };

  static uploadModelPrimitives = async (payload) => {
    const {id, file} = payload;
    const bodyFormData = new FormData();
    bodyFormData.append('file', file);
    return await axios.post(`${API_URLS.MODEL_V3}/${id}/primitives-import`, bodyFormData, {
      headers: { 'Content-Type': 'multipart/form-data' },
    });
  };
  
  static getModelConstraints = async (id) => {
    return await axios.get(`${API_URLS.MODEL_V3}/${id}/constraints`);
  };
  
  static setModelConstraints = async (payload) => {
    const {id, data} = payload;
    return await axios.put(`${API_URLS.MODEL_V3}/${id}/constraints`, {...data});
  };
  
  static tempDownloadConstraints = async (payload) => {
    const {id, data} = payload;
    return await axios.put(`${API_URLS.MODEL_V3}/${id}/seriesset-template`, {...data}, { responseType: 'arraybuffer' });
  };
  
  static tempDownloadPrimitives = async (payload) => {
    const {id} = payload;
    return await axios.get(`${API_URLS.MODEL_V3}/${id}/primitives-template`,{ responseType: 'arraybuffer' });
  };
  
  static getRecommendationMaps = async () => {
    return await axios.get(`${API_URLS.RECOMENDATION_MAPS}`);
  };
  
  static getRecommendationMapById = async (id) => {
    return await axios.get(`${API_URLS.RECOMENDATION_MAPS}/${id}`);
  };
  
  static createRecommendationMaps = async (data) => {
    return await axios.post(`${API_URLS.RECOMENDATION_MAPS}`, data);
  };
  
  static updateRecommendationMaps = async (payloud) => {
    const {id, data} = payloud;
    return await axios.put(`${API_URLS.RECOMENDATION_MAPS}/${id}`, data);
  };
  
  static deleteRecommendationMaps = async (id) => {
    return await axios.delete(`${API_URLS.RECOMENDATION_MAPS}/${id}`);
  };
}
