import React from 'react';

import PropTypes from 'prop-types';

import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { Button, Col, Modal, Row } from 'antd';
import { approveSuggestions } from '../../data/store/account/accountActions';

const ModalApproveCandidat = ({ userId, modalApproveCandidat, setModalApproveCandidat }) => {
  const { t } = useTranslation('');
  const dispatch = useDispatch();

  const { suggestions } = useSelector((state) => state.accountReducer);

  const getCandidatNameById = () => {
    return suggestions?.find(i => i.id === modalApproveCandidat)?.name || '- - -';
  };

  const handleCloseDelModal = () => {
    setModalApproveCandidat(0);
  };

  const handleApprove = () => {
    dispatch(approveSuggestions({ userId, candidatId: modalApproveCandidat }));
    setModalApproveCandidat(0);
  };

  return (
    <Modal
        width={650}
        className="modal-content-paper"
        open={modalApproveCandidat}
        onCancel={handleCloseDelModal}
        footer={null}
        destroyOnClose={true}
        zIndex={1600}>
        <Row justify="center">
          <Col className="modal-title">{t('CONFIRM_APPROVAL')}</Col>
        </Row>
        <Row justify="center" className="modal-text-wrapper">
          <Col>
            <p className="modal-advisor-text">{t('WANT_TO_APPROVE')}</p>
            <p className="modal-advisor-name">{getCandidatNameById()}</p>
          </Col>
        </Row>
        <Row className="modal-advisor-btn-wrapper">
          <Button
            className="modal-action-btn cancel-btn"
            onClick={handleCloseDelModal}
          >
            {t('CANCEL')}
          </Button>
          <Button className="modal-action-btn save-btn" onClick={handleApprove}>
            {t('APPROVE')}
          </Button>
        </Row>
      </Modal>
  );
};

export default ModalApproveCandidat;

ModalApproveCandidat.propTypes = {
  userId: PropTypes.string,
  modalApproveCandidat: PropTypes.number,
  setModalApproveCandidat: PropTypes.func,
};
