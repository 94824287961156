import React, { useContext, useEffect } from 'react';
import PropTypes from 'prop-types';

import { useTranslation } from 'react-i18next';

import { Form, Input, Select, Tooltip } from 'antd';
import { EditableContext } from './Portfolio';
import { formatDate } from '../../helpers/formatDate';
import CustomSelect from '../CustomSelect/CustomSelect';
import CustomInputNumber from '../CustomInputNumber/CustomInputNumber';

const EditableCellUnlisted = ({
  editing,
  children,
  dataIndex,
  record,
  handleSave,
  setTarget,
  ...restProps
}) => {
  // const dispatch = useDispatch();
  const { t } = useTranslation('');
  const form = useContext(EditableContext);

  useEffect(() => {
    if (dataIndex) {
      form.setFieldsValue({
        [dataIndex]: record[dataIndex],
      });
    }
  }, [dataIndex]);

  const save = async () => {
    try {
      const values = await form.getFieldsValue();
      if (['unitsNumber', 'latest_value_sek'].includes(dataIndex)) {
        handleSave({
          ...record,
          ...values,
          value: values.units_number * values.latest_value_sek,
        });
      } else {
        handleSave({
          ...record,
          ...values,
          value: values.units_number * values.latest_value_sek,
        });
      }
    } catch (errInfo) {
      console.log('Save failed:', errInfo);
    }
  };

  if (editing) {
    return (
      <td {...restProps}>
        <Form.Item
          style={{
            margin: 0,
          }}
          name={dataIndex}
        >
          {['units_number', 'latest_value_sek'].includes(dataIndex)
            ? (
            <CustomInputNumber
              className='custom-inputnumber'
              style={{ width: '100%' }}
              min={0}
              onBlur={save}
              onChange={save}
              onStep={save}
              onPressEnter={save}
              placeholder={record[dataIndex]}
              formatter={(value) =>
                `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ' ')
              }
              parser={(value) => value.replace(/\$\s?|( *)/g, '')}
            />
              )
            : dataIndex === 'name'
              ? (
            <Input
              className='custom-input'
              type="text"
              placeholder={t('ENTER_THE_NAME')}
              onInput={save}
            />
                )
              : (
            <CustomSelect
              width='280px'
              className="custom-select"
              placeholder={t('SELECT_OPTION')}
              type="select"
              onSelect={save}
            >
              <Select.Option value="16">{t('Forest')}</Select.Option>
              <Select.Option value="7">{t('Private Company')}</Select.Option>
              <Select.Option value="8">
                {t('Private Equity/Debt')}
              </Select.Option>
              <Select.Option value="15">{t('Real Estate')}</Select.Option>
            </CustomSelect>
                )}
        </Form.Item>
      </td>
    );
  } else {
    return dataIndex !== 'latest_value_sek'
      ? (
      <td {...restProps} onClick={setTarget}>
        {children}
      </td>
        )
      : (
      <Tooltip
        placement="top"
        title={
          <span style={{ color: '#000000' }}>
            {formatDate(record?.latest_value_time)}
          </span>
        }
        color="white"
      >
        <td {...restProps} onClick={setTarget}>
          {children}
        </td>
      </Tooltip>
        );
  }
};

export default EditableCellUnlisted;

EditableCellUnlisted.propTypes = {
  title: PropTypes.node,
  editing: PropTypes.bool,
  children: PropTypes.node,
  dataIndex: PropTypes.string,
  record: PropTypes.object,
  handleSave: PropTypes.func,
  setTarget: PropTypes.func,
  data: PropTypes.array,
};
