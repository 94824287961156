import React, { useContext, useEffect } from 'react';
import PropTypes from 'prop-types';

import { Form, Select } from 'antd';
import CustomSelect from '../../components/CustomSelect/CustomSelect';
import { EditableContext } from './ApproveInstruments';
import { textSort } from '../../helpers/textSort';

const EditableCell = ({
  title,
  editing,
  children,
  dataIndex,
  record,
  handleSaveAsset,
  optionForAdd,
  presrntAssets,
  ...restProps
}) => {
  const form = useContext(EditableContext);

  useEffect(() => {
    if (dataIndex) {
      form.setFieldsValue({
        [dataIndex]: record[dataIndex],
      });
    }
  }, [dataIndex]);

  const filterOption = (inputValue, option) => {
    const value = inputValue.toString();
    if (value?.length > 2) {
      return (
        option?.children.toString().toLowerCase().includes(value.toLowerCase())
      );
    } else {
      return option;
    }
  };

  const save = async () => {
    try {
      const values = await form.validateFields();
      handleSaveAsset({
        ...record,
        ...values,
      });
    } catch (errInfo) {
      console.log('Save failed:', errInfo);
    }
  };

  if (editing) {
    return (
      <td {...restProps}>
        <Form.Item
          style={{
            margin: 0,
          }}
          name={dataIndex}
        >
          {dataIndex === 'asset_id'
            ? (
              <CustomSelect
                width="360px"
                className="custom-select"
                showSearch
                onSelect={save}
                placeholder={record[title]}
                filterOption={(inputValue, option) => filterOption(inputValue, option)}
              >
                {optionForAdd
                  ?.slice()
                  ?.sort((a, b) => textSort(a.name, b.name))
                  ?.map(({ ID, name }) => (
                  <Select.Option key={ID} value={ID} disabled={presrntAssets.includes(ID)}>
                    {name}
                  </Select.Option>
                  ))
                }
              </CustomSelect>
              )
              : (
              <td {...restProps}>
                {children}
              </td>
                )
            }
        </Form.Item>
      </td>
    );
  } else {
    return (
      <td {...restProps}>
        {children}
      </td>);
  }
};

export default EditableCell;

EditableCell.propTypes = {
  title: PropTypes.node,
  editing: PropTypes.bool,
  children: PropTypes.node,
  dataIndex: PropTypes.string,
  record: PropTypes.object,
  handleSaveAsset: PropTypes.func,
  optionForAdd: PropTypes.array,
  presrntAssets: PropTypes.array,
};
