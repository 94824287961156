import {
  takeEvery, takeLeading,
  takeLatest
} from '@redux-saga/core/effects';
import {
  LOGIN_USER,
  FORGOT_PASSWORD,
  RESET_USER_PASSWORD,
  SIGN_UP_USER,
  LOG_OUT,
  CONFIRM_AUTH,
  CHANGE_EMAIL,
  USER_PROFILE_UPDATE,
  GET_ADVISOR_BY_ID,
  UPDATE_ADVISOR_EMAIL,
  UPDATE_ADVISOR_NAME,
  UPDATE_ADVISOR_PHONE,
  UPDATE_ADVISOR_PASSWORD, RESEND_EMAIL, GET_QR_BANKID, CLEAR_QR_BANKID, COLLECT_BANKID, GET_QR_SIGN_BANKID, CONFIRM_EMAIL, GET_TWOFACTOR_SECRET, GET_TWOFACTOR_CHECK, GET_QR_ATTACH_BANKID,
  GET_USER_BY_ID
} from './user/userActionTypes';
import * as userSaga from './user/userSaga';
import * as accountSaga from './account/accountSaga';
import * as portfolioSaga from './portfolio/portfolioSaga';
import * as instrumentsSaga from './instruments/instrumentsSaga';
import * as advisorSaga from './advisors/advisorSaga';
import {
  SET_CLIENT_TYPE,
  SET_CLIENT_INFORMATION,
  UPDATE_CLIENT_INFO,
  DELETE_CLIENT_PHONE,
  UPDATE_CLIENT_PHONE,
  SET_CLIENT_PHONE,
  SET_CLIENT_DATA,
  GET_CLIENT_DATA,
  SEND_INVITATION,
  GET_INVITATION_INFO,
  ACCEPT_INVITATION,
  SET_CLIENT_RISK_LEVEL,
  SEND_CLIENT_APPROVE,
  SET_CLIENT_FEES,
  GET_CLIENT_FEES,
  SET_CLIENT_SCORE,
  SET_CLIENT_ADDRESS,
  ADD_REPRESENTATIVE,
  UPDATE_REPRESENTATIVE,
  DELETE_REPRESENTATIVE,
  ADD_REPRESENTATIVE_DOC,
  DELETE_CLIENT,
  CREATE_FINPLAN,
  GET_FINPLAN,
  PATCH_FINPLAN,
  DELETE_FINPLAN,
  GET_SUGGESTIONS_PORTFOLIO,
  APPROVE_SUGGESTIONS_PORTFOLIO,
  DELETE_SUGGESTIONS_PORTFOLIO,
  GET_APPROVED_PORTFOLIO,
  GET_ALL_APPROVED_PORTFOLIO,
  GET_CANDIDATE_TRANSACTIONS,
  EXECUTE_PORTFOLIO,
  GET_ALL_EXECUTED_PORTFOLIO,
  GET_ALL_IMPLEMENTED_PORTFOLIO,
  GET_EXECUTED_PORTFOLIO,
  GET_IMPLEMENTED_PORTFOLIO,
  DOWNLOAD_PDF_REPOR,
  GET_ONDOARDING_STATUS,
  POST_ONDOARDING_DATA,
  DEL_ONDOARDING,
  SET_ONDOARDING_VERIFED,
  UPLOAD_ONDOARDING_DOCUMENT,
  GET_ONDOARDING_DOCUMENTS_LIST,
  DELETE_ONDOARDING_DOCUMENT,
  UPLOAD_ONDOARDING_AUDIO,
  IMPLEMENTE_PORTFOLIO,
  RESCHEDULE_ONDOARDING,
  CREATE_ACCOUNT,
  GET_ACCOUNTS_LIST,
  GET_ACCOUNT,
  GET_RELATED_ACCOUNT,
  ADD_RELATED_PERSON,
  CREATE_CLIENT_TO_ACCOUNT,
  DELETE_RELATED_PERSON,
  UPDATE_RELATED_PERSON,
  UPDATE_ACCOUNT
} from './account/accountActionsTypes';
import {
  CREATE_PORTFOLIO_CURRENT_ANALYSIS,
  CREATE_PORTFOLIO_TASK_ANALYSIS,
  CREATE_PORTFOLIO_TASK_PROSPECTING,
  CREATE_REPORT,
  DELETE_PORTFOLIO_BY_ID,
  DOWNLOAD_REPORT,
  DOWNLOAD_TEMP,
  EXPORT_PORTFOLIO,
  EXPORT_SELECTED_PORTFOLIO,
  GET_ALL_PORTFOLIO,
  GET_ALL_REPORTS,
  GET_AVAILABLE_PORTFOLIO_CODE,
  GET_CANDIDATES,
  GET_HISTORY_BY_PORTFOLIO_ID,
  GET_PORTFOLIO_BY_ID,
  GET_PRE_CANDIDATE,
  GET_STEP_OPTIMISATION_PORTFOLIO,
  GET_TRANSACTIONS,
  IMPORT_PORTFOLIO, MAPPING_LINK_PORTFOLIO, OPTIMIZATION_PORTFOLIO,
  PORTFOLIO_FINAL,
  POST_STEP_OPTIMISATION_PORTFOLIO,
  SAVE_FULL_PORTFOLIO,
  SEARCH_PARAMS,
  SEND_DATA_PDF_PORTFOLIO,
  SEND_FINANCIAL_PLAN
} from './portfolio/portfolioActionTypes';
import {
  ASSET_REQUEST,
  CREATE_ASSET_CLASS,
  CREATE_INSTRUMENT,
  CREATE_MODEL,
  CREATE_MODEL_LABEL,
  CREATE_RECOMMENDATION_MAPS,
  DELETE_ASSET_CLASS,
  DELETE_INSTRUMENT,
  DELETE_MODEL_LABEL,
  DELETE_NOTACTIVE_MODEL,
  DELETE_RECOMMENDATION_MAPS,
  DELETE_REQUEST,
  GET_ASSET_CATEGORIES,
  GET_ASSETS,
  GET_CURRENCY,
  GET_DIVERSIFICATION,
  GET_INSTRUMENTS_ALL, GET_INSTRUMENTS_TBD,
  GET_MODEL_BY_ID,
  GET_MODEL_CONSTRAINTS,
  GET_MODEL_LABEL,
  GET_MODEL_LIST,
  GET_MODEL_PRIMITIVES,
  GET_MODEL_SERIES,
  GET_RECOMMENDATION_MAP_BY_ID,
  GET_RECOMMENDATION_MAPS,
  MAPPING_REQUEST,
  SET_ACTIVE_MODEL,
  SET_MODEL_CONSTRAINTS,
  SET_MODEL_DATE,
  SET_MODEL_PRIMITIVES,
  SET_MODEL_SERIES,
  TEMP_DOWNLOAD_CONSTRAINTS,
  TEMP_DOWNLOAD_PRIMITIVES,
  UPDATE_ASSET_CLASS,
  UPDATE_FINANCIAL_ASSET,
  UPDATE_INSTRUMENT,
  UPDATE_MODEL_LABEL,
  UPDATE_RECOMMENDATION_MAPS,
  UPLOAD_MODEL_PRIMITIVES,
  UPLOAD_MODEL_SERIES
} from './instruments/instrumentsActionTypes';
import {
  ACTIVATE_ADVISOR,
  CREATE_ADVISOR,
  DEACTIVATE_ADVISOR,
  DELETE_ADVISOR,
  GET_ADVISORS,
  GET_ADVISORS_AWAIT_APPROVE,
  GET_USERS_FOR_ADVISOR,
  REINVITE_ADVISOR,
  UPDATE_ADVISOR
} from './advisors/advisorActionTypes';

export function * rootSaga () {
  yield takeEvery(LOGIN_USER.ACTION, userSaga.login);
  yield takeEvery(FORGOT_PASSWORD.ACTION, userSaga.forgotPassword);
  yield takeEvery(CONFIRM_EMAIL.ACTION, userSaga.confirmEmail);
  yield takeEvery(RESET_USER_PASSWORD.ACTION, userSaga.resetUserPassword);
  yield takeEvery(SIGN_UP_USER, userSaga.signUp);
  yield takeEvery(CONFIRM_AUTH.ACTION, userSaga.confirmAuth);
  yield takeEvery(LOG_OUT, userSaga.logOut);
  yield takeEvery(CHANGE_EMAIL.ACTION, userSaga.changeEmail);
  yield takeEvery(RESEND_EMAIL.ACTION, userSaga.resendEmail);
  yield takeEvery(USER_PROFILE_UPDATE.ACTION, userSaga.userProfileUpdate);
  yield takeEvery(GET_ADVISOR_BY_ID.ACTION, userSaga.getAdvisorById);
  yield takeEvery(UPDATE_ADVISOR_EMAIL.ACTION, userSaga.updateAdvisorEmail);
  yield takeEvery(UPDATE_ADVISOR_NAME.ACTION, userSaga.updateAdvisorName);
  yield takeEvery(UPDATE_ADVISOR_PHONE.ACTION, userSaga.updateAdvisorPhone);
  yield takeEvery(UPDATE_ADVISOR_PASSWORD.ACTION, userSaga.updateAdvisorPassword);
  yield takeLeading(GET_QR_BANKID.ACTION, userSaga.getQRCodeBankID);
  yield takeLeading(GET_QR_SIGN_BANKID.ACTION, userSaga.getQRCodeSignBankID);
  yield takeLeading(GET_QR_ATTACH_BANKID.ACTION, userSaga.getQRCodeAttachBankID);
  yield takeEvery(CLEAR_QR_BANKID.ACTION, userSaga.clearQRCodeBankID);
  yield takeLeading(COLLECT_BANKID.ACTION, userSaga.collectBankID);
  yield takeLeading(GET_TWOFACTOR_SECRET.ACTION, userSaga.getTwoFactorSecret);
  yield takeLeading(GET_TWOFACTOR_CHECK.ACTION, userSaga.getTwoFactorSecretCheck);
  yield takeLeading(GET_USER_BY_ID.ACTION, userSaga.getUserById);

  yield takeEvery(GET_ACCOUNTS_LIST.ACTION, accountSaga.getAccountsList);
  yield takeEvery(GET_ACCOUNT.ACTION, accountSaga.getAccount);
  yield takeEvery(GET_RELATED_ACCOUNT.ACTION, accountSaga.getRelatedAccount);
  yield takeEvery(CREATE_CLIENT_TO_ACCOUNT.ACTION, accountSaga.createClientToAccount);
  yield takeEvery(CREATE_ACCOUNT.ACTION, accountSaga.createAccount);
  yield takeEvery(UPDATE_CLIENT_INFO.ACTION, accountSaga.updateClientInfo);
  yield takeEvery(SET_CLIENT_ADDRESS.ACTION, accountSaga.updateClientAddress);
  yield takeEvery(SET_CLIENT_TYPE.ACTION, accountSaga.setClientType);
  yield takeEvery(SET_CLIENT_SCORE.ACTION, accountSaga.addClientScore);
  yield takeEvery(SET_CLIENT_PHONE.ACTION, accountSaga.addClientPhone);
  yield takeEvery(UPDATE_CLIENT_PHONE.ACTION, accountSaga.updateClientPhone);
  yield takeEvery(DELETE_CLIENT_PHONE.ACTION, accountSaga.deleteClientPhone);
  yield takeEvery(ADD_REPRESENTATIVE.ACTION, accountSaga.addRepresentative);
  yield takeEvery(UPDATE_REPRESENTATIVE.ACTION, accountSaga.updateRepresentative);
  yield takeEvery(DELETE_REPRESENTATIVE.ACTION, accountSaga.deleteRepresentative);
  yield takeEvery(ADD_REPRESENTATIVE_DOC.ACTION, accountSaga.addRepresentativeDoc);
  yield takeEvery(ADD_RELATED_PERSON.ACTION, accountSaga.addRelatedPerson);
  yield takeEvery(UPDATE_RELATED_PERSON.ACTION, accountSaga.updateRelatedPerson);
  yield takeEvery(DELETE_RELATED_PERSON.ACTION, accountSaga.deleteRelatedPerson);
  yield takeEvery(UPDATE_ACCOUNT.ACTION, accountSaga.updateAccount);

  yield takeEvery(SET_CLIENT_INFORMATION.ACTION, accountSaga.setClientInformation);
  yield takeEvery(SET_CLIENT_DATA.ACTION, accountSaga.setClientData);
  yield takeEvery(GET_CLIENT_DATA.ACTION, accountSaga.getClientData);
  yield takeEvery(SEND_INVITATION.ACTION, accountSaga.sendInvitation);
  yield takeEvery(GET_INVITATION_INFO.ACTION, accountSaga.getInvitationInfo);
  yield takeEvery(ACCEPT_INVITATION.ACTION, accountSaga.acceptInvitation);
  yield takeEvery(SET_CLIENT_RISK_LEVEL.ACTION, accountSaga.setRiskLevel);
  yield takeEvery(SEND_CLIENT_APPROVE.ACTION, accountSaga.sendClientApprove);
  yield takeEvery(SET_CLIENT_FEES.ACTION, accountSaga.setClientFees);
  yield takeEvery(GET_CLIENT_FEES.ACTION, accountSaga.getClientFees);
  yield takeEvery(DELETE_CLIENT.ACTION, accountSaga.deleteClient);
  yield takeEvery(GET_FINPLAN.ACTION, accountSaga.getFinPlan);
  yield takeEvery(CREATE_FINPLAN.ACTION, accountSaga.createFinPlan);
  yield takeEvery(PATCH_FINPLAN.ACTION, accountSaga.patchFinPlan);
  yield takeEvery(DELETE_FINPLAN.ACTION, accountSaga.deleteFinPlan);
  yield takeEvery(GET_SUGGESTIONS_PORTFOLIO.ACTION, accountSaga.getSuggestionsPortfolio);
  yield takeEvery(APPROVE_SUGGESTIONS_PORTFOLIO.ACTION, accountSaga.approveSuggestions);
  yield takeEvery(DELETE_SUGGESTIONS_PORTFOLIO.ACTION, accountSaga.deleteSuggestions);
  yield takeEvery(GET_APPROVED_PORTFOLIO.ACTION, accountSaga.getApprovedPortfolio);
  yield takeEvery(GET_ALL_APPROVED_PORTFOLIO.ACTION, accountSaga.getAllApprovedPortfolio);
  yield takeEvery(GET_CANDIDATE_TRANSACTIONS.ACTION, accountSaga.getCandidatTransactions);
  yield takeEvery(EXECUTE_PORTFOLIO.ACTION, accountSaga.executeApprovedPortfolio);
  yield takeEvery(IMPLEMENTE_PORTFOLIO.ACTION, accountSaga.implementePortfolio);
  yield takeEvery(GET_ALL_EXECUTED_PORTFOLIO.ACTION, accountSaga.getAllExecutedPortfolio);
  yield takeEvery(GET_ALL_IMPLEMENTED_PORTFOLIO.ACTION, accountSaga.getAllImplementedPortfolio);
  yield takeEvery(GET_EXECUTED_PORTFOLIO.ACTION, accountSaga.getExecutedPortfolio);
  yield takeEvery(GET_IMPLEMENTED_PORTFOLIO.ACTION, accountSaga.getImplementedPortfolio);
  yield takeEvery(DOWNLOAD_PDF_REPOR.ACTION, accountSaga.downloadPdfReport);
  yield takeLatest(GET_ONDOARDING_STATUS.ACTION, accountSaga.getOnboardingStatus);
  yield takeLatest(POST_ONDOARDING_DATA.ACTION, accountSaga.postOnboardingData);
  yield takeLatest(DEL_ONDOARDING.ACTION, accountSaga.delOnboarding);
  yield takeLatest(SET_ONDOARDING_VERIFED.ACTION, accountSaga.setOnboardingVerifed);
  yield takeEvery(UPLOAD_ONDOARDING_DOCUMENT.ACTION, accountSaga.uploadOnboardingDocument);
  yield takeLatest(GET_ONDOARDING_DOCUMENTS_LIST.ACTION, accountSaga.getOnboardingDocumentsList);
  yield takeLatest(DELETE_ONDOARDING_DOCUMENT.ACTION, accountSaga.deleteOnboardingDocument);
  yield takeLatest(UPLOAD_ONDOARDING_AUDIO.ACTION, accountSaga.uploadOnboardingAudio);
  yield takeLatest(RESCHEDULE_ONDOARDING.ACTION, accountSaga.rescheduleOnboarding);

  yield takeLatest(GET_ALL_PORTFOLIO.ACTION, portfolioSaga.getAllPortfolio);
  yield takeEvery(GET_PORTFOLIO_BY_ID.ACTION, portfolioSaga.getPortfolioById);
  yield takeEvery(SAVE_FULL_PORTFOLIO.ACTION, portfolioSaga.saveFullPortfolio);
  yield takeEvery(DELETE_PORTFOLIO_BY_ID.ACTION, portfolioSaga.deletePortfolio);
  yield takeEvery(SEARCH_PARAMS.ACTION, portfolioSaga.searchParams);
  yield takeEvery(EXPORT_PORTFOLIO.ACTION, portfolioSaga.exportPortfolio);
  yield takeEvery(EXPORT_SELECTED_PORTFOLIO.ACTION, portfolioSaga.exportSelectedPortfolio);
  yield takeEvery(IMPORT_PORTFOLIO.ACTION, portfolioSaga.importPortfolio);
  yield takeEvery(GET_ALL_REPORTS.ACTION, portfolioSaga.getAllReports);
  yield takeEvery(DOWNLOAD_REPORT.ACTION, portfolioSaga.downloadReport);
  yield takeEvery(DOWNLOAD_TEMP.ACTION, portfolioSaga.downloadTEMP);
  yield takeEvery(CREATE_REPORT.ACTION, portfolioSaga.createReport);
  yield takeEvery(GET_HISTORY_BY_PORTFOLIO_ID.ACTION, portfolioSaga.getHistoryByPortfolioId);
  yield takeEvery(OPTIMIZATION_PORTFOLIO.ACTION, portfolioSaga.optimizationRequest);
  yield takeEvery(CREATE_PORTFOLIO_TASK_ANALYSIS.ACTION, portfolioSaga.createPortfolioTaskAnalysis);
  yield takeEvery(CREATE_PORTFOLIO_TASK_PROSPECTING.ACTION, portfolioSaga.createPortfolioTaskProspecting);
  yield takeEvery(CREATE_PORTFOLIO_CURRENT_ANALYSIS.ACTION, portfolioSaga.createPortfolioCurrentAnalysis);
  yield takeEvery(GET_STEP_OPTIMISATION_PORTFOLIO.ACTION, portfolioSaga.getStepOptimisationPortfolio);
  yield takeEvery(POST_STEP_OPTIMISATION_PORTFOLIO.ACTION, portfolioSaga.postStepOptimisationPortfolio);
  yield takeEvery(SEND_FINANCIAL_PLAN.ACTION, portfolioSaga.sendFinancialPlan);
  yield takeEvery(PORTFOLIO_FINAL.ACTION, portfolioSaga.portfolioFinalrequest);
  yield takeEvery(SEND_DATA_PDF_PORTFOLIO.ACTION, portfolioSaga.sendDataPDFportfolio);
  yield takeEvery(MAPPING_LINK_PORTFOLIO.ACTION, portfolioSaga.mappingEditPortfolio);
  yield takeEvery(GET_CANDIDATES.ACTION, portfolioSaga.getCandidates);
  yield takeEvery(GET_TRANSACTIONS.ACTION, portfolioSaga.getTransactions);
  yield takeEvery(GET_AVAILABLE_PORTFOLIO_CODE.ACTION, portfolioSaga.getAvailablePortfolioCode);
  yield takeEvery(GET_PRE_CANDIDATE.ACTION, portfolioSaga.getPreCandidate);

  yield takeEvery(GET_INSTRUMENTS_ALL.ACTION, instrumentsSaga.getInstrumentsAll);
  yield takeLatest(GET_INSTRUMENTS_TBD.ACTION, instrumentsSaga.getInstrumentsTBDAll);
  yield takeEvery(GET_ASSETS.ACTION, instrumentsSaga.getAssets);
  yield takeEvery(GET_ASSET_CATEGORIES.ACTION, instrumentsSaga.getAssetCategories);
  yield takeEvery(UPDATE_FINANCIAL_ASSET.ACTION, instrumentsSaga.updateFinancialAssets);
  yield takeEvery(UPDATE_INSTRUMENT.ACTION, instrumentsSaga.updateInstrument);
  yield takeEvery(CREATE_INSTRUMENT.ACTION, instrumentsSaga.createInstrument);
  yield takeEvery(DELETE_INSTRUMENT.ACTION, instrumentsSaga.deleteInstrument);
  yield takeEvery(ASSET_REQUEST.ACTION, instrumentsSaga.assetRequest);
  yield takeEvery(MAPPING_REQUEST.ACTION, instrumentsSaga.mappingRequest);
  yield takeEvery(DELETE_REQUEST.ACTION, instrumentsSaga.deleteRequest);
  yield takeEvery(GET_CURRENCY.ACTION, instrumentsSaga.getCurrency);
  yield takeEvery(GET_DIVERSIFICATION.ACTION, instrumentsSaga.getDiversification);
  yield takeEvery(CREATE_ASSET_CLASS.ACTION, instrumentsSaga.createAssetClass);
  yield takeEvery(DELETE_ASSET_CLASS.ACTION, instrumentsSaga.deleteAssetClass);
  yield takeEvery(UPDATE_ASSET_CLASS.ACTION, instrumentsSaga.updateAssetClass);
  yield takeEvery(CREATE_MODEL.ACTION, instrumentsSaga.createModel);
  yield takeEvery(GET_MODEL_BY_ID.ACTION, instrumentsSaga.getModelById);
  yield takeEvery(SET_MODEL_DATE.ACTION, instrumentsSaga.setModelDate);
  yield takeEvery(SET_ACTIVE_MODEL.ACTION, instrumentsSaga.setActiveModel);
  yield takeEvery(DELETE_NOTACTIVE_MODEL.ACTION, instrumentsSaga.deleteNotActiveModel);
  yield takeEvery(SET_MODEL_SERIES.ACTION, instrumentsSaga.setModelSeries);
  yield takeEvery(GET_MODEL_LIST.ACTION, instrumentsSaga.getModelList);
  yield takeEvery(GET_MODEL_SERIES.ACTION, instrumentsSaga.getModelSeries);
  yield takeEvery(GET_MODEL_PRIMITIVES.ACTION, instrumentsSaga.getModelPrimitives);
  yield takeEvery(UPLOAD_MODEL_SERIES.ACTION, instrumentsSaga.uploadModelSeries);
  yield takeEvery(UPLOAD_MODEL_PRIMITIVES.ACTION, instrumentsSaga.uploadModelPrimitives);
  yield takeEvery(SET_MODEL_PRIMITIVES.ACTION, instrumentsSaga.setModelPrimitives);
  yield takeEvery(GET_MODEL_LABEL.ACTION, instrumentsSaga.getModelLabel);
  yield takeEvery(CREATE_MODEL_LABEL.ACTION, instrumentsSaga.createModelLabel);
  yield takeEvery(UPDATE_MODEL_LABEL.ACTION, instrumentsSaga.updateModelLabel);
  yield takeEvery(DELETE_MODEL_LABEL.ACTION, instrumentsSaga.deleteModelLabel);
  yield takeEvery(GET_MODEL_CONSTRAINTS.ACTION, instrumentsSaga.getModelConstraints);
  yield takeEvery(SET_MODEL_CONSTRAINTS.ACTION, instrumentsSaga.setModelConstraints);
  yield takeEvery(TEMP_DOWNLOAD_CONSTRAINTS.ACTION, instrumentsSaga.tempDownloadConstraints);
  yield takeEvery(TEMP_DOWNLOAD_PRIMITIVES.ACTION, instrumentsSaga.tempDownloadPrimitives);
  yield takeEvery(GET_RECOMMENDATION_MAPS.ACTION, instrumentsSaga.getRecommendationMaps);
  yield takeEvery(GET_RECOMMENDATION_MAP_BY_ID.ACTION, instrumentsSaga.getRecommendationMapById);
  yield takeEvery(CREATE_RECOMMENDATION_MAPS.ACTION, instrumentsSaga.createRecommendationMaps);
  yield takeEvery(UPDATE_RECOMMENDATION_MAPS.ACTION, instrumentsSaga.updateRecommendationMaps);
  yield takeEvery(DELETE_RECOMMENDATION_MAPS.ACTION, instrumentsSaga.deleteRecommendationMaps);

  yield takeEvery(GET_ADVISORS.ACTION, advisorSaga.getAdvisors);
  yield takeEvery(GET_ADVISORS_AWAIT_APPROVE.ACTION, advisorSaga.getAdvisorsAwaitApprove);
  yield takeEvery(ACTIVATE_ADVISOR.ACTION, advisorSaga.activateAdvisor);
  yield takeEvery(DEACTIVATE_ADVISOR.ACTION, advisorSaga.deactivateAdvisor);
  yield takeEvery(CREATE_ADVISOR.ACTION, advisorSaga.createAdvisor);
  yield takeEvery(UPDATE_ADVISOR.ACTION, advisorSaga.updateAdvisor);
  yield takeEvery(REINVITE_ADVISOR.ACTION, advisorSaga.reinviteAdvisor);
  yield takeEvery(DELETE_ADVISOR.ACTION, advisorSaga.deleteAdvisor);
  yield takeEvery(GET_USERS_FOR_ADVISOR.ACTION, advisorSaga.getUsersForAdvisor);
}
