import { useEffect } from 'react';
import { notification } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import { RESET_ERROR } from '../../data/store/helpers/auxiliaryActionTypes';
import startCase from 'lodash/startCase';

const translateErrorMessage = (text) => {
  if (!text.replace) {
    return '';
  }

  const replacedText = text.replace('ERR', '').toLowerCase();
  const startCased = startCase(replacedText);

  return startCased.replace('Isin', 'ISIN');
};

const ErrorNotification = () => {
  const dispatch = useDispatch();
  const isErrorClient = useSelector(state => state.accountReducer.isError);
  const isErrorInstrument = useSelector(state => state.instrumentsReducer.isError);
  const isErrorPortfolio = useSelector(state => state.portfolioReducer.isError);
  const isErrorUser = useSelector(state => state.userReducer.isError);
  const isErrorAdvisor = useSelector(state => state.advisorReducer.isError);

  const openNotification = (placement, type, ...rest) => {
    notification[type]({
      message: 'Something went wrong',
      description: rest.map(error => error && translateErrorMessage(error)),
      placement
    });
  };

  useEffect(() => {
    if (isErrorClient || isErrorInstrument || isErrorPortfolio || isErrorUser || isErrorAdvisor) {
      openNotification('bottomRight', 'error', isErrorClient, isErrorInstrument, isErrorPortfolio, isErrorUser, isErrorAdvisor);
      setTimeout(() => dispatch({ type: RESET_ERROR }), 1000);
    }
  }, [isErrorAdvisor, isErrorUser, isErrorPortfolio, isErrorInstrument, isErrorClient]);

  return null;
};

export default ErrorNotification;
