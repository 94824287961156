import React, { useEffect, useState } from 'react';

import PropTypes from 'prop-types';

import { useDispatch } from 'react-redux';
import { setLabel } from '../../data/store/helpers/auxiliaryActions';
import { useHistory, useLocation } from 'react-router';
import { useTranslation } from 'react-i18next';

import { login } from '../../data/store/user/userActions';

import { Button, Input } from 'antd';

import { ReactComponent as Gadget } from '../../assets/gadget-new-icon.svg';
import { ReactComponent as GadgetDark } from '../../assets/gadget-dark-icon.svg';
import titleLogo from '../../assets/lt_logo.png';
import titleLogo_dark from '../../assets/lt_logo_dark.png';

import './style.scss';

const TwoFactorPage = ({ remember, theme }) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const location = useLocation();
  const { t } = useTranslation('');

  const [code, setCode] = useState(null);

  useEffect(() => {
    dispatch(setLabel(history.location.pathname));
  }, [history]);
  useEffect(() => {
    if (code?.length === 6) handleSubmit();
  }, [code]);

  const handleChengeInput = (event) => {
    const { value } = event.target;
    setCode(value);
  };

  const handleSubmit = () => {
    const data = {
      email: location?.search?.replace('?email=', '') || '',
      password: location?.state?.password || '',
      code: code.toString(),
    };
    dispatch(login({ data, history, remember }));
  };

  return (
    <div className="login-form">
      <div className="login-form-title">
      <img src={theme !== 'dark' ? titleLogo : titleLogo_dark} alt="title logo" />
      </div>
      <div className="twofactor-wrapper">
          <h1 className="twofactor-header">{t('VERIFY_YOUR_TWO_FACTOR')}</h1>
          <div className="twofactor-code-img-wrapper">
            {theme !== 'dark' ? <Gadget/> : <GadgetDark/>}
          </div>
          <h2 className="code-title-text">{t('AUTHENTICATION_CODE')}</h2>
          <Input
            width={360}
            value={code}
            autoFocus="autofocus"
            className="custom-input login-input"
            inputMode="numeric"
            placeholder={t('AUTHENTICATION_CODE')}
            onChange={handleChengeInput}
          />

          <Button
            disabled={!(code && code?.toString()?.length === 6)}
            className="code-btn"
            onClick={handleSubmit}
          >
            {t('VERIFY')}
          </Button>
          <div className="twofactor-code-footer">
            {t('CODE_FOOTER')}
          </div>
      </div>
    </div>
  );
};

// TwoFactorPage.propTypes = {};

export default TwoFactorPage;

TwoFactorPage.propTypes = {
  remember: PropTypes.bool,
  theme: PropTypes.string,
};
