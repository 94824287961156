import React, { useEffect, useState } from 'react';

import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';

import { textSort } from '../../helpers/textSort';
import dayjs from 'dayjs';

import Container from '../../components/Container';
import { Button, Col, Layout, Row, Table } from 'antd';
import MainFooter from '../../components/MainFooter';

import { ReactComponent as Eye } from '../../assets/eye-icon.svg';
import { ReactComponent as Delete } from '../../assets/delete-black-icon.svg';

import ModelModalNew from './ModelModalNew';
import './style.scss';
import { clearModel, getModelList } from '../../data/store/instruments/instrumentsActions';
import ModalDeleteModel from './ModalDeleteModel';
import ModalActivateModel from './ModalActivateModel';

const { Header } = Layout;
const ModelList = () => {
  const { t } = useTranslation('');
  const dispatch = useDispatch();
  const history = useHistory();

  useEffect(() => {
    dispatch(clearModel());
    dispatch(getModelList());
  }, []);
  

  const modelList = useSelector((state) => state.instrumentsReducer.modelList);
  const [tableColumns, setTableColumns] = useState(null);
  const [tableRecentModelsColumns, setTableRecentModelsColumns] = useState(null);
  const [modalOpen, setModalOpen] = useState(false);
  const [modalActivateModel, setModalActivateModel] = useState(null);
  const [modalDeleteModel, setModalDeleteModel] = useState(null);

  const handleView = (id) => {
    history.push(`/quant/model/item-view/${id}`);
  };

  const handleDelete = (id) => {
    setModalDeleteModel(id);
    
  };

  const handleDeleteClose = () => {
    setModalDeleteModel(null);
  };

  const handleActivateClose = () => {
    setModalActivateModel(null);
  };

  const handleCreateModel = () => {
    setModalOpen(true);
    // history.push('/quant/model/item/new-item');
  };

  const handlMakeActive = (id) => {
    setModalActivateModel(id);
  };

  const handleClose = () => {
    setModalOpen(false);
  };

  useEffect(() => {
    const columns = [
      {
        title: t('MODEL'),
        className: 'exclude-holdings exclude-name model-name',
        dataIndex: 'name',
        width: '40%',
        render: (value, record) => {
          return (
            <>
              <p className='model-table-name'>{value}</p>
              <p className='model-table-subname'>{dayjs(record?.createAt).format('DD/MM/YYYY')} at {dayjs(record?.created_at).format('HH:mm')}</p>
            </>
          );
        },
        sorter: (a, b) => textSort(a.name, b.name),
      },
      {
        title: t('DESCRIPTION'),
        className: 'model-table-return',
        dataIndex: 'description',
        align: 'left',
        width: '40%',
      },
      {
        title: t('ACTIONS'),
        className: 'exclude-holdings exclude-operation',
        dataIndex: 'operation',
        align: 'center',
        width: '20%',
        render: (_, record) => (
          <>
            <Button
              icon={<Eye />}
              type="text"
              onClick={() => handleView(record.id)}
            />
          </>
        ),
      },
    ];
    setTableColumns(columns);
  }, [modelList]);

  useEffect(() => {
    const columns = [
      {
        title: t('MODEL'),
        className: 'exclude-holdings exclude-name model-name',
        dataIndex: 'name',
        width: '40%',
        render: (value, record) => {
          return (
            <>
              <p className='model-table-name'>{value}</p>
              <p className='model-table-subname'>{dayjs(record?.created_at).format('DD/MM/YYYY')} at {dayjs(record?.created_at).format('HH:mm')}</p>
            </>
          );
        },
        sorter: (a, b) => textSort(a.name, b.name),
      },
      {
        title: t('DESCRIPTION'),
        className: 'model-table-return',
        dataIndex: 'description',
        align: 'left',
        width: '40%',
      },
      {
        title: t('ACTIONS'),
        className: 'exclude-holdings exclude-operation',
        dataIndex: 'operation',
        align: 'center',
        width: '20%',
        render: (_, record) => (
          <div className="cell-button-wrapper">
            <Button
                className="action-btn advisor-action-btn approve"
                onClick={() => handlMakeActive(record.id)}
              >
                {t('MAKE_ACTIVE')}
              </Button>
            <Button
              icon={<Eye />}
              type="text"
              onClick={() => handleView(record.id)}
            />
            <Button
              icon={<Delete />}
              type="text"
              onClick={() => handleDelete(record.id)}
            />
          </div>
        ),
      },
    ];
    setTableRecentModelsColumns(columns);
  }, [modelList]);

  return (
    <div className="profile-wrap" style={{ position: 'relative' }}>
      <div className="modellist-wrapper" >
        <Header className="headerStyle">
          <Row className="overview-tabs-wrapper">
            <Col className="tabs-tab-wrapper" span={18}>
              <Button type="link" className="overview-btn ant-btn-active">
                {t('MODEL')}
              </Button>
            </Col>
          </Row>
          <div className="top-btn-wrapper">
          <Button
            onClick={handleCreateModel}
            className="portfolio-details-btn optimise-btn"
          >
            {t('CREATE_MODEL')}
          </Button>
        </div>
        </Header>

        <div style={{ padding: '80px 24px 0' }}>
          <Container widthP={100} mb="24px" padding="0">
            <h2 className="container-title">
              {t('CURRENT_MODEL')}
            </h2>
            <Table
              rowKey={(record) => record.id}
              className='exclude-holdings-table'
              rowClassName={(_, index) =>
                index % 2 === 0 ? 'editable-row' : 'editable-row is-odd'
              }
              dataSource={modelList?.filter(i => i.status === 'active')}
              columns={tableColumns}
              pagination={false}
            />
          </Container>
          
        </div>
        <h2 className="out-container-title">{t('RECENT_MODELS')}</h2>
        <div style={{ padding: '24px' }}>
          <Container widthP={100} mb="24px" padding="24px 0">
            <Table
              rowKey={(record) => record.id}
              className='exclude-holdings-table'
              rowClassName={(_, index) =>
                index % 2 === 0 ? 'editable-row' : 'editable-row is-odd'
              }
              dataSource={modelList?.filter(i => i.status !== 'active')}
              columns={tableRecentModelsColumns}
              pagination={false}
            />
          </Container>
          
        </div>
      </div>
      <MainFooter />
      <ModelModalNew
        modelList={modelList} 
        modalOpen={modalOpen}
        handleClose={handleClose}/>
      <ModalDeleteModel
        modalDeleteModel={modalDeleteModel} 
        handleDeleteClose={handleDeleteClose}/>
      <ModalActivateModel
        modalActivateModel={modalActivateModel}
        handleActivateClose={handleActivateClose}
      />
    </div>
  );
};

export default ModelList;