import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useHistory, useParams } from 'react-router';
import { useSelector, useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { Typography, Col, Row } from 'antd';
import Card from '../../components/Card';
import Container from '../../components/Container';
import MainButton from '../../components/MainButton';
import { Bag, Balance, CheckRed, Checked, CheckedAll, Cross, Document, Expirience, Goal, Info, Person, Planering, Risk, Zoom } from './icons';
import './style.scss';
import { getAccount, getClientData, sendClientApprove } from '../../data/store/account/accountActions';
const { Title } = Typography;

const Dashboard = () => {
  const history = useHistory();
  const { id } = useParams();
  const dispatch = useDispatch();
  const { t } = useTranslation('');
  const { user } = useSelector(state => state.accountReducer);
  const isAdvisor = useSelector(state => state.userReducer.advisor?.role) !== 'CLIENT';

  useEffect(() => {
    if (id) {
      dispatch(getAccount({ id }));
    }
  }, []);
  useEffect(() => {
    if (user) {
      if (!user?.client_data || !Object.keys(user.client_data).includes('purposeAndHeart')) {
        dispatch(getClientData({ id, name: 'purposeAndHeart' }));
        return;
      }
      if (!user?.client_data || !Object.keys(user.client_data).includes('economicSituation')) {
        dispatch(getClientData({ id, name: 'economicSituation' }));
        return;
      }
      if (!user?.client_data || !Object.keys(user.client_data).includes('economicSituation2')) {
        dispatch(getClientData({ id, name: 'economicSituation2' }));
        return;
      }
      if (!user?.client_data || !Object.keys(user.client_data).includes('economicSituation3')) {
        dispatch(getClientData({ id, name: 'economicSituation3' }));
        return;
      }
      if (!user?.client_data || !Object.keys(user.client_data).includes('financePlannering1')) {
        dispatch(getClientData({ id, name: 'financePlannering1' }));
        return;
      }
      if (!user?.client_data || !Object.keys(user.client_data).includes('financePlannering2')) {
        dispatch(getClientData({ id, name: 'financePlannering2' }));
        return;
      }
      if (!user?.client_data || !Object.keys(user.client_data).includes('financePlannering3')) {
        dispatch(getClientData({ id, name: 'financePlannering3' }));
        return;
      }
      if (!user?.client_data || !Object.keys(user.client_data).includes('riskProfile1')) {
        dispatch(getClientData({ id, name: 'riskProfile1' }));
        return;
      }
      if (!user?.client_data || !Object.keys(user.client_data).includes('legalNeedsPlanning')) {
        dispatch(getClientData({ id, name: 'legalNeedsPlanning' }));
        return;
      }
      if (!user?.client_data || !Object.keys(user.client_data).includes('customerKnowledge')) {
        dispatch(getClientData({ id, name: 'customerKnowledge' }));
        return;
      }
      if (!user?.client_data || !Object.keys(user.client_data).includes('experienceBackground')) {
        dispatch(getClientData({ id, name: 'experienceBackground' }));
      }
    }
  }, [user?.client_data]);

  const validateClientInfo = useMemo(() => {
    let mandatory = false;
    let fullFill = false;
    if (user?.type.type === 'private') {
      mandatory = !!user?.firstName &&
      !!user?.lastName &&
      !!user?.phones.length &&
      !!user?.address[0]?.city &&
      !!user?.address[0]?.postal &&
      !!user?.address[0]?.zip_code &&
      !!user?.marital[0]?.main_occupation;
      fullFill = mandatory && !!user?.marital[0].marital_status && !!user?.marital[0].children_on_support;
    } else if (user?.type.type === 'business') {
      mandatory = true;
      fullFill = false;
    }
    const response = fullFill ? 'full-fill' : mandatory ? 'mandatory-only' : '';
    return response || '';
  }, [user]);

  const initCheckDashboard = {
    purposeAndHeart: user?.client_data?.purposeAndHeart?.full_status ?? '',
    economicSituation: user?.client_data?.economicSituation?.full_status === 'full-fill' &&
    user?.client_data?.economicSituation2?.full_status === 'full-fill' &&
    user?.client_data?.economicSituation3?.full_status === 'full-fill'
      ? 'full-fill'
      : (user?.client_data?.economicSituation?.full_status === 'mandatory-only' || user?.client_data?.economicSituation?.full_status === 'full-fill') &&
      (user?.client_data?.economicSituation2?.full_status === 'mandatory-only' || user?.client_data?.economicSituation2?.full_status === 'full-fill') &&
      (user?.client_data?.economicSituation3?.full_status === 'mandatory-only' || user?.client_data?.economicSituation3?.full_status === 'full-fill')
          ? 'mandatory-only'
          : '',
    financePlannering: user?.client_data?.financePlannering1?.full_status === 'full-fill' &&
    user?.client_data?.financePlannering2?.full_status === 'full-fill' &&
    user?.client_data?.financePlannering3?.full_status === 'full-fill'
      ? 'full-fill'
      : (user?.client_data?.financePlannering1?.full_status === 'mandatory-only' || user?.client_data?.financePlannering1?.full_status === 'full-fill') &&
      (user?.client_data?.financePlannering2?.full_status === 'mandatory-only' || user?.client_data?.financePlannering2?.full_status === 'full-fill') &&
      (user?.client_data?.financePlannering3?.full_status === 'mandatory-only' || user?.client_data?.financePlannering3?.full_status === 'full-fill')
          ? 'mandatory-only'
          : '',
    riskProfile: user?.client_data?.riskProfile1?.full_status ?? '',
    legalNeedsPlanning: user?.client_data?.legalNeedsPlanning?.full_status ? user?.client_data?.legalNeedsPlanning?.full_status : 'mandatory-only',
    customerKnowledge: user?.client_data?.customerKnowledge?.full_status ?? '',
    experienceBackground: user?.client_data?.experienceBackground?.full_status ?? '',
    clientInfo: validateClientInfo || ''
  };

  const [checkDashboard, setCheckDashboard] = useState(null);

  useEffect(() => {
    setCheckDashboard(initCheckDashboard);
  }, [user?.client_data]);

  const goToEditType = useCallback(() => {
    if (user?.status !== 'CLIENT_STATUS_SENDED_APPROVE') history.push(`/onboarding/${id}/edit`);
  }, [history]);

  const goToMyDetails = useCallback(() => {
    if (user?.status !== 'CLIENT_STATUS_SENDED_APPROVE') history.push(`/my-details/${id}`);
  }, [history]);

  const goToTest = useCallback(() => {
    if (user?.status !== 'CLIENT_STATUS_SENDED_APPROVE') history.push(`/test/${id}`);
  }, [history]);

  const goToEkonomikSituation = useCallback(() => {
    if (user?.status !== 'CLIENT_STATUS_SENDED_APPROVE') history.push(`/financial-situation/${id}/page-one`);
  }, [history]);

  const goToFinancePlannering = useCallback(() => {
    if (user?.status !== 'CLIENT_STATUS_SENDED_APPROVE') history.push(`/finance-planering/${id}/page-one`);
  }, [history]);

  const goToRiskProfile = useCallback(() => {
    if (user?.status !== 'CLIENT_STATUS_SENDED_APPROVE') history.push(`/risk-profile/${id}/page-one`);
  }, [history]);

  const goLegalNeedsPlanning = useCallback(() => {
    if (user?.status !== 'CLIENT_STATUS_SENDED_APPROVE') history.push(`/legal-needs-planning/${id}`);
  }, [history]);

  const goCustomerKnowledge = useCallback(() => {
    if (user?.status !== 'CLIENT_STATUS_SENDED_APPROVE') history.push(`/customer-knowledge/${id}`);
  }, [history]);

  const goExperienceBackground = useCallback(() => {
    if (user?.status !== 'CLIENT_STATUS_SENDED_APPROVE') history.push(`/experience-background/${id}`);
  }, [history]);
  const TermsConditionsAgreement = useCallback(() => {
    if (user?.status !== 'CLIENT_STATUS_SENDED_APPROVE' || isAdvisor) {
      history.push(`/terms-conditions-agreement/${id}`);
    }
  }, [history]);

  const handleApproveButton = () => {
    dispatch(sendClientApprove({ id, status: 'CLIENT_STATUS_SENDED_APPROVE' }));
  };

  return (
    <div className='companent-padding'>
      <Title className='onboard-component-title'>
      {isAdvisor
        ? t('CLIENT_DASHBOARD')
        : user?.type?.type === 'private'
          ? t('MY_DETAILS')
          : user?.type?.type === 'business'
            ? t('COMPANY_DETAILS')
            : t('CUSTOMER_INFORMATION')}
      </Title>
      <Container>
        <h3 className="info-header">{user?.type?.type === 'private' ? t('SOCIAL_SECURITY_NUMBER') : t('ORGANIZATION_NUMBER')}</h3>
        <h5 className="info-client">{user?.type?.identification_number || ''}</h5>
        <h5 className="info-client">{user?.type?.type === 'private' ? t('PRIVATPERSON') : t('CORPORATE_CLIENT')}</h5>
        <span onClick={goToEditType} className="info-client" style={{ display: 'flex', justifyContent: 'end' }}><Cross style={{ marginRight: 5 }} /> {t('EDIT')}</span>
      </Container>
      <Row gutter={[16, 16]}>
        <Col span={12}>
          <Card
            onClick={goToMyDetails}
            icon={<Person />}
            header={user?.type?.type === 'private'
              ? t('CUSTOMER_INFORMATION')
              : user?.type?.type === 'business'
                ? t('COMPANY_INFORMATION')
                : t('CUSTOMER_INFORMATION')}
            isCheck={checkDashboard?.clientInfo === 'full-fill'
              ? <CheckedAll/>
              : checkDashboard?.clientInfo === 'mandatory-only'
                ? <Checked/>
                : <CheckRed/>}
          />
          <Card onClick={goToEkonomikSituation} icon={<Bag />} header={t('ECONOMIC_SITUATION')}
            isCheck={
              checkDashboard?.economicSituation === 'full-fill'
                ? <CheckedAll/>
                : checkDashboard?.economicSituation === 'mandatory-only'
                  ? <Checked/>
                  : <CheckRed/>
              } />
          <Card onClick={goToRiskProfile} icon={<Risk />} header={t('RISK_PROFILE')}
          isCheck={ checkDashboard?.riskProfile === 'full-fill'
            ? <CheckedAll/>
            : checkDashboard?.riskProfile === 'mandatory-only'
              ? <Checked/>
              : <CheckRed/>
              } />
          <Card onClick={goCustomerKnowledge} icon={<Zoom />} header={user?.type?.type === 'private' ? t('CUSTOMER_KNOWLEDGE') : t('COMPANY_KNOWLEDGE')}
          isCheck={ checkDashboard?.customerKnowledge === 'full-fill'
            ? <CheckedAll/>
            : checkDashboard?.customerKnowledge === 'mandatory-only'
              ? <Checked/>
              : <CheckRed/>
              }
          />
          <Card onClick={TermsConditionsAgreement} icon={<Document />} header={t('ASSIGNMENT_AGREEMENT')}
          isCheck={isAdvisor || user?.status === 'CLIENT_STATUS_APPROVED'
            ? <Checked />
            : <CheckRed/> } />
        </Col>
        <Col span={12}>
          <Card onClick={goToTest} icon={<Goal />} header={t('PURPOSE_MATTERS')}
            isCheck={
              checkDashboard?.purposeAndHeart === 'full-fill'
                ? <CheckedAll/>
                : checkDashboard?.purposeAndHeart === 'mandatory-only'
                  ? <Checked/>
                  : <CheckRed/>
              } />
          <Card onClick={goToFinancePlannering} icon={<Planering />} header={t('FINANCIAL_PLANNING')}
            isCheck={
              checkDashboard?.financePlannering === 'full-fill'
                ? <CheckedAll/>
                : checkDashboard?.financePlannering === 'mandatory-only'
                  ? <Checked/>
                  : <CheckRed/>
              } />
          <Card onClick={goLegalNeedsPlanning} icon={<Balance />} header={t('LEGAL_ANALYSIS')}
          isCheck={
            checkDashboard?.legalNeedsPlanning === 'full-fill'
              ? <CheckedAll/>
              : checkDashboard?.legalNeedsPlanning === 'mandatory-only'
                ? <Checked/>
                : <CheckRed/>
              } />
          <Card onClick={goExperienceBackground} icon={<Expirience />} header={t('EXPERIENCE_BACKGROUND')}
          isCheck={
            checkDashboard?.experienceBackground === 'full-fill'
              ? <CheckedAll/>
              : checkDashboard?.experienceBackground === 'mandatory-only'
                ? <Checked/>
                : <CheckRed/>
              } />
          <Card icon={<Info />} header={t('PURCHASE_INFORMATION')}
          isCheck={<Checked />} />
        </Col>
      </Row>
      <Row gutter={[16, 6]}>
        {!isAdvisor && user?.status !== 'CLIENT_STATUS_APPROVED' &&
        <MainButton
          className='approve-button'
          onClick={handleApproveButton}
          disabled={checkDashboard
            ? (user?.status === 'CLIENT_STATUS_SENDED_APPROVE') || Object.values(checkDashboard).some(i => !i)
            : true}> { user?.status === 'CLIENT_STATUS_SENDED_APPROVE' ? t('WAITING_ADVISOR_CONFIRMATION') : t('SEND_APPROVAL')}</MainButton>}
      </Row>
    </div>
  );
};

export default Dashboard;
