/* eslint-disable camelcase */
import React, { useEffect, useMemo, useRef, useState } from 'react';

import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import { Button, DatePicker, Form, Input, Radio, Select, Space } from 'antd';
import { CustomTextarea } from '../../../components/CustomTextfield/CustomTextfield';
import CustomInputNumber from '../../../components/CustomInputNumber/CustomInputNumber';
import CustomRadioButtom from '../../../components/CustomRadioButtom/CustomRadioButtom';
import CustomSelect from '../../../components/CustomSelect/CustomSelect';

import { ReactComponent as Attached } from '../img/attached.svg';
import { ReactComponent as DelImage } from '../img/del-cross-icon.svg';
import { ReactComponent as Pluse } from '../../../assets/pluse-black-icon.svg';
import { ReactComponent as Picker } from '../../../assets/date-picker-icon.svg';

import countryEN from '../../../helpers/countries/countryEN.json';
import countrySW from '../../../helpers/countries/countrySW.json';
import { textSort } from '../../../helpers/textSort';
import CustomCheckbox from '../../../components/CustomCheckbox/CustomCheckbox';
import { behalfOptions, depositFrequencyOptions, individualTransactionsOptions, monthlyIncomeOptions, occupationOptions, pepRelativeTypeOptions, pepTypeOptions, transferValueOptions, withdrawalFrequencyOptions, yearlyDepositOptions } from '../data/SelectOptions';
import { decimalSeparator } from '../../../helpers/decimalSeparator';
import { useDispatch, useSelector } from 'react-redux';
import { deleteOnboardingDocument, getOnboardingDocumentsList, setOnboardingVerifed, uploadOnboardingDocument } from '../../../data/store/account/accountActions';
import dayjs from 'dayjs';
import ModalPreview from '../ModalPreview/ModalPreview';

const CategoryKYC = ({ userId, t, onboardingData, token }) => {
  const [form] = Form.useForm();
  const { i18n } = useTranslation('');
  const dispatch = useDispatch();

  const { uploadDocs } = useSelector((state) => state.accountReducer);

  const [modalDownloadShow, setModalDownloadShow] = useState(null);
  const handleDocumentPreview = (id, extension, key) => {
    setModalDownloadShow({id, extension, key});
  };
  const modalDownloadHide = () => setModalDownloadShow(null);

  const imageDocOne = useRef(null);
  const imageDocTwo = useRef(null);
  const imageDocThree = useRef(null);
  const imageDocFour = useRef(null);
  const imageDocFive = useRef(null);

  const documents = useMemo(() => {
    if(uploadDocs) {
      const imageDocOne = uploadDocs.filter( i => i.type === 'image-doc-one');
      const imageDocTwo = uploadDocs.filter( i => i.type === 'image-doc-two');
      const imageDocThree = uploadDocs.filter( i => i.type === 'image-doc-three');
      const imageDocFour = uploadDocs.filter( i => i.type === 'image-doc-four');
      const imageDocFive = uploadDocs.filter( i => i.type === 'image-doc-five');
      return (
        {imageDocOne, imageDocTwo, imageDocThree, imageDocFour, imageDocFive}
      );
    } else {
      return null;
    }
  }, [uploadDocs]);
  
  const [country, setCountry] = useState([]);

  const language = i18n.languages?.length ? i18n.languages[0] : 'en';
  const currentLang = ['en', 'sw'].includes(language) ? language : 'en';

  const usa_relationships = Form.useWatch(['kyc', 'abroad', 'usa_relationships'], form);
  const foreign_transactions = Form.useWatch(['kyc', 'abroad', 'foreign_transactions'], form);
  const savings_purpose_other = Form.useWatch(['kyc', 'purpose_of_engagement', 'savings_purpose', 'savings_purpose_option7'], form);
  const behalf = Form.useWatch(['kyc', 'purpose_of_engagement', 'behalf'], form);
  const main_occupation = Form.useWatch(['kyc', 'occupation', 'main_occupation'], form);
  const money_tranfer = Form.useWatch(['kyc', 'capital_transfer', 'money_tranfer'], form);
  const money_origin_other = Form.useWatch(['kyc', 'capital_transfer', 'money_origin', 'money_origin_option6'], form);
  const pep_position = Form.useWatch(['kyc', 'political_position', 'pep_position'], form);
  const pep_relative = Form.useWatch(['kyc', 'political_position', 'pep_relative'], form);
  
  
  useEffect(() => {
    if (onboardingData && onboardingData?.result) {
      dispatch(getOnboardingDocumentsList({ userId, id: onboardingData.id }));
      const data = {...onboardingData.result};
      const date = data.kyc?.citizenship_and_residence?.doc_expiry_date;
      data.kyc.citizenship_and_residence.doc_expiry_date = date ? dayjs(date) : '';
      form.setFieldsValue(data);
    }
  }, [onboardingData]);

  useEffect(() => {
    let countryList = [];
    if (currentLang === 'en') {
      countryList = [...countryEN];
    }
    if (currentLang === 'sw') {
      countryList = [...countrySW];
    } else {
      countryList = [...countryEN];
    }
    setCountry(countryList);
  }, [currentLang]);

  const handleSaveOnbording = () => {
    form.submit();
  };

  const uploadImage = (event, docType) => {
    const files = event.target.files;
    dispatch(uploadOnboardingDocument({id: onboardingData.id, docType, files}));
  };

  const handleClearUpload = (docID) => {
    dispatch(deleteOnboardingDocument({docID}));
  };

  const onSubmit = (data) => {
    const result = { ...onboardingData.result, ...data };
    dispatch(setOnboardingVerifed({ userId, id: onboardingData.id, data: { result } }));
  };

  return (
    <div className="card-right-wrap">
      <div style={{ width: '327px', margin: '0 auto' }}>
        <h2 className="onbording-card-header complet-step">
          {t('CITIZENSHIP_TAX_RESIDENCE')}
        </h2>
      </div>
      <Form
        form={form}
        name="onboarding-form"
        layout="vertical"
        onFinish={onSubmit}
        className="onboarding-form-body"
      >
        <Form.Item
          label={t('CITIZENSHIP')}
          name={['kyc', 'citizenship_and_residence', 'citizenship']}
          className="custom-required">
          <CustomSelect
            showSearch
            placeholder={t('SELECT_YOUR_OPTION')}
            className="custom-select"
            filterOption={(inputValue, option) =>
              inputValue.length > 1
                ? option.value
                  .toUpperCase()
                  .indexOf(inputValue.toUpperCase()) !== -1
                : true
            }
            notFoundContent={<></>}
          >
            {country
              ?.slice()
              ?.sort((a, b) => textSort(a.name, b.name, 1))
              .map((item) => {
                return (
                  <Select.Option key={item.id} value={item.alpha3}>
                    {item.name}
                  </Select.Option>
                );
              })}
          </CustomSelect>
        </Form.Item>
        <Form.Item
          label={t('CITIZENSHIP') + ' 2'}
          name={['kyc', 'citizenship_and_residence', 'citizenship_other']}
          className=""
        >
          <CustomSelect
            showSearch
            placeholder={t('SELECT_YOUR_OPTION')}
            className="custom-select"
            filterOption={(inputValue, option) =>
              inputValue.length > 1
                ? option.value
                  .toUpperCase()
                  .indexOf(inputValue.toUpperCase()) !== -1
                : true
            }
            notFoundContent={<></>}
          >
            {country
              ?.slice()
              ?.sort((a, b) => textSort(a.name, b.name, 1))
              .map((item) => {
                return (
                  <Select.Option key={item.id} value={item.alpha3}>
                    {item.name}
                  </Select.Option>
                );
              })}
          </CustomSelect>
        </Form.Item>
        <Form.Item
          label={t('TAX_RESIDENCE')}
          name={['kyc', 'citizenship_and_residence', 'tax_residence']}
          className="custom-required">
          <CustomSelect
            showSearch
            placeholder={t('SELECT_YOUR_OPTION')}
            className="custom-select"
            filterOption={(inputValue, option) =>
              inputValue.length > 1
                ? option.value
                  .toUpperCase()
                  .indexOf(inputValue.toUpperCase()) !== -1
                : true
            }
            notFoundContent={<></>}
          >
            {country
              ?.slice()
              ?.sort((a, b) => textSort(a.name, b.name, 1))
              .map((item) => {
                return (
                  <Select.Option key={item.id} value={item.alpha3}>
                    {item.name}
                  </Select.Option>
                );
              })}
          </CustomSelect>
        </Form.Item>
        <div className="onboarding-upload-wrap">
          <h3 className="onboarding-upload-title">
            {t('UPLOAD_COPY_YOUR_PASSPORT')}*
          </h3>
          <div className="image-upload-wrapper onboarding-upload">
            <input
              ref={imageDocOne}
              type="file"
              multiple
              accept='.jpg, .jpeg, .png, .pdf'
              className="item-img-upload"
              onChange={(event) => uploadImage(event, 'image-doc-one')}
            />
            <Button
              type="text"
              className="onboarding-upload-btn"
              onClick={() => imageDocOne.current.click()}
              icon={<Pluse />}
              iconPosition={'end'}
            >
              {t('ONB_UPLOAD_DOCUMENT')}
            </Button>
            {documents?.imageDocOne && documents.imageDocOne?.length
              ? (
                <div className='upload-documents-wrapper'>
                  {
                    documents?.imageDocOne?.map( el => {
                      return (
                        <div key={el.id} className="name-upload-wrapper upload-documents">
                          <Attached />
                          <p className="name-uploaded"
                            onClick={() => handleDocumentPreview(el.id, el.extension, el.key)}>
                            {el?.name}</p>
                          <DelImage
                            className="clear-uploaded"
                            onClick={() => handleClearUpload(el.id)}
                          />
                      </div>);
                    })
                  }
                </div>)
              : <p className='onboarding-upload-filename'>{t('NO_DOCUMENT_ADDED')}</p>
            }
          </div>
        </div>
        <Form.Item
          label={t('ID_DOC_NUMBER')}
          name={['kyc', 'citizenship_and_residence', 'id_doc_number']}
          className="custom-required">
          <Input
            placeholder={t('ID_DOC_CONTROL_NUMBER')}
            className="custom-input"
          />
        </Form.Item>
        <Form.Item
          label={t('FILL_EXPIRY_DATE_ID_DOCUMENT')}
          className="custom-required"
          name={['kyc', 'citizenship_and_residence', 'doc_expiry_date']}
          required={false}>
          <DatePicker
            placeholder="dd/mm/yyyy"
            className="finplan-datepicker onboarding-datepicker"
            suffixIcon={<Picker />}
          />
        </Form.Item>
        <div style={{ width: '327px', margin: '40px auto' }}>
          <h2 className="onbording-card-header complet-step">
            {t('ABROAD')}
          </h2>
        </div>
        <Form.Item
          label={t('USA_RELATIONSHIPS')}
          name={['kyc', 'abroad', 'usa_relationships']}
          className="m-top-12 m-bottom-0 custom-required">
          <Radio.Group className="checkbox-block">
            <Space
              direction="horizontal"
              align="start"
              className="ondoarding-space-wrap">
              <CustomRadioButtom value={false}>
                {t('NO')}
              </CustomRadioButtom>
              <CustomRadioButtom value={true}>
                {t('YES')}
              </CustomRadioButtom>
            </Space>
          </Radio.Group>
        </Form.Item>
        {
          usa_relationships &&
          <Form.Item
            name={['kyc', 'abroad', 'usa_relationships_type']}
            className="onboarding-textarea-purpose custom-required"
            label={t('USA_RELATIONSHIPS_TYPE')}>
            <CustomTextarea rows={4} placeholder={t('YOUR_ANSWER')} />
          </Form.Item>
        }
        <div className="onboarding-upload-wrap">
          <h3 className="onboarding-upload-title">
            {t('ONB_UPLOAD_DOCUMENTS')}
          </h3>
          <div className="image-upload-wrapper onboarding-upload">
            <input
              ref={imageDocTwo}
              type="file"
              multiple
              accept='.jpg, .jpeg, .png, .pdf'
              className="item-img-upload"
              onChange={(event) => uploadImage(event, 'image-doc-two')}
            />
            <Button
              type="text"
              className="onboarding-upload-btn"
              onClick={() => imageDocTwo.current.click()}
              icon={<Pluse />}
              iconPosition={'end'}>
              {t('ONB_UPLOAD_DOCUMENT')}
            </Button>
            {documents?.imageDocTwo && documents.imageDocTwo?.length
              ? (
                <div className='upload-documents-wrapper'>
                  {
                    documents?.imageDocTwo?.map( el => {
                      return (
                        <div key={el.id} className="name-upload-wrapper upload-documents">
                          <Attached />
                          <p className="name-uploaded"
                              onClick={() => handleDocumentPreview(el.id, el.extension, el.key)}>
                              {el?.name}</p>
                          <DelImage
                            className="clear-uploaded"
                            onClick={() => handleClearUpload(el.id)}
                          />
                        </div>);
                    })
                  }
                </div>)
              : <p className='onboarding-upload-filename'>{t('NO_DOCUMENT_ADDED')}</p>
            }
          </div>
        </div>
        <Form.Item
          label={t('FOREIGN_TRANSACTIONS')}
          name={['kyc', 'abroad', 'foreign_transactions']}
          className="m-top-12 m-bottom-0">
          <Radio.Group className="checkbox-block">
            <Space
              direction="horizontal"
              align="start"
              className="ondoarding-space-wrap">
              <CustomRadioButtom value={false}>
                {t('NO')}
              </CustomRadioButtom>
              <CustomRadioButtom value={true}>
                {t('YES')}
              </CustomRadioButtom>
            </Space>
          </Radio.Group>
        </Form.Item>
        {foreign_transactions &&
          <Form.Item
            className=""
            name={['kyc', 'abroad', 'transactions_amount']}
            label={t('TRANSACTIONS_AMOUNT')}>
              <CustomTextarea rows={4} placeholder={t('YOUR_ANSWER')} />
          </Form.Item>
        }
        <div className="onboarding-upload-wrap">
          <h3 className="onboarding-upload-title">
            {t('ONB_UPLOAD_DOCUMENTS')}
          </h3>
          <div className="image-upload-wrapper onboarding-upload">
            <input
              ref={imageDocThree}
              type="file"
              multiple
              accept='.jpg, .jpeg, .png, .pdf'
              className="item-img-upload"
              onChange={(event) => uploadImage(event, 'image-doc-three')}
            />
            <Button
              type="text"
              className="onboarding-upload-btn"
              onClick={() => imageDocThree.current.click()}
              icon={<Pluse />}
              iconPosition={'end'}>
              {t('ONB_UPLOAD_DOCUMENT')}
            </Button>
            {documents?.imageDocThree && documents.imageDocThree?.length
              ? (
                <div className='upload-documents-wrapper'>
                  {
                    documents?.imageDocThree?.map( el => {
                      return (
                        <div key={el.id} className="name-upload-wrapper upload-documents">
                          <Attached />
                          <p className="name-uploaded"
                              onClick={() => handleDocumentPreview(el.id, el.extension, el.key)}>
                              {el?.name}</p>
                          <DelImage
                            className="clear-uploaded"
                            onClick={() => handleClearUpload(el.id)}
                          />
                        </div>);
                    })
                  }
                </div>)
              : <p className='onboarding-upload-filename'>{t('NO_DOCUMENT_ADDED')}</p>
            }
          </div>
        </div>
        <div style={{ width: '327px', margin: '40px auto' }}>
          <h2 className="onbording-card-header complet-step">
            {t('PURPOSE_OF_ENGAGEMENT')}
          </h2>
        </div>
        <div className='onbording-check-wrap'>
          <h3 className='onbording-check-title'>{t('SAVINGS_PURPOSE')}*</h3>
          <Form.Item
            name={['kyc', 'purpose_of_engagement', 'savings_purpose', 'savings_purpose_option1']}
            valuePropName="checked"
            normalize={(value) => value ?? false}
            className="filter-check-item">
            <CustomCheckbox>{t('SAVINGS_INVESTMENTS')}</CustomCheckbox>
          </Form.Item>
          <Form.Item
            name={['kyc', 'purpose_of_engagement', 'savings_purpose', 'savings_purpose_option2']}
            valuePropName="checked"
            normalize={(value) => value ?? false}
            className="filter-check-item">
            <CustomCheckbox>{t('FINANCIAL_SECURITY')}</CustomCheckbox>
          </Form.Item>
          <Form.Item
            name={['kyc', 'purpose_of_engagement', 'savings_purpose', 'savings_purpose_option3']}
            valuePropName="checked"
            normalize={(value) => value ?? false}
            className="filter-check-item">
            <CustomCheckbox>{t('PENSION')}</CustomCheckbox>
          </Form.Item>
          <Form.Item
            name={['kyc', 'purpose_of_engagement', 'savings_purpose', 'savings_purpose_option4']}
            valuePropName="checked"
            normalize={(value) => value ?? false}
            className="filter-check-item">
            <CustomCheckbox>{t('PRIVATE_CONSUMPTION')}</CustomCheckbox>
          </Form.Item>
          <Form.Item
            name={['kyc', 'purpose_of_engagement', 'savings_purpose', 'savings_purpose_option5']}
            valuePropName="checked"
            normalize={(value) => value ?? false}
            className="filter-check-item">
            <CustomCheckbox>{t('GIFT_CLOSE_RELATIVE')}</CustomCheckbox>
          </Form.Item>
          <Form.Item
            name={['kyc', 'purpose_of_engagement', 'savings_purpose', 'savings_purpose_option6']}
            valuePropName="checked"
            normalize={(value) => value ?? false}
            className="filter-check-item">
            <CustomCheckbox>{t('BENEFICIARY')}</CustomCheckbox>
          </Form.Item>
          <Form.Item
            name={['kyc', 'purpose_of_engagement', 'savings_purpose', 'savings_purpose_option7']}
            valuePropName="checked"
            normalize={(value) => value ?? false}
            className="filter-check-item">
            <CustomCheckbox>{t('OTHER_DESCRIBE')}</CustomCheckbox>
          </Form.Item>
        </div>
        {savings_purpose_other &&
          <Form.Item
            name={['kyc', 'purpose_of_engagement', 'describe_other_purpose']}
            className="onboarding-textarea-purpose custom-required"
            label={t('DESCRIPTION')}>
            <CustomTextarea rows={4} placeholder={t('YOUR_TEXT')} maxLength={200} />
          </Form.Item>
        }
        <Form.Item
          label={t('BEHALF')}
          name={['kyc', 'purpose_of_engagement', 'behalf']}
          className="custom-required"
          required={false}>
          <CustomSelect className="custom-select" placeholder={t('SELECT_YOUR_OPTION')}>
            {behalfOptions?.map((i) => (
              <Select.Option key={i.value} value={i.value}>
                {t(i.label)}
              </Select.Option>
            ))}
          </CustomSelect>
        </Form.Item>
        {behalf === 'behalf_option2' &&
          <Form.Item
            name={['kyc', 'purpose_of_engagement', 'comment_on_proxy']}
            className="onboarding-textarea-purpose custom-required"
            label={t('COMMENT_ON_PROXY')}>
            <CustomTextarea rows={4} placeholder={t('YOUR_TEXT')} maxLength={200}/>
          </Form.Item>
        }
        {behalf === 'behalf_option3' &&
          <Form.Item
            name={['kyc', 'purpose_of_engagement', 'describe_other_behalf']}
            className="onboarding-textarea-purpose custom-required"
            label={t('COMMENT_ON_BEHALF')}>
            <CustomTextarea rows={4} placeholder={t('YOUR_TEXT')} />
          </Form.Item>
        }
        {behalf === 'behalf_option2' &&
        <div className="onboarding-upload-wrap">
          <h3 className="onboarding-upload-title">
            {t('UPLOAD_DOCUMENTS_PROVING')}
          </h3>
          <div className="image-upload-wrapper onboarding-upload">
            <input
              ref={imageDocFour}
              type="file"
              multiple
              accept='.jpg, .jpeg, .png, .pdf'
              className="item-img-upload"
              onChange={(event) => uploadImage(event, 'image-doc-four')}
            />
            <Button
              type="text"
              className="onboarding-upload-btn"
              onClick={() => imageDocFour.current.click()}
              icon={<Pluse />}
              iconPosition={'end'}>
              {t('ONB_UPLOAD_DOCUMENT')}
            </Button>
            {documents?.imageDocFour && documents.imageDocFour?.length
              ? (
                <div className='upload-documents-wrapper'>
                  {
                    documents?.imageDocFour?.map( el => {
                      return (
                        <div key={el.id} className="name-upload-wrapper upload-documents">
                          <Attached />
                          <p className="name-uploaded"
                              onClick={() => handleDocumentPreview(el.id, el.extension, el.key)}>
                              {el?.name}</p>
                          <DelImage
                            className="clear-uploaded"
                            onClick={() => handleClearUpload(el.id)}
                          />
                        </div>);
                    })
                  }
                </div>)
              : <p className='onboarding-upload-filename'>{t('NO_DOCUMENT_ADDED')}</p>
            }
          </div>
        </div>}
        <div style={{ width: '327px', margin: '0 auto' }}>
          <h2 className="onbording-card-header complet-step">
            {t('MAIN_OCCUPATION')}
          </h2>
        </div>
        <Form.Item
          label={t('WHAT_YOUR_MAIN_OCCUPATION')}
          name={['kyc', 'occupation', 'main_occupation']}
          className="custom-required"
          required={false}
        >
          <CustomSelect className="custom-select" placeholder={t('SELECT_YOUR_OPTION')}>
            {occupationOptions?.map((i) => (
              <Select.Option key={i.value} value={i.value}>
                {t(i.label)}
              </Select.Option>
            ))}
          </CustomSelect>
        </Form.Item>
        {(main_occupation === 'main_occupation_option1' || main_occupation === 'main_occupation_option2') &&
          <Form.Item
            name={['kyc', 'occupation', 'work_sector']}
            className="onboarding-textarea-purpose custom-required"
            label={t('WORK_SECTOR')}>
            <CustomTextarea rows={4} placeholder={t('YOUR_TEXT')} />
          </Form.Item>
        }
        {main_occupation === 'main_occupation_option5' &&
          <Form.Item
            name={['kyc', 'occupation', 'describe_other']}
            className="onboarding-textarea-purpose custom-required"
            label={t('COMMENT_ON_BEHALF')}>
            <CustomTextarea rows={4} placeholder={t('YOUR_TEXT')} />
          </Form.Item>
        }
        <Form.Item
          label={t('MONTHLY_INCOME')}
          name={['kyc', 'occupation', 'monthly_income']}
          className="custom-required"
          required={false}>
          <CustomSelect className="custom-select" placeholder={t('SELECT_YOUR_OPTION')}>
            {monthlyIncomeOptions?.map((i) => (
              <Select.Option key={i.value} value={i.value}>
                {t(i.label)}
              </Select.Option>
            ))}
          </CustomSelect>
        </Form.Item>
        <div style={{ width: '327px', margin: '0 auto' }}>
          <h2 className="onbording-card-header complet-step">
            {t('CAPITAL_TRANSFER')}
          </h2>
        </div>
        <Form.Item
          label={t('MONEY_TRANFER')}
          name={['kyc', 'capital_transfer', 'money_tranfer']}
          className="m-top-12 m-bottom-0">
          <Radio.Group className="checkbox-block">
            <Space
              direction="horizontal"
              align="start"
              className="ondoarding-space-wrap">
              <CustomRadioButtom value={false}>
                {t('NO')}
              </CustomRadioButtom>
              <CustomRadioButtom value={true}>
                {t('YES')}
              </CustomRadioButtom>
            </Space>
          </Radio.Group>
        </Form.Item>
        {!money_tranfer &&
          <Form.Item
            name={['kyc', 'capital_transfer', 'whose_money']}
            className="onboarding-textarea-purpose custom-required"
            label={t('WHOSE_MONEY')}>
            <CustomTextarea rows={4} placeholder={t('YOUR_ANSWER')} maxLength={500}/>
          </Form.Item>
        }
        <div className="onboarding-upload-wrap">
          <h3 className="onboarding-upload-title">
            {t('ONB_UPLOAD_DOCUMENTS')}
          </h3>
          <div className="image-upload-wrapper onboarding-upload">
            <input
              ref={imageDocFive}
              type="file"
              multiple
              accept='.jpg, .jpeg, .png, .pdf'
              className="item-img-upload"
              onChange={(event) => uploadImage(event, 'image-doc-five')}
            />
            <Button
              type="text"
              className="onboarding-upload-btn"
              onClick={() => imageDocFive.current.click()}
              icon={<Pluse />}
              iconPosition={'end'}>
              {t('ONB_UPLOAD_DOCUMENT')}
            </Button>
          </div>
          {documents?.imageDocFive && documents.imageDocFive?.length
            ? (
              <div className='upload-documents-wrapper'>
                {
                  documents?.imageDocFive?.map( el => {
                    return (
                      <div key={el.id} className="name-upload-wrapper upload-documents">
                        <Attached />
                        <p className="name-uploaded"
                          onClick={() => handleDocumentPreview(el.id, el.extension, el.key)}>
                        {el?.name}</p>
                        <DelImage
                          className="clear-uploaded"
                          onClick={() => handleClearUpload(el.id)}
                        />
                      </div>);
                  })
                }
              </div>)
            : <p className='onboarding-upload-filename'>{t('NO_DOCUMENT_ADDED')}</p>
          }
        </div>
        <div className='onbording-check-wrap'>
          <h3 className='onbording-check-title'>{t('MONEY_ORIGIN')}*</h3>
          <Form.Item
            name={['kyc', 'capital_transfer', 'money_origin', 'money_origin_option1']}
            valuePropName="checked"
            normalize={(value) => value ?? false}
            className="filter-check-item">
            <CustomCheckbox>{t('SALARY_PENSION')}</CustomCheckbox>
          </Form.Item>
          <Form.Item
            name={['kyc', 'capital_transfer', 'money_origin', 'money_origin_option2']}
            valuePropName="checked"
            normalize={(value) => value ?? false}
            className="filter-check-item">
            <CustomCheckbox>{t('SAVINGS_INVESTMENTS')}</CustomCheckbox>
          </Form.Item>
          <Form.Item
            name={['kyc', 'capital_transfer', 'money_origin', 'money_origin_option3']}
            valuePropName="checked"
            normalize={(value) => value ?? false}
            className="filter-check-item">
            <CustomCheckbox>{t('SALE_OF_COMPANY')}</CustomCheckbox>
          </Form.Item>
          <Form.Item
            name={['kyc', 'capital_transfer', 'money_origin', 'money_origin_option4']}
            valuePropName="checked"
            normalize={(value) => value ?? false}
            className="filter-check-item">
            <CustomCheckbox>{t('INHERITANCE_GIFT')}</CustomCheckbox>
          </Form.Item>
          <Form.Item
            name={['kyc', 'capital_transfer', 'money_origin', 'money_origin_option5']}
            valuePropName="checked"
            normalize={(value) => value ?? false}
            className="filter-check-item">
            <CustomCheckbox>{t('SALE_HOME_PROPERTY')}</CustomCheckbox>
          </Form.Item>
          <Form.Item
            name={['kyc', 'capital_transfer', 'money_origin', 'money_origin_option6']}
            valuePropName="checked"
            normalize={(value) => value ?? false}
            className="filter-check-item">
            <CustomCheckbox>{t('OTHER_DESCRIBE')}</CustomCheckbox>
          </Form.Item>
        </div>
        {
          money_origin_other &&
          <Form.Item
            name={['kyc', 'capital_transfer', 'describe_other']}
            className="onboarding-textarea-purpose custom-required"
            label={t('OTHER_DESCRIBE')}>
            <CustomTextarea rows={4} placeholder={t('YOUR_TEXT')} maxLength={200}/>
          </Form.Item>
        }
        <div className='onbording-check-wrap'>
          <h3 className='onbording-check-title'>{t('BANK_CAPITAL')}*</h3>
          <Form.Item
            name={['kyc', 'capital_transfer', 'bank_capital', 'bank_capital_option1']}
            valuePropName="checked"
            normalize={(value) => value ?? false}
            className="filter-check-item">
            <CustomCheckbox>{t('BANK_CAPITAL_OPTION_01')}</CustomCheckbox>
          </Form.Item>
          <Form.Item
            name={['kyc', 'capital_transfer', 'bank_capital', 'bank_capital_option2']}
            valuePropName="checked"
            normalize={(value) => value ?? false}
            className="filter-check-item">
            <CustomCheckbox>{t('BANK_CAPITAL_OPTION_02')}</CustomCheckbox>
          </Form.Item>
          <Form.Item
            name={['kyc', 'capital_transfer', 'bank_capital', 'bank_capital_option3']}
            valuePropName="checked"
            normalize={(value) => value ?? false}
            className="filter-check-item">
            <CustomCheckbox>{t('BANK_CAPITAL_OPTION_03')}</CustomCheckbox>
          </Form.Item>
          <Form.Item
            name={['kyc', 'capital_transfer', 'bank_capital', 'bank_capital_option4']}
            valuePropName="checked"
            normalize={(value) => value ?? false}
            className="filter-check-item">
            <CustomCheckbox>{t('BANK_CAPITAL_OPTION_04')}</CustomCheckbox>
          </Form.Item>
          <Form.Item
            name={['kyc', 'capital_transfer', 'bank_capital', 'bank_capital_option5']}
            valuePropName="checked"
            normalize={(value) => value ?? false}
            className="filter-check-item">
            <CustomCheckbox>{t('BANK_CAPITAL_OPTION_05')}</CustomCheckbox>
          </Form.Item>
          <Form.Item
            name={['kyc', 'capital_transfer', 'bank_capital', 'bank_capital_option6']}
            valuePropName="checked"
            normalize={(value) => value ?? false}
            className="filter-check-item">
            <CustomCheckbox>{t('BANK_CAPITAL_OPTION_06')}</CustomCheckbox>
          </Form.Item>
        </div>
        <Form.Item
          label={t('TRANSFER_VALUE')}
          name={['kyc', 'capital_transfer', 'transfer_value']}
          className="custom-required"
          required={false}
        >
          <CustomSelect className="custom-select" placeholder={t('SELECT_YOUR_OPTION')}>
            {transferValueOptions?.map((i) => (
              <Select.Option key={i.value} value={i.value}>
                {t(i.label)}
              </Select.Option>
            ))}
          </CustomSelect>
        </Form.Item>
        <Form.Item
          className="custom-required"
          name={['kyc', 'capital_transfer', 'estimate']}
          label={t('ENTER_ESTIMATE')}>
          <CustomInputNumber
            formatter={(value) => value.toString()?.replace('.', decimalSeparator()).replace(/\B(?=(\d{3})+(?!\d))/g, ' ') }
            parser={(value) => Number(value.replace(/ /g, '').replace(',', '.'))}
            placeholder='0,00'
            min={1}/>
        </Form.Item>

        <div style={{ width: '327px', margin: '40px auto' }}>
          <h2 className="onbording-card-header complet-step">
            {t('DEPOSITS_WITHDRAWALS')}
          </h2>
        </div>
        <Form.Item
          label={t('YEARLY_DEPOSIT')}
          name={['kyc', 'deposits_withdrawals', 'yearly_deposit']}
          className="custom-required"
          required={false}>
          <CustomSelect className="custom-select" placeholder={t('SELECT_YOUR_OPTION')}>
            {yearlyDepositOptions?.map((i) => (
              <Select.Option key={i.value} value={i.value}>
                {t(i.label)}
              </Select.Option>
            ))}
          </CustomSelect>
        </Form.Item>
        {
          <Form.Item
          className="custom-required"
          name={['kyc', 'deposits_withdrawals', 'estimate_deposit']}
          label={t('ENTER_ESTIMATE')}>
          <CustomInputNumber
            placeholder='0,00'
            formatter={(value) => value.toString()?.replace('.', decimalSeparator()).replace(/\B(?=(\d{3})+(?!\d))/g, ' ') }
            parser={(value) => Number(value.replace(/ /g, '').replace(',', '.'))}
            min={1}/>
        </Form.Item>}
        <Form.Item
          label={t('DEPOSIT_FREQUENCY')}
          name={['kyc', 'deposits_withdrawals', 'deposit_frequency']}
          className="custom-required"
          required={false}>
          <CustomSelect className="custom-select" placeholder={t('SELECT_YOUR_OPTION')}>
            {depositFrequencyOptions?.map((i) => (
              <Select.Option key={i.value} value={i.value}>
                {t(i.label)}
              </Select.Option>
            ))}
          </CustomSelect>
        </Form.Item>
        <Form.Item
          label={t('WITHDRAWAL_FREQUENCY')}
          name={['kyc', 'deposits_withdrawals', 'withdrawal_frequency']}
          className="custom-required"
          required={false}>
          <CustomSelect className="custom-select" placeholder={t('SELECT_YOUR_OPTION')}>
            {withdrawalFrequencyOptions?.map((i) => (
              <Select.Option key={i.value} value={i.value}>
                {t(i.label)}
              </Select.Option>
            ))}
          </CustomSelect>
        </Form.Item>
        <Form.Item
          label={t('INDIVIDUAL_TRANSACTIONS')}
          name={['kyc', 'deposits_withdrawals', 'individual_transactions']}
          className="custom-required"
          required={false}>
          <CustomSelect className="custom-select" placeholder={t('SELECT_YOUR_OPTION')}>
            {individualTransactionsOptions?.map((i) => (
              <Select.Option key={i.value} value={i.value}>
                {t(i.label)}
              </Select.Option>
            ))}
          </CustomSelect>
        </Form.Item>
        <Form.Item
          className="custom-required"
          name={['kyc', 'deposits_withdrawals', 'estimate_transactions']}
          label={t('ENTER_ESTIMATE')}>
          <CustomInputNumber
            formatter={(value) => value.toString()?.replace('.', decimalSeparator()).replace(/\B(?=(\d{3})+(?!\d))/g, ' ') }
            parser={(value) => Number(value.replace(/ /g, '').replace(',', '.'))}
            placeholder='0,00'
            min={1}/>
        </Form.Item>

        <div style={{ width: '327px', margin: '40px auto' }}>
          <h2 className="onbording-card-header complet-step">
            {t('PERSON_VULNERABLE_POSITION')}
          </h2>
        </div>
        <Form.Item
          label={t('PEP_POSITION')}
          name={['kyc', 'political_position', 'pep_position']}
          className="m-top-12 m-bottom-0 custom-required">
          <Radio.Group className="checkbox-block">
            <Space
              direction="horizontal"
              align="start"
              className="ondoarding-space-wrap">
              <CustomRadioButtom value={false}>
                {t('NO')}
              </CustomRadioButtom>
              <CustomRadioButtom value={true}>
                {t('YES')}
              </CustomRadioButtom>
            </Space>
          </Radio.Group>
        </Form.Item>
        {pep_position &&
          <Form.Item
            label={t('PEP_TYPE_QUESTION')}
            name={['kyc', 'political_position', 'pep_type']}
            className="custom-required"
            required={false}>
            <CustomSelect className="custom-select" placeholder={t('SELECT_YOUR_OPTION')}>
              {pepTypeOptions?.map((i) => (
                <Select.Option key={i.value} value={i.value}>
                  {t(i.label)}
                </Select.Option>
              ))}
            </CustomSelect>
          </Form.Item>
        }
        <Form.Item
          label={t('PEP_RELATIVE')}
          name={['kyc', 'political_position', 'pep_relative']}
          className="m-top-12 m-bottom-0 custom-required">
          <Radio.Group className="checkbox-block">
            <Space
              direction="horizontal"
              align="start"
              className="ondoarding-space-wrap">
              <CustomRadioButtom value={false}>
                {t('NO')}
              </CustomRadioButtom>
              <CustomRadioButtom value={true}>
                {t('YES')}
              </CustomRadioButtom>
            </Space>
          </Radio.Group>
        </Form.Item>
        {pep_relative &&
          <Form.Item
            label={t('PEP_RELATIVE_TYPE_QUESTION')}
            name={['kyc', 'political_position', 'pep_relative_type']}
            className="custom-required"
            required={false}>
            <CustomSelect className="custom-select" placeholder={t('SELECT_YOUR_OPTION')}>
              {pepRelativeTypeOptions?.map((i) => (
                <Select.Option key={i.value} value={i.value}>
                  {t(i.label)}
                </Select.Option>
              ))}
            </CustomSelect>
          </Form.Item>
        }
        <Button
          disabled={false}
          className="optimise-btn start-onbording-btn"
          onClick={handleSaveOnbording}
        >
          {t('SAVE')}
        </Button>
      </Form>
      <ModalPreview
        modalDownloadShow={modalDownloadShow}
        modalDownloadHide={modalDownloadHide}
        token={token}
      />
    </div>
  );
};

export default CategoryKYC;

CategoryKYC.propTypes = {
  userId: PropTypes.string,
  token: PropTypes.string,
  t: PropTypes.func,
  onboardingData: PropTypes.object,
  setOnboardingData: PropTypes.func,
};
