import React, { useCallback, useEffect, useState } from 'react';

import { useSelector, useDispatch } from 'react-redux';

import { useHistory, useParams } from 'react-router';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import { Typography, Radio, Input } from 'antd';

import Container from '../../components/Container';
import { Menu } from '../../assets';
import MainButtonArray from '../../components/MainButtonArray';
import './style.scss';
import ExpBackTableItem from './ExpBackTableItem';
import {
  getAccount,
  getClientData,
  setClientData
} from '../../data/store/account/accountActions';

const { Title } = Typography;

const tableItems = [
  {
    id: '1',
    title: 'SHARES_LISTED',
    name: 'sharesListed',
  },
  {
    id: '2',
    title: 'SHARES_UNLISTED',
    name: 'sharesUnlisted',
  },
  {
    id: '3',
    title: 'FUNDS_UCITS',
    name: 'fundsUCITS',
  },
  {
    id: '4',
    title: 'FUNDS_AIF_SPECIALFONDER',
    name: 'fundsAIFSpecialFunds',
  },
  {
    id: '5',
    title: 'BONDS_LISTED',
    name: 'bondsListed',
  },
  {
    id: '6',
    title: 'BONDS_UNLISTED',
    name: 'bondsUnlisted',
  },
  {
    id: '7',
    title: 'STOCK_INDEX_BONDS',
    name: 'stockIndexBonds',
  },
  {
    id: '8',
    title: 'HEDGE_FUNDS',
    name: 'hedgeFunds',
  },
  {
    id: '9',
    title: 'CREDIT_CERTIFICATE',
    name: 'creditCertificate',
  },
  {
    id: '10',
    title: 'AUTOCALLS',
    name: 'autocalls',
  },
  {
    id: '11',
    title: 'PORTFOLIO_MANAGEMENT',
    name: 'portfolioManagement',
  },
  {
    id: '12',
    title: 'EXCHANGE_TRADED_FUNDS',
    name: 'exchangeTradedFunds',
  },
  {
    id: '13',
    title: 'DERIVATIVES_LEVERAGED',
    name: 'derivativesAndLeveraged',
  },
];

const ExperienceBackground = () => {
  const { id } = useParams();
  const history = useHistory();
  const { t } = useTranslation('');
  const dispatch = useDispatch();
  const { user } = useSelector((state) => state.accountReducer);

  useEffect(() => {
    if (!user?.ID) {
      dispatch(getAccount({ id }));
    }
  }, []);
  useEffect(() => {
    if (user) {
      if (!user?.client_data || !Object.keys(user.client_data).includes('experienceBackground')) {
        dispatch(getClientData({ id, name: 'experienceBackground' }));
      }
    }
  }, [user?.client_data]);

  const initTableItem = (name) => ({
    numberOfDeals: user?.client_data?.experienceBackground?.data?.specificKnowledge[name]?.numberOfDeals ?? null,
    knowledgeAndExperience: user?.client_data?.experienceBackground?.data?.specificKnowledge[name]?.knowledgeAndExperience ?? null,
    hasBeenTrained: user?.client_data?.experienceBackground?.data?.specificKnowledge[name]?.hasBeenTrained ?? false,
    adviserBelieves: user?.client_data?.experienceBackground?.data?.specificKnowledge[name]?.adviserBelieves ?? false,
  });
  const initFields = {
    workedProfessionally: user?.client_data?.experienceBackground?.data?.workedProfessionally ?? null,
    manyYearsInvesting: user?.client_data?.experienceBackground?.data?.manyYearsInvesting ?? null,
    clientIsAware: user?.client_data?.experienceBackground?.data?.clientIsAware ?? null,
    specificKnowledge: {
      sharesListed: initTableItem('sharesListed'),
      sharesUnlisted: initTableItem('sharesUnlisted'),
      fundsUCITS: initTableItem('fundsUCITS'),
      fundsAIFSpecialFunds: initTableItem('fundsAIFSpecialFunds'),
      bondsListed: initTableItem('bondsListed'),
      bondsUnlisted: initTableItem('bondsUnlisted'),
      stockIndexBonds: initTableItem('stockIndexBonds'),
      hedgeFunds: initTableItem('hedgeFunds'),
      creditCertificate: initTableItem('creditCertificate'),
      autocalls: initTableItem('autocalls'),
      portfolioManagement: initTableItem('portfolioManagement'),
      exchangeTradedFunds: initTableItem('exchangeTradedFunds'),
      derivativesAndLeveraged: initTableItem('derivativesAndLeveraged'),
    },
  };

  const [fields, setFields] = useState(initFields);

  useEffect(() => {
    setFields(initFields);
  }, [user?.client_data]);

  const handleChangeInput = (event) => {
    const { name, value } = event.target;
    (+value < 100 && +value >= 1 && +value === Math.round(+value)) || value === ''
      ? setFields({ ...fields, [name]: value === '' ? '' : +value })
      : setFields({ ...fields });
  };

  const handleChangeTableInput = (event, itemName) => {
    const { name, value } = event.target;
    (+value < 10000 && +value >= 0 && +value === Math.round(+value)) || value === ''
      ? setFields({
        ...fields,
        specificKnowledge: {
          ...fields.specificKnowledge,
          [itemName]: { ...fields.specificKnowledge[itemName], [name]: value === '' ? '' : +value }
        }
      })
      : setFields({
        ...fields,
        specificKnowledge: {
          ...fields.specificKnowledge,
          [itemName]: { ...fields.specificKnowledge[itemName], [name]: fields.specificKnowledge[itemName][name] }
        }
      });
  };
  const handleChangeSelect = (value, name, itemName) => {
    setFields({
      ...fields,
      specificKnowledge: {
        ...fields.specificKnowledge,
        [itemName]: { ...fields.specificKnowledge[itemName], [name]: value }
      }
    });
  };

  const handleChangeCheckbox = (event, itemName) => {
    const { checked, name } = event.target;
    setFields({
      ...fields,
      specificKnowledge: {
        ...fields.specificKnowledge,
        [itemName]: { ...fields.specificKnowledge[itemName], [name]: checked }
      }
    });
  };

  const handleChangeRadio = (event) => {
    const { value, name } = event.target;
    setFields({ ...fields, [name]: value === 'yes' });
  };
  // const validManyYearsInvesting = () => {
  //   return fields.manyYearsInvesting !== null && (+fields.manyYearsInvesting < 10000 && +fields.manyYearsInvesting >= 1);
  // };
  const validSpecificKnowledge = () => {
    const isValid = [];
    for (const key in fields.specificKnowledge) {
      const element = fields.specificKnowledge[key];
      isValid.push(element.numberOfDeals !== null && element.numberOfDeals !== '' && !!element.knowledgeAndExperience);
    }
    return isValid.every(i => !!i);
  };
  const validateFull = () => {
    const validResp = fields.workedProfessionally !== null &&
    !!fields.manyYearsInvesting &&
    !!fields.clientIsAware &&
    validSpecificKnowledge();
    return validResp;
  };

  const validateExp = () => {
    const mandatory = validateFull();
    const fullFill = mandatory;
    return fullFill ? 'full-fill' : mandatory ? 'mandatory-only' : '';
  };

  const hendleSubmit = useCallback(() => {
    const data = {
      type: 'experienceBackground',
      data: fields,
      full_status: validateExp()
    };
    dispatch(setClientData({ id, fields: data }));
    history.push(`/dashboard/${id}`);
  }, [history, fields]);

  return (
    <>
      <Title className='onboard-component-title'>
        {t('EXPERIENCE_BACKGROUND')}
      </Title>
      <Container>
        <header className='legal-header'>
          <h4 className='legal-header'>{t('GENERAL_INFORMATION')}</h4>
        </header>
        <div className='saving-subtitle customer-subtitle'>
          <span>{t('DO_YOU_HAVE_EDUCATION')}</span>
        </div>
        <div style={{ display: 'flex' }}>
          <Radio.Group
            name='workedProfessionally'
            onChange={handleChangeRadio}
            value={fields.workedProfessionally === null
              ? ''
              : fields.workedProfessionally ? 'yes' : 'no'}
            className='saving-radio customer-radio'
          >
            <Radio value='yes'>{t('YES')}</Radio>
            <Radio value='no'>{t('No')}</Radio>
          </Radio.Group>
        </div>
        <p className='saving-lable mt-12'>{t('HOW_MANY_YEARS_INVESTING')}</p>
        <Input
          className='saving-input'
          name='manyYearsInvesting'
          value={fields.manyYearsInvesting}
          type='number'
          max={99}
          placeholder={t('ENTER_THE_NUMBER_YEARS')}
          onChange={handleChangeInput}
        />
      </Container>
      <Container className='experience-background-container'>
        <header className='legal-header'>
          <h4 className='legal-header'>{t('SPECIFIC_KNOWLEDGE_DIFFERENT')}</h4>
        </header>
        <div className='experience-background-grid'>
          <div className='experience-background-header'>
            {t('ASSET_CLASSES')}
          </div>
          <div className='experience-background-header'>
            <div className='w-118'>{t('NUMBER_OF_DEALS')}</div>
          </div>
          <div className='experience-background-header'>
            {t('KNOWLEDGE_AND_EXPERIENCE')}
          </div>
          <div className='experience-background-header center'>
            {t('THE_CUSTOMER_HAS_TRAINED')}
          </div>
          <div className='experience-background-header pl-62'>
            {t('THE_ADVISER_BELIEVES_CUSTOMER')}
          </div>
          {user && fields && tableItems.map((item) => (
            <ExpBackTableItem
              key={item.id}
              item={item}
              field={fields.specificKnowledge[item.name]}
              handleChangeTableInput={handleChangeTableInput}
              handleChangeSelect={handleChangeSelect}
              handleChangeCheckbox={handleChangeCheckbox}
            />
          ))}
        </div>
      </Container>
      <Container>
        <header className='legal-header'>
          <h4 className='legal-header'>{t('RISK_OF_LOSS')}</h4>
        </header>
        <div className='saving-subtitle customer-subtitle'>
          <span>{t('THE_CLIENT_AWARE_THAT_FINANCIAL_INSTRUMENTS')}</span>
        </div>
        <div style={{ display: 'flex' }}>
          <Radio.Group
            name='clientIsAware'
            onChange={handleChangeRadio}
            value={fields.clientIsAware === null
              ? ''
              : fields.clientIsAware ? 'yes' : 'no'}
            className='saving-radio customer-radio'
          >
            <Radio value='yes'>{t('YES')}</Radio>
            <Radio value='no'>{t('No')}</Radio>
          </Radio.Group>
        </div>
      </Container>
      <div className='btn-container'>
        <MainButtonArray
          style={{ width: 150, height: 40 }}
          type='link'
          arrayIconStart
          onClick={() => history.goBack()}
        >
          {t('GO_BACK')}
        </MainButtonArray>
        <div className='menu-wrapper'>
          <Menu
            onClick={() => history.replace(`/dashboard/${id}`)}
            style={{ width: 85 }}
          />
          <p className='menu-title'>{t('MY_DETAILS')}</p>
        </div>
        <MainButtonArray
          style={{ width: 150, height: 40 }}
          type='submit'
          onClick={hendleSubmit}
          arrayIconEnd
        >
          {t('MOVE_ON')}
        </MainButtonArray>
      </div>
    </>
  );
};

ExperienceBackground.propTypes = {
  id: PropTypes.string,
};

export default ExperienceBackground;
