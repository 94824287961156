import React, { useEffect, useCallback } from 'react';

import PropTypes from 'prop-types';

import { useHistory } from 'react-router';
import { useDispatch, useSelector } from 'react-redux';
import { getHistoryByPortfolioId } from '../../data/store/portfolio/portfolioActions';
import { Row, Typography } from 'antd';

import './style.scss';
import HistoryList from '../../components/HistoryList';
import Loading from '../../components/Loading';
import MainButton from '../../components/MainButton';

const { Title } = Typography;

const PortfolioHistory = ({ userId, portfolioId }) => {
  const history = useHistory();
  const dispatch = useDispatch();
  const isLoading = useSelector(state => state.portfolioReducer.isLoading);
  const historyByPortfolio = useSelector(state => state.portfolioReducer.history);

  useEffect(() => {
    dispatch(getHistoryByPortfolioId(+portfolioId));
  }, [dispatch]);

  const goBack = useCallback(() => {
    history.push(`/accounts/${userId}/portfolio/${portfolioId}`);
  }, [history, userId, portfolioId]);

  return (
    <Loading loading={isLoading}>
      <Row className='portfolio-history-wrapper'>
      {
        !historyByPortfolio?.portfolioHistory?.length
          ? <Row style={{ width: '100%', display: 'flex', alignItems: 'center', flexDirection: 'column' }}>
            <Title level={3}>History is empty</Title>
            <MainButton style={{ width: 200, marginTop: 15 }} onClick={goBack}>Go portfolio</MainButton>
          </Row>
          : <Row className='history-wrapper'>
            <HistoryList history={historyByPortfolio} />
          </Row>
      }
      </Row>
    </Loading>
  );
};

export default PortfolioHistory;

PortfolioHistory.propTypes = {
  userId: PropTypes.string,
  portfolioId: PropTypes.string,
};
