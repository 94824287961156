/* eslint-disable no-unused-vars */
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';

import { signUp } from '../../../data/store/user/userActions';
import {
  confirmPassRules,
  emailRules,
  nameRules,
  passwordRules
} from '../../../helpers/validation';
import { Form, Input, Row, Radio, Space, Divider, Button } from 'antd';
import CustomRadioButtom from '../../CustomRadioButtom/CustomRadioButtom';
import CustomCheckbox from '../../CustomCheckbox/CustomCheckbox';

import { ReactComponent as Arrow } from '../../../assets/arrow-up-right.svg';

import PropTypes from 'prop-types';
import CustomPasswordInput from '../../CustomPasswordInput/CustomPasswordInput';
import './style.scss';

const SignUpForm = ({theme}) => {
  const dispatch = useDispatch();
  const { t } = useTranslation('');
  const [form] = Form.useForm();
  const [, forceUpdate] = useState({});
  const [type, setType] = React.useState('ADVISER');

  const accountType = Form.useWatch('role', form);

  const handleValueChange = async () => {

  };

  const handleTermClick = () => {
    console.log('Terms');
  };

  const onChangeType = (e) => {
    setType(e.target.value);
  };

  const onSubmit = useCallback((fields) => {
    console.log('🚀 ~ onSubmit ~ fields:', fields);
    // const { firstName, lastName, ...data } = fields;
    dispatch(signUp({ ...fields }));
  }, [dispatch]);

  // To disable submit button at the beginning.
  useEffect(() => {
    forceUpdate({});
  }, []);

  return (
    <Row className='sign-up-form-wrapper'>
      <Form
        form={form}
        name="sign-up-form"
        layout="vertical"
        onFinish={onSubmit}
        className='sign-up-form'>
        <Row
          justify={'space-between'}
          align={'middle'}
          className='sign-up-form-check-wrap'>
          
          <h2 className='sign-up-check-lable'>{t('ROLE')}</h2>
          <Form.Item
            name="role"
            required={false}
            className='custom-required'
            >
            <Radio.Group onChange={onChangeType} value={type} className=''>
            <Space direction="horizontal">
              <CustomRadioButtom 
                className={'client-signup'}
                value="CLIENT">{t('CLIENT')}</CustomRadioButtom>
              <CustomRadioButtom 
                className={'client-signup'}
                value="ADVISER">{t('ADVISOR')}</CustomRadioButtom>
            </Space>
            </Radio.Group>
            </Form.Item>
        </Row>
        <Divider className='login-divider'/>
        <div className="sign-up-wrapper">
          <Form.Item
            label={t('FIRST_NAME')}
            name="first_name"
            rules={nameRules}
            required={false}
            className='custom-required'
            >
            <Input
              className='login-input custom-input'
              placeholder={t('FIRST_NAME')} />
          </Form.Item>

          <Form.Item
            label={t('LAST_NAME')}
            name="last_name"
            rules={nameRules}
            required={false}
            className='custom-required'
          >
            <Input
              className='login-input custom-input'
              placeholder={t('LAST_NAME')} />
          </Form.Item>
        </div>

        <div className="sign-up-wrapper">
          <Form.Item
            label={t('EMAIL')}
            name="email"
            rules={emailRules}
            required={false}
            className='custom-required'
          >
            <Input
              key={theme}
              className='custom-input'
              placeholder={t('EMAIL')}
              />
          </Form.Item>
        </div>
        {accountType === 'CLIENT' &&
          <div className="sign-up-wrapper">
          <Form.Item
            label={t('REGISTRATION_CODE')}
            name="registration_code"
            rules={[{
              required: true,
              message: `${t('REGISTRATION_CODE')}`,
            }]}
            required={false}
            className='custom-input'
          >
            <Input
              className='login-input custom-input'
              placeholder={t('REGISTRATION_CODE')}
              />
          </Form.Item>
        </div>}

        <div className="sign-up-wrapper">
          <Form.Item
            label={t('PASSWORD')}
            name="password"
            rules={passwordRules}
          >
            <CustomPasswordInput
              key={theme}
              placeholder={t('PASSWORD')}
              className='login-input custom-input'
              onChange={handleValueChange}
              autoComplete="new-password"
              />
          </Form.Item>

          <Form.Item
            label={t('CONFIRM_PASSWORD')}
            name="passwordConfirm"
            dependencies={['password']}
            rules={confirmPassRules}
          >
            <CustomPasswordInput
              key={theme}
              placeholder={t('CONFIRM_PASSWORD')}
              className='login-input custom-input'
              onChange={handleValueChange}
              autoComplete="new-password"
              />
          </Form.Item>
        </div>
        <div>
            <h3 className='pass-setup-term-title'>{t('TERM_AND_CONDITIONS')}
            <Arrow className='terms-link' onClick={handleTermClick}/>
            </h3>
              <Form.Item name="agreement" valuePropName="checked" required={true}>
                <CustomCheckbox
                  onChange={handleValueChange}>
                  {t('AGREEMENT_TEXT')}
                </CustomCheckbox>
              </Form.Item>
          </div>

        <Form.Item shouldUpdate>
          {() => (
            <Button
              type='submit'
              className='signup-submit-btn'
              onClick={() => form.submit()}
              disabled={
                // !form.isFieldsTouched(true) ||
                !!form.getFieldsError().filter(({ errors }) => errors.length).length ||
                !form.getFieldValue('agreement')
              }
            >
              {t('REGISTER')}
            </Button>
          )}
        </Form.Item>

      </Form>
    </Row>
  );
};

export default SignUpForm;

SignUpForm.propTypes = {
  theme: PropTypes.string,
};