/* eslint-disable no-unused-vars */
/* eslint-disable camelcase */
import React, { useEffect, useState } from 'react';

import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

// import ProfileRow from './ProfileRow';
import {
  getAdvisorById,
  getTwoFactorSecret,
  updateAdvisorPassword,
  userProfileUpdate,
  // updateAdvisorEmail,
  // updateAdvisorName,
  // updateAdvisorPhone,
} from '../../data/store/user/userActions';
import {
  emailRules,
  nameRules,
  numberRules,
  passwordRules,
} from '../../helpers/validation';

import {
  Alert,
  Button,
  Col,
  Divider,
  Form,
  Input,
  Radio,
  Row,
  Space,
  Switch,
} from 'antd';
import ModalTwoFactror from '../../components/Modal2FA/ModalTwoFactror';
import Container from '../../components/Container';
import { Header } from 'antd/es/layout/layout';

import Loading from '../../components/Loading';
import MainFooter from '../../components/MainFooter';

import CustomRadioButtom from '../../components/CustomRadioButtom/CustomRadioButtom';
// import { ReactComponent as Delete } from '../../assets/delete-icon.svg';
import PersonalNumberRow from './PersonalNumberRow/PersonalNumberRow';

import './style.scss';
import CustomPhoneInput from '../../components/CustomPhoneInput/CustomPhoneInput';
import CustomPasswordInput from '../../components/CustomPasswordInput/CustomPasswordInput';

const Profile = () => {
  const dispatch = useDispatch();
  const advisor = useSelector((state) => state.userReducer.advisor);
  const isLoading = useSelector((state) => state.userReducer.isLoading);
  const { t, i18n } = useTranslation('');
  const [form] = Form.useForm();

  const [language, setLanguage] = useState(null);
  const [newpass, setNewpass] = useState(null);
  const [confirmPass, setConfirmPass] = useState(null);
  const [isProfileValid, setIsProfileValid] = useState(false);
  const [isValidPass, setIsValidPass] = useState(false);

  useEffect(() => {
    setLanguage(i18n.language);
  }, []);

  const { first_name, last_name, phone, country_code } = advisor || {};
  const { email, id, identification_number, is2fa } = advisor;

  const initData = () => {
    const data = {
      first_name,
      last_name,
      phone,
      country_code: country_code || '+46',
      email,
    };
    form.setFieldsValue(data);
  };

  useEffect(() => {
    if (advisor) {
      initData(initData);
    }
  }, [advisor]);

  useEffect(() => {
    if (language) {
      i18n.changeLanguage(language);
    }
  }, [language]);

  const [alert, setAlert] = useState({ value: '', type: '' });
  const [open2FAModal, setOpen2FAModal] = useState(false);

  const successAlert = (value) => {
    setAlert(value);
    setTimeout(() => {
      setAlert('');
    }, 3500);
  };

  useEffect(() => {
    if (Object.keys(advisor).length === 0) {
      dispatch(getAdvisorById());
    }
  }, []);
  // 201801022383
  const [twofactor, setTwofactor] = useState(is2fa);

  useEffect(() => {
    setTwofactor(is2fa);
  }, [is2fa]);

  const hendleChangeTwoAuth = () => {
    if (!is2fa) {
      setOpen2FAModal(true);
      dispatch(getTwoFactorSecret());
    }
  };

  const hendleCloseModal = () => {
    setOpen2FAModal(false);
  };

  // const handleDeleteValue = () => {
  //   console.log('Delete personal nyumber');
  // };

  const handleFieldChange = (_, allValue) => {
    const { email, firstName, lastName, phone, country_code } = allValue;
    const isValid = email && firstName && lastName && phone && !isNaN(+phone);
    const needSave =
      email !== advisor?.email ||
      firstName !== advisor?.profile?.firstName ||
      lastName !== advisor?.profile?.lastName ||
      phone !== advisor?.profile?.phone ||
      country_code !== advisor?.profile?.country_code;
    setIsProfileValid(isValid && needSave);
  };

  const handleSetPass = (event, type) => {
    const { value } = event.target;
    if (type === 'new') {
      setNewpass(value);
      setIsValidPass(
        value === confirmPass &&
          !!value.match(
            /^(?=.*[0-9])(?=.*[A-Z])(?=.*[~!@#$%^&*()_+])(?=.*[a-z]).{8,}$/,
          ),
      );
    }
    if (type === 'confirm') {
      setConfirmPass(value);
      setIsValidPass(
        newpass === value &&
          !!value.match(
            /^(?=.*[0-9])(?=.*[A-Z])(?=.*[~!@#$%^&*()_+])(?=.*[a-z]).{8,}$/,
          ),
      );
    }
  };

  const handleLanguageChange = (event) => {
    setLanguage(event.target.value);
  };

  const handleSubmit = () => {
    form.submit();
  };
  const onSubmit = (value) => {
    // console.log('🚀 ~ onSubmit ~ value:', value);
    dispatch(userProfileUpdate({ data: value, id }));
  };
  const handleSubmitChangePass = () => {
    if (isValidPass) {
      dispatch(
        updateAdvisorPassword({ id, fields: { password: newpass } }),
      );
    }
  };

  return (
    <div className="profile-wrap" style={{ position: 'relative' }}>
      <div>
        <Header className="headerStyle">
          <Row className="overview-tabs-wrapper">
            <Col className="tabs-tab-wrapper" span={18}>
              <Button type="text" className="overview-btn ant-btn-active">
                {t('PROFILE')}
              </Button>
            </Col>
          </Row>
        </Header>
        <Row
          justify={'space-between'}
          align={'middle'}
          style={{ marginBottom: '24px', padding: '16px 40px 0' }}
        >
          <Col>
            <h2 className="suport-header-title">{t('PROFILE')}</h2>
          </Col>
        </Row>
        {alert.type && (
          <Alert
            message={alert.value}
            type={alert.type}
            className="profile-alert"
          />
        )}

        <Form
          form={form}
          name="client-profile"
          layout="vertical"
          onFinish={onSubmit}
          onValuesChange={handleFieldChange}
          style={{ padding: '0 24px' }}
        >
          <Container widthP={100} mb="24px" padding="48px 24px">
            <Row justify={'start'} style={{ gap: '48px' }}>
              <Col>
                <Form.Item
                  label={t('FIRST_NAME')}
                  className="custom-required"
                  placeholder="First name"
                  name="first_name"
                  maxLength={50}
                  rules={nameRules}
                  required={false}
                >
                  <Input
                    autoFocus
                    autoComplete="off"
                    className="custom-input"
                  />
                </Form.Item>
              </Col>
              <Col>
                <Form.Item
                  label={t('LAST_NAME')}
                  className="custom-required"
                  placeholder="Last name"
                  name="last_name"
                  maxLength={50}
                  rules={nameRules}
                  required={false}
                >
                  <Input autoComplete="off" className="custom-input" />
                </Form.Item>
              </Col>
            </Row>
            <Divider className="profile-divider" />
            <Row justify={'start'} style={{ gap: '48px', maxWidth: '1128px' }}>
              <Col>
                <Form.Item
                  label={t('EMAIL')}
                  className="custom-required"
                  placeholder="Email"
                  name="email"
                  maxLength={50}
                  rules={emailRules}
                  required={false}
                >
                  <Input autoComplete="off" className="custom-input" />
                </Form.Item>
              </Col>
              <Col>
                <Form.Item
                  label={t('PHONE')}
                  className="custom-required"
                  placeholder="Phone"
                  name="phone"
                  maxLength={50}
                  rules={numberRules}
                  required={false}
                >
                  <CustomPhoneInput nameCode="country_code" />
                </Form.Item>
              </Col>
              <Col className="btn-wrapper">
                <Button
                  className="portfolio-details-btn portfolio-export-btn profile-btn"
                  onClick={initData}
                >
                  {t('CANCEL')}
                </Button>
                <Button
                  disabled={!isProfileValid}
                  onClick={handleSubmit}
                  className="portfolio-details-btn btn-edit-save profile-btn"
                >
                  {t('SAVE')}
                </Button>
              </Col>
            </Row>
          </Container>
        </Form>
        <div style={{ padding: '0 24px' }}>
          <Container widthP={100} mb="24px" padding="24px">
            <Row
              justify={'space-between'}
              align={'middle'}
              style={{ maxWidth: '1128px', marginBottom: '0', padding: '0' }}
            >
              <Col>
                <h2 className="setting-header-title">{t('LANGUAGE')}</h2>
              </Col>
              <Col className="instrument-button-wraper">
                <Radio.Group
                  className="portfolio-form-radio"
                  value={language}
                  onChange={handleLanguageChange}
                >
                  <Space direction="horizontal">
                    <CustomRadioButtom value="en">
                      {t('ENGLISH')}
                    </CustomRadioButtom>
                    <CustomRadioButtom value="sw">
                      {t('SVENSKA')}
                    </CustomRadioButtom>
                  </Space>
                </Radio.Group>
              </Col>
            </Row>
          </Container>
        </div>
        <Form
          name="change-pass"
          layout="vertical"
          style={{ padding: '0 24px' }}
        >
          <Container widthP={100} mb="24px" padding="41px 24px 24px">
            <Row
              justify={'space-between'}
              align={'middle'}
              style={{ maxWidth: '1128px', marginBottom: '41px', padding: '0' }}
            >
              <Col>
                <h2 className="setting-header-title">{t('CHANGE_PASSWORD')}</h2>
              </Col>
            </Row>
            <Row
              justify={'start'}
              align={'top'}
              style={{ gap: '48px', maxWidth: '1128px', flexWrap: 'nowrap' }}
            >
              <Col>
                <Form.Item
                  label={t('PASSWORD')}
                  placeholder={t('PASSWORD')}
                  name="password"
                  maxLength={50}
                  initialValue={newpass}
                  rules={passwordRules}
                  required={false}
                >
                  <CustomPasswordInput
                    autoComplete="new-password"
                    className="login-input"
                    visible="true"
                    onChange={(value) => handleSetPass(value, 'new')}
                  />
                </Form.Item>
              </Col>
              <Col>
                <Form.Item
                  label={t('CONFIRM_PASSWORD')}
                  placeholder={t('CONFIRM_PASSWORD')}
                  name="password-confirm"
                  maxLength={50}
                  initialValue={confirmPass}
                  rules={passwordRules}
                  required={false}
                  dependencies={['password']}
                >
                  <CustomPasswordInput
                    autoComplete="new-password"
                    className="login-input"
                    visible="true"
                    onChange={(value) => handleSetPass(value, 'confirm')}
                  />
                </Form.Item>
              </Col>
              <Col>
                <Button
                  style={{ marginTop: '24px' }}
                  disabled={!isValidPass}
                  onClick={handleSubmitChangePass}
                  className="portfolio-details-btn btn-edit-save optimise-btn"
                >
                  {t('CHANGE_PASSWORD')}
                </Button>
              </Col>
            </Row>
          </Container>
        </Form>
        {!identification_number && !is2fa && (
          <p className="number-is2fa-warning">{t('INSTRUCTION')}</p>
        )}
        <div style={{ padding: '0 24px' }}>
          <Container widthP={100} mb="24px" padding="24px">
            <PersonalNumberRow
              successAlert={successAlert}
              value={identification_number}
            />
          </Container>
        </div>
        <div style={{ padding: '0 24px' }}>
          <Container widthP={100} mb="24px" padding="24px">
            <Row
              justify={'space-between'}
              align={'middle'}
              style={{ maxWidth: '1128px', marginBottom: '0', padding: '0' }}
            >
              <Col
                style={{ display: 'flex', alignItems: 'center', gap: '48px' }}
              >
                <h2 className="setting-header-title">{t('ENABLE_2FA')}</h2>
              </Col>
              <Col>
                <Switch
                  className={
                    is2fa
                      ? 'twoAuth-swich-button twoAuth-swich-button_off'
                      : 'twoAuth-swich-button'
                  }
                  onChange={hendleChangeTwoAuth}
                  checkedChildren="ON"
                  unCheckedChildren="OFF"
                  checked={twofactor}
                  disabled={is2fa}
                />
              </Col>
            </Row>
          </Container>
        </div>
        <ModalTwoFactror
          open2FAModal={open2FAModal}
          closeModal={hendleCloseModal}
        />
        <Loading loading={isLoading} />
      </div>
      <MainFooter />
    </div>
  );
};

export default Profile;
