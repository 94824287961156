/* eslint-disable no-unused-vars */
import { Button, Form, Input, Modal, Row, } from 'antd';
import PropTypes from 'prop-types';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { emailRules, nameRules } from '../../../helpers/validation';

import './style.scss';
import { createClientToAccount } from '../../../data/store/account/accountActions';

const ModalInvateClient = ({open, closeModal}) => {
  const { t } = useTranslation('');
  const [form] = Form.useForm();
  const dispatch = useDispatch();

  const close = () => {
    form.resetFields();
    closeModal();
  };

  const onSubmit = (fields) => {
      dispatch(createClientToAccount({ data: {accountId: open, client: fields}, closeModal: close }));
    };

  return (
    <Modal
      width={650}
      className="modal-invate-client"
      open={open}
      onCancel={close}
      footer={null}
      zIndex={1620}
      destroyOnClose={true}
    >
      <Row className="sign-up-form-wrapper">
        <Form
          form={form}
          name="sign-up-form"
          layout="vertical"
          onFinish={onSubmit}
          className="sign-up-form"
        >
        <h2 className="mapping-modal-header">{t('CREATE_AND_INVITE_CLIENT')}</h2>
          <div className="sign-up-wrapper">
            <Form.Item
              label={t('FIRST_NAME')}
              name="first_name"
              rules={nameRules}
              required={false}
              className="custom-required"
            >
              <Input className="login-input custom-input" placeholder={t('FIRST_NAME')} />
            </Form.Item>

            <Form.Item
              label={t('LAST_NAME')}
              name="last_name"
              rules={nameRules}
              required={false}
              className="custom-required"
            >
              <Input className="login-input custom-input" placeholder={t('LAST_NAME')} />
            </Form.Item>
          </div>

          <div className="sign-up-wrapper">
            <Form.Item
              label={t('EMAIL')}
              name="email"
              rules={emailRules}
              required={false}
              className="custom-required"
            >
              <Input
                className="login-input custom-input"
                placeholder={t('EMAIL')}
              />
            </Form.Item>
          </div>

          <Form.Item shouldUpdate>
            {() => (
              <Button
                type="submit"
                className="signup-submit-btn"
                onClick={() => form.submit()}
                disabled={
                  !form.isFieldsTouched(true) ||
                  !!form.getFieldsError().filter(({ errors }) => errors.length)
                    .length
                }
              >
                {t('REGISTER')}
              </Button>
            )}
          </Form.Item>
        </Form>
      </Row>
    </Modal>
  );
};

export default ModalInvateClient;

ModalInvateClient.propTypes = {
  open: PropTypes.number,
  closeModal: PropTypes.func,
};