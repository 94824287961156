import {
  CHANGE_TO_CURRENT,
  CHANGE_TO_DRAFT,
  CLEAR_PORTFOLIO_STATE,
  CREATE_REPORT,
  DELETE_PORTFOLIO_BY_ID,
  DOWNLOAD_REPORT,
  EXPORT_PORTFOLIO,
  GET_ALL_PORTFOLIO,
  GET_ALL_REPORTS,
  GET_HISTORY_BY_PORTFOLIO_ID,
  GET_PORTFOLIO_BY_ID,
  IMPORT_PORTFOLIO,
  OPTIMIZATION_PORTFOLIO,
  SAVE_FULL_PORTFOLIO,
  SEARCH_PARAMS,
  CREATE_PORTFOLIO_TASK_PROSPECTING,
  CREATE_PORTFOLIO_TASK_ANALYSIS,
  PORTFOLIO_FINAL,
  GET_STEP_OPTIMISATION_PORTFOLIO,
  POST_STEP_OPTIMISATION_PORTFOLIO,
  SEND_FINANCIAL_PLAN,
  SEND_DATA_PDF_PORTFOLIO,
  SET_STEPING,
  CLEAR_PORTFOLIO_PROSPECTING,
  DOWNLOAD_TEMP,
  GET_CANDIDATES,
  CLEAR_CANDIDATES,
  GET_TRANSACTIONS,
  CLEAR_PORTFOLIO_LIST,
  GET_AVAILABLE_PORTFOLIO_CODE,
  GET_PRE_CANDIDATE,
  CREATE_PORTFOLIO_CURRENT_ANALYSIS,
} from './portfolioActionTypes';
import {
  RESET_ERROR,
  RESET_NOTIFICATION,
} from '../helpers/auxiliaryActionTypes';
import { LOG_OUT } from '../user/userActionTypes';

const initialState = {
  portfolioList: {},
  portfolioById: {},
  prospectPortfolioSet: {},
  analyzedPortfolio: {},
  analyzedPortfolioDot: {},
  optimisationStep: {},
  metadata: {
    exclude_instrument: null,
    exclude_asset_class: null
  },
  bias: [],
  constraints: [],
  fixation: [],
  plan: {},
  instruments: {},
  tempInstruments: null,
  candidates: null,
  transactions: null,
  precandidate: null,
  stepsArray: [],
  reports: {},
  history: {},
  notification: {
    success: null,
    info: null,
  },
  isLoading: false,
  portfolioCodeList: [],
  isLoadingCode: false,
  isError: false,
  isReportOptPrepare: null,
  requestCount: 0,
};

export const portfolioReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_ALL_PORTFOLIO.REQUEST:
      return {
        ...state,
        portfolioList: {},
        isLoading: true,
      };

    case GET_ALL_PORTFOLIO.SUCCESS:
      return {
        ...state,
        portfolioList: action.payload,
        isLoading: false,
      };

    case GET_ALL_PORTFOLIO.ERROR:
      return {
        ...state,
        isLoading: false,
        isError: action.payload,
      };

    case GET_PORTFOLIO_BY_ID.REQUEST:
      return {
        ...state,
        isLoading: true,
        requestCount: state.requestCount + 1
      };

    case GET_PORTFOLIO_BY_ID.SUCCESS:
      return {
        ...state,
        isLoading: !!(state.requestCount - 1),
        requestCount: state.requestCount - 1,
        isError: false,
        portfolioById: action.payload,
      };

    case GET_PORTFOLIO_BY_ID.ERROR:
      return {
        ...state,
        isLoading: !!(state.requestCount - 1),
        requestCount: state.requestCount - 1,
        isError: action.payload,
      };

    case SAVE_FULL_PORTFOLIO.REQUEST:
      return {
        ...state,
        isLoading: true,
        requestCount: state.requestCount + 1,
      };

    case SAVE_FULL_PORTFOLIO.SUCCESS:
      return {
        ...state,
        // portfolioById: action.payload,
        isLoading: !!(state.requestCount - 1),
        requestCount: state.requestCount - 1,
        isError: false,
      };

    case SAVE_FULL_PORTFOLIO.ERROR:
      return {
        ...state,
        isLoading: !!(state.requestCount - 1),
        requestCount: state.requestCount - 1,
        isError: action.payload,
      };

    case DELETE_PORTFOLIO_BY_ID.REQUEST:
      return {
        ...state,
        isLoading: true,
        requestCount: state.requestCount + 1,
      };

    case DELETE_PORTFOLIO_BY_ID.SUCCESS: {
      const newList = state.portfolioList.list.filter(
        (el) => el.ID !== action.payload.portfolioId,
      );
      return {
        ...state,
        isLoading: !!(state.requestCount - 1),
        requestCount: state.requestCount - 1,
        isError: false,
        portfolioList: { ...state.portfolioList, list: newList },
        portfolioById: {}
      };
    }

    case DELETE_PORTFOLIO_BY_ID.ERROR:
      return {
        ...state,
        isLoading: !!(state.requestCount - 1),
        requestCount: state.requestCount - 1,
        isError: action.payload,
      };

    case SEARCH_PARAMS.REQUEST:
      return {
        ...state,
        isError: false,
      };

    case SEARCH_PARAMS.SUCCESS:
      return {
        ...state,
        instruments: action.payload || {},
        isError: false,
      };

    case SEARCH_PARAMS.ERROR:
      return {
        ...state,
        isError: action.payload,
      };

    case CLEAR_PORTFOLIO_STATE.ACTION:
      return {
        ...state,
        metadata: {
          exclude_instrument: null,
          exclude_asset_class: null
        },
        bias: [],
        constraints: [],
        fixation: [],
        plan: {},
        portfolioById: {},
        portfolioList: {},
      };

    case CHANGE_TO_DRAFT.ACTION:
      return {
        ...state,
        portfolioById: {
          ...state.portfolioById,
          state: 'DRAFT',
        },
      };

    case SET_STEPING.ACTION:
      return {
        ...state,
        stepsArray: action.payload,
      };

    case CHANGE_TO_CURRENT.ACTION:
      return {
        ...state,
        portfolioById: {
          ...state.portfolioById,
          state: 'CURRENT',
        },
      };

    case EXPORT_PORTFOLIO.REQUEST:
      return {
        ...state,
        isLoading: true,
        requestCount: state.requestCount + 1,
      };

    case EXPORT_PORTFOLIO.SUCCESS:
      return {
        ...state,
        isLoading: !!(state.requestCount - 1),
        requestCount: state.requestCount - 1,
        isError: false,
      };

    case EXPORT_PORTFOLIO.ERROR:
      return {
        ...state,
        isLoading: !!(state.requestCount - 1),
        requestCount: state.requestCount - 1,
        isError: action.payload,
      };

    case IMPORT_PORTFOLIO.REQUEST:
      return {
        ...state,
        isLoading: true,
        requestCount: state.requestCount + 1,
      };

    case IMPORT_PORTFOLIO.SUCCESS:
      return {
        ...state,
        tempInstruments: action.payload,
        isLoading: !!(state.requestCount - 1),
        requestCount: state.requestCount - 1,
        isError: false,
      };

    case IMPORT_PORTFOLIO.ERROR:
      return {
        ...state,
        isLoading: !!(state.requestCount - 1),
        requestCount: state.requestCount - 1,
        isError: action.payload,
      };

    case GET_ALL_REPORTS.REQUEST:
      return {
        ...state,
        isLoading: true,
        requestCount: state.requestCount + 1,
      };

    case GET_ALL_REPORTS.SUCCESS:
      return {
        ...state,
        reports: action.payload,
        isLoading: !!(state.requestCount - 1),
        requestCount: state.requestCount - 1,
        isError: false,
      };

    case GET_ALL_REPORTS.ERROR:
      return {
        ...state,
        isLoading: !!(state.requestCount - 1),
        requestCount: state.requestCount - 1,
        isError: action.payload,
      };

    case DOWNLOAD_REPORT.REQUEST:
      return {
        ...state,
        isLoading: true,
        requestCount: state.requestCount + 1,
      };

    case DOWNLOAD_REPORT.SUCCESS:
      return {
        ...state,
        isLoading: !!(state.requestCount - 1),
        requestCount: state.requestCount - 1,
        isError: false,
      };

    case DOWNLOAD_REPORT.ERROR:
      return {
        ...state,
        isLoading: !!(state.requestCount - 1),
        requestCount: state.requestCount - 1,
        isError: action.payload,
      };

    case DOWNLOAD_TEMP.ERROR:
      return {
        ...state,
        isError: action.payload,
      };

    case RESET_ERROR:
      return {
        ...state,
        isError: false,
      };

    case GET_HISTORY_BY_PORTFOLIO_ID.REQUEST:
      return {
        ...state,
        isLoading: true,
        requestCount: state.requestCount + 1,
      };

    case GET_HISTORY_BY_PORTFOLIO_ID.SUCCESS:
      return {
        ...state,
        history: action.payload,
        isLoading: !!(state.requestCount - 1),
        requestCount: state.requestCount - 1,
        isError: false,
      };

    case GET_HISTORY_BY_PORTFOLIO_ID.ERROR:
      return {
        ...state,
        isLoading: !!(state.requestCount - 1),
        requestCount: state.requestCount - 1,
        isError: action.payload,
      };

    case CREATE_REPORT.ERROR:
      return {
        ...state,
        isError: action.payload,
      };

    case OPTIMIZATION_PORTFOLIO.REQUEST:
      return {
        ...state,
        isLoading: true,
        requestCount: state.requestCount + 1,
      };

    case OPTIMIZATION_PORTFOLIO.SUCCESS:
      return {
        ...state,
        notification: action.payload,
        isLoading: !!(state.requestCount - 1),
        requestCount: state.requestCount - 1,
        isError: false,
      };

    case OPTIMIZATION_PORTFOLIO.ERROR:
      return {
        ...state,
        isLoading: !!(state.requestCount - 1),
        requestCount: state.requestCount - 1,
        isError: action.payload,
      };

    case CREATE_PORTFOLIO_TASK_ANALYSIS.REQUEST:
      return {
        ...state,
        analyzedPortfolioDot: {},
        isLoading: true,
        requestCount: state.requestCount + 1,
      };

    case CREATE_PORTFOLIO_TASK_ANALYSIS.SUCCESS:
      return {
        ...state,
        analyzedPortfolioDot: action.payload,
        isLoading: !!(state.requestCount - 1),
        requestCount: state.requestCount - 1,
        isError: false,
      };

    case CREATE_PORTFOLIO_TASK_ANALYSIS.ERROR:
      return {
        ...state,
        analyzedPortfolioDot: {},
        isLoading: !!(state.requestCount - 1),
        requestCount: state.requestCount - 1,
        isError: action.payload,
      };
      // TODO
    case CREATE_PORTFOLIO_TASK_PROSPECTING.REQUEST:
      return {
        ...state,
        isLoading: true,
        requestCount: state.requestCount + 1,
      };

    case CREATE_PORTFOLIO_TASK_PROSPECTING.SUCCESS:
      return {
        ...state,
        prospectPortfolioSet: action.payload,
        isLoading: !!(state.requestCount - 1),
        requestCount: state.requestCount - 1,
        isError: false,
      };

    case CREATE_PORTFOLIO_TASK_PROSPECTING.ERROR:
      return {
        ...state,
        isLoading: !!(state.requestCount - 1),
        requestCount: state.requestCount - 1,
        isError: action.payload,
      };

    case CREATE_PORTFOLIO_CURRENT_ANALYSIS.REQUEST:
      return {
        ...state,
        isLoading: true,
        requestCount: state.requestCount + 1,
      };

    case CREATE_PORTFOLIO_CURRENT_ANALYSIS.SUCCESS:
      return {
        ...state,
        analyzedPortfolio: action.payload,
        isLoading: !!(state.requestCount - 1),
        requestCount: state.requestCount - 1,
        isError: false,
      };

    case CREATE_PORTFOLIO_CURRENT_ANALYSIS.ERROR:
      return {
        ...state,
        isLoading: !!(state.requestCount - 1),
        requestCount: state.requestCount - 1,
        isError: action.payload,
      };

    case GET_STEP_OPTIMISATION_PORTFOLIO.REQUEST:
      return {
        ...state,
        isLoading: true,
        requestCount: state.requestCount + 1,
      };

    case GET_STEP_OPTIMISATION_PORTFOLIO.SUCCESS:
      return {
        ...state,
        metadata: action.payload?.metadata,
        bias: action.payload?.bias === null ? [] : action.payload?.bias || state.bias,
        constraints: action.payload?.constraints === null ? [] : action.payload?.constraints || state.constraints,
        fixation: action.payload?.fixation === null ? [] : action.payload?.fixation || state.fixation,
        plan: action.payload?.plan === null ? [] : action.payload?.plan || state.plan,
        isLoading: !!(state.requestCount - 1),
        requestCount: state.requestCount - 1,
        isError: false,
      };

    case GET_STEP_OPTIMISATION_PORTFOLIO.ERROR:
      return {
        ...state,
        isLoading: !!(state.requestCount - 1),
        requestCount: state.requestCount - 1,
        isError: action.payload,
      };

    case POST_STEP_OPTIMISATION_PORTFOLIO.REQUEST:
      return {
        ...state,
        isLoading: true,
        requestCount: state.requestCount + 1,
      };

    case POST_STEP_OPTIMISATION_PORTFOLIO.SUCCESS:
      return {
        ...state,
        metadata: action.payload?.metadata,
        bias: action.payload?.bias || state.bias,
        constraints: action.payload?.constraints || state.constraints,
        fixation: action.payload?.fixation || state.fixation,
        plan: action.payload?.plan || state.plan,
        isLoading: !!(state.requestCount - 1),
        requestCount: state.requestCount - 1,
        isError: false,
      };

    case POST_STEP_OPTIMISATION_PORTFOLIO.ERROR:
      return {
        ...state,
        isLoading: !!(state.requestCount - 1),
        requestCount: state.requestCount - 1,
        isError: action.payload,
      };

    case PORTFOLIO_FINAL.REQUEST:
      return {
        ...state,
        isLoading: true,
        requestCount: state.requestCount + 1,
      };

    case PORTFOLIO_FINAL.SUCCESS:
      return {
        ...state,
        isLoading: !!(state.requestCount - 1),
        requestCount: state.requestCount - 1,
        isError: false,
      };

    case PORTFOLIO_FINAL.ERROR:
      return {
        ...state,
        isLoading: !!(state.requestCount - 1),
        requestCount: state.requestCount - 1,
        isError: action.payload,
      };
    case SEND_FINANCIAL_PLAN.REQUEST:
      return {
        ...state,
        isLoading: true,
        requestCount: state.requestCount + 1,
      };

    case SEND_FINANCIAL_PLAN.SUCCESS:
      return {
        ...state,
        isLoading: !!(state.requestCount - 1),
        requestCount: state.requestCount - 1,
        isError: false,
      };

    case SEND_FINANCIAL_PLAN.ERROR:
      return {
        ...state,
        isLoading: !!(state.requestCount - 1),
        requestCount: state.requestCount - 1,
        isError: action.payload,
      };

    case SEND_DATA_PDF_PORTFOLIO.REQUEST:
      return {
        ...state,
        isLoading: true,
        isReportOptPrepare: true,
        requestCount: state.requestCount + 1,
      };

    case SEND_DATA_PDF_PORTFOLIO.SUCCESS:
      return {
        ...state,
        isLoading: !!(state.requestCount - 1),
        requestCount: state.requestCount - 1,
        isError: false,
        isReportOptPrepare: action.payload,
      };

    case SEND_DATA_PDF_PORTFOLIO.ERROR:
      return {
        ...state,
        isLoading: !!(state.requestCount - 1),
        requestCount: state.requestCount - 1,
        isError: action.payload,
        isReportOptPrepare: false,
      };

    case GET_CANDIDATES.REQUEST:
      return {
        ...state,
        isLoading: true,
        requestCount: state.requestCount + 1,
        candidates: null
      };

    case GET_CANDIDATES.SUCCESS:
      return {
        ...state,
        candidates: action.payload?.items,
        isLoading: !!(state.requestCount - 1),
        requestCount: state.requestCount - 1,
        isError: false,
      };

    case GET_CANDIDATES.ERROR:
      return {
        ...state,
        isLoading: !!(state.requestCount - 1),
        requestCount: state.requestCount - 1,
        isError: action.payload,
      };

    case GET_TRANSACTIONS.REQUEST:
      return {
        ...state,
        isLoading: true,
        requestCount: state.requestCount + 1,
        transactions: null
      };

    case GET_TRANSACTIONS.SUCCESS:
      return {
        ...state,
        transactions: action.payload,
        isLoading: !!(state.requestCount - 1),
        requestCount: state.requestCount - 1,
        isError: false,
      };

    case GET_TRANSACTIONS.ERROR:
      return {
        ...state,
        isLoading: !!(state.requestCount - 1),
        requestCount: state.requestCount - 1,
        isError: action.payload,
      };

    case GET_AVAILABLE_PORTFOLIO_CODE.REQUEST:
      return {
        ...state,
        isLoadingCode: true,

      };

    case GET_AVAILABLE_PORTFOLIO_CODE.SUCCESS:
      return {
        ...state,
        isLoadingCode: false,
        portfolioCodeList: action.payload,
        isError: false,
      };

    case GET_AVAILABLE_PORTFOLIO_CODE.ERROR:
      return {
        ...state,
        isLoadingCode: false,
        isError: action.payload,
      };

    case GET_PRE_CANDIDATE.REQUEST:
      return {
        ...state,
        isLoading: true,

      };

    case GET_PRE_CANDIDATE.SUCCESS:
      return {
        ...state,
        precandidate: action.payload,
        isLoading: false,
        isError: false,
      };

    case GET_PRE_CANDIDATE.ERROR:
      return {
        ...state,
        isLoading: false,
        isError: action.payload,
      };

    case CLEAR_PORTFOLIO_PROSPECTING.ACTION:
      return {
        ...state,
        analyzedPortfolio: null,
        analyzedPortfolioDot: null,
        prospectPortfolioSet: null,
      };

    case CLEAR_CANDIDATES.ACTION:
      return {
        ...state,
        candidates: [],
        transactions: [],
        plan: {},
      };

    case CLEAR_PORTFOLIO_LIST.ACTION:
      return {
        ...state,
        portfolioList: { ...state.portfolioList, list: null },
      };

    case RESET_NOTIFICATION:
      return {
        ...state,
        notification: {
          success: null,
          info: null,
        },
      };

    case LOG_OUT:
      return {
        portfolioList: {},
        portfolioById: {},
        instruments: {},
        reports: {},
        history: {},
        notification: {
          success: null,
          info: null,
        },
        isLoading: false,
        isError: false,
      };

    default: {
      return state;
    }
  }
};
