import React, { useCallback, useState, useEffect } from 'react';

import { useSelector, useDispatch } from 'react-redux';

import { useHistory } from 'react-router';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import { Typography, Input, Radio, InputNumber, Slider } from 'antd';
import TextArea from 'antd/lib/input/TextArea';

import Container from '../../../components/Container';
import MainButtonArray from '../../../components/MainButtonArray';
import { Menu } from '../../../assets';
import './style.scss';
import {
  getAccount,
  getClientData,
  setClientData
} from '../../../data/store/account/accountActions';
const { Title } = Typography;

const FinancePlanneringPartOne = (props) => {
  const { id } = props;
  const history = useHistory();
  const { t } = useTranslation('');
  const dispatch = useDispatch();
  const { user } = useSelector((state) => state.accountReducer);

  useEffect(() => {
    if (!user?.ID) {
      dispatch(getAccount({ id }));
    }
  }, []);
  useEffect(() => {
    if (user) {
      if (!user?.client_data || !Object.keys(user.client_data).includes('economicSituation2')) {
        dispatch(getClientData({ id, name: 'economicSituation2' }));
      }
      if (!user?.client_data || !Object.keys(user.client_data).includes('economicSituation3')) {
        dispatch(getClientData({ id, name: 'economicSituation3' }));
      }
      if (!user?.client_data || !Object.keys(user.client_data).includes('financePlannering1')) {
        dispatch(getClientData({ id, name: 'financePlannering1' }));
      }
    }
  }, [user?.client_data]);

  const defaultInvestibleCapital = () => {
    const financialAssets = user && user?.client_data && user?.client_data?.economicSituation2?.data
      ? user?.client_data?.economicSituation2?.data?.financialAssets?.cashEquivalents +
        user?.client_data?.economicSituation2?.data?.financialAssets?.financialInvestments +
        user?.client_data?.economicSituation2?.data?.financialAssets?.pensionSavings +
        user?.client_data?.economicSituation2?.data?.financialAssets?.otherAssets
      : 0;
    const fixedAssets = user && user?.client_data && user?.client_data?.economicSituation2?.data
      ? user?.client_data?.economicSituation2?.data?.fixedAssets?.home +
          user?.client_data?.economicSituation2?.data?.fixedAssets?.otherRealAssets
      : 0;
    const debts = user && user?.client_data && user?.client_data?.economicSituation2?.data
      ? user?.client_data?.economicSituation2?.data?.debts?.mortgages +
          user?.client_data?.economicSituation2?.data?.debts?.otherDebts
      : 0;
    return financialAssets + fixedAssets - debts;
  };

  const initPlanneringOne = {
    abilityLiquidate: user?.client_data?.financePlannering1?.data?.abilityLiquidate ?? null,
    howFutureGenerations: user?.client_data?.financePlannering1?.data?.howFutureGenerations ?? '',
    timeHorizon: user?.client_data?.financePlannering1?.data?.timeHorizon ?? 0,
    commentTimeHorizon: user?.client_data?.financePlannering1?.data?.commentTimeHorizon ?? '',
    investableCapital: user?.client_data?.economicSituation3?.data?.investibleCapital || defaultInvestibleCapital(),
    regularDeposits: user?.client_data?.financePlannering1?.data?.regularDeposits ?? null,
  };

  const [savingField, setSavingField] = useState(initPlanneringOne);

  useEffect(() => {
    setSavingField(initPlanneringOne);
  }, [user?.client_data]);

  const handleChangeRadio = (event) => {
    const { value } = event.target;
    setSavingField({ ...savingField, abilityLiquidate: value === 'yes' });
  };
  const handleChangeText = (event) => {
    const { name, value } = event.target;
    setSavingField({ ...savingField, [name]: value });
  };
  const handleChangeRange = (value, name) => {
    setSavingField({ ...savingField, [name]: value });
  };
  const handleChangeNumber = (event) => {
    const { name, value } = event.target;
    const validatedValue = () => {
      if (isNaN(+value)) return false;
      if (value.length > 8) return false;
      if (value <= 0) return false;
      return true;
    };
    if (validatedValue()) setSavingField({ ...savingField, [name]: +value });
    if (value === '') setSavingField({ ...savingField, [name]: null });
  };

  const validateFinPlanOne = () => {
    const mandatory = (savingField.abilityLiquidate !== null) && !!savingField.timeHorizon;
    const fullFill = mandatory && !!savingField.howFutureGenerations && !!savingField.commentTimeHorizon && !!savingField.regularDeposits;
    return fullFill ? 'full-fill' : mandatory ? 'mandatory-only' : '';
  };

  const hendleSubmit = useCallback(() => {
    const data = {
      type: 'financePlannering1',
      data: savingField,
      full_status: validateFinPlanOne()
    };
    dispatch(setClientData({ id, fields: data }));
    history.push('./page-two');
  }, [history, savingField]);

  return (
    <>
      <Title className='onboard-component-title'>
      {t('SAVING')}
      </Title>
      <Container>
        <header className='saving-header'>
          <h4 className='ekonomik-header'>{t('SAVING')}</h4>
        </header>
        <div className='saving-subtitle'>
          <span>
            {t('IS_THERE_NEED_FOR_ABILITY_LIQUIDATE')}
          </span>
        </div>
        <div style={{ display: 'flex' }}>
          <Radio.Group
            onChange={handleChangeRadio}
            value={savingField.abilityLiquidate
              ? 'yes'
              : savingField.abilityLiquidate === null
                ? ''
                : 'no'}
            className='saving-radio'>
            <Radio value='yes'>{t('YES')}</Radio>
            <Radio value='no'>{t('No')}</Radio>
          </Radio.Group>
        </div>
        <p className='saving-lable'>
            {t('HOW_FUTURE_GENERATIONS')}
        </p>
        <TextArea
          type='text'
          name='howFutureGenerations'
          value={savingField.howFutureGenerations}
          onChange={handleChangeText}
          maxLength={200}
        />
        <div className='saving-subtitle-time'>
          <span>{t('TIME_HORIZON')}</span>
          <div className='saving-range-wrapper'>
          <InputNumber
            name='timeHorizon'
            min={0}
            max={25}
            step={1}
            bordered={false}
            size='small'
            formatter={(value) =>
              `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ' ')
            }
            parser={(value) => value.replace(/\$\s?|( *)/g, '')}
            value={savingField.timeHorizon}
            onChange={(value) => handleChangeRange(value, 'timeHorizon')}
          />
          <span className='saving-range-suffix'>{t('YEAR')}</span>
          </div>
        </div>
        <p className='ekonomik-lable'></p>
        <Slider
          min={0}
          max={25}
          step={1}
          value={savingField.timeHorizon}
          onChange={(value) => handleChangeRange(value, 'timeHorizon')}
        />

        <p className='saving-lable'>
            {t('COMMENT_TIME_HORIZON')}
        </p>
        <TextArea
          type='text'
          name='commentTimeHorizon'
          value={savingField.commentTimeHorizon}
          onChange={handleChangeText}
          maxLength={200}
        />

        <div className='saving-subtitle-time'>
          <span>{t('INVESTIBLE_CAPITAL_ADV')}</span>
        {/* Read only should be a copy of the information gathered in Financial situation */}
          <span>{`${savingField.investableCapital.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ' ')} kr`}</span>
        </div>
        <p className='saving-lable'>{t('REGULAR_DEPOSITS')}</p>
          <Input
          className='saving-input'
          name='regularDeposits'
          value={savingField.regularDeposits}
          type='text'
          placeholder={t('ENTER_REGULAR_DEPOSITS')}
          onChange={handleChangeNumber}
          />
      </Container>
      <div className='btn-container'>
        <MainButtonArray
          style={{ width: 150, height: 40 }}
          type='link'
          arrayIconStart
          onClick={() => history.goBack()}
        >
          {t('GO_BACK')}
        </MainButtonArray>
        <div className='menu-wrapper'>
          <Menu
            onClick={() => history.replace(`/dashboard/${id}`)}
            style={{ width: 85 }}
          />
          <p className='menu-title'>{t('MY_DETAILS')}</p>
        </div>
        <MainButtonArray
          style={{ width: 150, height: 40 }}
          type='link'
          onClick={hendleSubmit}
          arrayIconEnd
        >
          {t('MOVE_ON')}
        </MainButtonArray>
      </div>
    </>
  );
};

FinancePlanneringPartOne.propTypes = {
  id: PropTypes.string,
};

export default FinancePlanneringPartOne;
