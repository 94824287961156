import React, { useEffect } from 'react';

import PropTypes from 'prop-types';
import { Button, Form, Select } from 'antd';
import CustomSelect from '../../../components/CustomSelect/CustomSelect';
import {
  doubleValueDropOptions,
  expectedReturnOptions,
  investmentPhilosophyOptions,
  lowValueIncreaseOptions,
  mediaInfluenceOptions,
  negativeNewsOptions,
  portfolioEvaluationOptions,
  portfolioValueDropOptions,
  returnTargetOptions,
} from '../data/SelectOptions';
import { CustomTextarea } from '../../../components/CustomTextfield/CustomTextfield';
import { useDispatch } from 'react-redux';
import { setOnboardingVerifed } from '../../../data/store/account/accountActions';

const CategoryRiskTolerance = ({ userId, t, onboardingData }) => {
  const [form] = Form.useForm();
  const dispatch = useDispatch();

  useEffect(() => {
    if (onboardingData) {
      form.setFieldsValue(onboardingData?.result);
    }
  }, [onboardingData]);

  const handleSaveOnbording = () => {
    form.submit();
  };

  const onSubmit = (data) => {
    const result = { ...onboardingData.result, ...data };
    dispatch(setOnboardingVerifed({ userId, id: onboardingData.id, data: { result } }));
  };

  return (
    <div className="card-right-wrap">
      <div style={{ width: '327px', margin: '0 auto' }}>
        <h2 className="onbording-card-header complet-step">
          {t('RISK_TOLERANCE')}
        </h2>
      </div>
      <Form
        form={form}
        name="onboarding-form"
        layout="vertical"
        onFinish={onSubmit}
        className="onboarding-form-body"
      >
        <Form.Item
          label={t('EXPECTED_RETURN')}
          name={['risk_tolerance', 'expected_return']}
          className="custom-required"
          required={false}
        >
          <CustomSelect
            className="custom-select"
            placeholder={t('SELECT_YOUR_OPTION')}
          >
            {expectedReturnOptions?.map((i) => (
              <Select.Option key={i.value} value={i.value}>
                {t(i.label)}
              </Select.Option>
            ))}
          </CustomSelect>
        </Form.Item>
        <Form.Item
          label={t('RETURN_TARGET')}
          name={['risk_tolerance', 'return_target']}
          className="custom-required"
          required={false}
        >
          <CustomSelect
            className="custom-select"
            placeholder={t('SELECT_YOUR_OPTION')}
          >
            {returnTargetOptions?.map((i) => (
              <Select.Option key={i.value} value={i.value}>
                {t(i.label)}
              </Select.Option>
            ))}
          </CustomSelect>
        </Form.Item>
        <Form.Item
          label={t('INVESTMENT_PHILOSOPHY')}
          name={['risk_tolerance', 'investment_philosophy']}
          className="custom-required"
          required={false}
        >
          <CustomSelect
            className="custom-select"
            placeholder={t('SELECT_YOUR_OPTION')}
          >
            {investmentPhilosophyOptions?.map((i) => (
              <Select.Option key={i.value} value={i.value}>
                {t(i.label)}
              </Select.Option>
            ))}
          </CustomSelect>
        </Form.Item>
        <Form.Item
          label={t('MEDIA_INFLUENCE')}
          name={['risk_tolerance', 'media_influence']}
          className="custom-required"
          required={false}
        >
          <CustomSelect
            className="custom-select"
            placeholder={t('SELECT_YOUR_OPTION')}
          >
            {mediaInfluenceOptions?.map((i) => (
              <Select.Option key={i.value} value={i.value}>
                {t(i.label)}
              </Select.Option>
            ))}
          </CustomSelect>
        </Form.Item>
        <Form.Item
          label={t('NEGATIVE_NEWS')}
          name={['risk_tolerance', 'negative_news']}
          className="custom-required"
          required={false}
        >
          <CustomSelect
            className="custom-select"
            placeholder={t('SELECT_YOUR_OPTION')}
          >
            {negativeNewsOptions?.map((i) => (
              <Select.Option key={i.value} value={i.value}>
                {t(i.label)}
              </Select.Option>
            ))}
          </CustomSelect>
        </Form.Item>
        <Form.Item
          label={t('PORTFOLIO_VALUE_DROP')}
          name={['risk_tolerance', 'portfolio_value_drop']}
          className="custom-required"
          required={false}
        >
          <CustomSelect
            className="custom-select"
            placeholder={t('SELECT_YOUR_OPTION')}
          >
            {portfolioValueDropOptions?.map((i) => (
              <Select.Option key={i.value} value={i.value}>
                {t(i.label)}
              </Select.Option>
            ))}
          </CustomSelect>
        </Form.Item>
        <Form.Item
          label={t('DOUBLE_VALUE_DROP')}
          name={['risk_tolerance', 'double_value_drop']}
          className="custom-required"
          required={false}
        >
          <CustomSelect
            className="custom-select"
            placeholder={t('SELECT_YOUR_OPTION')}
          >
            {doubleValueDropOptions?.map((i) => (
              <Select.Option key={i.value} value={i.value}>
                {t(i.label)}
              </Select.Option>
            ))}
          </CustomSelect>
        </Form.Item>
        <Form.Item
          label={t('LOW_VALUE_INCREASE')}
          name={['risk_tolerance', 'low_value_increase']}
          className="custom-required"
          required={false}
        >
          <CustomSelect
            className="custom-select"
            placeholder={t('SELECT_YOUR_OPTION')}
          >
            {lowValueIncreaseOptions?.map((i) => (
              <Select.Option key={i.value} value={i.value}>
                {t(i.label)}
              </Select.Option>
            ))}
          </CustomSelect>
        </Form.Item>
        <Form.Item
          label={t('PORTFOLIO_EVALUATION')}
          name={['risk_tolerance', 'portfolio_evaluation']}
          className="custom-required"
          required={false}
        >
          <CustomSelect
            className="custom-select"
            placeholder={t('SELECT_YOUR_OPTION')}
          >
            {portfolioEvaluationOptions?.map((i) => (
              <Select.Option key={i.value} value={i.value}>
                {t(i.label)}
              </Select.Option>
            ))}
          </CustomSelect>
        </Form.Item>
        <Form.Item
          name={['risk_tolerance', 'additional_comment']}
          className="onboarding-textarea-purpose"
          label={t('ADDITIONAL_COMMENT')}
        >
          <CustomTextarea rows={4} placeholder={t('YOUR_TEXT')} maxLength={200}/>
        </Form.Item>

        <Button
          disabled={false}
          className="optimise-btn start-onbording-btn"
          onClick={handleSaveOnbording}
        >
          {t('SAVE')}
        </Button>
      </Form>
    </div>
  );
};

export default CategoryRiskTolerance;

CategoryRiskTolerance.propTypes = {
  userId: PropTypes.string,
  t: PropTypes.func,
  onboardingData: PropTypes.object,
};
