/* eslint-disable no-unused-vars */
/* eslint-disable camelcase */
import { useCallback } from 'react';

export const useBarDataOpt = (data, colorsCategory, portfolioTotal) => {
  const barData = useCallback(() => {
    if (!data) return [];

    const assetClassSums = data?.reduce((acc, el) => {
      const name = el.financialAsset?.name || 'TBD';
      const weight_fin = el?.weight_fin;
      if (acc[name]) {
        acc[name].weight_fin += weight_fin;
      } else {
        acc[name] = {
          name,
          weight_fin,
          color: colorsCategory[el?.financialAssetCategory?.name],
          categoryName: el.financialAssetCategory?.name
        };
      }
      return acc;
    }, {});

    return Object.entries(assetClassSums).map(([_, { name, weight_fin, color, categoryName }]) => {
      return {
        name,
        categoryName,
        y: weight_fin,
        color
      };
    });
  }, [data, portfolioTotal]);

  return barData;
};
