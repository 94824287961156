import React, { useCallback, useState, useEffect } from 'react';

import { useSelector, useDispatch } from 'react-redux';

import { useHistory } from 'react-router';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import { Typography, Switch, Slider, InputNumber } from 'antd';
import TextArea from 'antd/lib/input/TextArea';

import Container from '../../../components/Container';
import MainButtonArray from '../../../components/MainButtonArray';
import { Menu } from '../../../assets';

import './style.scss';
import {
  getAccount,
  getClientData,
  setClientData
} from '../../../data/store/account/accountActions';

const { Title } = Typography;

const FinancialSituationPartTwo = (props) => {
  const { id } = props;
  const history = useHistory();
  const { t } = useTranslation('');
  const dispatch = useDispatch();
  const { user } = useSelector((state) => state.accountReducer);

  useEffect(() => {
    if (!user?.ID) {
      dispatch(getAccount({ id }));
    }
  }, []);

  useEffect(() => {
    if (user) {
      if (!user?.client_data || !Object.keys(user.client_data).includes('economicSituation2')) {
        dispatch(getClientData({ id, name: 'economicSituation2' }));
      }
    }
  }, [user?.client_data]);

  const initFinancialAssets = {
    financialAssetsSwitcher: user?.client_data?.economicSituation2?.data?.financialAssets?.financialAssetsSwitcher ?? true,
    financialInvestments: user?.client_data?.economicSituation2?.data?.financialAssets?.financialInvestments ?? 0,
    cashEquivalents: user?.client_data?.economicSituation2?.data?.financialAssets?.cashEquivalents ?? 0,
    pensionSavings: user?.client_data?.economicSituation2?.data?.financialAssets?.pensionSavings ?? 0,
    otherAssets: user?.client_data?.economicSituation2?.data?.financialAssets?.otherAssets ?? 0,
    comment: user?.client_data?.economicSituation2?.data?.financialAssets?.comment ?? '',
  };
  const resetFinancialAssets = {
    financialInvestments: 0,
    cashEquivalents: 0,
    pensionSavings: 0,
    otherAssets: 0,
  };

  const initFixedAssets = {
    fixedAssetsSwitcher: user?.client_data?.economicSituation2?.data?.fixedAssets?.fixedAssetsSwitcher ?? true,
    home: user?.client_data?.economicSituation2?.data?.fixedAssets?.home ?? 0,
    otherRealAssets: user?.client_data?.economicSituation2?.data?.fixedAssets?.otherRealAssets ?? 0,
    comment: user?.client_data?.economicSituation2?.data?.fixedAssets?.comment ?? '',
  };
  const resetFixedAssets = {
    home: 0,
    otherRealAssets: 0,
  };

  const initDebts = {
    debtsSwitcher: user?.client_data?.economicSituation2?.data?.debts?.debtsSwitcher ?? true,
    mortgages: user?.client_data?.economicSituation2?.data?.debts?.mortgages ?? 0,
    otherDebts: user?.client_data?.economicSituation2?.data?.debts?.otherDebts ?? 0,
    comment: user?.client_data?.economicSituation2?.data?.debts?.comment ?? '',
  };
  const resetDebts = {
    mortgages: 0,
    otherDebts: 0,
  };

  const [financialAssets, setFinancialAssets] = useState(initFinancialAssets);
  const [fixedAssets, setFixedAssets] = useState(initFixedAssets);
  const [debts, setDebts] = useState(initDebts);

  useEffect(() => {
    setFinancialAssets(initFinancialAssets);
    setFixedAssets(initFixedAssets);
    setDebts(initDebts);
  }, [user?.client_data]);

  const financialAssetsChange = (value, name) => {
    setFinancialAssets({ ...financialAssets, [name]: value });
  };
  const fixedAssetsChange = (value, name) => {
    setFixedAssets({ ...fixedAssets, [name]: value });
  };
  const debtsChange = (value, name) => {
    setDebts({ ...debts, [name]: value });
  };
  const commentChangeFinancialAssets = (event) => {
    const { name, value } = event.target;
    setFinancialAssets({ ...financialAssets, [name]: value });
  };
  const commentChangeFixedlAssets = (event) => {
    const { name, value } = event.target;
    setFixedAssets({ ...fixedAssets, [name]: value });
  };
  const commentChangeDebts = (event) => {
    const { name, value } = event.target;
    setDebts({ ...debts, [name]: value });
  };

  const hendlSwitchBlockFirst = () => {
    setFinancialAssets({ ...financialAssets, ...resetFinancialAssets, financialAssetsSwitcher: !financialAssets.financialAssetsSwitcher });
  };
  const hendlSwitchBlockSecond = () => {
    setFixedAssets({ ...fixedAssets, ...resetFixedAssets, fixedAssetsSwitcher: !fixedAssets.fixedAssetsSwitcher });
  };
  const hendlSwitchBlockThird = () => {
    setDebts({ ...debts, ...resetDebts, debtsSwitcher: !debts.debtsSwitcher });
  };

  const validateFinSitTwo = () => {
    const mandatory = true;
    const fullFill = mandatory &&
      !!financialAssets.comment &&
      !!fixedAssets.comment &&
      !!debts.comment &&
      financialAssets.financialAssetsSwitcher && fixedAssets.fixedAssetsSwitcher && debts.debtsSwitcher
      ;
    return fullFill ? 'full-fill' : mandatory ? 'mandatory-only' : '';
  };

  const hendleSubmit = useCallback(() => {
    const data = {
      type: 'economicSituation2',
      data: { financialAssets, fixedAssets, debts },
      full_status: validateFinSitTwo()
    };
    dispatch(setClientData({ id, fields: data }));
    history.push('./page-three');
  }, [history, financialAssets, fixedAssets, debts]);

  return (
    <>
      <Title className='onboard-component-title'>
        {t('ECONOMIC_SITUATION')}
      </Title>
      <Container>
        <header className='header'>
          <h4 className='ekonomik-header'>{t('FINANCIAL_ASSETS')}</h4>
          <Switch onChange={hendlSwitchBlockFirst} defaultChecked={financialAssets.financialAssetsSwitcher} />
        </header>
        <div className='ekonomik-subtitle'>
          <span>{t('FINANCIAL_INVESTMENTS')}</span>
          <InputNumber
            name='financialInvestments'
            min={0}
            max={20000000}
            step={10000}
            bordered={false}
            size='small'
            formatter={(value) =>
              `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ' ')
            }
            parser={(value) => value.replace(/\$\s?|( *)/g, '')}
            value={financialAssets.financialInvestments}
            onChange={(value) => financialAssetsChange(value, 'financialInvestments')}
            disabled={!financialAssets.financialAssetsSwitcher}
          />
        </div>
        <p className='ekonomik-lable'></p>
        <Slider
          min={0}
          max={20000000}
          step={10000}
          value={financialAssets.financialInvestments}
          onChange={(value) => financialAssetsChange(value, 'financialInvestments')}
          disabled={!financialAssets.financialAssetsSwitcher}
        />

        <div className='ekonomik-subtitle'>
          <span>{t('CASH_AND_CASH_EQUIVALENTS')}</span>
          <InputNumber
            name='cashEquivalents'
            min={0}
            max={20000000}
            step={10000}
            bordered={false}
            size='small'
            formatter={(value) =>
              `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ' ')
            }
            parser={(value) => value.replace(/\$\s?|( *)/g, '')}
            value={financialAssets.cashEquivalents}
            onChange={(value) => financialAssetsChange(value, 'cashEquivalents')}
            disabled={!financialAssets.financialAssetsSwitcher}
          />
        </div>
        <p className='ekonomik-lable'></p>
        <Slider
          min={0}
          max={20000000}
          step={10000}
          value={financialAssets.cashEquivalents}
          onChange={(value) => financialAssetsChange(value, 'cashEquivalents')}
          disabled={!financialAssets.financialAssetsSwitcher}
        />

        <div className='ekonomik-subtitle'>
          <span>{t('PENSION_SAVINGS_AND_SIMILAR')}</span>
          <InputNumber
            name='pensionSavings'
            min={0}
            max={20000000}
            step={10000}
            bordered={false}
            size='small'
            formatter={(value) =>
              `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ' ')
            }
            parser={(value) => value.replace(/\$\s?|( *)/g, '')}
            value={financialAssets.pensionSavings}
            onChange={(value) => financialAssetsChange(value, 'pensionSavings')}
            disabled={!financialAssets.financialAssetsSwitcher}
          />
        </div>
        <p className='ekonomik-lable'></p>
        <Slider
          min={0}
          max={20000000}
          step={10000}
          value={financialAssets.pensionSavings}
          onChange={(value) => financialAssetsChange(value, 'pensionSavings')}
          disabled={!financialAssets.financialAssetsSwitcher}
        />

        <div className='ekonomik-subtitle'>
          <span>{t('OTHER_ASSETS')}</span>
          <InputNumber
            name='otherAssets'
            min={0}
            max={20000000}
            step={10000}
            bordered={false}
            size='small'
            formatter={(value) =>
              `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ' ')
            }
            parser={(value) => value.replace(/\$\s?|( *)/g, '')}
            value={financialAssets.otherAssets}
            onChange={(value) => financialAssetsChange(value, 'otherAssets')}
            disabled={!financialAssets.financialAssetsSwitcher}
          />
        </div>
        <p className='ekonomik-lable'></p>
        <Slider
          min={0}
          max={20000000}
          step={10000}
          value={financialAssets.otherAssets}
          onChange={(value) => financialAssetsChange(value, 'otherAssets')}
          disabled={!financialAssets.financialAssetsSwitcher}
        />

        <p className='ekonomik-lable ekonomik-comment'>{t('COMMENT')}</p>
        <TextArea
          type='text'
          name='comment'
          value={financialAssets.comment}
          onChange={commentChangeFinancialAssets}
          maxLength={200}
        />
      </Container>

      <Container>
        <header className='header'>
          <h4 className='ekonomik-header'>{t('FIXED_ASSETS')}</h4>
          <Switch onChange={hendlSwitchBlockSecond} defaultChecked={fixedAssets.fixedAssetsSwitcher} />
        </header>
        <div className='ekonomik-subtitle'>
          <span>{t('HOME')}</span>
          <InputNumber
            name='home'
            min={0}
            max={20000000}
            step={100000}
            bordered={false}
            size='small'
            formatter={(value) =>
              `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ' ')
            }
            parser={(value) => value.replace(/\$\s?|( *)/g, '')}
            value={fixedAssets.home}
            onChange={(value) => fixedAssetsChange(value, 'home')}
            disabled={!fixedAssets.fixedAssetsSwitcher}
          />
        </div>
        <p className='ekonomik-lable'></p>
        <Slider
          min={0}
          max={20000000}
          step={100000}
          value={fixedAssets.home}
          onChange={(value) => fixedAssetsChange(value, 'home')}
          disabled={!fixedAssets.fixedAssetsSwitcher}
        />

        <div className='ekonomik-subtitle'>
          <span>{t('OTHER_REAL_ASSETS')}</span>
          <InputNumber
            name='otherRealAssets'
            min={0}
            max={10000000}
            step={100000}
            bordered={false}
            size='small'
            formatter={(value) =>
              `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ' ')
            }
            parser={(value) => value.replace(/\$\s?|( *)/g, '')}
            value={fixedAssets.otherRealAssets}
            onChange={(value) => fixedAssetsChange(value, 'otherRealAssets')}
            disabled={!fixedAssets.fixedAssetsSwitcher}
          />
        </div>
        <p className='ekonomik-lable'></p>
        <Slider
          min={0}
          max={10000000}
          step={100000}
          value={fixedAssets.otherRealAssets}
          onChange={(value) => fixedAssetsChange(value, 'otherRealAssets')}
          disabled={!fixedAssets.fixedAssetsSwitcher}
        />

        <p className='ekonomik-lable ekonomik-comment'>{t('COMMENT')}</p>
        <TextArea
          type='text'
          name='comment'
          value={fixedAssets.comment}
          onChange={commentChangeFixedlAssets}
          maxLength={200}
        />
      </Container>

      <Container>
        <header className='header'>
          <h4 className='ekonomik-header'>{t('DEBTS')}</h4>
          <Switch onChange={hendlSwitchBlockThird} defaultChecked={debts.debtsSwitcher} />
        </header>
        <div className='ekonomik-subtitle'>
          <span>{t('MORTGAGES')}</span>
          <InputNumber
            name='mortgages'
            min={0}
            max={20000000}
            step={10000}
            bordered={false}
            size='small'
            formatter={(value) =>
              `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ' ')
            }
            parser={(value) => value.replace(/\$\s?|( *)/g, '')}
            value={debts.mortgages}
            onChange={(value) => debtsChange(value, 'mortgages')}
            disabled={!debts.debtsSwitcher}
          />
        </div>
        <p className='ekonomik-lable'></p>
        <Slider
          min={0}
          max={20000000}
          step={10000}
          value={debts.mortgages}
          onChange={(value) => debtsChange(value, 'mortgages')}
          disabled={!debts.debtsSwitcher}
        />

        <div className='ekonomik-subtitle'>
          <span>{t('OTHER_DEBTS')}</span>
          <InputNumber
            name='otherDebts'
            min={0}
            max={10000000}
            step={10000}
            bordered={false}
            size='small'
            formatter={(value) =>
              `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ' ')
            }
            parser={(value) => value.replace(/\$\s?|( *)/g, '')}
            value={debts.otherDebts}
            onChange={(value) => debtsChange(value, 'otherDebts')}
            disabled={!debts.debtsSwitcher}
          />
        </div>
        <p className='ekonomik-lable'></p>
        <Slider
          min={0}
          max={10000000}
          step={10000}
          value={debts.otherDebts}
          onChange={(value) => debtsChange(value, 'otherDebts')}
          disabled={!debts.debtsSwitcher}
        />
        <p className='ekonomik-lable ekonomik-comment'>{t('COMMENT')}</p>
        <TextArea
          type='text'
          name='comment'
          value={debts.comment}
          onChange={commentChangeDebts}
          maxLength={200}
        />
      </Container>
      <div className='btn-container'>
        <MainButtonArray
          style={{ width: 150, height: 40 }}
          type='link'
          arrayIconStart
          onClick={() => history.goBack()}
        >
          {t('GO_BACK')}
        </MainButtonArray>
        <div className='menu-wrapper'>
          <Menu
            onClick={() => history.replace(`/dashboard/${id}`)}
            style={{ width: 85 }}
          />
          <p className='menu-title'>{t('MY_DETAILS')}</p>
        </div>
        <MainButtonArray
          style={{ width: 150, height: 40 }}
          type='link'
          onClick={hendleSubmit}
          arrayIconEnd
        >
          {t('MOVE_ON')}
        </MainButtonArray>
      </div>
    </>
  );
};

FinancialSituationPartTwo.propTypes = {
  id: PropTypes.string,
};

export default FinancialSituationPartTwo;
