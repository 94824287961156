import React, { useEffect } from 'react';

import { useRouteMatch, Switch, Route, Redirect} from 'react-router-dom';
import RecommendationMapsList from './RecommendationMapsList';
import RecommendationMapsItem from './RecommendationMapsItem';
import { useDispatch, useSelector } from 'react-redux';
import { getRecommendationMaps } from '../../data/store/instruments/instrumentsActions';

const RecommendationMapsRouter = () => {
  const { url } = useRouteMatch();
  const dispatch = useDispatch();
  const recommendation = useSelector((state) => state.instrumentsReducer.recommendation);

  useEffect(() => {
    dispatch(getRecommendationMaps());
  }, []);
  

  return (
    <Switch>
        <Route path={`${url}/list`}>
          <RecommendationMapsList recommendation={recommendation}/>
        </Route>
        <Route path={`${url}/edit/:mapsId`}>
          <RecommendationMapsItem recommendation={recommendation}/>
        </Route>
        <Route path='*'>
          <Redirect to={`${url}/list`} />
        </Route>

    </Switch>
  );
};

export default RecommendationMapsRouter;