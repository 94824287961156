import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';

import { Button, Col, Row } from 'antd';

import { useTranslation } from 'react-i18next';

import { ReactComponent as Delete } from '../../assets/delete-black-icon.svg';
import CustomSelect from '../../components/CustomSelect/CustomSelect';

const ExcludeAssetClassItem = (props) => {
  const {
    i, handleSelect, renderSelectOption,
    handleDelete,
  } = props;
  const { t } = useTranslation('');
  const [searchAssets, setSearchAssets] = useState(null);
  const [filteredAssets, setFilteredAssets] = useState(renderSelectOption);

  useEffect(() => {
    if (searchAssets?.length > 2) {
      const render = renderSelectOption.filter(i => i?.label.toLowerCase().includes(searchAssets.toLowerCase()));
      setFilteredAssets(render);
    } else setFilteredAssets(renderSelectOption);
  }, [searchAssets]);

  return (
    <div className='bias-row-wrapper' key={i.ID}>
      <Row className="bias-form-row" align="middle" >
        <Col style={{ width: '29.16%' }}>
          <CustomSelect
            showSearch
            width='100%'
            className="custom-select"
            labelInValue
            value={{ value: i.id, label: i.label }}
            onSearch={(value) => setSearchAssets(value)}
            onSelect={(value) => handleSelect(value, i?.key)}
            options={filteredAssets}
            placeholder={t('ASSET_CLASS')}
            filterOption={false}
          />
        </Col>
        <Col style={{ flexBasis: '6.33%' }}>
          <div
            style={{ width: '100%', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
            <Button
              type='text'
              style={{ marginTop: '5px', cursor: 'pointer' }}
              icon={<Delete />}
              onClick={() => handleDelete(i?.key)}
            />
          </div>
        </Col>
      </Row>
    </div>
  );
};

export default ExcludeAssetClassItem;

ExcludeAssetClassItem.propTypes = {
  i: PropTypes.object,
  selectedAssetClass: PropTypes.array,
  setSelectedAssetClass: PropTypes.func,
  renderSelectOption: PropTypes.array,
  handleSelect: PropTypes.func,
  handleChangeFix: PropTypes.func,
  handleDelete: PropTypes.func,
};
