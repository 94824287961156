import React from 'react';
import PropTypes from 'prop-types';

import ReactQrCode from '@devmehq/react-qr-code';
import { useTranslation } from 'react-i18next';

import { ReactComponent as Telephone } from '../../assets/comp-banck-icon.svg';
import { ReactComponent as Arrow } from '../../assets/arrow-up-right.svg';
import { Modal, Divider, Button } from 'antd';

const ModalVerifyPersonalNumber = props => {
  const { openBankId, closeBankID, codeQR, restartSign } = props;
  const { t } = useTranslation('');

  const isMobileDevice = () => {
    return /iPhone|iPad|iPod|Android/i.test(navigator.userAgent);
  };

  const openBankIDApp = () => {
    if (isMobileDevice()) {
      window.location.href = 'bankid://';
      setTimeout(() => {
        window.open('https://www.bankid.com', '_blank');
      }, 2000);
    } else {
      window.open('https://www.bankid.com', '_blank');
    }
  };

  const ModalTitle = () => {
    return (
        <div className='bankId-logo-wrapper'><div className='bankId-logo' /></div>
    );
  };

  return (
    <Modal
      title={<ModalTitle/>}
      centered
      open={openBankId}
      onCancel={closeBankID}
      width={650}
      destroyOnClose={true}
      footer={false}
      wrapClassName="modal-bankId-wrap"
    >
    <div style={{ minHeight: '400px' }}>
      {codeQR && codeQR.includes('bankid.') &&
      <>
        <div className='modal-bankId-header'>{t('MOBILEID_HEADER')}</div>
        <div className='modal-bankId-code-wrapper'><ReactQrCode value={codeQR} size={200}/></div>
        <div className="confirm-reg-links">
        <a onClick={openBankIDApp}>
          <p className="mail-change-link">
            {t('USE_BANKID')}
            <Arrow />
          </p>
        </a>
    </div>
      </>}
      {codeQR === 'Try again' &&
      <div style={{ width: '380px', margin: '50px auto' }}>
        <div className='modal-bankId-header'>{t('THE_LOGIN_FAILED')}</div>
        <p className='modal-bankId-text'>{t('LOGIN_FAILED_TEXT')}</p>
        <div className='modal-bankId-link'>
          <button onClick={restartSign }>{t('TRY_AGAIN')}</button>
        </div>
      </div>}
      {codeQR === 'wait finger' &&
      <div style={{ width: '380px', margin: '10px auto' }}>
        <div className='modal-bankId-header'>{t('LOGIN_WITH_BANKID')}</div>
        <div className='modal-bankId-code-wrapper'><Telephone /></div>
        <p className='modal-bankId-text'>{t('TYPE_YOUR_SECURITY')}</p>
        <Divider className='login-divider'/>
        <Button
          type='text'
          onClick={closeBankID}
          className='modal-bankId-cancel'>
          {t('CANCEL')}
        </Button>
      </div>}
    </div>
    </Modal>
  );
};

ModalVerifyPersonalNumber.propTypes = {
  openBankId: PropTypes.bool,
  closeBankID: PropTypes.func,
  codeQR: PropTypes.string,
  restartSign: PropTypes.func,
};

export default ModalVerifyPersonalNumber;
