import React, { useEffect, useCallback, useState } from 'react';

import { useSelector, useDispatch } from 'react-redux';

import { useHistory, useParams } from 'react-router';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import {
  getAccount,
  getClientData,
  setClientData,
} from '../../data/store/account/accountActions';

import { Typography } from 'antd';
import TextArea from 'antd/lib/input/TextArea';

import Container from '../../components/Container';
import { Menu } from '../../assets';
import MainButtonArray from '../../components/MainButtonArray';
import './style.scss';

const { Title } = Typography;

const LegalNeedsPlanning = () => {
  const { id } = useParams();
  const history = useHistory();
  const { t } = useTranslation('');
  const dispatch = useDispatch();
  const { user } = useSelector((state) => state.accountReducer);

  useEffect(() => {
    if (!user?.ID) {
      dispatch(getAccount({ id }));
    }
  }, []);
  useEffect(() => {
    if (user) {
      if (
        !user?.client_data ||
        !Object.keys(user.client_data).includes('legalNeedsPlanning')
      ) {
        dispatch(getClientData({ id, name: 'legalNeedsPlanning' }));
      }
    }
  }, [user?.client_data]);

  const initFields = {
    legalQuestion_01:
      user?.client_data?.legalNeedsPlanning?.data?.legalQuestion_01 ?? '',
    legalQuestion_02:
      user?.client_data?.legalNeedsPlanning?.data?.legalQuestion_02 ?? '',
    legalQuestion_03:
      user?.client_data?.legalNeedsPlanning?.data?.legalQuestion_03 ?? '',
    legalQuestion_04:
      user?.client_data?.legalNeedsPlanning?.data?.legalQuestion_04 ?? '',
    legalQuestion_05:
      user?.client_data?.legalNeedsPlanning?.data?.legalQuestion_05 ?? '',
    comment: user?.client_data?.legalNeedsPlanning?.data?.comment ?? '',
  };

  const [fields, setFields] = useState(initFields);

  useEffect(() => {
    setFields(initFields);
  }, [user?.client_data]);

  const changeTextAria = (event) => {
    const { name, value } = event.target;
    setFields({ ...fields, [name]: value });
  };

  const validateFull = () => {
    const validate = { ...fields };
    delete validate.comment;
    const resp = Object.values(validate).every((value) => !!value);
    return resp;
  };

  const validateLegal = () => {
    const mandatory = true;
    const fullFill = mandatory && validateFull();
    return fullFill ? 'full-fill' : mandatory ? 'mandatory-only' : '';
  };

  const hendleSubmit = useCallback(() => {
    const data = {
      type: 'legalNeedsPlanning',
      data: fields,
      full_status: validateLegal(),
    };
    dispatch(setClientData({ id, fields: data }));

    history.push(`/dashboard/${id}`);
  }, [history, fields]);
  return (
    <>
      <Title className="onboard-component-title">
        {t('LEGAL_NEEDS_PLANNING')}
      </Title>
      <Container>
        <header className="legal-header">
          <h4 className="legal-header">{t('STRUCTURE_FOR_INVESTMENTS')}</h4>
        </header>
        <p className="legal-lable">{t('WHAT_INVESTMENTS_YOU_CURRENTLY')}</p>
        <TextArea
          type="text"
          name="legalQuestion_01"
          value={fields.legalQuestion_01}
          onChange={changeTextAria}
          maxLength={200}
        />
        <p className="legal-lable">{t('WHAT_OTHER_ASSETS_DO_YOU_CURRENTLY')}</p>
        <TextArea
          type="text"
          name="legalQuestion_02"
          value={fields.legalQuestion_02}
          onChange={changeTextAria}
          maxLength={200}
        />
        <p className="legal-lable">{t('HAVE_YOU_ASSETS_THAT')}</p>
        <TextArea
          type="text"
          name="legalQuestion_03"
          value={fields.legalQuestion_03}
          onChange={changeTextAria}
          maxLength={200}
        />
        <p className="legal-lable">{t('WHAT_ARE_THE_PLANS')}</p>
        <TextArea
          type="text"
          name="legalQuestion_04"
          value={fields.legalQuestion_04}
          onChange={changeTextAria}
          maxLength={200}
        />
        <p className="legal-lable">{t('OTHER_ISSUES_OF_A_LEGAL')}</p>
        <TextArea
          type="text"
          name="legalQuestion_05"
          value={fields.legalQuestion_05}
          onChange={changeTextAria}
          maxLength={200}
        />
        <p className="legal-lable">{t('COMMENT_LEGAL')}</p>
        <TextArea
          type="text"
          name="comment"
          value={fields.comment}
          onChange={changeTextAria}
          maxLength={200}
        />
      </Container>
      <div className="btn-container">
        <MainButtonArray
          style={{ width: 150, height: 40 }}
          type="link"
          arrayIconStart
          onClick={() => history.goBack()}
        >
          {t('GO_BACK')}
        </MainButtonArray>
        <div className="menu-wrapper">
          <Menu
            onClick={() => history.replace(`/dashboard/${id}`)}
            style={{ width: 85 }}
          />
          <p className="menu-title">{t('MY_DETAILS')}</p>
        </div>
        <MainButtonArray
          style={{ width: 150, height: 40 }}
          type="submit"
          onClick={hendleSubmit}
          arrayIconEnd
        >
          {t('MOVE_ON')}
        </MainButtonArray>
      </div>
    </>
  );
};

LegalNeedsPlanning.propTypes = {
  id: PropTypes.string,
};

export default LegalNeedsPlanning;
