import {
  ASSET_REQUEST,
  CLEAR_MODEL_BY_ID,
  CLEAR_PRIMITIVES,
  CLEAR_RECOMMENDATION_MAP_BY_ID,
  CREATE_ASSET_CLASS,
  CREATE_INSTRUMENT,
  CREATE_MODEL,
  CREATE_MODEL_LABEL,
  CREATE_RECOMMENDATION_MAPS,
  DELETE_ASSET_CLASS,
  DELETE_INSTRUMENT,
  DELETE_MODEL_LABEL,
  DELETE_NOTACTIVE_MODEL,
  DELETE_RECOMMENDATION_MAPS,
  DELETE_REQUEST,
  GET_ASSET_CATEGORIES,
  GET_ASSETS,
  GET_CURRENCY,
  GET_DIVERSIFICATION,
  GET_INSTRUMENTS_ALL,
  GET_INSTRUMENTS_TBD,
  GET_MODEL_BY_ID,
  GET_MODEL_CONSTRAINTS,
  GET_MODEL_LABEL,
  GET_MODEL_LIST,
  GET_MODEL_PRIMITIVES,
  GET_MODEL_SERIES,
  GET_RECOMMENDATION_MAP_BY_ID,
  GET_RECOMMENDATION_MAPS,
  MAPPING_REQUEST,
  SET_ACTIVE_MODEL,
  SET_MODEL_CONSTRAINTS,
  SET_MODEL_DATE,
  SET_MODEL_PRIMITIVES,
  SET_MODEL_SERIES,
  TEMP_DOWNLOAD_CONSTRAINTS,
  TEMP_DOWNLOAD_PRIMITIVES,
  UPDATE_ASSET_CLASS,
  UPDATE_FINANCIAL_ASSET,
  UPDATE_INSTRUMENT,
  UPDATE_MODEL_LABEL,
  UPDATE_RECOMMENDATION_MAPS,
  UPLOAD_MODEL_PRIMITIVES,
  UPLOAD_MODEL_SERIES,
} from './instrumentsActionTypes';

export const getInstrumentsAll = (params) => ({
  type: GET_INSTRUMENTS_ALL.ACTION,
  payload: params,
});

export const getInstrumentsTBD = (offset) => ({
  type: GET_INSTRUMENTS_TBD.ACTION,
  payload: offset,
});

export const getAssets = () => ({ type: GET_ASSETS.ACTION });

export const getAssetCategories = () => ({ type: GET_ASSET_CATEGORIES.ACTION });

export const updateFinancialAsset = (data) => ({
  type: UPDATE_FINANCIAL_ASSET.ACTION,
  payload: data,
});

export const updateInstrument = (data) => ({
  type: UPDATE_INSTRUMENT.ACTION,
  payload: data,
});

export const createInstrument = (data) => ({
  type: CREATE_INSTRUMENT.ACTION,
  payload: data,
});

export const deleteInstrument = (data) => ({
  type: DELETE_INSTRUMENT.ACTION,
  payload: data,
});

export const assetRequest = (data) => ({
  type: ASSET_REQUEST.ACTION,
  payload: data,
});

export const createAssetClass = (data) => ({
  type: CREATE_ASSET_CLASS.ACTION,
  payload: data,
});

export const deleteAssetClass = (id) => ({
  type: DELETE_ASSET_CLASS.ACTION,
  payload: id,
});

export const updateAssetClass = (data) => ({
  type: UPDATE_ASSET_CLASS.ACTION,
  payload: data,
});

export const mappingRequest = (data) => ({
  type: MAPPING_REQUEST.ACTION,
  payload: data,
});

export const deleteRequest = (data) => ({
  type: DELETE_REQUEST.ACTION,
  payload: data,
});

export const createModel = (data) => ({
  type: CREATE_MODEL.ACTION,
  payload: data,
});

export const getModelById = (id) => ({
  type: GET_MODEL_BY_ID.ACTION,
  payload: id,
});

export const getModelList = () => ({
  type: GET_MODEL_LIST.ACTION,
});

export const setModelDate = (date) => ({
  type: SET_MODEL_DATE.ACTION,
  payload: date,
});

export const setActiveModel = (id) => ({
  type: SET_ACTIVE_MODEL.ACTION,
  payload: id,
});

export const deleteNotActiveModel = (id) => ({
  type: DELETE_NOTACTIVE_MODEL.ACTION,
  payload: id,
});

export const setModelSeries = (data) => ({
  type: SET_MODEL_SERIES.ACTION,
  payload: data,
});

export const getModelSeries = (data) => ({
  type: GET_MODEL_SERIES.ACTION,
  payload: data,
});

export const getModelPrimitives = (id) => ({
  type: GET_MODEL_PRIMITIVES.ACTION,
  payload: id,
});

export const uploadModelSeries = (data) => ({
  type: UPLOAD_MODEL_SERIES.ACTION,
  payload: data,
});

export const uploadModelPrimitives = (data) => ({
  type: UPLOAD_MODEL_PRIMITIVES.ACTION,
  payload: data,
});

export const clearModel = () => ({
  type: CLEAR_MODEL_BY_ID.ACTION,
});

export const clearPrimitives = () => ({
  type: CLEAR_PRIMITIVES.ACTION,
});

export const setModelPrimitives = (data) => ({
  type: SET_MODEL_PRIMITIVES.ACTION,
  payload: data,
});

export const getModelLabel = (id) => ({
  type: GET_MODEL_LABEL.ACTION,
  payload: id,
});

export const createModelLabel = (data) => ({
  type: CREATE_MODEL_LABEL.ACTION,
  payload: data,
});

export const deleteModelLabel = (data) => ({
  type: DELETE_MODEL_LABEL.ACTION,
  payload: data,
});

export const updateModelLabel = (data) => ({
  type: UPDATE_MODEL_LABEL.ACTION,
  payload: data,
});

export const getModelConstraints = (id) => ({
  type: GET_MODEL_CONSTRAINTS.ACTION,
  payload: id,
});

export const setModelConstraints = (data) => ({
  type: SET_MODEL_CONSTRAINTS.ACTION,
  payload: data,
});

export const tempDownloadConstraints = (data) => ({
  type: TEMP_DOWNLOAD_CONSTRAINTS.ACTION,
  payload: data,
});

export const tempDownloadPrimitives = (data) => ({
  type: TEMP_DOWNLOAD_PRIMITIVES.ACTION,
  payload: data,
});

export const getRecommendationMaps = () => ({
  type: GET_RECOMMENDATION_MAPS.ACTION,
});

export const getRecommendationMapById = (id) => ({
  type: GET_RECOMMENDATION_MAP_BY_ID.ACTION,
  payload: id,
});

export const clearRecommendationMapById = () => ({
  type: CLEAR_RECOMMENDATION_MAP_BY_ID.ACTION,
});

export const createRecommendationMaps = (data) => ({
  type: CREATE_RECOMMENDATION_MAPS.ACTION,
  payload: data,
});

export const updateRecommendationMaps = (data) => ({
  type: UPDATE_RECOMMENDATION_MAPS.ACTION,
  payload: data,
});

export const deleteRecommendationMaps = (id) => ({
  type: DELETE_RECOMMENDATION_MAPS.ACTION,
  payload: id,
});

export const getCurrency = () => ({ type: GET_CURRENCY.ACTION });

export const getDiversification = () => ({ type: GET_DIVERSIFICATION.ACTION });
