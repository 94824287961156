/* eslint-disable camelcase */
import React, { useCallback, useState, useEffect, useMemo } from 'react';

import PropTypes from 'prop-types';
import { v4 as uuidv4 } from 'uuid';

import './style.scss';

import { Form, Row, Col, Modal, Divider, Button, Table, Tooltip } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import {
  getPortfolioById,
  saveFullPortfolio,
} from '../../data/store/portfolio/portfolioActions';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import EditableCellNew from './EditableCellPortfolioNew';
import EditableCellUnlisted from './EditableCellUnlisted';
import { numberWithSpaces } from '../../helpers/formatInteger';
import Container from '../Container';
import { IMPORT_PORTFOLIO } from '../../data/store/portfolio/portfolioActionTypes';
import {
  getCurrency,
  getInstrumentsAll,
} from '../../data/store/instruments/instrumentsActions';
import { twoDecimalWithSpaces } from '../../helpers/formatFractional';
import { decimalSeparator } from '../../helpers/decimalSeparator';
import EditableCellCashes from './EditableCellCashes';
// import { ReactComponent as Pencil } from '../../assets/pencil-edit.svg';
import { ReactComponent as Pluse } from '../../assets/add-icon.svg';
import { ReactComponent as Delete } from '../../assets/delete-black-icon.svg';
import MainFooter from '../MainFooter';
import ModalUnsaveData from '../ModalUnsaveData/ModalUnsaveData';
import { textSort } from '../../helpers/textSort';

export const EditableContext = React.createContext(null);

const EditableRow = ({ ...props }) => {
  const [form] = Form.useForm();
  return (
    <Form form={form} component={false}>
      <EditableContext.Provider value={form}>
        <tr {...props} />
      </EditableContext.Provider>
    </Form>
  );
};

const Portfolio = ({
  userId,
  portfolioId,
  needSave,
  setNeedSave,
  saveAlert,
  setSaveAlert,
}) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const { t } = useTranslation('');

  const [data, setData] = useState([]);
  const [dataUnlisted, setDataUnlisted] = useState([]);
  const [dataCashes, setDataCashes] = useState([]);

  const errorDescription = {
    INVALID_ISIN: 'Invalid ISIN',
    INVALID_UNIT: 'Invalid unit',
    INVALID_SHAED_PRICE: 'Invalid shared price',
    INVALID_VALUE: 'Invalid value',
    INVALID_ASSET_CLASS: 'Invalid asset class',
    IMPORT_ERROR_ROW_NOT_FULL: 'Import error. Row not full',
  };
  const [isErrorImportVisible, setIsErrorImportVisible] = useState(false);
  const [importError, setImportError] = useState(null);
  const [modalCancelVisible, setModalCancelVisible] = useState(false);

  const [editingKey, setEditingKey] = useState('');
  const isEditing = (record) => record.key === editingKey;
  const [editingKeyUnlisted, setEditingKeyUnlisted] = useState('');
  const isEditingUnlisted = (record) => record.key === editingKeyUnlisted;
  const [editingKeyCashes, setEditingKeyCashes] = useState('');
  const isEditingCashes = (record) => record.key === editingKeyCashes;

  const [tableColumns, setTableColumns] = useState([]);
  const [tableColumnsUnlisted, setTableColumnsUnlisted] = useState([]);
  const [tableColumnsCashes, setTableColumnsCashes] = useState([]);

  const { portfolioById, tempInstruments, portfolioById: { is_locked } } = useSelector(
    (state) => state.portfolioReducer
  );
  const instrumentsList = useSelector(
    (state) => state.instrumentsReducer?.withoutCashes?.list
  );
  const cashesList = useSelector(
    (state) => state.instrumentsReducer.cashes.list
  );
  const { currencyList } = useSelector((state) => state.instrumentsReducer);

  useEffect(() => {
    setSaveAlert({ flag: false, path: '' });
    dispatch(getInstrumentsAll({ limit: -1, offset: 0, type: 'cashes-only' }));
    dispatch(
      getInstrumentsAll({ limit: -1, offset: 0, type: 'without-cashes' })
    );
    dispatch(getCurrency());
  }, []);

  const getName = (id) => {
    return cashesList.find(i => i.ID === id)?.name;
  };

  useEffect(() => {
    saveAlert && saveAlert.flag && setModalCancelVisible(true);
  }, [saveAlert]);

  const importPortfolio = useCallback(() => {
    const initialValues = tempInstruments?.listed
      ?.filter((i) => !i.error || i?.error?.codes.includes('INVALID_ISIN'))
      ?.map((portfolioRow) => ({
        key: uuidv4(),
        instrument_name: portfolioRow?.data?.name,
        isin: !portfolioRow.error ? portfolioRow?.data?.isin : 'N/A',
        units_number: portfolioRow?.data?.unitsNumber,
        latest_value_sek: portfolioRow?.data?.latest_value_sek,
        latest_value_time: portfolioRow?.data?.latest_value_time,
        value: portfolioRow?.data?.value,
        currency_id: 1,
        fee: portfolioRow?.data?.fee,
        asset_class: portfolioRow?.data?.asset_class || 'TBD',
        financial_instrument_id: portfolioRow?.data?.instrument_id,
        financial_asset_id: portfolioRow?.data?.financial_asset_id,
        financial_asset_category_id: portfolioRow?.data?.category_id,
      }));
    setData(initialValues);

    const errorImportListed = tempInstruments?.listed
      ?.filter((i) => i.error && !i.error?.codes.includes('INVALID_ISIN'))
      ?.map((i) => ({
        key: uuidv4(),
        name: i?.data?.name,
        isin: i?.data?.isin,
        errors: i?.error.codes,
      }));

    const initialValuesUnlisted = tempInstruments?.unlisted
      ?.filter((i) => !i.error)
      ?.map((item) => ({
        key: uuidv4(),
        name: item?.data?.name,
        units_number: item?.data?.units_number,
        latest_value_sek: item?.data?.latest_value_sek,
        latest_value_time: item?.data?.latest_value_time,
        value: item?.data?.value,
        financia_asset_id: item?.data?.asset_class_id.toString(),
      }));
    setDataUnlisted(initialValuesUnlisted);

    const errorImportUnlisted = tempInstruments?.unlisted
      ?.filter((i) => i.error)
      ?.map((i) => ({
        key: uuidv4(),
        name: i?.data?.name,
        isin: 'N/A',
        errors: i?.error.codes,
      }));

    const initialValuesCashes = tempInstruments?.cashes
      ?.filter((i) => !i.error)
      ?.map((item) => ({
        key: uuidv4(),
        name: getName(item?.data?.instrument_id),
        instrument_id: item?.data?.instrument_id,
        currency_id: item?.data?.currency_id,
        amount: item?.data?.amount,
      }));
    setDataCashes(initialValuesCashes);

    const errorImportCashes = tempInstruments?.cashes
      ?.filter((i) => i.error)
      ?.map((i) => ({
        key: uuidv4(),
        name: `amount: ${(i?.data?.amount || 0)} currency: ${i?.data?.currency || '?'}`,
        errors: i?.error.codes,
      }));
    if (errorImportListed?.length || errorImportUnlisted?.length || errorImportCashes?.length) {
      setImportError({
        listed: errorImportListed?.length ? errorImportListed : null,
        unlisted: errorImportUnlisted?.length ? errorImportUnlisted : null,
        cashes: errorImportCashes?.length ? errorImportCashes : null,
      });
      setIsErrorImportVisible(true);
    }
  }, [tempInstruments]);

  const getRate = (cashCurency) => {
    const rate = currencyList?.find((i) => i.id === cashCurency)?.rate;
    return userId ? rate : '0';
  };

  const resetInitialValues = useCallback(() => {
    const initialValues = [...portfolioById.portfolio_content].map(
      (portfolioRow) => ({
        key: portfolioRow.id,
        id: portfolioRow.id,
        instrument_name: portfolioRow.instrument_name,
        isin:
          portfolioRow.isin === 'NOT_APPLICABLE' ? 'N/A' : portfolioRow.isin,
        units_number: portfolioRow.units_number || 0,
        latest_value_sek: Math.round(portfolioRow.latest_value_sek * 100) / 100,
        latest_value_time: portfolioRow?.latest_value_time || '',
        value: portfolioRow.value || 0,
        currency_id: portfolioRow.currency_id,
        fee: portfolioRow.fee,
        asset_class: portfolioRow?.financial_asset?.name || 'TBD',
        financial_instrument_id: portfolioRow?.financial_instrument_id || null,
        financial_asset_id: portfolioRow?.financial_asset_id || null,
        financial_asset_category_id:
          portfolioRow?.financial_asset_category_id || null,
      })
    );
    setData(initialValues?.sort((a, b) => b.value - a.value));
    const getName = (id) => {
      return cashesList.find((i) => i.ID === id)?.name;
    };
    const initialValuesUnlisted = portfolioById?.unlisted_content
      ? [...portfolioById.unlisted_content].map((item) => ({
          key: item.id,
          id: item.id,
          name: item.name,
          units_number: item.units_number || 0,
          latest_value_sek: item.latest_value_sek || 0,
          latest_value_time: item.latest_value_time || '',
          value: item.value || 0,
          financial_asset_id: item?.financial_asset_id?.toString(),
        }))
      : [];
    setDataUnlisted(initialValuesUnlisted?.sort((a, b) => b.value - a.value));

    const initialValuesCashes = portfolioById?.portfolio_cashes
      ? [...portfolioById.portfolio_cashes].map((item) => ({
          key: item.id,
          id: item.id,
          name: getName(item.instrument_id),
          instrument_id: item.instrument_id,
          currency_id: item.currency_id,
          amount: item.amount,
          totalAmount: Math.round(item.amount * getRate(item.currency_id) * 100) / 100
        }))
      : [];
    setDataCashes(initialValuesCashes?.sort((a, b) => b.amount - a.amount));
  }, [portfolioById, portfolioId, cashesList]);

  useEffect(() => {
    if (
      portfolioById &&
      Object.keys(portfolioById)?.length &&
      portfolioId &&
      !tempInstruments
    ) {
      resetInitialValues();
    } else if (tempInstruments) {
      importPortfolio();
    }
  }, [portfolioById, portfolioId, tempInstruments, cashesList]);

  const getPrice = (id) => {
    const price = instrumentsList.find((i) => i.ID === id)?.latest_value_sek;
    return id ? Math.round(price * 100) / 100 : 0;
  };

  useEffect(() => {
    const columns = [
      {
        title: t('INSTRUMENT'),
        dataIndex: 'instrument_name',
        width: '30%',
        editable: true,
        sorter: (a, b) => textSort(a.instrument_name, b.instrument_name),
      },
      {
        title: t('ISIN'),
        dataIndex: 'isin',
        width: '8%',
        sorter: (a, b) => textSort(a.isin, b.isin),
      },
      {
        title: t('UNITS'),
        dataIndex: 'units_number',
        width: '11%',
        editable: true,
        render: (value) => {
          return numberWithSpaces(value);
        },
        sorter: (a, b) => a.units_number - b.units_number,
        sortDirections: ['descend', 'ascend'],
      },
      {
        title: t('SHARE_PRICE'),
        dataIndex: 'latest_value_sek',
        width: '11%',
        editable: true,
        render: (value, record) => {
          return value
            ? `${twoDecimalWithSpaces(value)} kr`
            : `${twoDecimalWithSpaces(getPrice(record?.financial_instrument_id))} kr`;
        },
        sorter: (a, b) => a.latest_value_sek - b.latest_value_sek,
        sortDirections: ['descend', 'ascend'],
      },
      {
        title: t('VALUE'),
        dataIndex: 'value',
        width: '11%',
        render: (value) => {
          return `${twoDecimalWithSpaces(value)} kr`;
        },
        sorter: (a, b) => a.value - b.value,
        sortDirections: ['descend', 'ascend'],
      },
      {
        title: t('FEE'),
        dataIndex: 'fee',
        width: '7%',
        render: (value) => {
          return `${value?.toString()?.replace('.', decimalSeparator())} %`;
        },
        sorter: (a, b) => a.fee - b.fee,
        sortDirections: ['descend', 'ascend'],
      },
      {
        title: t('ASSET_CLASS'),
        dataIndex: 'asset_class',
        width: '20%',
        render: (value) => {
          if (value !== 'TBD') {
            return value;
          }
          return '- - -';
        },
        sorter: (a, b) => textSort(a.asset_class, b.asset_class),
      },
      {
        title: '',
        dataIndex: 'operation',
        width: '2%',
        // eslint-disable-next-line react/display-name
        render: (_, record) =>
          data.length >= 1
            ? (
            <Button
              icon={<Delete />}
              disabled={is_locked || portfolioById?.status === 'ACTIVE_DEPRECATED'}
              type="text"
              onClick={() => handleDelete(record.key)}
            />
              )
            : null,
      },
    ];
    const getAssetClass = (value) => {
      const classObj = {
        16: 'Forest',
        7: 'Private Company',
        8: 'Private Equity/Debt',
        15: 'Real Estate',
      };
      return classObj[value];
    };
    const columnsUnlisted = [
      {
        title: t('INSTRUMENT'),
        dataIndex: 'name',
        width: '30%',
        editable: true,
        sorter: (a, b) => textSort(a.name, b.name),
      },
      {
        title: t('UNITS'),
        dataIndex: 'units_number',
        width: '15%',
        editable: true,
        render: (value) => {
          return numberWithSpaces(value);
        },
        sorter: (a, b) => a.units_number - b.units_number,
        sortDirections: ['descend', 'ascend'],
      },
      {
        title: t('SHARE_PRICE'),
        dataIndex: 'latest_value_sek',
        width: '15%',
        editable: true,
        render: (value) => {
          return `${twoDecimalWithSpaces(value)} kr`;
        },
        sorter: (a, b) => a.latest_value_sek - b.latest_value_sek,
        sortDirections: ['descend', 'ascend'],
      },
      {
        title: t('VALUE'),
        dataIndex: 'value',
        width: '15%',
        editable: false,
        render: (value) => {
          return `${twoDecimalWithSpaces(value)} kr`;
        },
        sorter: (a, b) => a.value - b.value,
        sortDirections: ['descend', 'ascend'],
      },
      {
        title: t('ASSET_CLASS'),
        dataIndex: 'financial_asset_id',
        width: '23%',
        editable: true,
        render: (value) => {
          return getAssetClass(value);
        },
        sorter: (a, b) => textSort(a.financial_asset_id, b.financial_asset_id),
      },
      {
        title: '',
        dataIndex: 'operation',
        width: '2%',
        // eslint-disable-next-line react/display-name
        render: (_, record) =>
          dataUnlisted.length >= 1
            ? (
            <Button
              disabled={is_locked || portfolioById?.status === 'ACTIVE_DEPRECATED'}
              icon={<Delete />}
              type="text"
              onClick={() => handleDeleteUnlisted(record.key)}
            />
              )
            : null,
      },
    ];

    const columnsCashes = [
      {
        title: t('CURRENCY'),
        dataIndex: 'name',
        width: '30%',
        editable: true,
        sorter: (a, b) => textSort(a.name, b.name),
      },
      {
        title: t('AMOUNT'),
        dataIndex: 'amount',
        width: '15%',
        editable: true,
        render: (value) => {
          return twoDecimalWithSpaces(value);
        },
        sorter: (a, b) => a.amount - b.amount,
        sortDirections: ['descend', 'ascend'],
      },
      {
        title: t('FX_RATE'),
        dataIndex: 'rate',
        width: '15%',
        editable: false,
        render: (_, record) => {
          return record.currency_id ? `${getRate(record.currency_id)} kr` : '';
        },
        sorter: (a, b) => getRate(a.currency_id) - getRate(b.currency_id),
        sortDirections: ['descend', 'ascend'],
      },
      {
        title: t('AMOUNT') + ' kr',
        dataIndex: 'totalAmount',
        width: '15%',
        editable: false,
        render: (value, record) => {
          return `${twoDecimalWithSpaces(record.amount * getRate(record.currency_id))} kr`;
        },
        sorter: (a, b) => a.totalAmount - b.totalAmount,
        sortDirections: ['descend', 'ascend'],
      },
      {
        title: t('ASSET_CLASS'),
        dataIndex: 'financial_asset',
        width: '23%',
        editable: false,
        render: () => {
          return 'Liquidity';
        },
        sorter: (a, b) => textSort(a.financial_asset, b.financial_asset),
      },
      {
        title: '',
        dataIndex: 'operation',
        width: '2%',
        // eslint-disable-next-line react/display-name
        render: (_, record) =>
          dataCashes.length >= 1
            ? (
            <Button
              icon={<Delete />}
              disabled={is_locked || portfolioById?.status === 'ACTIVE_DEPRECATED'}
              type="text"
              onClick={() => handleDeleteCashes(record.key)}
            />
              )
            : null,
      },
    ];
    setTableColumns(columns);
    setTableColumnsUnlisted(columnsUnlisted);
    setTableColumnsCashes(columnsCashes);
    setNeedSave(hasChanges());
  }, [portfolioById, portfolioId, data, dataUnlisted, dataCashes]);

  const handleDelete = (key) => {
    setData(data.filter((item) => item.key !== key));
  };

  const handleDeleteUnlisted = (key) => {
    setDataUnlisted(dataUnlisted.filter((item) => item.key !== key));
  };

  const handleDeleteCashes = (key) => {
    setDataCashes(dataCashes.filter((item) => item.key !== key));
  };

  const handleAdd = () => {
    const newData = {
      key: Date.now(),
      instrument_name: '',
      isin: 'N/A',
      units_number: 0,
      latest_value_sek: 0,
      value: 0,
      fee: 0,
      asset_class: 'TBD',
      financial_instrument_id: null,
      financial_asset_id: null,
      financial_asset_category_id: null,
    };

    data.length ? setData([...data, newData]) : setData([newData]);
    setEditingKey(newData.key);
  };

  const handleAddUnlisted = () => {
    const newData = {
      key: Date.now(),
      name: '',
      units_number: 0,
      latest_value_sek: 0,
      value: 0,
      financial_asset_id: '',
    };

    dataUnlisted.length
      ? setDataUnlisted([...dataUnlisted, newData])
      : setDataUnlisted([newData]);
    setEditingKeyUnlisted(newData.key);
  };

  const handleAddCashes = () => {
    const newData = {
      key: Date.now(),
      instrument_id: null,
      currency_id: null,
      name: '',
      amount: 0,
      rate: 0,
      totalAmount: 0,
      financia_asset: '',
    };

    dataCashes.length
      ? setDataCashes([...dataCashes, newData])
      : setDataCashes([newData]);
    setEditingKeyCashes(newData.key);
  };

  const handleSave = (row) => {
    const newData = data.map((item) => (row.key === item.key ? row : item));
    setData(newData);
  };

  const handleSaveUnlisted = (row) => {
    const newData = dataUnlisted.map((item) =>
      row.key === item.key ? row : item
    );
    setDataUnlisted(newData);
  };

  const handleSaveCashes = (row) => {
    const newData = dataCashes.map((item) =>
      row.key === item.key ? row : item
    );
    setDataCashes(newData);
  };

  const components = {
    body: {
      row: EditableRow,
      cell: EditableCellNew,
    },
  };

  const componentsUnlisted = {
    body: {
      row: EditableRow,
      cell: EditableCellUnlisted,
    },
  };
  const componentsCashes = {
    body: {
      row: EditableRow,
      cell: EditableCellCashes,
    },
  };

  const columnsData = tableColumns.map((col) => {
    if (!col?.editable) {
      return {
        ...col,
        onCell: (record) => ({
          decimalSeparator: decimalSeparator(),
          setTarget: () => (!is_locked && portfolioById?.status !== 'ACTIVE_DEPRECATED') && setEditingKey(editingKey === record.key ? '' : record.key),
        }),
      };
    }
    return {
      ...col,
      onCell: (record) => ({
        record,
        dataIndex: col.dataIndex,
        title: col.title,
        handleSave,
        data,
        setTarget: () => (!is_locked && portfolioById?.status !== 'ACTIVE_DEPRECATED') && setEditingKey(editingKey === record.key ? '' : record.key),
        decimalSeparator: decimalSeparator(),
        editing: isEditing(record),
      }),
    };
  });
  const columnsDataUnlisted = tableColumnsUnlisted.map((col) => {
    if (!col?.editable) {
      return {
        ...col,
        onCell: (record) => ({
          setTarget: () => (!is_locked && portfolioById?.status !== 'ACTIVE_DEPRECATED') &&
            setEditingKeyUnlisted(
              editingKeyUnlisted === record.key ? '' : record.key
            ),
        }),
      };
    }
    return {
      ...col,
      onCell: (record) => ({
        record,
        dataIndex: col.dataIndex,
        title: col.title,
        handleSave: handleSaveUnlisted,
        data,
        setTarget: () => (!is_locked && portfolioById?.status !== 'ACTIVE_DEPRECATED') &&
          setEditingKeyUnlisted(
            editingKeyUnlisted === record.key ? '' : record.key
          ),
        editing: isEditingUnlisted(record),
      }),
    };
  });
  const columnsDataCashes = tableColumnsCashes.map((col) => {
    if (!col?.editable) {
      return {
        ...col,
        currencyList,
        onCell: (record) => ({
          setTarget: () => (!is_locked && portfolioById?.status !== 'ACTIVE_DEPRECATED') &&
            setEditingKeyCashes(
              editingKeyCashes === record.key ? '' : record.key
            ),
        }),
      };
    }
    return {
      ...col,
      onCell: (record) => ({
        record,
        dataIndex: col.dataIndex,
        title: col.title,
        handleSave: handleSaveCashes,
        dataCashes,
        currencyList,
        setTarget: () => (!is_locked && portfolioById?.status !== 'ACTIVE_DEPRECATED') &&
          setEditingKeyCashes(
            editingKeyCashes === record.key ? '' : record.key
          ),
        editing: isEditingCashes(record),
      }),
    };
  });

  const onSave = () => {
    setEditingKey('');
    setEditingKeyUnlisted('');
    setEditingKeyCashes('');
    if (
      (data && data.length) ||
      (dataUnlisted && dataUnlisted.length) ||
      (dataCashes && dataCashes.length)
    ) {
      const validListed =
        data?.filter(
          (i) =>
            !(
              !i.units_number ||
              !i.latest_value_sek ||
              !i.instrument_name
            )
        ) || [];
      const validUnlisted =
        dataUnlisted?.filter(
          (i) =>
            !(
              !i.units_number ||
              !i.latest_value_sek ||
              !i.name ||
              !i.financial_asset_id
            )
        ) || [];
      const validCashes =
        dataCashes?.filter((i) => !(!i.name || !i.amount)) || [];
      const newData = validListed.map((el) => {
        return {
          ...el,
          isin: el.isin === 'N/A' ? 'NOT_APPLICABLE' : el.isin,
          currency_id: el?.currency_id || 1,
          latest_value_sek: Number(el.latest_value_sek),
          value: Number(el.latest_value_sek) * Number(el.units_number),
          units_number: Number(el.units_number),
          fee: Number(el.fee),
        };
      });
      const newDataUnlisted = validUnlisted.map((el) => {
        return {
          ...el,
          name: el.name,
          units_number: Number(el.units_number),
          currency_id: 1,
          latest_value_sek: Number(el.latest_value_sek),
          value: Number(el.latest_value_sek) * Number(el.units_Number),
          financial_asset_id: Number(el.financial_asset_id),
        };
      });
      const newDataCashes = validCashes.map((el) => {
        const newData = {
          name: el.name,
          amount: el.amount,
          currency_id: el.currency_id,
          instrument_id: el.instrument_id,
        };
        if (el?.ID) {
          newData.id = el.ID;
        }
        return newData;
      });

      const dataNew = {
        account_id: Number(userId),
        replaceExisted: true,
        content: newData,
        cashes: newDataCashes,
        unlisted_content: newDataUnlisted,
      };

      saveCurrentPortfolio(dataNew, true);
    }
  };

  const saveCurrentPortfolio = (data) => {
    dispatch(saveFullPortfolio({ data, history, userId, portfolioId, path: saveAlert.path }));
  };

  const isNeedSaveListed = () => {
    const validListed = data?.filter(
      (i) =>
        !(!i.units_number || !i.latest_value_sek || !i.instrument_name)
    );
    let isNeed = !!validListed?.length;
    const diffListed = isNeed
      ? validListed.map((i) => {
        const oldInstrument = i?.id
          ? portfolioById?.portfolio_content.find((el) => el.id === i.id)
          : i;
        if (oldInstrument?.id) {
          return (
            i?.financial_instrument_id ===
                oldInstrument.financial_instrument_id &&
              i?.instrument_name === oldInstrument.instrument_name &&
              i?.units_number === oldInstrument.units_number &&
              i?.latest_value_sek ===
                Math.round(oldInstrument.latest_value_sek * 100) / 100
          );
        } else {
          return false;
        }
      })
      : [true];
    isNeed = !diffListed.every((i) => !!i);
    return isNeed;
  };

  const isNeedSaveUnlisted = () => {
    const validUnlisted = dataUnlisted?.filter(
      (i) => !(!i.units_number || !i.latest_value_sek || !i.name)
    );
    let isNeed = !!validUnlisted?.length;
    const diffListed = isNeed
      ? validUnlisted.map((i) => {
        const oldInstrument = i?.id
          ? portfolioById?.unlisted_content.find((el) => el.id === i.id)
          : i;
        if (oldInstrument?.id) {
          return (
            +i?.financial_asset_id === oldInstrument.financial_asset_id &&
              i?.name === oldInstrument.name &&
              i?.units_number === oldInstrument.units_number &&
              i?.latest_value_sek === oldInstrument.latest_value_sek
          );
        } else {
          return false;
        }
      })
      : [true];
    isNeed = !diffListed.every((i) => !!i);
    return isNeed;
  };

  const isNeedSaveCashes = () => {
    const validCashes = dataCashes?.filter((i) => !(!i.name || !i.amount));
    let isNeed = !!validCashes?.length;
    const diffListed = isNeed
      ? validCashes.map((i) => {
        const oldInstrument = i?.id
          ? portfolioById?.portfolio_cashes.find((el) => el.id === i.id)
          : i;
        if (oldInstrument?.id) {
          return (
            i?.currency_id === oldInstrument.currency_id &&
              i?.amount === oldInstrument.amount
          );
        } else {
          return false;
        }
      })
      : [true];
    isNeed = !diffListed.every((i) => !!i);
    return isNeed;
  };

  function hasChanges () {
    if (
      data?.length < portfolioById?.portfolio_content?.length ||
      dataUnlisted?.length < portfolioById?.unlisted_content?.length ||
      dataCashes?.length < portfolioById?.portfolio_cashes?.length ||
      isNeedSaveListed() ||
      isNeedSaveCashes() ||
      isNeedSaveUnlisted()
    ) {
      return true;
    } else {
      return false;
    }
  }

  const closeModal = () => {
    setModalCancelVisible(false);
  };

  const cancelWithoutChange = () => {
    const path = saveAlert.path;
    setNeedSave(false);
    setSaveAlert({ flag: false, path: '' });
    dispatch(getPortfolioById({ portfolioId }));
    setEditingKey('');
    setEditingKeyUnlisted('');
    setEditingKeyCashes('');
    closeModal();
    history.push(path);
  };

  const confirmEditing = () => {
    if (portfolioById?.tempInstruments) {
      dispatch({ type: IMPORT_PORTFOLIO.SUCCESS, payload: null });
      onSave();
    } else {
      onSave();
    }
    setEditingKey('');
    setEditingKeyUnlisted('');
    setEditingKeyCashes('');
    setNeedSave(false);
    setSaveAlert({ flag: false, path: '' });
    closeModal();
  };

  const handleReset = () => {
    resetInitialValues();
    setNeedSave(false);
    setSaveAlert({ flag: false, path: '' });
    setEditingKey('');
    setEditingKeyUnlisted('');
    setEditingKeyCashes('');
  };

  const total = useMemo(() => {
    function rate (cashCurency = 1) {
      return currencyList?.find((i) => i.id === cashCurency)?.rate || 0;
    }
    const totalValueCash = dataCashes
      .reduce((acc, cur) => acc + (cur?.amount || 0) * rate(cur?.currency_id), 0);

    const totalValueListed = data
      .reduce((acc, cur) => acc + cur?.value, 0);

    const totalValueUnlisted = dataUnlisted
      .reduce((acc, cur) => acc + cur?.value, 0);

    return { cash: twoDecimalWithSpaces(totalValueCash), listed: twoDecimalWithSpaces(totalValueListed), unlisted: twoDecimalWithSpaces(totalValueUnlisted) };
  }, [data, dataUnlisted, dataCashes, currencyList]);

  return (
    <>
      <div className="top-btn-wrapper">
        <Button
          // disabled={!needSave}
          className="portfolio-details-btn portfolio-export-btn"
          onClick={handleReset}
        >
          {t('CANCEL')}
        </Button>
        <Tooltip
            placement="top"
            title={
              is_locked || portfolioById?.status === 'ACTIVE_DEPRECATED'
                ? <span style={{ color: '#000000' }}>{t('NOT_AVAILABLE_FOR_EDITING')}</span>
                : null
            }
            color='white'
          >
            <Button
              disabled={!needSave || is_locked || portfolioById?.status === 'ACTIVE_DEPRECATED'}
              onClick={onSave}
              className="portfolio-details-btn btn-edit-save"
            >
              {t('SAVE')}
            </Button>
          </Tooltip>
      </div>
      <div className="portfolio-title-wrapper">
        <h2 className="portfolio-container-title">{t('HOLDINGS')}</h2>
      </div>
      <div style={{ padding: '0 24px' }} className='table-inner-wrapper'>
        <Container widthP={100} mb='24' padding='0'>
          <Row
            justify={'space-between'}
            align={'middle'}
            style={{ marginBottom: '18px', padding: '16px 40px' }}>
            <Col>
              <h2 className="portfolio-header-title">{t('CASH')}</h2>
            </Col>
            <Col >
              <span className='holdings-text'>{t('TOTAL')}</span>
              <span className='holdings-value'>{total?.cash ?? '0.00'} kr</span>
            </Col>
          </Row>
          <Table
            components={componentsCashes}
            rowClassName={(_, index) =>
              index % 2 === 0 ? 'editable-row' : 'editable-row is-odd'
            }
            bordered
            dataSource={dataCashes}
            columns={columnsDataCashes}
            pagination={false}
          />
          <Row
            style={{ padding: '0 24px 36px' }}
            justify={'end'}
          >
              <Button
                type='text'
                disabled={is_locked || portfolioById?.status === 'ACTIVE_DEPRECATED'}
                className="holdings-add-btn"
                onClick={handleAddCashes}
                icon={<Pluse />}
                iconPosition={'end'}
              >{t('ADD')}</Button>
          </Row>
        </Container>
        <Container widthP={100} mb='24' padding='0'>
          <Row
            justify={'space-between'}
            align={'middle'}
            style={{ marginBottom: '18px', padding: '16px 40px' }}>
            <Col>
              <h2 className="portfolio-header-title">{t('LISTED_HOLDINGS')}</h2>
            </Col>
            <Col >
              <span className='holdings-text'>{t('TOTAL')}</span><span className='holdings-value'>{total?.listed ?? '0.00'} kr</span>
            </Col>
          </Row>
          <Table
            components={components}
            rowClassName={(_, index) =>
              index % 2 === 0 ? 'editable-row' : 'editable-row is-odd'
            }
            bordered
            dataSource={data}
            columns={columnsData}
            pagination={false}
          />
          <Row
            style={{ padding: '0 24px 36px' }}
            justify={'end'}>
              <Button
                type='text'
                className="holdings-add-btn"
                disabled={is_locked || portfolioById?.status === 'ACTIVE_DEPRECATED'}
                onClick={handleAdd}
                icon={<Pluse />}
                iconPosition={'end'}
              >{t('ADD')}</Button>
          </Row>
        </Container>
        <Container widthP={100} mb='24px' padding='0'>
          <Row justify={'space-between'}
            align={'middle'}
            style={{ marginBottom: '18px', padding: '16px 40px' }}>
            <Col>
              <h2 className="portfolio-header-title">{t('UNLISTED_HOLDINGS')}</h2>
            </Col>
            <Col >
              <span className='holdings-text'>{t('TOTAL')}</span><span className='holdings-value'>{total?.unlisted ?? '0.00'} kr</span>
            </Col>
          </Row>
          <Table
            components={componentsUnlisted}
            rowClassName={(_, index) =>
              index % 2 === 0 ? 'editable-row' : 'editable-row is-odd'
            }
            bordered
            dataSource={dataUnlisted}
            columns={columnsDataUnlisted}
            pagination={false}
          />
          <Row
            style={{ padding: '0 24px 36px' }}
            justify={'end'}>
              <Button
                type='text'
                className="holdings-add-btn"
                disabled={is_locked || portfolioById?.status === 'ACTIVE_DEPRECATED'}
                onClick={handleAddUnlisted}
                icon={<Pluse />}
                iconPosition={'end'}
              >{t('ADD')}</Button>
          </Row>
        </Container>
      </div>
      <Modal
        title={t('FAILED_TO_IMPORT')}
        centered
        open={isErrorImportVisible}
        onCancel={() => setIsErrorImportVisible(false)}
        footer={false}
      >
        {importError?.listed && (
          <h4 className="portfolio-container-title">{t('LISTED_HOLDINGS')}:</h4>
        )}
        {importError &&
          importError?.listed?.map((item, index) => (
            <div key={uuidv4()}>
              <p className="import-error-text">
                {index + 1}) {item.name} - - {item?.isin}
              </p>
              {item?.errors?.map((i) => (
                <p key={uuidv4()} className="import-error-massage">
                  message: {errorDescription[i]}
                </p>
              ))}
            </div>
          ))}
        <Divider />
        {importError?.unlisted && (
          <h4 className="portfolio-container-title">
            {t('UNLISTED_HOLDINGS')}:
          </h4>
        )}
        {importError &&
          importError?.unlisted?.map((item, index) => (
            <div key={uuidv4()}>
              <p className="import-error-text">
                {index + 1}) {item.name} - - {item?.isin}
              </p>
              {item?.errors?.map((i) => (
                <p key={uuidv4()} className="import-error-massage">
                  message: {errorDescription[i]}
                </p>
              ))}
            </div>
          ))}
      </Modal>
      <ModalUnsaveData
        open={modalCancelVisible}
        closeModal={closeModal}
        cancelWithoutChange={cancelWithoutChange}
        onSubmit={confirmEditing}
      />

      <MainFooter />
    </>
  );
};

export default Portfolio;

Portfolio.propTypes = {
  userId: PropTypes.string,
  portfolioId: PropTypes.string,
  needSave: PropTypes.bool,
  setNeedSave: PropTypes.func,
  saveAlert: PropTypes.object,
  setSaveAlert: PropTypes.func,
};
EditableRow.propTypes = {
  index: PropTypes.number,
};
