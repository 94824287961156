/* eslint-disable no-unused-vars */
/* eslint-disable camelcase */
import React, { useEffect, useState } from 'react';

import { useHistory } from 'react-router';
import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';

import { createClient } from '../../data/store/account/accountActions';
import { Form, Input, Row, Col, Layout, Button, Divider } from 'antd';
import { emailRules, nameRules } from '../../helpers/validation';

import Container from '../../components/Container';

import './style.scss';
import CustomPhoneInput from '../../components/CustomPhoneInput/CustomPhoneInput';

const { Header } = Layout;

const CreateClientAccount = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const { t } = useTranslation('');
  const [form] = Form.useForm();
  const [isFormValid, setIsFormValid] = useState(false);

  const validateForm = () => {
    const hasErrors = form
      .getFieldsError()
      .some((field) => field.errors.length > 0);
    setIsFormValid(!hasErrors);
  };

  useEffect(() => {
    validateForm();
  }, []);

  const handleCancel = () => {
    history.goBack();
  };

  const handleSubmit = () => {
    form.submit();
  };

  const onSubmit = (fields) => {
    // const { phone, country_code, ...rest } = fields;
    console.log('🚀 ~ onSubmit ~ fields:', fields);
    // dispatch(createClientToAcco({ client, history }));
  };

  return (
    <div className="crete-client-account">
      <Header className="headerStyle">
        <Row className="overview-tabs-wrapper">
          <Col className="tabs-tab-wrapper" span={18}>
            <Button type="link" className="overview-btn ant-btn-active">
              {t('CLIENT_CREATION')}
            </Button>
          </Col>
          <Col className="tabs-btn-wrapper" span={6}>
            <Button type="default" className="edit-btn" onClick={handleCancel}>
              {t('CANCEL')}
            </Button>
            <Button
              type="link"
              className="optimise-btn"
              onClick={handleSubmit}
              disabled={!isFormValid}
            >
              {t('REGISTER')}
            </Button>
          </Col>
        </Row>
      </Header>
      <div className="portfolio-title-wrapper">
        <h2 className="portfolio-container-title">{t('CREATE_CLIENT_ACCOUNT')}</h2>
      </div>
      <div className='new-client-form-wrapper'>
        <Container widthP={100}>
          <Form
            form={form}
            name="horizontal_login"
            layout="vertical"
            className="new-client-form"
            onFieldsChange={() => validateForm()}
            onFinish={onSubmit}
          >
            <div className="new-client-wrapper">
            <Row gutter={48}>
            <Col>
              <Form.Item
                label={t('FIRST_NAME')}
                name="firstName"
                rules={nameRules}
                required={false}
              >
                <Input
                  placeholder={t('FIRST_NAME')}
                  className='custom-input'
                />
              </Form.Item>
            </Col>
            <Col>
              <Form.Item
                label={t('LAST_NAME')}
                name="lastName"
                rules={nameRules}
                required={false}
              >
                <Input
                  placeholder={t('LAST_NAME')}
                  className='custom-input'
                />
              </Form.Item>
            </Col>
            </Row>
            <Divider />
            <Row gutter={48}>
            <Col>
              <Form.Item
                label={t('EMAIL')}
                name="email"
                rules={emailRules}
                required={false}
              >
                <Input
                placeholder={t('EMAIL')}
                className='custom-input'
                />
              </Form.Item>
            </Col>
            <Col>
              <Form.Item
                label={t('MOBILE')}
                name="phone"
                rules={[{
                  required: true,
                  message: `${t('ENTER_PHONE_NUMBER')}`,
                }]}
                required={false}
              >
                <CustomPhoneInput
                  placeholder={'0343423431'}
                  nameCode='country_code'
                />
              </Form.Item>
            </Col>
            </Row>
            </div>
          </Form>
        </Container>
      </div>
    </div>
  );
};

export default CreateClientAccount;
